import React from 'react';
import {View} from 'react-native';
import Text from '../../../components/Text';
import {
  FilterArguments,
} from '../alertsReducer';
import {makeId} from '../../../utils';
import Badge from '../../../components/Badge';
import HStack from '../../../components/HStack';


export default function ActiveFiltersSection(props: {
    filterArgs: FilterArguments;
    units: Array<{name: string; id: string}>;
  }) {
    const items: Array<{label: string | any; key: string}> = [];
  
    for (const key in props?.filterArgs) {
      if (Object.prototype.hasOwnProperty.call(props?.filterArgs, key)) {
        let label = props?.filterArgs[key];
        if (label !== null && label !== '') {
          if (key === 'unit') {
            label = props?.units.find(unit => unit.id === label).name;
          }
          if (key === 'startDate' || key === 'endDate') {
            label = new Date(label).toLocaleString();
          }
          items.push({label, key});
        }
      }
    }
  
    if (items.length > 0) {
      return (
        <View
          style={{
            marginHorizontal: 12,
          }}>
          <Text>Active Filters :</Text>
          <HStack display="flex" flexWrap="wrap">
            {items.map(item => (
              <Badge
                borderRadius={6}
                key={makeId(16)}>{`${item.key} - ${item.label}`}</Badge>
            ))}
          </HStack>
        </View>
      );
    } else {
      return (
        <Text style={{textAlign: 'center', padding: 12}}>
          No active filters
        </Text>
      );
    }
  }