import {toLocalDateTime} from '../../utils';

const buildTodayAlertArgs = (
  personName: string,
  unitName: string,
): {todayArguments: string; todayStartDate: string; todayEndDate: string, todayDescription: string} => {
  const todayStart = new Date();
  const todayStartDate = toLocalDateTime(todayStart, true);
  const todayDescription = new Date(todayStart).toLocaleDateString();
  const todayEnd = new Date();
  const todayEndDate = toLocalDateTime(todayEnd, false);
  const todayArguments = `{'Name':'${personName}','SenderType':'Guest','UnitName':'${unitName}','StartDate':'${todayStartDate}','EndDate':'${todayEndDate}','Description':'${todayDescription}'}`;

  return {todayArguments, todayStartDate, todayEndDate, todayDescription};
};

const buildTommorowAlertArgs = (
  personName: string,
  unitName: string,
): {
  tommorowArguments: string;
  tommorowStartDate: string;
  tommorowEndDate: string;
  tommorowDescription: string;
} => {
  const tommorow = new Date().setDate(new Date().getDate() + 1);
  const tommorowStart = new Date(tommorow);
  const tommorowStartDate = toLocalDateTime(tommorowStart, true);

  const tommorowEnd = new Date(tommorow);
  const tommorowEndDate = toLocalDateTime(tommorowEnd, false);
  const tommorowDescription = new Date(tommorowEnd).toLocaleDateString();
  const tommorowArguments = `{'Name':'${personName}','SenderType':'Guest','UnitName':'${unitName}','StartDate':'${tommorowStartDate}','EndDate':'${tommorowEndDate}','Description':'${tommorowDescription}'}`;

  return {tommorowArguments, tommorowStartDate, tommorowEndDate, tommorowDescription};
};

export {buildTodayAlertArgs, buildTommorowAlertArgs};
