// @ts-nocheck
import React from 'react';
import tailwind from 'tailwind-rn';
import {View, Alert} from 'react-native';
import AsterixSwitch from './AsterixSwitch';
import {styles} from '../styles';
import {useState} from 'react';
import {useEffect} from 'react';
import {UnitLink} from '../@types/UnitLink';
import {useDispatch, useSelector} from 'react-redux';
import {RootStore} from '../redux/store';
import {GetListPeopleTypes} from '../redux/reducers/listPeopleTypesReducer';
import {ListPeopleType} from '../@types/ListPeopleType';
import {SubmitButton} from '.';
import {
  getCurrentUnitLink,
  mapPermissionStringToSwitches,
  mapSwitchesToPermissionString,
} from '../utils';
import {useUpdatePermissions} from '../hooks/permissions';
import Input from './Input';
import Select from './Select';
import Accordion from './Accordion';
import Text from '../components/Text';
import PermissionInfoText from './PermissionInfoText';
import ConfirmationDialog from './ConfirmationDialog';

const directoryScopes = ['Unit', 'Community', 'Building', 'Site'];

const Permissions = ({
  initialPeopleState,
  updateParentState,
  isEditMode,
  isCreate,
  renderCustomComponent,
  hasUpdateButton = false
}) => {
  const [unitPeopleState, setUnitPeopleState] =
    useState<UnitLink>(initialPeopleState);
  const {listPeopleTypes, loading} = useSelector(
    (state: RootStore) => state.listPeopleTypes,
  );
  const [permissions, setPermissions] = useState([]);
  //Activate toggles if in edit mode
  // const [togglesDisabled, setTogglesDisabled] = useState(!isEditMode);
  const [togglesDisabled, setTogglesDisabled] = useState(!isEditMode);
  const dispatch = useDispatch();
  const {mutate, isLoading} = useUpdatePermissions();

  const [isOpen, setIsOpen] = React.useState(false);
  const onClose = () => setIsOpen(false);
  const [currentPeopleType, setCurrentPeopleType] = useState(null);

  let defaultUnitLink = getCurrentUnitLink();

  useEffect(() => {
    setPermissions(mapPermissionStringToSwitches(unitPeopleState));
    dispatch(GetListPeopleTypes());
  }, []);

  const updatePermissions = (peopleTypeId: string) => {
    const personType: ListPeopleType = listPeopleTypes.filter(
      type => type.id === peopleTypeId,
    )[0];

    const nextState: UnitLink = {
      ...unitPeopleState,
      peopleType: {
        id: personType.id,
        name: personType.peopleType,
        residentCanAdd: personType.residentCanAdd,
      },
      addForeverDateFrame: personType.addForeverDateFrame,
      canManagePeople: personType.normallyCanManagePeople,
      allowedToChekoutKey: personType.normallyAllowedToChekoutKey,
      allowedToClaimPackages: personType.normallyAllowedToClaimPackages,
      allowedToAuthorizeEntry: personType.normallyallowedToAuthorizeEntry,
      alwaysNeedsEntrancePermission: !personType.autoCheckIn,
      canViewLog: personType.normallyCanViewLog,
      peoplePermission: personType.normallyPeoplePermission,
      unitPermission: personType.normallyUnitPermission,
      vehiclePermission: personType.normallyVehiclePermission,
      petPermission: personType.normallyPetPermission,
      storagePermission: personType.normallyStoragePermission,
      parkingPermission: personType.normallyParkingPermission,
      violationsPermission: personType.normallyViolationsPermission,
      deliveryPermission: personType.normallyDeliveryPermission,
      gateCardPermission: personType.normallyGateCardPermission,
      getNotifications: personType.normallyGetsNotifications,
      //people without "Allowed to Authorize Entry" should not be able to edit the Always call permission
      alwaysCall: defaultUnitLink.allowedToAuthorizeEntry
        ? personType.normallyAlwaysCall
        : unitPeopleState.alwaysCall,
      canEditself: personType.normallyCanEditSelf,
      allowedtoApprove: personType.normallyAllowedtoApprove,

      resident: personType.residentDefault,
      canViewUnitHistory: personType.normallyCanViewUnitHistory,
      allowedToRequestAccess: personType.normallyallowedToRequestAccess,
      privateFilesManage: personType.normallyPrivateFilesManage,
      privateFilesAccess: personType.normallyPrivateFilesAccess,
      ownerFilesAccess: personType.normallyOwnerFilesAccess,
      managePublicFiles: personType.normallyManagePublicFiles
    };

    setPermissions(mapPermissionStringToSwitches(nextState));
    setUnitPeopleState(nextState);
    updateParentState(nextState);
    //Disabled editing of toggles
    // setTogglesDisabled(!isEditMode);
    setTogglesDisabled(true);
  };

  const showManagementPrompt = (peopleTypeObj: ListPeopleType) => {
    setCurrentPeopleType(peopleTypeObj);
    setIsOpen(true);
  };

  const handlePermissionsToggle = newPermissions => {
    const resourcePermissions = {};
    newPermissions.forEach(permission => {
      resourcePermissions[permission.title.toLowerCase() + 'Permission'] =
        permission.permissions.map(perm => (perm.value ? '1' : '0')).join('');
    });
    setUnitPeopleState({
      ...unitPeopleState,
      ...resourcePermissions,
    });
  };

  return (
    <>
      {isEditMode ? (
        <>
          <Text style={styles.textInputLabel}>People type</Text>
          <Select
            placeholder="Select people type "
            minWidth={200}
            selectedValue={
              unitPeopleState?.peopleType?.id
                ? unitPeopleState?.peopleType?.id
                : undefined
            }
            onValueChange={value => {
              const peopleTypeObj = listPeopleTypes?.filter(
                item => item.id === value,
              )[0];
              !peopleTypeObj?.residentCanAdd
                ? showManagementPrompt(peopleTypeObj)
                : updatePermissions(value);
            }}>
            {listPeopleTypes?.map(peopleType => (
              <Select.Item
                key={peopleType.id}
                label={
                  peopleType?.residentCanAdd
                    ? peopleType?.peopleType
                    : `${peopleType?.peopleType} - ( requires management approval )`
                }
                value={peopleType.id}
              />
            ))}
          </Select>
        </>
      ) : (
        <View style={tailwind('flex-row  my-3')}>
          <Text style={styles.textInputLabel}>People type</Text>
          <Text style={styles.detailsText}>
            {unitPeopleState.peopleType.name}
          </Text>
        </View>
      )}
      {!!renderCustomComponent && renderCustomComponent()}
      {!!unitPeopleState.peopleType && (
        <>
          <Accordion style={tailwind('mt-4')}>
            <Accordion.Item>
              <Accordion.Summary>
                Permissions
                <Accordion.Icon />
              </Accordion.Summary>
              <Accordion.Details>
                <>
                  <View
                    style={tailwind('flex-row justify-between  pt-2 flex-1')}>
                    <PermissionInfoText
                      title="Resident"
                      description="Resident"
                    />
                    <AsterixSwitch
                      value={unitPeopleState.resident}
                      // disabled={togglesDisabled}
                      onValueChange={value => {
                        setUnitPeopleState({
                          ...unitPeopleState,
                          resident: value,
                        });
                        updateParentState({
                          ...unitPeopleState,
                          resident: value,
                        });
                      }}
                    />
                  </View>
                  <View
                    style={tailwind(
                      'flex-row justify-between  pt-1 pb-3 flex-1',
                    )}>
                    <PermissionInfoText
                      title="Needs help, in emergency ?"
                      description="Needs help, in emergency  description ?"
                    />
                    <AsterixSwitch
                      value={unitPeopleState.needsHelpInEmergency}
                      disabled={togglesDisabled}
                      onValueChange={value => {
                        setUnitPeopleState({
                          ...unitPeopleState,
                          needsHelpInEmergency: value,
                        });
                        updateParentState({
                          ...unitPeopleState,
                          needsHelpInEmergency: value,
                        });
                      }}
                    />
                  </View>
                  <View style={tailwind('flex-row justify-between  pt-1 pb-3')}>
                    <PermissionInfoText
                      title="Mail to ?"
                      description="Mail to description ?"
                    />
                    <AsterixSwitch
                      value={unitPeopleState.mailTo}
                      disabled={togglesDisabled}
                      onValueChange={value => {
                        setUnitPeopleState({
                          ...unitPeopleState,
                          mailTo: value,
                        });
                        updateParentState({
                          ...unitPeopleState,
                          mailTo: value,
                        });
                      }}
                    />
                  </View>
                </>
                <>
                  <View style={tailwind('flex-row justify-between  pt-1 pb-3')}>
                    <PermissionInfoText
                      title="Always call for gate authorizations ?"
                      description="Always call for gate authorizations ?"
                    />
                    <AsterixSwitch
                      //people without "Allowed to Authorize Entry" should not be able to edit the Always call permission
                      // disabled={!defaultUnitLink.allowedToAuthorizeEntry}
                      value={unitPeopleState.alwaysCall}
                      onValueChange={value => {
                        setUnitPeopleState({
                          ...unitPeopleState,
                          alwaysCall: value,
                        });
                        updateParentState({
                          ...unitPeopleState,
                          alwaysCall: value,
                        });
                      }}
                    />
                  </View>
                  <View style={tailwind('flex-row justify-between  pt-1 pb-3')}>
                    <PermissionInfoText
                      title="Get notifications ?"
                      description="Get notifications description"
                    />
                    <AsterixSwitch
                      value={unitPeopleState.getNotifications}
                      disabled={togglesDisabled}
                      onValueChange={value => {
                        setUnitPeopleState({
                          ...unitPeopleState,
                          getNotifications: value,
                        });
                        updateParentState({
                          ...unitPeopleState,
                          getNotifications: value,
                        });
                      }}
                    />
                  </View>
                  <View style={tailwind('flex-row justify-between  pt-1 pb-3')}>
                    <PermissionInfoText
                      title="Allowed to approve ?"
                      description="Allowed to approve description"
                    />
                    <AsterixSwitch
                      value={unitPeopleState.allowedtoApprove}
                      disabled={togglesDisabled}
                      onValueChange={value => {
                        setUnitPeopleState({
                          ...unitPeopleState,
                          allowedtoApprove: value,
                        });
                        updateParentState({
                          ...unitPeopleState,
                          allowedtoApprove: value,
                        });
                      }}
                    />
                  </View>
                  <View style={tailwind('flex-row justify-between  pt-1 pb-3')}>
                    <PermissionInfoText
                      title="Emergency contact ?"
                      description="Emergency contact description"
                    />
                    <AsterixSwitch
                      value={unitPeopleState.emergencyContact}
                      disabled={togglesDisabled}
                      onValueChange={value => {
                        setUnitPeopleState({
                          ...unitPeopleState,
                          emergencyContact: value,
                        });
                        updateParentState({
                          ...unitPeopleState,
                          emergencyContact: value,
                        });
                      }}
                    />
                  </View>
                  <View style={tailwind('flex-row justify-between  pt-1 pb-3')}>
                    <PermissionInfoText
                      title="Can manage people ?"
                      description="Can manage description"
                    />
                    <AsterixSwitch
                      value={unitPeopleState.canManagePeople}
                      disabled={togglesDisabled}
                      onValueChange={value => {
                        setUnitPeopleState({
                          ...unitPeopleState,
                          canManagePeople: value,
                        });
                        updateParentState({
                          ...unitPeopleState,
                          canManagePeople: value,
                        });
                      }}
                    />
                  </View>
                  <View style={tailwind('flex-row justify-between  pt-1 pb-3')}>
                    <PermissionInfoText
                      title="Allowed to checkout key ?"
                      description="Allowed to checkout description"
                    />
                    <AsterixSwitch
                      value={unitPeopleState.allowedToChekoutKey}
                      disabled={togglesDisabled}
                      onValueChange={value => {
                        setUnitPeopleState({
                          ...unitPeopleState,
                          allowedToChekoutKey: value,
                        });
                        updateParentState({
                          ...unitPeopleState,
                          allowedToChekoutKey: value,
                        });
                      }}
                    />
                  </View>
                  <View style={tailwind('flex-row justify-between  pt-1 pb-3')}>
                    <PermissionInfoText
                      title=" Allowed to claim packages ?"
                      description="Allowed to claim packages description"
                    />
                    <AsterixSwitch
                      value={unitPeopleState.allowedToClaimPackages}
                      disabled={togglesDisabled}
                      onValueChange={value => {
                        setUnitPeopleState({
                          ...unitPeopleState,
                          allowedToClaimPackages: value,
                        });
                        updateParentState({
                          ...unitPeopleState,
                          allowedToClaimPackages: value,
                        });
                      }}
                    />
                  </View>
                  <View style={tailwind('flex-row justify-between  pt-1 pb-3')}>
                    <PermissionInfoText
                      title="Allowed to authorize entry ?"
                      description="Allowed to authorize entry description"
                    />
                    <AsterixSwitch
                      value={unitPeopleState.allowedToAuthorizeEntry}
                      disabled={togglesDisabled}
                      onValueChange={value => {
                        setUnitPeopleState({
                          ...unitPeopleState,
                          allowedToAuthorizeEntry: value,
                        });
                        updateParentState({
                          ...unitPeopleState,
                          allowedToAuthorizeEntry: value,
                        });
                      }}
                    />
                  </View>
                  <View style={tailwind('flex-row justify-between  pt-1 pb-3')}>
                    <PermissionInfoText
                      title="Always needs entrance permission ?"
                      description="Always needs entrance permission"
                    />
                    <AsterixSwitch
                      value={unitPeopleState.alwaysNeedsEntrancePermission}
                      disabled={togglesDisabled}
                      onValueChange={value => {
                        setUnitPeopleState({
                          ...unitPeopleState,
                          alwaysNeedsEntrancePermission: value,
                        });
                        updateParentState({
                          ...unitPeopleState,
                          alwaysNeedsEntrancePermission: value,
                        });
                      }}
                    />
                  </View>
                  <View style={tailwind('flex-row justify-between  pt-1 pb-3')}>
                    <PermissionInfoText
                      title="Allowed to request access ?"
                      description="Allowed to request access permission"
                    />
                    <AsterixSwitch
                      value={unitPeopleState.allowedToRequestAccess}
                      disabled={togglesDisabled}
                      onValueChange={value => {
                        setUnitPeopleState({
                          ...unitPeopleState,
                          allowedToRequestAccess: value,
                        });
                        updateParentState({
                          ...unitPeopleState,
                          allowedToRequestAccess: value,
                        });
                      }}
                    />
                  </View>
                  <>
                    <Text style={styles.textInputLabel}>
                      Access instructions
                    </Text>
                    {isEditMode ? (
                      <Input
                        placeholder="Enter access instructions"
                        value={unitPeopleState.accessInstructions}
                        multiline
                        onChangeText={text => {
                          setUnitPeopleState({
                            ...unitPeopleState,
                            accessInstructions: text,
                          });
                          updateParentState({
                            ...unitPeopleState,
                            accessInstructions: text,
                          });
                        }}
                      />
                    ) : (
                      <Text>{unitPeopleState.accessInstructions}</Text>
                    )}
                  </>
                  {isEditMode && (
                    <View style={tailwind('flex-row justify-between  pt-2')}>
                      <PermissionInfoText
                      title="Can edit self ?"
                      description="Can edit self description"
                    />
                      <AsterixSwitch
                        value={unitPeopleState.canEditself}
                        onValueChange={value => {
                          setUnitPeopleState({
                            ...unitPeopleState,
                            canEditself: value,
                          });
                          updateParentState({
                            ...unitPeopleState,
                            canEditself: value,
                          });
                        }}
                        disabled
                      />
                    </View>
                  )}
                </>
                {/* <>
                  {permissions.map(permObj => {
                    return (
                      <>
                        <HStack>
                          <Badge colorScheme="dark" rounded="lg" mt="4">
                            {permObj.title}
                          </Badge>
                        </HStack>
                        {permObj.permissions.map((permission, index) => {
                          return (
                            <View
                              style={tailwind(
                                'flex-row justify-between  pt-1 pb-3',
                              )}>
                              <Text style={styles.checkBoxLabel}>
                                {`${permission.text} ?`}
                              </Text>
                              <AsterixSwitch
                                value={permission.value}
                                disabled={togglesDisabled}
                                onValueChange={value => {
                                  const subPermissions =
                                    permObj.permissions.map(item => {
                                      if (
                                        item.text ===
                                        permObj.permissions[index].text
                                      ) {
                                        return {
                                          ...item,
                                          value: value,
                                        };
                                      }
                                      return item;
                                    });
                                  const perms = permissions.map(p => {
                                    return p.title === permObj.title
                                      ? {
                                          ...p,
                                          permissions: [...subPermissions],
                                        }
                                      : p;
                                  });
                                  setPermissions(perms);
                                  handlePermissionsToggle(perms);
                                }}
                              />
                            </View>
                          );
                        })}
                      </>
                    );
                  })}
                </> */}
              </Accordion.Details>
            </Accordion.Item>
            {/* 
            <Accordion.Item>
              <Accordion.Summary>
                Directory Information
                <Accordion.Icon />
              </Accordion.Summary>
              <Accordion.Details>
                <>
                  {unitPeopleState?.resident ? (
                    <>
                      <View
                        style={tailwind('flex-row justify-between  pt-1 pb-3')}>
                        <Text style={styles.checkBoxLabel}>
                          Directory Opt out ?
                        </Text>
                        <AsterixSwitch
                          value={unitPeopleState.directoryOptOut}
                          onValueChange={value => {
                            setUnitPeopleState({
                              ...unitPeopleState,
                              directoryOptOut: value,
                              directoryDoNotShareEmail: value,
                              directoryDoNotSharePhone1: value,
                            });
                            updateParentState({
                              ...unitPeopleState,
                              directoryOptOut: value,
                              directoryDoNotShareEmail: value,
                              directoryDoNotSharePhone1: value,
                            });
                          }}
                        />
                      </View>

                      <>
                        <Text style={styles.onePickerLabel}>
                          Directory scope
                        </Text>
                        <Select
                          selectedValue={
                            unitPeopleState.directoryScope
                              ? unitPeopleState.directoryScope
                              : ''
                          }
                          onValueChange={val => {
                            setUnitPeopleState({
                              ...unitPeopleState,
                              directoryScope: val,
                            });
                            updateParentState({
                              ...unitPeopleState,
                              directoryScope: val,
                            });
                          }}>
                          {directoryScopes.map(scope => (
                            <Select.Item
                              key={scope}
                              label={scope}
                              value={scope}
                            />
                          ))}
                        </Select>
                      </>
                      <View
                        style={tailwind('flex-row justify-between  pt-1 pb-3')}>
                        <Text style={styles.checkBoxLabel}>
                          Directory do not share email ?
                        </Text>
                        <AsterixSwitch
                          value={unitPeopleState.directoryDoNotShareEmail}
                          onValueChange={value => {
                            setUnitPeopleState({
                              ...unitPeopleState,
                              directoryDoNotShareEmail: value,
                            });
                            updateParentState({
                              ...unitPeopleState,
                              directoryDoNotShareEmail: value,
                            });
                          }}
                        />
                      </View>
                      <View
                        style={tailwind('flex-row justify-between  pt-1 pb-3')}>
                        <Text style={styles.checkBoxLabel}>
                          Directory do not share phone 1 ?
                        </Text>
                        <AsterixSwitch
                          value={unitPeopleState.directoryDoNotSharePhone1}
                          onValueChange={value => {
                            setUnitPeopleState({
                              ...unitPeopleState,
                              directoryDoNotSharePhone1: value,
                            });
                            updateParentState({
                              ...unitPeopleState,
                              directoryDoNotSharePhone1: value,
                            });
                          }}
                        />
                      </View>
                      <>
                        <Text style={styles.textInputLabel}>Relationship</Text>
                        <Input
                          placeholder="Enter relationship"
                          value={unitPeopleState.relationship}
                          onChangeText={text => {
                            setUnitPeopleState({
                              ...unitPeopleState,
                              relationship: text,
                            });
                            updateParentState({
                              ...unitPeopleState,
                              relationship: text,
                            });
                          }}
                        />
                      </>

                      <>
                        <Text style={styles.textInputLabel}>Office notes</Text>
                        <TextArea
                          placeholder="Enter office notes"
                          value={unitPeopleState.officeNotes}
                          multiline
                          onChangeText={text => {
                            setUnitPeopleState({
                              ...unitPeopleState,
                              officeNotes: text,
                            });
                            updateParentState({
                              ...unitPeopleState,
                              officeNotes: text,
                            });
                          }}
                          autoCompleteType=""
                        />
                      </>

                      <>
                        <Text style={styles.textInputLabel}>Guard notes</Text>
                        <TextArea
                          placeholder="Enter guard notes"
                          value={unitPeopleState.guardNotes}
                          onChangeText={text => {
                            setUnitPeopleState({
                              ...unitPeopleState,
                              guardNotes: text,
                            });
                            updateParentState({
                              ...unitPeopleState,
                              guardNotes: text,
                            });
                          }}
                          autoCompleteType=""
                        />
                      </>
                    </>
                  ) : (
                    <Text
                      style={
                        styles.textInputLabel
                      }>{`Directory information is not available for ${unitPeopleState?.peopleType?.name}`}</Text>
                  )}
                </>
              </Accordion.Details>
            </Accordion.Item> */}
          </Accordion>
          {isEditMode && hasUpdateButton && !isCreate && (
            <SubmitButton
              title={isLoading ? 'Updating ...' : 'Update'}
              onPress={() => {
                const perms = mapSwitchesToPermissionString(permissions);

                const newObj = {
                  // peopleTypeId: unitPeopleState.peopleType.id,
                  alwaysCall: unitPeopleState.alwaysCall,
                  getNotifications: unitPeopleState.getNotifications,
                  // resident: unitPeopleState.resident,
                  allowedToApprove: !!unitPeopleState.allowedtoApprove,
                  emergencyContact: unitPeopleState.emergencyContact,
                  // needsHelpInEmergency: unitPeopleState.needsHelpInEmergency,
                  canManagePeople: unitPeopleState.canManagePeople,
                  allowedToChekoutKey: unitPeopleState.allowedToChekoutKey,
                  allowedToClaimPackages:
                    unitPeopleState.allowedToClaimPackages,
                  allowedToAuthorizeEntry:
                    unitPeopleState.allowedToAuthorizeEntry,
                  // mailTo: unitPeopleState.mailTo,
                  alwaysNeedsEntrancePermission:
                    unitPeopleState.alwaysNeedsEntrancePermission,
                  // canViewLog: unitPeopleState.canViewLog,
                  // accessInstructions: unitPeopleState.accessInstructions,
                  // ...perms,
                  // directoryOptOut: unitPeopleState.directoryOptOut,
                  // directoryScope: unitPeopleState.directoryScope,
                  // directoryDoNotShareEmail:
                  //   unitPeopleState.directoryDoNotShareEmail,
                  // directoryDoNotSharePhone1:
                  //   unitPeopleState.directoryDoNotSharePhone1,
                  // relationship: unitPeopleState.relationship,
                  // officeNotes: unitPeopleState.officeNotes,
                  // guardNotes: unitPeopleState.guardNotes,
                  // canEditself: unitPeopleState.canEditself,
                };
                mutate({
                  unitPeopleLinkId: unitPeopleState.id,
                  payload: newObj,
                });
              }}
            />
          )}
       
        </>
      )}
         <ConfirmationDialog
          isOpen={isOpen}
          onClose={onClose}
          onNoPressed={onClose}
          onYesPressed={()=> updatePermissions(currentPeopleType.id)}
          description={`Adding ${currentPeopleType?.peopleType} will require management approval`}
        />
    </>
  );
};

export default Permissions;
