import {AxiosError} from 'axios';
import {useInfiniteQuery} from 'react-query';
import {LIMIT_PER_PAGE} from '../api/constants';
import {residentApiWithAuth} from '../api/resident';
import {showErrorMessage} from '../service/flashMessage';
import {getCurrentUnitLink} from '../utils';
import E_QUERY_NAMES from './query-names';
import {QueryParams} from '../modules/DoorLogs/types';

export function usePaginatedDoorLogsByUnit(queryParams: QueryParams) {
  const currentUnitId = getCurrentUnitLink()?.unit?.id;
  let url = `/doorLogs/unit/${currentUnitId}?pageSize=${LIMIT_PER_PAGE}`;

  if (queryParams.doorId) {
    url = `${queryParams.doorId}`;
  }

  if (queryParams.startDate) {
    url = `${queryParams.startDate}`;
  }

  if (queryParams.endDate) {
    url = `${queryParams.endDate}`;
  }

  return useInfiniteQuery(
    [E_QUERY_NAMES.DOOR_LOGS + currentUnitId],
    async ({pageParam = 1}) => {
      const paginatedUrl = `${url}&pageNumber=${pageParam}`;
      console.log('paginatedUrl', paginatedUrl);
      const response = await residentApiWithAuth().get(paginatedUrl);
      const data = await response?.data;
      return data;
    },
    {
      getNextPageParam: lastPage => {
        if (lastPage.nextPage !== null) {
          return lastPage.pageNumber + 1;
        }
        return undefined;
      },
      onSuccess: data => {
        return data;
      },
      onError: (error: AxiosError) => {
        const message = error.message;
        showErrorMessage(message);
      },
    },
  );
}
