import {Dispatch} from 'redux';
import {residentApiWithAuth} from '../../api/resident';
import {showErrorMessage, showSuccessMessage} from '../../service/flashMessage';
import * as RootNavigation from '../../navigation';
import {store} from '../../redux/store';
import {Delivery} from '../../@types';

export const DELIVERIES_LOADING = 'DELIVERIES_LOADING';
export const DELIVERIES_FAIL = 'DELIVERIES_FAIL';
export const DELIVERIES_SUCCESS = 'DELIVERIES_SUCCESS';
export const DELIVERIES_UPDATE_LOADING = 'DELIVERIES_UPDATE_LOADING';
export const DELIVERIES_UPDATE_FAIL = 'DELIVERIES_UPDATE_FAIL';
export const DELIVERIES_UPDATE_SUCCESS = 'DELIVERIES_UPDATE_SUCCESS';
export const DELIVERIES_ADD_LOADING = 'DELIVERIES_ADD_LOADING';
export const DELIVERIES_ADD_FAIL = 'DELIVERIES_UPDATE_FAIL';
export const DELIVERIES_ADD_SUCCESS = 'DELIVERIES_ADD_SUCCESS';
export const DELIVERIES_SEARCH = 'DELIVERIES_SEARCH';
export const DELIVERIES_DELETE = 'DELIVERIES_DELETE';
export const SET_DELIVERY_TAB_SELECTED = 'SET_DELIVERY_TAB_SELECTED';

export interface DeliveriesLoading {
  type: typeof DELIVERIES_LOADING;
}

export interface DeliveriesFail {
  type: typeof DELIVERIES_FAIL;
  error: string | object;
}

export interface DeliveriesSuccess {
  type: typeof DELIVERIES_SUCCESS;
  payload: Delivery[];
}

export interface DeliveriesUpdateLoading {
  type: typeof DELIVERIES_UPDATE_LOADING;
}

export interface DeliveriesUpdateFail {
  type: typeof DELIVERIES_UPDATE_FAIL;
  error: string | object;
}

export interface DeliveriesUpdateSuccess {
  type: typeof DELIVERIES_UPDATE_SUCCESS;
  payload: Delivery;
}

export interface DeliveriesAddSuccess {
  type: typeof DELIVERIES_ADD_SUCCESS;
  payload: Delivery;
}

export interface DeliveriesAddLoading {
  type: typeof DELIVERIES_ADD_LOADING;
}

export interface DeliveriesAddFail {
  type: typeof DELIVERIES_ADD_FAIL;
  error: string | object;
}

export interface DeliveriesUpdateSuccess {
  type: typeof DELIVERIES_UPDATE_SUCCESS;
  payload: Delivery;
}

export interface DeliverySearch {
  type: typeof DELIVERIES_SEARCH;
  payload: {
    searchTerm: string;
  };
}

export interface DeliveryDelete {
  type: typeof DELIVERIES_DELETE;
  id: string;
}
export interface DeliveriesTabSelected {
  type: typeof SET_DELIVERY_TAB_SELECTED;
  deliveriesTabSelected: boolean;
}

export function setDeliveriesTabSelected(deliveriesTabSelected: boolean) {
  return {
    type: SET_DELIVERY_TAB_SELECTED,
    deliveriesTabSelected,
  };
}

export type DeliveriesDispatchTypes =
  | DeliveriesLoading
  | DeliveriesFail
  | DeliveriesSuccess
  | DeliveriesUpdateLoading
  | DeliveriesFail
  | DeliveriesUpdateSuccess
  | DeliveriesAddLoading
  | DeliveriesAddFail
  | DeliveriesAddSuccess
  | DeliverySearch
  | DeliveryDelete
  | DeliveriesTabSelected;

// List should autofilter expired deliveries
export const GetDeliveries =
  () => async (dispatch: Dispatch<DeliveriesDispatchTypes>) => {
    try {
      dispatch({type: DELIVERIES_LOADING});
      const defaultLink = store
        .getState()
        .units.unitLinks.filter(unitLink => unitLink.isDefault === true)[0];

      const res = await residentApiWithAuth().get(
        `/deliveries/unit/${defaultLink?.unit.id}`,
      );

      dispatch({type: DELIVERIES_SUCCESS, payload: res.data.data});
    } catch (error) {
      dispatch({type: DELIVERIES_FAIL, error: error});
    }
  };

export const CreateDelivery =
  (dateFrame: any, delivery: any) =>
  async (dispatch: Dispatch<DeliveriesDispatchTypes>) => {
    try {
      // dispatch({type: DELIVERIES_ADD_LOADING});

      const response = await residentApiWithAuth().post(
        '/dateFrame',
        dateFrame,
      );
      const newDateFrame = await response.data.data;
      const res = await residentApiWithAuth().post('/deliveries', {
        ...delivery,
        dateFrameId: newDateFrame.id,
      });

      const newDelivery = await res.data.data;
      dispatch({type: DELIVERIES_ADD_SUCCESS, payload: newDelivery});
      showSuccessMessage('Delivery added');

      RootNavigation.goBack();
    } catch (error) {
      dispatch({type: DELIVERIES_ADD_FAIL, error: error});
      showErrorMessage(error.response.data.errors[0]);
    }
  };

export const UpdateDelivery =
  (dateFrame, delivery: any) =>
  async (dispatch: Dispatch<DeliveriesDispatchTypes>) => {
    const newDelivery = {
      category: delivery.category,
      authorizationTo: delivery.authorizationTo,
      company: delivery.company,
      expectedTime: delivery.expectedTime,
      //@ts-ignore
      instructions: delivery.instructions,
    };

    try {
      dispatch({type: DELIVERIES_UPDATE_LOADING});
      await residentApiWithAuth().post(`/dateFrame/${dateFrame.id}`, dateFrame);
      const res = await residentApiWithAuth().post(
        `/deliveries/${delivery.id}`,
        newDelivery,
      );
      dispatch({type: DELIVERIES_UPDATE_SUCCESS, payload: res.data.data});
      showSuccessMessage('Delivery updated successfully');

      RootNavigation.goBack();
    } catch (error) {
      dispatch({type: DELIVERIES_FAIL, error: error});
      showErrorMessage(error.response.data.errors[0] || error.message);
    }
  };

export const DeleteDelivery =
  (id: string) => async (dispatch: Dispatch<DeliveriesDispatchTypes>) => {
    try {
      await residentApiWithAuth().delete(`/deliveries/${id}`);
      dispatch({type: DELIVERIES_DELETE, id});

      showSuccessMessage('Delivery deleted');
    } catch (error) {
      showErrorMessage(
        error.response?.data?.errors[0]
          ? error.response?.data?.errors[0]
          : error,
      );
    }
  };

export const DeliverySearch =
  (searchTerm: string) =>
  async (dispatch: Dispatch<DeliveriesDispatchTypes>) => {
    dispatch({
      type: DELIVERIES_SEARCH,
      payload: {searchTerm},
    });
  };

interface DefaultStateI {
  loading: boolean;
  error?: string | object;
  deliveries?: Delivery[];
  deliveriesHolder: Delivery[];
  deliveriesTabShown: boolean;
}

const defaultState: DefaultStateI = {
  loading: true,
  deliveries: [],
  deliveriesHolder: [],
  deliveriesTabShown: false,
};

const deliveriesReducer = (
  state: DefaultStateI = defaultState,
  action: DeliveriesDispatchTypes,
): DefaultStateI => {
  switch (action.type) {
    case DELIVERIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case DELIVERIES_LOADING:
      return {
        ...state,
        loading: true,
      };
    case DELIVERIES_SUCCESS:
      const deliveries = action.payload;
      deliveries.sort(
        (a, b) =>
          new Date(b.dateFrame.startDate).getTime() -
          new Date(a.dateFrame.startDate).getTime(),
      );
      return {
        ...state,
        loading: false,
        deliveries: deliveries,
        deliveriesHolder: deliveries,
      };
    case DELIVERIES_UPDATE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case DELIVERIES_UPDATE_SUCCESS:
      const {id} = action.payload;
      const previousState = state.deliveries;
      const newState = previousState.map((item, _) => {
        if (item.id !== id) {
          return item;
        }
        return {
          ...item,
          ...action.payload,
        };
      });
      return {
        ...state,
        deliveries: newState,
        deliveriesHolder: newState,
        loading: false,
      };
    case DELIVERIES_SEARCH:
      if (action.payload.searchTerm) {
        const newDeliveries = state.deliveriesHolder.filter(delivery => {
          const deliveryData = delivery.company
            ? `${delivery.company}`.toUpperCase()
            : '';
          const searchTermData = action.payload.searchTerm.toUpperCase();
          return deliveryData.indexOf(searchTermData) > -1;
        });
        return {
          ...state,
          deliveries: newDeliveries,
        };
      } else {
        return {
          ...state,
          deliveries: state.deliveriesHolder,
        };
      }
    case DELIVERIES_DELETE:
      const nextState = state.deliveries.filter(
        delivery => delivery.id !== action.id,
      );
      return {
        ...state,
        deliveries: [...nextState],
      };
    case SET_DELIVERY_TAB_SELECTED:
      return {
        ...state,
        deliveriesTabShown: action.deliveriesTabSelected,
      };
    case DELIVERIES_ADD_LOADING:
      return {
        ...state,
        loading: true,
      };
    case DELIVERIES_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        deliveries: [action.payload, ...state.deliveries],
      };
    case DELIVERIES_ADD_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      break;
  }
  return state;
};

export default deliveriesReducer;
