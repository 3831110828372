export const initialParkingState = {
  unitId: '',
  name: '',
  beginDate: new Date().toISOString(),
  endDate: new Date().toISOString(),
  vechileInSpace: '',
  parkingSpace: '',
  notes: '',
  createdLogon: '',
  createdTimeDate: new Date().toISOString(),
  lastLogon: '',
  lastTimeDate: new Date().toISOString(),
};
