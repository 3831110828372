export const initialStorageState = {
  unitId: '',
  dateFrameId: '',
  storageBin: '',
  binLocation: '',
  description: '',
  monthlyCost: 0,
  createdLogon: '',
  createdTimeDate: new Date().toISOString(),
  lastLogon: '',
  lastTimeDate: new Date().toISOString(),
};

export const initialDateFrameState = {
  startDate: new Date().toISOString(),
  endDate: new Date().toISOString(),
  description: '',
};
