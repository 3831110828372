import React from 'react';
import {View} from 'react-native';
import Button from '../../../components/Button';

interface I_AuthorizeButton_Props {
  allowedToday: boolean;
  allowedTommorow: boolean;
  authorizeForToday: () => Promise<void>;
  authorizeForTommorow: () => Promise<void>;
  loadingToday: boolean;
  loadingTommorow: boolean;
}

const AuthorizeButtons = ({
  allowedToday,
  allowedTommorow,
  authorizeForToday,
  authorizeForTommorow,
  loadingToday,
  loadingTommorow
}: I_AuthorizeButton_Props) => {
  if (allowedToday && allowedTommorow) {
    return;
  }

  return (
    <View
      style={{
        paddingHorizontal: 16,
        paddingTop: 16,
        paddingBottom: 24,
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}>
      {!allowedToday && (
        <Button onPress={authorizeForToday} size="lg" py="3" isLoading={loadingToday} isLoadingText='Authorizing ...'>
          Authorize for today
        </Button>
      )}
      {!allowedTommorow && (
        <Button onPress={authorizeForTommorow} size="lg" py="3" isLoading={loadingTommorow} isLoadingText='Authorizing ...'>
          Authorize for tommorow
        </Button>
      )}
    </View>
  );
};

export default AuthorizeButtons;
