// @ts-nocheck
import {Dispatch} from 'redux';
import {showErrorMessage, showSuccessMessage} from '../../service/flashMessage';
import {fetchVehiclesByUnit, updateVehicle} from '../../api/vehicles';
import * as RootNavigation from '../../navigation';
import {UnitLink} from '../../../@types/UnitLink';
import {store} from '../../redux/store';
import {Vehicle} from '../../@types/Vehicle';
import {residentApiWithAuth} from '../../api/resident';

export const VEHICLE_LOADING = 'VEHICLE_LOADING';
export const VEHICLE_FAIL = 'VEHICLE_FAIL';
export const VEHICLE_SUCCESS = 'VEHICLE_SUCCESS';
export const VEHICLE_UPDATE_LOADING = 'VEHICLE_UPDATE_LOADING';
export const VEHICLE_UPDATE_FAIL = 'VEHICLE_UPDATE_FAIL';
export const VEHICLE_UPDATE_SUCCESS = 'VEHICLE_UPDATE_SUCCESS';
export const VEHICLE_CREATE_LOADING = 'VEHICLE_CREATE_LOADING';
export const VEHICLE_CREATE_FAIL = 'VEHICLE_CREATE_FAIL';
export const VEHICLE_CREATE_SUCCESS = 'VEHICLE_CREATE_SUCCESS';
export const VEHICLE_SEARCH = 'VEHICLE_SEARCH';

export interface VehicleLoading {
  type: typeof VEHICLE_LOADING;
}

export interface VehicleFail {
  type: typeof VEHICLE_FAIL;
  error: string | object;
}

export interface VehicleSuccess {
  type: typeof VEHICLE_SUCCESS;
  payload: Vehicle[];
}

export interface VehicleUpdateLoading {
  type: typeof VEHICLE_UPDATE_LOADING;
}

export interface VehicleUpdateFail {
  type: typeof VEHICLE_UPDATE_FAIL;
  error: string | object;
}

export interface VehicleUpdateSuccess {
  type: typeof VEHICLE_UPDATE_SUCCESS;
  payload: Vehicle;
}

export interface VehicleCreateLoading {
  type: typeof VEHICLE_CREATE_LOADING;
}

export interface VehicleCreateFail {
  type: typeof VEHICLE_CREATE_FAIL;
  error: string | object;
}

export interface VehicleCreateSuccess {
  type: typeof VEHICLE_CREATE_SUCCESS;
  payload: Vehicle;
}

export interface VehicleSearch {
  type: typeof VEHICLE_SEARCH;
  payload: {
    searchTerm: string;
    cachedVehicles: Vehicle[];
  };
}

export type VehicleDispatchTypes =
  | VehicleLoading
  | VehicleFail
  | VehicleSuccess
  | VehicleUpdateSuccess
  | VehicleUpdateLoading
  | VehicleUpdateFail
  | VehicleCreateSuccess
  | VehicleCreateLoading
  | VehicleCreateFail
  | VehicleSearch;

export const GetVehicles =
  (searchTerm: string) => async (dispatch: Dispatch<VehicleDispatchTypes>) => {
    try {
      dispatch({type: VEHICLE_LOADING});

      const defaultLink = store
        .getState()
        .units.unitLinks.filter(unitLink => unitLink.isDefault === true)[0];

      let vehicles: Vehicle[] = await fetchVehiclesByUnit(
        defaultLink?.unit.id,
        searchTerm,
      );
      dispatch({type: VEHICLE_SUCCESS, payload: vehicles});
    } catch (error) {
      console.log('error', error);
      dispatch({type: VEHICLE_FAIL, error: error});
    }
  };

export const UpdateVehicle =
  (vehicle: Vehicle, vehicleId: string) =>
  async (dispatch: Dispatch<VehicleDispatchTypes>) => {
    try {
      dispatch({type: VEHICLE_UPDATE_LOADING});
      //Update in API,
      const newVehicle = await updateVehicle(vehicle, vehicleId);

      //update state
      dispatch({type: VEHICLE_UPDATE_SUCCESS, payload: newVehicle});

      showSuccessMessage('Vehicle updated successfully');
      RootNavigation.goBack();
    } catch (error) {
      dispatch({type: VEHICLE_UPDATE_FAIL, error: error});
      showErrorMessage(error);
    }
  };

export const AddVehicle =
  (vehicle: Vehicle) => async (dispatch: Dispatch<VehicleDispatchTypes>) => {
    try {
      dispatch({type: VEHICLE_CREATE_LOADING});
      //Create in API,
      let res = await residentApiWithAuth().post('/vehicles', vehicle);
      const result = res.data;
      console.log(result);
      if (result.errors) {
        showErrorMessage(result.errors[0]);
      } else {
        const newVehicle: Vehicle = result.data;

        //create in state
        dispatch({type: VEHICLE_CREATE_SUCCESS, payload: newVehicle});

        showSuccessMessage('Vehicle created successfully');
        RootNavigation.goBack();
      }
    } catch (error) {
      dispatch({type: VEHICLE_CREATE_FAIL, error: error});
      showErrorMessage(error.response?.data?.errors[0] || error.message);
    }
  };

interface DefaultStateI {
  loading: boolean;
  error?: string | object;
  vehicles?: Vehicle[];
}

const defaultState: DefaultStateI = {
  loading: false,
  error: null,
  vehicles: [],
};

const vehicleReducer = (
  state: DefaultStateI = defaultState,
  action: VehicleDispatchTypes,
): DefaultStateI => {
  switch (action.type) {
    case VEHICLE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case VEHICLE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case VEHICLE_SUCCESS:
      return {
        ...state,
        loading: false,
        vehicles: action.payload,
      };
    case VEHICLE_UPDATE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case VEHICLE_UPDATE_SUCCESS:
      const {id} = action.payload;
      const previousState = state.vehicles;
      const newState = previousState.map((item, _) => {
        if (item.id !== id) {
          return item;
        }
        return {
          ...item,
          ...action.payload,
        };
      });
      return {
        ...state,
        vehicles: newState,
        loading: false,
      };
    case VEHICLE_CREATE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case VEHICLE_CREATE_SUCCESS:
      return {
        ...state,
        vehicles: [action.payload, ...state.vehicles],
        loading: false,
      };
    case VEHICLE_SEARCH:
      const newVehicles = action.payload.cachedVehicles.filter(vehicle => {
        const vehicleData = vehicle.licensePlate
          ? `${vehicle.licensePlate}`.toUpperCase()
          : ''.toUpperCase();
        const searchTermData = action.payload.searchTerm.toUpperCase();
        return vehicleData.indexOf(searchTermData) > -1;
      });
      return {
        ...state,
        vehicles: newVehicles,
      };
    default:
      break;
  }
  return state;
};

export default vehicleReducer;
