// @ts-nocheck
import React, {useCallback, useState} from 'react';
import {FlatList, Platform, RefreshControl} from 'react-native';
import Button from '../../../components/Button';
import Heading from '../../../components/Heading';
import HStack from '../../../components/HStack';
import tailwind from 'tailwind-rn';
import {useNavigation} from '@react-navigation/core';
import {useDispatch, useSelector} from 'react-redux';
import {
  DeleteAllApprovals,
  GetApprovals,
} from '../../../redux/reducers/approvalsReducer';
import {RootStore} from '../../../redux/store';
import ApprovalItem from '../components/ApprovalItem';
import {LoadingSpinner} from '../../../components';
import {useFocusEffect} from '@react-navigation/native';
import Text from '../../../components/Text';
import ActiveFiltersSection from '../components/ActiveFiltersSection';
import {VStack} from 'native-base';

const Approvals = () => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const {approvals, loading, filterArgs} = useSelector(
    (state: RootStore) => state.approvals,
  );
  const [refreshing, setRefreshing] = useState(false);
  const [pendingShown, setPendingShown] = useState(true);

  const {unitLinksHolder} = useSelector((state: RootStore) => state.units);

  const units: Array<{id: string; name: string}> = unitLinksHolder?.map(
    unitlink => {
      return {
        id: unitlink.unit.id,
        name: unitlink.unitNickname
          ? unitlink.unitNickname
          : unitlink.unit.unitName,
      };
    },
  );

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    dispatch(DeleteAllApprovals());
    dispatch(GetApprovals(pendingShown));
    setRefreshing(false);
  }, [pendingShown, dispatch]);

  useFocusEffect(
    useCallback(() => {
      dispatch(DeleteAllApprovals());
      dispatch(GetApprovals(pendingShown));
    }, []),
  );

  const handleClick = () => {
    setPendingShown(!pendingShown);
    dispatch(DeleteAllApprovals());
    dispatch(GetApprovals(!pendingShown));
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <FlatList
          refreshControl={
            <RefreshControl onRefresh={onRefresh} refreshing={refreshing} />
          }
          style={
            ([tailwind('pt-2')],
            {marginBottom: Platform.OS === 'ios' ? 140 : 76})
          }
          data={approvals}
          renderItem={({item}) => (
            <ApprovalItem
              key={item?.id}
              approval={item}
              approvalClickHandler={() =>
                navigation.navigate('Approval Detail', {
                  id: item.id,
                  unitId: item.unit.unitId,
                })
              }
              refreshList={() => {
                dispatch(GetApprovals(pendingShown));
              }}
            />
          )}
          ListHeaderComponent={
            <VStack>
              <HStack justifyContent="space-between">
                <Heading mx="2" py="3">
                  {`${pendingShown ? 'Pending' : 'All'} approvals`}
                </Heading>
                <Button variant="link" onPress={handleClick}>
                  {`Show ${pendingShown ? 'All' : 'Pending'}`}
                </Button>
              </HStack>
              <ActiveFiltersSection filterArgs={filterArgs} units={units} />
            </VStack>
          }
          ListEmptyComponent={
            <Text>{`You have no ${
              pendingShown ? 'pending' : ''
            } approvals`}</Text>
          }
        />
      )}
    </>
  );
};

export default Approvals;
