enum E_QUERY_NAMES {
  APPROVALS_QUERY = 'APPROVALS_QUERY',
  UPDATE_PERMISSIONS = 'UPDATE_PERMISSIONS',
  PACKAGES_BY_UNIT_QUERY = 'PACKAGES_BY_UNIT_QUERY',
  PACKAGES_HISTORIES_QUERY = 'PACKAGES_HISTORIES_QUERY',
  PACKAGES_BY_PERSON_QUERY = 'PACKAGES_BY_PERSON_QUERY',
  PACKAGE_QUERY = 'PACKAGE_QUERY',
  PACKAGE_HISTORIES = 'PACKAGE_HISTORIES',
  PACKAGE_LOGS = 'PACKAGE_LOGS',
  VISITOR_LOGS = 'VISITOR_LOGS',
  LIST_EVENTS = 'LIST_EVENTS',
  KEY_LOGS = 'KEY_LOGS',
  KEY_LOG = 'KEY_LOG',
  DOOR_LOGS = 'DOOR_LOGS',
}

export default E_QUERY_NAMES;
