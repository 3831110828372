import React from 'react';
import {LoadingSpinner} from '../../../components';
import {FlatList, View} from 'react-native';
import Text from '../../../components/Text';
import Button from '../../../components/Button';
import tailwind from 'tailwind-rn';
import VersionCode from '../../Auth/components/VersionCode';
import { colorSwatches } from '../../../styles';

export default function NoUnits({
  searchMode,
  isLoading,
  handleUnitCode,
  handlePeopleCode,
}: {
  searchMode: boolean;
  isLoading: boolean;
  handleUnitCode: () => void;
  handlePeopleCode: () => void;
}) {
  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (searchMode) {
    return (
      <Text style={{textAlign: 'center'}}>
        No units found, try adjusting your search
      </Text>
    );
  }
  const data = [
    {
      id: 1,
      text: (
        <Text>
          Click here to use <Text style={{fontWeight: '900'}}>code </Text> you
          received in your welcome email to a unit.
        </Text>
      ),
      onPress: handleUnitCode,
      backgroundColor: colorSwatches.green.color,
    },
    {
      id: 2,
      text: (
        <Text>
          Click here to generate{' '}
          <Text style={{fontWeight: '900'}}> unit link code </Text>and get ADDED
          as a Guest or vendor to the unit.
        </Text>
      ),
      onPress: handlePeopleCode,
      backgroundColor: colorSwatches.primary.color,
    },
  ];

  return (
    <>
      <FlatList
        style={{marginHorizontal: 16}}
        data={data}
        renderItem={({item}) => (
          <Button
            key={item.id}
            style={{
              marginTop: 8,
              borderRadius: 4,
              backgroundColor: item.backgroundColor
            }}
            onPress={item.onPress}
            >
            <Text
              style={{
                padding: 16,
                color: '#fff',
              }}>
              {item.text}
            </Text>
          </Button>
        )}
        ListHeaderComponent={
          <>
            <Text style={tailwind('text-lg')}>
              Congratulations on creating your profile, now we need to get your
              unit(s) linked so you can be informed and in control.
            </Text>
            <Text style={tailwind('text-lg my-4')}>
              Select an option to continue:
            </Text>
          </>
        }
        ListFooterComponent={
          <Text style={tailwind('mt-8 text-lg')}>
            Guests and Vendors are added to the unit by the owner.
          </Text>
        }
      />
      <View
        style={{
          position: 'relative',
          marginTop: 100,
        }}>
        <VersionCode />
      </View>
    </>
  );
}
