// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  AsterixDialog,
  HeaderTabs,
  MySearchbar,
  NavbarWithAction,
} from '../../../components';
import {GetAlerts, ReadAllAlerts} from '../alertsReducer';
import {RootStore} from '../../../redux/store';
import Approvals from './Approvals';
import Notifications from './Notification';
import FilterPanel from '../components/FilterPanel';
import {GetApprovals} from '../../../redux/reducers/approvalsReducer';
import {GetDeliveries} from '../../Deliveries/deliveriesReducer';
import {usePackagesByPerson} from '../../../hooks/packages';
import {E_NAVIGATION_DESTINATIONS} from '../../../@types';
import Container from '../../../components/Container';
import {useRoute} from '@react-navigation/native';

const Alerts = ({navigation}) => {
  const dispatch = useDispatch();
  const [isNavbarShown, setIsNavbarShown] = useState(true);

  const notifications = useSelector((state: RootStore) => state.alerts.alerts);
  const [notificationsHolder, setNotificationsHolder] = useState(notifications);
  const isLoading = useSelector((state: RootStore) => state.alerts.loading);
  const [searchTerm, setSearchTerm] = useState('');
  const [swipeablePanelActive, setSwipeablePanelActive] = useState(false);
  const route = useRoute();
  const [activeTab, setActiveTab] = useState(
    !!route.params?.activeTab ? route.params.activeTab : 'Notifications',
  );
  const {isReadingAll, pageNumber} = useSelector(
    (state: RootStore) => state.alerts,
  );

  const personId = useSelector((state: RootStore) => state?.auth?.user?.id);
  const [status, setStatus] = React.useState<'Unclaimed' | 'Claimed' | 'All'>(
    'Unclaimed',
  );
  const {} = usePackagesByPerson(personId, false, false);

  const searchHandler = (searchString: string) => {
    setSearchTerm(searchString);
    const newNotifications = notificationsHolder.filter(notification => {
      const notificationData = notification.message
        ? `${notification.message.toUpperCase()}`
        : '';

      const searchTermData = searchString.toUpperCase();
      return notificationData.indexOf(searchTermData) > -1;
    });
  };

  useEffect(() => {
    if (!!route.params?.activeTab) {
      setActiveTab(route.params.activeTab);
    }
  }, [route.params]);

  useEffect(() => {
    if (pageNumber === 1) {
      dispatch(GetAlerts());
      dispatch(GetApprovals());
      dispatch(GetDeliveries());
    }
  }, [pageNumber]);

  const renderHeader = () => {
    return (
      <>
        {isNavbarShown ? (
          <NavbarWithAction
            title="Alerts"
            navigation={navigation}
            onClickSearch={() => setIsNavbarShown(false)}
            hasFilter={!isLoading}
            onClickFilter={() => setSwipeablePanelActive(true)}
            hasMoreMenu
            hasSignOut
            hasReadAll
            hasSwitchUnit
            readAll={() => {
              dispatch(ReadAllAlerts());
            }}
            switchUnit={() => {
              navigation.navigate('Units Chooser');
            }}
            hasAccessCard
            onClickAccessCard={() =>
              navigation.navigate(E_NAVIGATION_DESTINATIONS.ID_CARD)
            }
            fullScreen
          />
        ) : (
          <MySearchbar
            placeholder="Search"
            setValue={val => searchHandler(val)}
            resetSearch={() => {
              setIsNavbarShown(true);
              setSearchTerm('');
            }}
          />
        )}
      </>
    );
  };

  return (
    <Container>
      {renderHeader()}
      <HeaderTabs activeTab={activeTab} setActiveTab={setActiveTab} />
      {activeTab === 'Notifications' ? <Notifications /> : <Approvals />}
      <AsterixDialog
        isSwitching={isReadingAll}
        message={'Reading alerts ...'}
      />
      <FilterPanel
        panelActive={swipeablePanelActive}
        setPanelActive={(newState: boolean) =>
          setSwipeablePanelActive(newState)
        }
        activeTab={activeTab}
      />
    </Container>
  );
};

export default Alerts;
