/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import {XMLParser} from 'fast-xml-parser';
import {FlatList, ScrollView, View} from 'react-native';
import Text from '../../../components/Text';
import {Card, Divider} from 'react-native-paper';
import tailwind from 'tailwind-rn';
import {styles} from '../../../styles';
import {LoadingSpinner, Navbar} from '../../../components';
import {VisitorLog} from '../types';
import {useVisitorLog} from '../../../hooks/logs';
import {Table, Row, Rows} from 'react-native-table-component';
import Accordion from '../../../components/Accordion';
import Container from '../../../components/Container';
import ZoomableImage from '../../../components/ZoomableImage';

const VisitorLogDetails = ({navigation, route}) => {
  const {isLoading, data} = useVisitorLog(route.params?.visitorLog?.id);
  const visitorLog: VisitorLog = data;

  const faceImage = visitorLog?.faceImage
    ? `data:image/jpeg;base64,${visitorLog?.faceImage}`
    : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAG1BMVEXr6+vY2Njp6ena2trd3d3g4ODj4+Pm5ubW1tbKf8WKAAADqklEQVR4nO3a25biIBBGYUMOzPs/8aiQSOAHk8zYTbn2d9m0LsqC4pDcbgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAY9w83S2u1u5D+0926f+ah2jyqnkZY/Mom/vnpuFF5OlNswFpBMMwZ61u3DVbDHEZhlYM+/iHsTpXu5Xl6G432ea8Nc9x/4oQhjFpzRNsMYlFCnfjtN1qgitDSNJUpvC+ovxmdy9QMbzSNInGsfV1HSqnYRKEV42DsYkoI1zLqW78igjjkiDqzLdEGKqJqkLfEmGoprIKmYtQR9GK0Fot1eUy5Kk1gg1pVJNmFbJDLerNCM2dgvVkq0dobRqq09PQrDTmpqHOVAhDViFja8WTCGOuthhMoUxidV9q5/zr054WM7F+tkiXCt9tVXXzM6TXBXBRbLau50vJFqCP39FlkH6LZ1xPulnRfM21bO+95tZN23d0uP7vRt4U0+hHEcfD8va/+ys9+4G3Vo7k3nvaFZMk42u6soz3djOVr+JbwpYpzs7sA3HWjvMaeF5+eiuvRd1MRpnzurO7v5d7oL6SKPZoJztY7tT7moliq3luK/3PX/BpajN9quCrffqnOnuJivBMqZAHqs9194L2Ffd78kbgc929QEZ4fCLpW50P9ve8xon+CAOnfi+H2eFhKj/d12qh+3i4msoR0NnmW46zMgvOe7HBkdf8vb2CImtFFuH98Bfk7xDpCH+w94eo+9E0wrjT3prSDMkI+9qW3t4+uJ7LK41XjPKR+C/E8IaoNVuEiy6W21j9YyCFcibGXjp9w5+EUf4AnS0VQVlOwzjUa+UukNpHe5OnKkwl/Rxm+59nKEX++wywOAY/x2A7wDWY7MfpbLFPTEU3q1Nw/0Psfpze1vqdZM14TrEDAYYQk8nadYC3beELd2uHAoxZjHdvBt6Jds4v8dHDwQDjXLx/bqncyvXqbZHJQjSn8tqM1Nv17yGVN58qutzDtMnn+A39LoE1JyZhYG0qVt6JaujwuNR0cow+9L8Qps7U0S2JpurphRTaKjany0xgKImXUmhpJp4vpIGdcnpxkBpaEy8OUju1pnX11GZld3plMQysTMTrEfb1ULSOCImwf/Va+nh2OI31Umulllbu2MY5XqU5r59FGdq2iRuMac4e+y7iZzCTwmKcjrPajvl8c2dlRxPE10rvM2+S4QV+Xt97HvMkG+Ae7114967fz/9SL2gAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAX+wvqtkVJSpt2GwAAAAASUVORK5CYII=';

  const tagImage = visitorLog?.tagImage
    ? `data:image/jpeg;base64,${visitorLog.tagImage}`
    : 'https://nichemodels.co/wp-content/uploads/2019/03/user-dummy-pic.png';

  const fullImage = visitorLog?.fullimage
    ? `data:image/jpeg;base64,${visitorLog.fullimage}`
    : 'https://nichemodels.co/wp-content/uploads/2019/03/user-dummy-pic.png';

  function Recipients() {
    const parser = new XMLParser();
    const recipientsData = parser.parse(visitorLog.log);
    const recipients = recipientsData.NewDataSet.RecipientsTable;

    const tableHeader = ['Name', 'SentVia', 'Action'];
    const tableData = recipients.map(recipient => [
      recipient.Resident_x0020_Name,
      recipient.SendVia,
      recipient.Action,
    ]);

    return (
      <Accordion>
        <Accordion.Item>
          <Accordion.Summary>
            Recipients
            <Accordion.Icon />
          </Accordion.Summary>
          <Accordion.Details>
            <FlatList
              contentContainerStyle={tailwind('')}
              data={[]}
              ListEmptyComponent={
                <Table style={tailwind('mt-2')}>
                  <Row
                    data={tableHeader}
                    style={styles.head}
                    textStyle={styles.userName}
                  />
                  <Rows
                    data={tableData}
                    style={{
                      borderWidth: 1,
                      borderColor: '#ccc',
                      padding: 4,
                      marginVertical: 6,
                      borderRadius: 4,
                    }}
                    textStyle={styles.userName}
                  />
                </Table>
              }
              renderItem={({}) => <></>}
              ListFooterComponent={<View style={tailwind('h-12')} />}
            />
          </Accordion.Details>
        </Accordion.Item>
      </Accordion>
    );
  }

  return (
    <Container>
        <Navbar navigation={navigation} title="Visitor Log details" />
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <Card style={tailwind('mx-2 mt-4 pb-2')}>
              <View style={tailwind('flex-row justify-between p-2')}>
                  <ZoomableImage
                  title={visitorLog.visitorFirstName}
                    source={{
                      uri: faceImage,
                    }}
                    style={styles.image}
                  />
                <View style={tailwind('flex-1 ml-4')}>
                  <Text style={styles.userName}>
                    {visitorLog?.visitorFirstName} {visitorLog?.visitorLastName}
                  </Text>
                  <Text style={styles.email}>{visitorLog?.vehicleLicense}</Text>
                </View>
              </View>
            </Card>
            <ScrollView
              style={tailwind('mx-2 py-4')}
              showsVerticalScrollIndicator={false}>
              <Card style={[tailwind('p-2 mb-8')]}>
                <View style={tailwind('flex-1 flex-row justify-between')} />
                <View style={tailwind('flex-row  my-3')}>
                  <Text style={styles.textLabel}>Unit name</Text>
                  <Text style={styles.detailsText}>{visitorLog?.unitName}</Text>
                </View>
                <Divider />
                <View style={tailwind('flex-row  my-3')}>
                  <Text style={styles.textLabel}>Date time of entrance</Text>
                  <Text style={styles.detailsText}>
                    {visitorLog?.dateTimeofEntrance}
                  </Text>
                </View>
                <Divider />
                <View style={tailwind('flex-row  my-3')}>
                  <Text style={styles.textLabel}>Security officer</Text>
                  <Text style={styles.detailsText}>
                    {visitorLog?.securityOfficer}
                  </Text>
                </View>
                <Divider />
                <View style={tailwind('flex-row  my-3')}>
                  <Text style={styles.textLabel}>Access gate</Text>
                  <Text style={styles.detailsText}>
                    {visitorLog?.accessGate}
                  </Text>
                </View>
                <Divider />
                <View style={tailwind('flex-row  my-3')}>
                  <Text style={styles.textLabel}>Status</Text>
                  <Text style={styles.detailsText}>{visitorLog?.status}</Text>
                </View>
                <Divider />
                <View style={tailwind('flex-row  my-3')}>
                  <Text style={styles.textLabel}>Destination</Text>
                  <Text style={styles.detailsText}>
                    {visitorLog?.destination}
                  </Text>
                </View>
                <Divider />
                <View style={tailwind('flex-row  my-3')}>
                  <Text style={styles.textLabel}>Reason for denial</Text>
                  <Text style={styles.detailsText}>
                    {visitorLog?.reasonforDenial}
                  </Text>
                </View>
                <Divider />
                <View style={tailwind('flex-row  my-3')}>
                  <Text style={styles.textLabel}>Check in</Text>
                  <Text style={styles.detailsText}>{visitorLog?.checkIn}</Text>
                </View>
                <Divider />
                <View style={tailwind('flex-row  my-3')}>
                  <Text style={styles.textLabel}>Check in by</Text>
                  <Text style={styles.detailsText}>
                    {visitorLog?.checkinby}
                  </Text>
                </View>
                <Divider />
                <View style={tailwind('flex-row  my-3')}>
                  <Text style={styles.textLabel}>Check in time date</Text>
                  {visitorLog?.checkinTimeDate ? (
                    <Text style={styles.detailsText}>
                      {new Date(visitorLog?.checkinTimeDate).toLocaleString()}
                    </Text>
                  ) : (
                    <Text style={styles.detailsText}>Not Available</Text>
                  )}
                </View>
                <Divider />
                <View style={tailwind('flex-row  my-3')}>
                  <Text style={styles.textLabel}>Check out</Text>
                  <Text style={styles.detailsText}>{visitorLog?.checkOut}</Text>
                </View>
                <Divider />
                <View style={tailwind('flex-row  my-3')}>
                  <Text style={styles.textLabel}>Check in by</Text>
                  <Text style={styles.detailsText}>
                    {visitorLog?.checkOutby}
                  </Text>
                </View>
                <Divider />
                <View style={tailwind('flex-row  my-3')}>
                  <Text style={styles.textLabel}>Check out time date</Text>
                  {visitorLog?.checkOutTimeDate ? (
                    <Text style={styles.detailsText}>
                      {new Date(visitorLog?.checkOutTimeDate).toLocaleString()}
                    </Text>
                  ) : (
                    <Text style={styles.detailsText}>Not Available</Text>
                  )}
                </View>
                <Divider />
                <View style={tailwind('flex-row  my-3')}>
                  <Text style={styles.textLabel}>Vehicle Make</Text>
                  <Text style={styles.detailsText}>
                    {visitorLog?.vehicleMake}
                  </Text>
                </View>
                <Divider />
                <View style={tailwind('flex-row my-3')}>
                  <Text style={styles.textLabel}>Vehicle Model</Text>
                  <Text style={styles.detailsText}>
                    {visitorLog?.vehicleModel}
                  </Text>
                </View>
                <Divider />
                <View style={tailwind('flex-row  my-3')}>
                  <Text style={styles.textLabel}>Vehicle License</Text>
                  <Text style={styles.detailsText}>
                    {visitorLog?.vehicleLicense}
                  </Text>
                </View>
                <Divider />
                <View style={tailwind('flex-row  my-3')}>
                  <Text style={styles.textLabel}>Vehicle License State</Text>
                  <Text style={styles.detailsText}>
                    {visitorLog?.vehicleLicenseState}
                  </Text>
                </View>
                <Divider />
                <View style={tailwind('flex-row  my-3')}>
                  <Text style={styles.textLabel}>Vehicle color</Text>
                  <Text style={styles.detailsText}>
                    {visitorLog?.vehicleColor}
                  </Text>
                </View>
                <Divider />
                <View style={tailwind('flex-row  my-3')}>
                  <Text style={styles.textLabel}>Vehicle Pass Expiration</Text>
                  <Text style={styles.detailsText}>
                    {visitorLog?.vehiclePassExpiration}
                  </Text>
                </View>
                <Divider />
                <View style={tailwind('flex-row  my-3')}>
                  <Text style={styles.textLabel}>Visitor Type</Text>
                  <Text style={styles.detailsText}>
                    {visitorLog?.visitorType}
                  </Text>
                </View>
                <Divider />
                <View style={tailwind('flex-row  my-3')}>
                  <Text style={styles.textLabel}>Visitor company</Text>
                  <Text style={styles.detailsText}>
                    {visitorLog?.visitorCompany}
                  </Text>
                </View>
                <Divider />
                <View style={tailwind('flex-row  my-3')}>
                  <Text style={styles.textLabel}>Authorized To</Text>
                  <Text style={styles.detailsText}>
                    {visitorLog?.authorizedTo}
                  </Text>
                </View>
                <View style={tailwind('flex-row  my-3')}>
                  <Text style={styles.textLabel}>Note</Text>
                  <Text style={styles.detailsText}>{visitorLog?.note}</Text>
                </View>
                <Text style={styles.textLabel}>Tag image</Text>
                  <ZoomableImage
                  title='Tag Image'
                    source={{
                      uri: tagImage,
                    }}
                    style={{width: 150, height: 76, borderRadius: 4}}
                  />
                <Text style={styles.textLabel}>Full image</Text>
                  <ZoomableImage
                  title={visitorLog?.visitorFirstName}
                    source={{
                      uri: fullImage,
                    }}
                    style={styles.image}
                  />
                <View style={tailwind('flex-row  my-3')}>
                  <Text style={styles.textLabel}>Time to process</Text>
                  <Text style={styles.detailsText}>
                    {visitorLog?.timeToProcess}
                  </Text>
                </View>
                <View style={tailwind('my-3')}>
                  <Recipients />
                </View>
              </Card>
            </ScrollView>
          </>
        )}
    </Container>
  );
};

export default VisitorLogDetails;
