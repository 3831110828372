// @ts-nocheck
import React, {useEffect, useState} from 'react';
import {
  TouchableOpacity,
  KeyboardAvoidingView,
  Platform,
  Keyboard,
  View,
} from 'react-native';
import Text from '../../../components/Text';
import Icon from 'react-native-vector-icons/Feather';
import tailwind from 'tailwind-rn';
import {useDispatch, useSelector} from 'react-redux';
import {useFocusEffect, useNavigation} from '@react-navigation/native';
import {styles} from '../../../styles';
import VersionCode from '../components/VersionCode';
import {RootStore} from '../../../redux/store';
import {AUTH_SIGN_IN_LOADING, SignIn} from '../../Auth/reducer';
import {getRememberMe, getUser} from '../../../local';
import Button from '../../../components/Button';
import Checkbox from '../../../components/Checkbox';
import Heading from '../../../components/Heading';
import Input from '../../../components/Input';
import {RootState} from '../../../redux/reducers';
import ScreenWrapper from '../components/ScreenWrapper';
import ScreenContent from '../components/ScreenContent';
import {useToast} from 'native-base';

const SignInScreen = () => {
  const navigation = useNavigation();
  const registeredUserName = useSelector(
    (state: RootState) => state.register?.username,
  );
  const [userName, setUserName] = useState('');

  const [password, setPassword] = useState('');
  const [passwordHidden, setpasswordHidden] = useState(true);
  const [rememberMeChecked, setRememberMeChecked] = useState(false);

  const isLoading = useSelector((state: RootStore) => state.auth.loading);
  const dispatch = useDispatch();

  const toast = useToast();

  useFocusEffect(
    React.useCallback(() => {
      dispatch({type: AUTH_SIGN_IN_LOADING, loading: false});
      if (!registeredUserName) {
        updateStateWithLocalStore();
      }
    }, [registeredUserName]),
  );

  useEffect(() => {
    setUserName(registeredUserName);
    setPassword('');
  }, [registeredUserName]);

  const updateStateWithLocalStore = async () => {
    try {
      const rememberMeString = await getRememberMe();
      let rememberMe = rememberMeString === 'true' ? true : false;
      setRememberMeChecked(rememberMe);
      if (rememberMe) {
        const user = await getUser();
        if (user != null) {
          setUserName(user.username);
          setPassword(user.password);
          setpasswordHidden(true);
        }
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleSubmit = () => {
    Keyboard.dismiss();
    dispatch(SignIn(userName?.trim(), password?.trim(), rememberMeChecked));
  };

  return (
    <ScreenWrapper>
      <ScreenContent>
        <View style={tailwind('mx-6 mt-4')}>
          <Heading testID="SignScreenLabelText">Sign In </Heading>
        </View>
        <View style={tailwind('pt-16 pb-8 px-4 flex-1 justify-around')}>
          <View style={tailwind('pt-16')}>
            <Input
              style={tailwind('flex-1 ml-3 text-lg ')}
              onChangeText={text => setUserName(text)}
              value={userName}
              placeholder={'Enter username'}
              autoCorrect={false}
              autoCapitalize="none"
              InputLeftElement={
                <Icon
                  color="gray"
                  name="user"
                  size={24}
                  style={tailwind('m-2')}
                />
              }
              testID="LoginUserNameInput"
            />
          </View>
          <View style={tailwind('mt-12 justify-between flex-1')}>
            <Input
              style={tailwind('flex-1 ml-3 text-lg')}
              onChangeText={text => setPassword(text)}
              value={password}
              placeholder={'Enter password'}
              autoCorrect={false}
              autoCapitalize="none"
              secureTextEntry={passwordHidden}
              InputLeftElement={
                <Icon
                  color="gray"
                  name="lock"
                  size={24}
                  style={tailwind('m-2')}
                />
              }
              InputRightElement={
                <TouchableOpacity
                  onPress={() => setpasswordHidden(!passwordHidden)}>
                  <Icon
                    name={passwordHidden ? 'eye-off' : 'eye'}
                    size={24}
                    color="gray"
                    style={tailwind('m-2')}
                  />
                </TouchableOpacity>
              }
            />
          </View>
          <View style={tailwind('py-2 mt-8 justify-between')}>
            <View style={tailwind('flex-row flex-1')}>
              <Checkbox
                style={tailwind('mt-2 mr-1')}
                value={rememberMeChecked ? 'true' : 'false'}
                isChecked={rememberMeChecked}
                onChange={value => {
                  setRememberMeChecked(value);
                }}
                aria-label="Remember me"
              />
              <Text style={[styles.checkBoxLabel]}>Remember me</Text>
            </View>
            <Button
              onPress={() => handleSubmit()}
              isLoading={isLoading}
              isDisabled={isLoading}
              isLoadingText="Signing in ..."
              size="lg"
              py="3"
              style={tailwind('mt-12')}>
              Sign in
            </Button>
            <Button
              onPress={() => navigation.navigate('Forgot Password')}
              variant="link"
              isDisabled={isLoading}
              size="lg"
              isLoadingText="Signing in ..."
              style={tailwind('mt-6')}>
              Forgot password
            </Button>
            <Button
              onPress={() => navigation.navigate('Account Setup Info')}
              variant="link"
              size="lg"
              isDisabled={isLoading}
              isLoadingText="Signing in ..."
              style={tailwind('mt-3')}>
              Don't have an account? Create here
            </Button>
            <Button
              onPress={() => {
                navigation.navigate('Trainning Video')
              }}
              variant="link"
              size="lg"
              isDisabled={isLoading}
              style={tailwind('mt-1 text-center')}>
              Install Help, Tap Here
            </Button>
          </View>
        </View>
        <VersionCode />
      </ScreenContent>
    </ScreenWrapper>
  );
};

export default SignInScreen;
