import React from 'react';
import {
  useToast,
  VStack,
  HStack,
  IconButton,
  CloseIcon,
  Alert,
} from 'native-base';
import Text from './Text';
import {Pressable} from 'react-native';

const PermissionInfoText = ({title, description}) => {
  const toast = useToast();
  const ToastDetails = [
    {
      title: title.endsWith('?') ? title.split('?')[0] : title,
      variant: 'solid',
      description,
      isClosable: true,
    },
  ];

  const ToastAlert = ({
    id,
    status,
    variant,
    title,
    description,
    isClosable,
    ...rest
  }) => (
    <Alert
      mx={2}
      maxWidth="100%"
      alignSelf="center"
      flexDirection="row"
      status={status ? status : 'info'}
      variant={variant}
      {...rest}>
      <VStack space={1} flexShrink={1} w="100%">
        <HStack
          flexShrink={1}
          alignItems="center"
          justifyContent="space-between">
          <HStack space={2} flexShrink={1} alignItems="center">
            <Alert.Icon />
            <Text
              fontSize="md"
              fontWeight="medium"
              flexShrink={1}
              color={
                variant === 'solid'
                  ? 'lightText'
                  : variant !== 'outline'
                  ? 'darkText'
                  : null
              }>
              {title}
            </Text>
          </HStack>
          {isClosable ? (
            <IconButton
              variant="unstyled"
              icon={<CloseIcon size="3" />}
              _icon={{
                color: variant === 'solid' ? 'lightText' : 'darkText',
              }}
              onPress={() => toast.close(id)}
            />
          ) : null}
        </HStack>
        <Text
          px="6"
          color={
            variant === 'solid'
              ? 'lightText'
              : variant !== 'outline'
              ? 'darkText'
              : null
          }>
          {description}
        </Text>
      </VStack>
    </Alert>
  );

  return (
    <>
      {ToastDetails.map((item, index) => (
        <Pressable
          style={{
            flex: 1,
          }}
          onPress={
            () => null
            // toast.show({
            //   render: ({id}) => {
            //     return <ToastAlert status="info" id={id} {...item} />;
            //   },
            // })
          }>
          <Text
            key={index}
            style={{
              color: '#52525b',
              marginBottom: 4,
              flex: 1,
              fontSize: 15,
              marginTop: 8,
            }}>
            {title}
          </Text>
        </Pressable>
      ))}
    </>
  );
};

export default PermissionInfoText;
