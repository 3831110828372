import React, {useState} from 'react';
import {View, Linking} from 'react-native';
import Text from '../../../components/Text';
import {Menu, Card} from 'react-native-paper';
import tailwind from 'tailwind-rn';
import {styles} from '../../../styles';
import {deriveDisplayName} from '../../../utils';
import IconButton from '../../../components/IconButton';
import {initiateSMS, initiateSendEmail} from '../../App/service';
import {showInformationMessage} from '../../../service/flashMessage';
import ZoomableImage from '../../../components/ZoomableImage';

const DirectoryItem = ({unitPeopleLink, currentUser}) => {
  const [menuVisible, setMenuVisible] = useState(false);
  // const hasOwner = unitPeopleLink.person.owner ? true : false;
  const imageUrl = unitPeopleLink?.profilePicture
    ? `data:image/jpeg;base64,${unitPeopleLink?.profilePicture}`
    : 'https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/default-avatar.png';
  const phoneNumber = unitPeopleLink?.person.phone
    ? unitPeopleLink?.person.phone
    : unitPeopleLink.phone;
  const phoneAvailable = !!phoneNumber;
  const email = unitPeopleLink?.person.email
    ? unitPeopleLink?.person.email
    : unitPeopleLink.email;
  const emailAvailable = !!email;
  const isCurrentUser = currentUser === unitPeopleLink?.person?.id;

  return (
    <Card style={tailwind('rounded my-2')}>
      <View style={tailwind('flex-row p-2 justify-between items-center')}>
        <ZoomableImage
        title={deriveDisplayName(unitPeopleLink)}
          style={styles.userImage}
          source={{
            uri: imageUrl,
          }}
        />
        <View style={tailwind('flex-1 ml-2 flex-col justify-around')}>
          <View style={tailwind('flex-row')}>
            <Text style={tailwind('text-lg flex-1')}>
              {deriveDisplayName(unitPeopleLink)}
            </Text>
            {isCurrentUser && (
              <View style={styles.currentUser}>
                <Text style={tailwind('text-xs px-2 mx-1')} numberOfLines={1}>
                  You
                </Text>
              </View>
            )}
          </View>
          {deriveDisplayName(unitPeopleLink?.person) !== ' ' && (
            <Text style={tailwind('flex-1')}>
              {`Profile name-  ${deriveDisplayName(unitPeopleLink?.person)}`}
            </Text>
          )}
          <Text style={tailwind('text-xs my-1')}>
            {unitPeopleLink?.peopleType?.name?.toUpperCase()}
          </Text>
        </View>
        <View style={tailwind('mt-3 mr-4')}>
          <Menu
            visible={menuVisible}
            onDismiss={() => setMenuVisible(false)}
            anchor={
              <IconButton
                icon="ellipsis-vertical"
                iconColor={'#718096'}
                size={24}
                onPress={() => setMenuVisible(true)}
              />
            }>
            <>
              <Menu.Item
                onPress={() => {
                  setMenuVisible(false);
                  Linking.openURL(`tel:${unitPeopleLink?.person?.phone}`);
                }}
                title={phoneAvailable ? 'Call' : 'Phone number N/A'}
                disabled={!phoneAvailable}
              />
              <Menu.Item
                onPress={() => {
                  setMenuVisible(false);
                  initiateSMS(unitPeopleLink?.person?.phone);
                }}
                title={phoneAvailable ? 'Send Text' : 'Phone number N/A'}
                disabled={!phoneAvailable}
              />
            </>
            <Menu.Item
              onPress={() => {
                setMenuVisible(false);
                initiateSendEmail(unitPeopleLink?.person?.email, '', '');
              }}
              title={emailAvailable ? 'Send Email' : 'Email addres N/A'}
              disabled={!emailAvailable}
            />
            <Menu.Item
              onPress={() => {
                setMenuVisible(false);
                showInformationMessage('Will trigger approval process');
              }}
              title={'Add to my unit'}
            />
          </Menu>
        </View>
      </View>
    </Card>
  );
};

export default DirectoryItem;
