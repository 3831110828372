import React from 'react';
import {View} from 'react-native';
import Text from '../../../components/Text';
import VStack from '../../../components/VStack';
import HStack from '../../../components/VStack';
import tailwind from 'tailwind-rn';
import {colorSwatches} from '../../../styles';

const Legend = () => {
  return (
    <VStack px={2} mt={4}>
      <Text style={{fontSize: 16}}>Legend</Text>
      <HStack style={tailwind('mt-2 flex-row')}>
        <View
          style={[
            tailwind('w-4 h-4 rounded-full'),
            {backgroundColor: colorSwatches.secondary.color},
          ]}
        />
        <Text style={{marginLeft: 8}}>
          Dates marked in green are approved .
        </Text>
      </HStack>
      <HStack style={tailwind('mt-1 flex-row')}>
        <View
          style={[
            tailwind('w-4 h-4 rounded-full'),
            {backgroundColor: colorSwatches.gray.color},
          ]}
        />
        <Text style={{marginLeft: 8}}>
          Dates marked in grey are not approved .
        </Text>
      </HStack>
    </VStack>
  );
};

export default Legend;
