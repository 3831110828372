const buildAlertObject = data => {
  if (data.EventName.toLowerCase().includes('bulk')) {
    return {
      tag: data.Message,
      message: data.Message,
      eventName: data.EventName,
      data: {
        eventName: data.EventName,
        id: data.Id,
        message: data.Message,
        alertId: data.AlertId
      },
    };
  }
  const approval = JSON.parse(data.Approval);
  const unit = JSON.parse(data.Unit);
  const dateFrame = JSON.parse(data.DateFrame);

  let alert = {
    tag: data.AlertId,
    message: data.Message,
    eventName: data.EventName,
    // button1: data.Button1 || null,
    // button2: data.Button2 || null,
    // button3: data.Button3 || null,
    data: {
      alertId: data.AlertId,
      approval: {
        id: approval.Id,
        recordId: approval.RecordId,
        type: approval.Type,
      },
      dateFrame: {
        description: dateFrame.Description,
        endDate: dateFrame.EndDate,
        startDate: dateFrame.StartDate,
      },
      eventName: data.EventName,
      id: data.Id,
      message: data.Message,
      status: data.Status,
      unit: {
        unitName: unit.UnitName,
        unitId: unit.UnitId,
      },
    },
  };

  if (!!data.Sender) {
    const sender = JSON.parse(data.Sender);
    alert = {
      ...alert,
      data: {
        ...alert.data,
        // @ts-ignore
        sender: {
          deviceName: sender.DeviceName,
          id: sender.Id,
          image: sender.Image,
          name: sender.Name,
          type: sender.Type,
        },
      },
    };
  }
  console.log('alert in buildAlert object', alert);
  return alert;
};

export default buildAlertObject;
