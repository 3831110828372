export const IMAGE_OPTIONS = {
  width: 300,
  height: 400,
  cropping: false,
  includeBase64: true,
  compressImageQuality: 0.6,
};

export const NOTIFICATION_STATUS = {
  READ: 'Read',
};

export const SYSTEM_FOREVER_DATEFRAME_ID =
  '11111111-1111-1111-1111-111111111111';
