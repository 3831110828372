const initialViolationState = {
  unitId: '', //
  violationCategory: '', //
  specificViolation: '', //
  severityOfViolation: '', //
  violationDescription: '', //
  violationSource: '', //
  licensePlate: '', //
  licenseState: '', //
  parkingSpaceDecalNo: '', //
  dateReported: new Date().toISOString(), //
  timeReported: new Date().toISOString(), //
  actionTaken: '', //
  nextStatus: new Date().toISOString(), //
  dateCorrected: new Date().toISOString(), //
  timeCorrected: new Date().toISOString(), //
  dateToBeCorrectedBy: new Date().toISOString(), //
  reportedBy: '', //
  locationOfViolation: '', //
  statusOfViolation: '', //
  violationFee: 0, //
  createdLogon: '', //
  createdTimeDate: new Date().toISOString(), //
  lastLogon: '', //
  lastTimeDate: new Date().toISOString(), //
};

const fields = [
  {
    fieldName: 'Violation category',
    state: 'violationCategory',
    type: 'textInput',
  },
  {
    fieldName: 'Specific violation',
    state: 'specificViolation',
    type: 'textInput',
  },
  {
    fieldName: 'Severity of violation',
    state: 'severityOfViolation',
    type: 'textInput',
  },
  {
    fieldName: 'Violation description',
    state: 'violationDescription',
    type: 'textInput',
  },
  {
    fieldName: 'Violation source',
    state: 'violationSource',
    type: 'textInput',
  },
  {
    fieldName: 'License plate',
    state: 'licensePlate',
    type: 'textInput',
  },
  {
    fieldName: 'License state',
    state: 'licenseState',
    type: 'select',
  },
  {
    fieldName: 'Parking space DecalNo',
    state: 'parkingSpaceDecalNo',
    type: 'textInput',
  },
  {
    fieldName: 'Action taken',
    state: 'actionTaken',
    type: 'textInput',
  },
  {
    fieldName: 'Reported by',
    state: 'reportedBy',
    type: 'textInput',
  },
  {
    fieldName: 'Location of violation',
    state: 'locationOfViolation',
    type: 'textInput',
  },
  {
    fieldName: 'Status of violation',
    state: 'statusOfViolation',
    type: 'textInput',
  },
  {
    fieldName: 'Violation fee',
    state: 'violationFee',
    type: 'textInput',
  },
];

export {initialViolationState, fields};
