import {Dispatch} from 'redux';
import {showErrorMessage, showSuccessMessage} from '../../service/flashMessage';
import {fetchPetsByUnit, updatePet} from '../../api/pets';
import * as RootNavigation from '../../navigation';
import {Pet} from '../../@types';
import {store} from '../../redux/store';

export const PET_LOADING = 'PET_LOADING';
export const PET_FAIL = 'PET_FAIL';
export const PET_SUCCESS = 'PET_SUCCESS';
export const PET_UPDATE_LOADING = 'PET_UPDATE_LOADING';
export const PET_UPDATE_FAIL = 'PET_UPDATE_FAIL';
export const PET_UPDATE_SUCCESS = 'PET_UPDATE_SUCCESS';
export const PET_SEARCH = 'PET_SEARCH';

export interface PetLoading {
  type: typeof PET_LOADING;
}

export interface PetFail {
  type: typeof PET_FAIL;
  error: string | object;
}

export interface PetSuccess {
  type: typeof PET_SUCCESS;
  payload: Pet[];
}

export interface PetUpdateLoading {
  type: typeof PET_UPDATE_LOADING;
}

export interface PetUpdateFail {
  type: typeof PET_UPDATE_FAIL;
  error: string | object;
}

export interface PetUpdateSuccess {
  type: typeof PET_UPDATE_SUCCESS;
  payload: Pet;
}

export interface PetSearch {
  type: typeof PET_SEARCH;
  payload: {
    searchTerm: string;
    cachedPets: Pet[];
  };
}

export type PetDispatchTypes =
  | PetLoading
  | PetFail
  | PetSuccess
  | PetUpdateSuccess
  | PetUpdateLoading
  | PetUpdateFail
  | PetSearch;

export const GetPets =
  (searchTerm: string) => async (dispatch: Dispatch<PetDispatchTypes>) => {
    try {
      dispatch({type: PET_LOADING});

      const defaultLink = store
        .getState()
        .units.unitLinks.filter(unitLink => unitLink.isDefault === true)[0];

      let pets: Pet[] = await fetchPetsByUnit(defaultLink?.unit.id, searchTerm);

      dispatch({type: PET_SUCCESS, payload: pets});
    } catch (error) {
      console.log('error', error);
      dispatch({type: PET_FAIL, error: error});
    }
  };

export const UpdatePet =
  (petId: string) => async (dispatch: Dispatch<PetDispatchTypes>) => {
    try {
      dispatch({type: PET_UPDATE_LOADING});

      const newPet = await updatePet(petId);

      dispatch({type: PET_UPDATE_SUCCESS, payload: newPet});

      showSuccessMessage('Pet updated successfully');

      RootNavigation.goBack();
    } catch (error) {
      dispatch({type: PET_UPDATE_FAIL, error: error});
      showErrorMessage(error);
    }
  };

interface DefaultStateI {
  loading: boolean;
  error?: string | object;
  pets?: Pet[];
}

const defaultState: DefaultStateI = {
  loading: false,
  error: null,
  pets: [],
};

const petReducer = (
  state: DefaultStateI = defaultState,
  action: PetDispatchTypes,
): DefaultStateI => {
  switch (action.type) {
    case PET_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case PET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case PET_SUCCESS:
      return {
        ...state,
        loading: false,
        pets: action.payload,
      };
    case PET_UPDATE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case PET_UPDATE_SUCCESS:
      const {id} = action.payload;
      const previousState = state.pets;
      const newState = previousState.map((item, _) => {
        if (item.id !== id) {
          return item;
        }
        return {
          ...item,
          ...action.payload,
        };
      });
      return {
        ...state,
        pets: newState,
        loading: false,
      };
    case PET_SEARCH:
      const newPets = action.payload.cachedPets.filter(pet => {
        const petData = pet.petName
          ? `${pet.petName}`.toUpperCase()
          : ''.toUpperCase();
        const searchTermData = action.payload.searchTerm.toUpperCase();
        return petData.indexOf(searchTermData) > -1;
      });
      return {
        ...state,
        pets: newPets,
      };
    default:
      break;
  }
  return state;
};

export default petReducer;
