// @ts-nocheck
import React, {useState} from 'react';
import {ScrollView, View} from 'react-native';
import Text from '../../../components/Text';
import {ActivityIndicator, Card, Divider} from 'react-native-paper';
import tailwind from 'tailwind-rn';
import {styles} from '../../../styles';
import {Navbar} from '../../../components';
import {useEffect} from 'react';
import {showErrorMessage} from '../../../service/flashMessage';
import {Vehicle} from '../types';
import {fetchVehicleById} from '../../../api/vehicles';
import Container from '../../../components/Container';
import ZoomableImage from '../../../components/ZoomableImage';

const VehicleDetail = ({navigation, route}) => {
  const {vehicleId} = route.params;
  const [isLoading, setIsLoading] = useState(true);
  const [vehicle, setVehicle] = useState<Vehicle>({});

  const fetchVehicle = async (id: string) => {
    try {
      const res: Vehicle = await fetchVehicleById(id);
      setVehicle(res);
      setIsLoading(false);
    } catch (error) {
      showErrorMessage(error.message);
    }
  };

  useEffect(() => {
    fetchVehicle(vehicleId);
  }, [vehicleId]);

  return (
    <Container>
        <Navbar navigation={navigation} title="Vehicle details" />
        {isLoading ? (
          <ActivityIndicator />
        ) : (
          <>
            <Card style={tailwind('mx-2 mt-4 pb-2')}>
              <View style={tailwind('flex-row justify-between p-2')}>
                <ZoomableImage
                  title={vehicle.licensePlate}
                  source={{
                    uri: vehicle.vehicleImage
                      ? `data:image/jpeg;base64,${vehicle.vehicleImage}`
                      : 'https://nichemodels.co/wp-content/uploads/2019/03/user-dummy-pic.png',
                  }}
                  style={styles.image}
                />
                <View style={tailwind('flex-1 ml-4')}>
                  <Text style={styles.userName}>{vehicle.vehicleOwner}</Text>
                  <Text style={styles.email}>{vehicle.licensePlate}</Text>
                </View>
              </View>
            </Card>
            <ScrollView
              style={tailwind('mx-2 py-4')}
              showsVerticalScrollIndicator={false}>
              <Card style={[tailwind('p-2 mb-8')]}>
                <View style={tailwind('flex-1 flex-row justify-between')} />
                <View style={tailwind('flex-row  my-3')}>
                  <Text style={styles.textLabel}>License Plate state</Text>
                  <Text style={styles.detailsText}>{vehicle.licenseState}</Text>
                </View>
                <Divider />
                <View style={tailwind('flex-row  my-3')}>
                  <Text style={styles.textLabel}>Make</Text>
                  <Text style={styles.detailsText}>
                    {vehicle.makeOfVehicle}
                  </Text>
                </View>
                <Divider />
                <View style={tailwind('flex-row  my-3')}>
                  <Text style={styles.textLabel}>Model</Text>
                  <Text style={styles.detailsText}>
                    {vehicle.modelOfVehicle}
                  </Text>
                </View>
                <Divider />
                <View style={tailwind('flex-row  my-3')}>
                  <Text style={styles.textLabel}>Year</Text>
                  <Text style={styles.detailsText}>
                    {vehicle.yearOfVehicle}
                  </Text>
                </View>
                <Divider />
                <View style={tailwind('flex-row my-3')}>
                  <Text style={styles.textLabel}>Color</Text>
                  <Text style={styles.detailsText}>
                    {vehicle.colorOfVehicle}
                  </Text>
                </View>
                <Divider />
                <View style={tailwind('flex-row  my-3')}>
                  <Text style={styles.textLabel}>Vehicle Security Pass</Text>
                  <Text style={styles.detailsText}>
                    {vehicle.vehicleSecurityPass}
                  </Text>
                </View>
                <Divider />
                <View style={tailwind('flex-row  my-3')}>
                  <Text style={styles.textLabel}>Dateframe StartDate</Text>
                  <Text style={styles.detailsText}>
                    {new Date(vehicle.dateFrame.startDate).toLocaleDateString()}
                  </Text>
                </View>
                <Divider />
                <View style={tailwind('flex-row  my-3')}>
                  <Text style={styles.textLabel}>Dateframe EndDate</Text>
                  <Text style={styles.detailsText}>
                    {new Date(vehicle.dateFrame.endDate).toLocaleDateString()}
                  </Text>
                </View>
                <Divider />
                <View style={tailwind('flex-row  my-3')}>
                  <Text style={styles.textLabel}>Dateframe Description</Text>
                  <Text style={styles.detailsText}>
                    {vehicle.dateFrame.description}
                  </Text>
                </View>
                <Divider />
                <View style={tailwind('flex-row  my-3')}>
                  <Text style={styles.textLabel}>Valet Vehicle No</Text>
                  <Text style={styles.detailsText}>
                    {vehicle.valetVehicleNo}
                  </Text>
                </View>
                <Divider />
                <View style={tailwind('flex-row  my-3')}>
                  <Text style={styles.textLabel}>Valet information</Text>
                  <Text style={styles.detailsText}>
                    {vehicle.valetInformation}
                  </Text>
                </View>
                <Divider />
                <View style={tailwind('flex-row  my-3')}>
                  <Text style={styles.textLabel}>Valet status</Text>
                  <Text style={styles.detailsText}>{vehicle.valetStatus}</Text>
                </View>
              </Card>
            </ScrollView>
          </>
        )}
    </Container>
  );
};

export default VehicleDetail;
