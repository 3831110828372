import React from 'react';
import {Navbar} from '../../components';
import {deriveDisplayName} from '../../utils';
import EditOthersUnitLink from '../UnitPeopleLink/screens/EditOthersUnitLink';
import Container from '../../components/Container';
import FormContainer from '../../components/FormContainer';

export default function UserPermissions({navigation, route}) {
  const {
    isLoggedInUser,
    unitPeopleLink,
    canEditOtherPeoplePermissions,
    canEditOwnPermissions,
  } = route.params;
  const unitName = unitPeopleLink.unitNickname
    ? unitPeopleLink.unitNickname
    : unitPeopleLink.unit.unitName;

  const title = `${
    isLoggedInUser ? 'Your' : `${deriveDisplayName(unitPeopleLink)}'s`
  } permissions for unit ${unitName}`;

  return (
    <Container>
      <Navbar navigation={navigation} title={title} />
      <FormContainer>
        <EditOthersUnitLink
          initialPeopleState={unitPeopleLink}
          updateParentState={() => null}
          isEditMode={
            isLoggedInUser
              ? canEditOwnPermissions
              : canEditOtherPeoplePermissions
          }
          unitPeopleLink={unitPeopleLink}
        />
      </FormContainer>
    </Container>
  );
}
