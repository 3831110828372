/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import {FlatList, ScrollView, View} from 'react-native';
import Text from '../../../components/Text';
import {Card, Divider} from 'react-native-paper';
import tailwind from 'tailwind-rn';
import {styles} from '../../../styles';
import {LoadingSpinner, Navbar} from '../../../components';
import {Package} from '../types';
import {usePackage, usePackageLogs} from '../../../hooks/packages';
import Container from '../../../components/Container';
import Content from '../../../navigation/Content';
import ZoomableImage from '../../../components/ZoomableImage';

export default function PackageDetails({navigation, route}) {
  const {isLoading, data} = usePackage(route.params?.packageItem?.id);

  const packageItem: Package = data;
  const imageUrl = packageItem?.receivedImage
    ? `data:image/jpeg;base64,${packageItem?.receivedImage}`
    : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAG1BMVEXr6+vY2Njp6ena2trd3d3g4ODj4+Pm5ubW1tbKf8WKAAADqklEQVR4nO3a25biIBBGYUMOzPs/8aiQSOAHk8zYTbn2d9m0LsqC4pDcbgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAY9w83S2u1u5D+0926f+ah2jyqnkZY/Mom/vnpuFF5OlNswFpBMMwZ61u3DVbDHEZhlYM+/iHsTpXu5Xl6G432ea8Nc9x/4oQhjFpzRNsMYlFCnfjtN1qgitDSNJUpvC+ovxmdy9QMbzSNInGsfV1HSqnYRKEV42DsYkoI1zLqW78igjjkiDqzLdEGKqJqkLfEmGoprIKmYtQR9GK0Fot1eUy5Kk1gg1pVJNmFbJDLerNCM2dgvVkq0dobRqq09PQrDTmpqHOVAhDViFja8WTCGOuthhMoUxidV9q5/zr054WM7F+tkiXCt9tVXXzM6TXBXBRbLau50vJFqCP39FlkH6LZ1xPulnRfM21bO+95tZN23d0uP7vRt4U0+hHEcfD8va/+ys9+4G3Vo7k3nvaFZMk42u6soz3djOVr+JbwpYpzs7sA3HWjvMaeF5+eiuvRd1MRpnzurO7v5d7oL6SKPZoJztY7tT7moliq3luK/3PX/BpajN9quCrffqnOnuJivBMqZAHqs9194L2Ffd78kbgc929QEZ4fCLpW50P9ve8xon+CAOnfi+H2eFhKj/d12qh+3i4msoR0NnmW46zMgvOe7HBkdf8vb2CImtFFuH98Bfk7xDpCH+w94eo+9E0wrjT3prSDMkI+9qW3t4+uJ7LK41XjPKR+C/E8IaoNVuEiy6W21j9YyCFcibGXjp9w5+EUf4AnS0VQVlOwzjUa+UukNpHe5OnKkwl/Rxm+59nKEX++wywOAY/x2A7wDWY7MfpbLFPTEU3q1Nw/0Psfpze1vqdZM14TrEDAYYQk8nadYC3beELd2uHAoxZjHdvBt6Jds4v8dHDwQDjXLx/bqncyvXqbZHJQjSn8tqM1Nv17yGVN58qutzDtMnn+A39LoE1JyZhYG0qVt6JaujwuNR0cow+9L8Qps7U0S2JpurphRTaKjany0xgKImXUmhpJp4vpIGdcnpxkBpaEy8OUju1pnX11GZld3plMQysTMTrEfb1ULSOCImwf/Va+nh2OI31Umulllbu2MY5XqU5r59FGdq2iRuMac4e+y7iZzCTwmKcjrPajvl8c2dlRxPE10rvM2+S4QV+Xt97HvMkG+Ae7114967fz/9SL2gAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAX+wvqtkVJSpt2GwAAAAASUVORK5CYII=';

  const claimedImage = packageItem?.claimedImage
    ? `data:image/jpeg;base64,${packageItem?.claimedImage}`
    : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAG1BMVEXr6+vY2Njp6ena2trd3d3g4ODj4+Pm5ubW1tbKf8WKAAADqklEQVR4nO3a25biIBBGYUMOzPs/8aiQSOAHk8zYTbn2d9m0LsqC4pDcbgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAY9w83S2u1u5D+0926f+ah2jyqnkZY/Mom/vnpuFF5OlNswFpBMMwZ61u3DVbDHEZhlYM+/iHsTpXu5Xl6G432ea8Nc9x/4oQhjFpzRNsMYlFCnfjtN1qgitDSNJUpvC+ovxmdy9QMbzSNInGsfV1HSqnYRKEV42DsYkoI1zLqW78igjjkiDqzLdEGKqJqkLfEmGoprIKmYtQR9GK0Fot1eUy5Kk1gg1pVJNmFbJDLerNCM2dgvVkq0dobRqq09PQrDTmpqHOVAhDViFja8WTCGOuthhMoUxidV9q5/zr054WM7F+tkiXCt9tVXXzM6TXBXBRbLau50vJFqCP39FlkH6LZ1xPulnRfM21bO+95tZN23d0uP7vRt4U0+hHEcfD8va/+ys9+4G3Vo7k3nvaFZMk42u6soz3djOVr+JbwpYpzs7sA3HWjvMaeF5+eiuvRd1MRpnzurO7v5d7oL6SKPZoJztY7tT7moliq3luK/3PX/BpajN9quCrffqnOnuJivBMqZAHqs9194L2Ffd78kbgc929QEZ4fCLpW50P9ve8xon+CAOnfi+H2eFhKj/d12qh+3i4msoR0NnmW46zMgvOe7HBkdf8vb2CImtFFuH98Bfk7xDpCH+w94eo+9E0wrjT3prSDMkI+9qW3t4+uJ7LK41XjPKR+C/E8IaoNVuEiy6W21j9YyCFcibGXjp9w5+EUf4AnS0VQVlOwzjUa+UukNpHe5OnKkwl/Rxm+59nKEX++wywOAY/x2A7wDWY7MfpbLFPTEU3q1Nw/0Psfpze1vqdZM14TrEDAYYQk8nadYC3beELd2uHAoxZjHdvBt6Jds4v8dHDwQDjXLx/bqncyvXqbZHJQjSn8tqM1Nv17yGVN58qutzDtMnn+A39LoE1JyZhYG0qVt6JaujwuNR0cow+9L8Qps7U0S2JpurphRTaKjany0xgKImXUmhpJp4vpIGdcnpxkBpaEy8OUju1pnX11GZld3plMQysTMTrEfb1ULSOCImwf/Va+nh2OI31Umulllbu2MY5XqU5r59FGdq2iRuMac4e+y7iZzCTwmKcjrPajvl8c2dlRxPE10rvM2+S4QV+Xt97HvMkG+Ae7114967fz/9SL2gAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAX+wvqtkVJSpt2GwAAAAASUVORK5CYII=';

  const signatureImage = packageItem?.signatureImage
    ? `data:image/jpeg;base64,${packageItem?.signatureImage}`
    : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAG1BMVEXr6+vY2Njp6ena2trd3d3g4ODj4+Pm5ubW1tbKf8WKAAADqklEQVR4nO3a25biIBBGYUMOzPs/8aiQSOAHk8zYTbn2d9m0LsqC4pDcbgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAY9w83S2u1u5D+0926f+ah2jyqnkZY/Mom/vnpuFF5OlNswFpBMMwZ61u3DVbDHEZhlYM+/iHsTpXu5Xl6G432ea8Nc9x/4oQhjFpzRNsMYlFCnfjtN1qgitDSNJUpvC+ovxmdy9QMbzSNInGsfV1HSqnYRKEV42DsYkoI1zLqW78igjjkiDqzLdEGKqJqkLfEmGoprIKmYtQR9GK0Fot1eUy5Kk1gg1pVJNmFbJDLerNCM2dgvVkq0dobRqq09PQrDTmpqHOVAhDViFja8WTCGOuthhMoUxidV9q5/zr054WM7F+tkiXCt9tVXXzM6TXBXBRbLau50vJFqCP39FlkH6LZ1xPulnRfM21bO+95tZN23d0uP7vRt4U0+hHEcfD8va/+ys9+4G3Vo7k3nvaFZMk42u6soz3djOVr+JbwpYpzs7sA3HWjvMaeF5+eiuvRd1MRpnzurO7v5d7oL6SKPZoJztY7tT7moliq3luK/3PX/BpajN9quCrffqnOnuJivBMqZAHqs9194L2Ffd78kbgc929QEZ4fCLpW50P9ve8xon+CAOnfi+H2eFhKj/d12qh+3i4msoR0NnmW46zMgvOe7HBkdf8vb2CImtFFuH98Bfk7xDpCH+w94eo+9E0wrjT3prSDMkI+9qW3t4+uJ7LK41XjPKR+C/E8IaoNVuEiy6W21j9YyCFcibGXjp9w5+EUf4AnS0VQVlOwzjUa+UukNpHe5OnKkwl/Rxm+59nKEX++wywOAY/x2A7wDWY7MfpbLFPTEU3q1Nw/0Psfpze1vqdZM14TrEDAYYQk8nadYC3beELd2uHAoxZjHdvBt6Jds4v8dHDwQDjXLx/bqncyvXqbZHJQjSn8tqM1Nv17yGVN58qutzDtMnn+A39LoE1JyZhYG0qVt6JaujwuNR0cow+9L8Qps7U0S2JpurphRTaKjany0xgKImXUmhpJp4vpIGdcnpxkBpaEy8OUju1pnX11GZld3plMQysTMTrEfb1ULSOCImwf/Va+nh2OI31Umulllbu2MY5XqU5r59FGdq2iRuMac4e+y7iZzCTwmKcjrPajvl8c2dlRxPE10rvM2+S4QV+Xt97HvMkG+Ae7114967fz/9SL2gAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAX+wvqtkVJSpt2GwAAAAASUVORK5CYII=';

  const {data: logs, isLoading: isLoadingLogs} = usePackageLogs(
    route.params?.packageItem?.id,
  );
  function Details() {
    if (isLoading) {
      return <LoadingSpinner />;
    }

    return (
      <>
        <ScrollView
          style={tailwind('mx-2 py-4')}
          showsVerticalScrollIndicator={false}>
          <Card style={[tailwind('p-2 mb-8')]}>
            <View style={tailwind('flex-row justify-between p-2')}>
              <ZoomableImage
                title='Package Received'
                source={{
                  uri: imageUrl,
                }}
                style={styles.image}
              />
              <View style={tailwind('flex-1 ml-4')}>
                <Text style={styles.userName}>
                  {packageItem.trackingNumber}
                </Text>
                <Text style={styles.email}>{`From ${packageItem.from}`}</Text>
              </View>
            </View>
            <View style={tailwind('flex-1 flex-row justify-between')} />
            <View style={tailwind('flex-row  my-3')}>
              <Text style={styles.textLabel}>Location</Text>
              <Text style={styles.detailsText}>{packageItem.location}</Text>
            </View>
            <Divider />
            <View style={tailwind('flex-row  my-3')}>
              <Text style={styles.textLabel}>Delivery Company</Text>
              <Text style={styles.detailsText}>
                {packageItem.deliveryCompany}
              </Text>
            </View>
            <Divider />
            <View style={tailwind('flex-row  my-3')}>
              <Text style={styles.textLabel}>Size</Text>
              <Text style={styles.detailsText}>{packageItem.size}</Text>
            </View>
            <Divider />
            <View style={tailwind('flex-row  my-3')}>
              <Text style={styles.textLabel}>Status</Text>
              <Text style={styles.detailsText}>{packageItem.status}</Text>
            </View>
            <Divider />
            <View style={tailwind('flex-row my-3')}>
              <Text style={styles.textLabel}>To</Text>
              <Text style={styles.detailsText}>{packageItem.to}</Text>
            </View>
            <Divider />
            <View style={tailwind('flex-row  my-3')}>
              <Text style={styles.textLabel}>Date time received</Text>
              <Text style={styles.detailsText}>
                {packageItem.dateTimeRecieved}
              </Text>
            </View>
            <Divider />
            <View style={tailwind('flex-row  my-3')}>
              <Text style={styles.textLabel}>Claimed image</Text>
            </View>
            {packageItem.claimedImage ? (
              <ZoomableImage
                title='Package Claimed'
                source={{
                  uri: claimedImage,
                }}
                style={styles.unitsCardImage}
              />
            ) : (
              <View style={styles.emptyDetailsCard} />
            )}
            <View style={tailwind('flex-row  my-3')}>
              <Text style={styles.textLabel}>Claimed date time</Text>
              {packageItem.claimedDateTime ? (
                <Text style={styles.detailsText}>
                  {`${new Date(
                    packageItem.claimedDateTime,
                  ).toLocaleDateString()}`}
                </Text>
              ) : (
                <Text style={styles.detailsText}>Not Provided</Text>
              )}
            </View>
            <Divider />
            <View style={tailwind('flex-row  my-3')}>
              <Text style={styles.textLabel}>Claimed by </Text>
              <Text style={styles.detailsText}>
                {packageItem.whoClaimedPackage
                  ? packageItem.whoClaimedPackage
                  : 'Not Provided'}
              </Text>
            </View>
            <Divider />
            <View style={tailwind('flex-row  my-3')}>
              <Text style={styles.textLabel}>Who claimed package</Text>
              <Text style={styles.detailsText}>
                {packageItem.whoGavePackage
                  ? packageItem.whoGavePackage
                  : 'Not Provided'}
              </Text>
            </View>
            <Divider />
            <View style={tailwind('flex-row  my-3')}>
              <Text style={styles.textLabel}>Unit display</Text>
              <Text style={styles.detailsText}>
                {packageItem?.unit?.unitDisplay
                  ? packageItem.unit.unitDisplay
                  : 'Not Provided'}
              </Text>
            </View>
            <Divider />
            <View style={tailwind('flex-row  my-3')}>
              <Text style={styles.textLabel}>Unit</Text>
              <Text style={styles.detailsText}>{packageItem.unit.unit}</Text>
            </View>
            <Divider />
            <Text style={styles.textLabel}>Signature image</Text>
            {packageItem.signatureImage ? (
              <ZoomableImage
              title='Signature'
                source={{
                  uri: signatureImage,
                }}
                style={styles.unitsCardImage}
              />
            ) : (
              <View style={styles.emptyDetailsCard} />
            )}
          </Card>
        </ScrollView>
      </>
    );
  }

  return (
    <Container>
      <Content>
        <Navbar navigation={navigation} title="Package details" />
        <FlatList
          data={[]}
          renderItem={({}) => <></>}
          ListEmptyComponent={
            <>
              <Text style={tailwind('text-lg  flex-1')}>
                Package information
              </Text>
              <Details />
            </>
          }
        />
      </Content>
    </Container>
  );
}
