import React from 'react';
import Text from '../components/Text';
import HStack from './HStack';
import Spacer from './Spacer';
import Ionicon from 'react-native-vector-icons/Ionicons';
import {UnitLink} from '../@types/UnitLink';
import {colorSwatches} from '../styles';
import { VStack } from 'native-base';

export default function ApprovalStatus({
  unitPeopleLink,
}: {
  unitPeopleLink: UnitLink;
}) {
  return (
    <VStack>
      <HStack>
        <Ionicon
          name={
            unitPeopleLink.unitLinkApproved
              ? 'checkmark-circle'
              : 'alert-circle'
          }
          size={14}
          color={
            unitPeopleLink.unitLinkApproved
              ? colorSwatches.green.color
              : 'orange'
          }
          style={{
            marginTop: 2,
            marginRight: 4,
          }}
        />
        <Text>
          {unitPeopleLink.unitLinkApproved
            ? 'Person approved'
            : 'Person not approved'}
        </Text>
      </HStack>
      <Spacer />
      <HStack>
        <Ionicon
          name={
            unitPeopleLink.peopleLinkApproved  && !!unitPeopleLink.person.id
              ? 'checkmark-circle'
              : 'alert-circle'
          }
          size={14}
          color={
            unitPeopleLink.peopleLinkApproved && !!unitPeopleLink.person.id
              ? colorSwatches.green.color
              : 'orange'
          }
          style={{
            marginTop: 2,
            marginRight: 4,
          }}
        />
        <Text>
          {unitPeopleLink.peopleLinkApproved && !!unitPeopleLink.person.id
            ? 'App link approved'
            : 'No linked profile'}
        </Text>
      </HStack>
    </VStack>
  );
}
