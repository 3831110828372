import {extendTheme} from 'native-base';
import {Appearance} from 'react-native';
const colorScheme = Appearance.getColorScheme();

const theme = extendTheme({
  colors: {
    // Add new color
    primary: {
      50: '#dcf3ff',
      100: '#aed7ff',
      200: '#7dbdff',
      300: '#4aa3ff',
      400: '#1a88ff',
      500: '#006fe6',
      600: '#0056b4',
      700: '#003d82',
      800: '#002551',
      900: '#000d21',
    },
    // Redefinig only one shade, rest of the color will remain same.
    amber: {
      400: '#d97706',
    },
  },
  config: {
    // Changing initialColorMode to 'dark'
    // initialColorMode: colorScheme,
  },
});

export default theme;
