import {residentApiWithAuth} from './resident';

const createVehicle = async (vehicle) => {
  try {
    let res = await residentApiWithAuth().post('/vehicles', vehicle);
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

const updateVehicle = async (vehicle, vehicleId) => {
  try {
    let res = await residentApiWithAuth().post(
      `/vehicles/${vehicleId}`,
      vehicle,
    );
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetchVehicles = async () => {
  try {
    let res = await residentApiWithAuth().get('/vehicles');
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetchVehicleById = async (vehicleId) => {
  try {
    let res = await residentApiWithAuth().get(`/vehicles/${vehicleId}`);

    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

const deleteVehicle = async (vehicleId) => {
  try {
    let res = await residentApiWithAuth().delete(`/vehicles/${vehicleId}`);
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetchVehiclesByUnit = async (unitId, searchTerm) => {
  try {
    let res = await residentApiWithAuth().get(
      `/vehicles/unit/${unitId}?searchKey=${searchTerm}`,
    );
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export {
  createVehicle,
  updateVehicle,
  fetchVehicleById,
  deleteVehicle,
  fetchVehicles,
  fetchVehiclesByUnit,
};
