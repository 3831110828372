// @ts-nocheck
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useState} from 'react';
import {
  Linking,
  useWindowDimensions,
  View,
  StyleSheet,
  Image,
  ScrollView,
} from 'react-native';
import {TouchableOpacity} from 'react-native-gesture-handler';
import {Card, Divider} from 'react-native-paper';
import tailwind from 'tailwind-rn';
import Ionicons from 'react-native-vector-icons/Ionicons';
import {fetchAlertDetail, fetchNotificationById} from '../../../api/alert';
import {LoadingSpinner, Navbar} from '../../../components';
import {showErrorMessage} from '../../../service/flashMessage';
import {styles} from '../../../styles';
import {getNotificationAction} from '../../../utils';
import {useDispatch, useSelector} from 'react-redux';
import {HandleAlertButton, ReadAlert} from '../alertsReducer';
import useColorMode from '../../../components/useColorMode';
import {Alert} from '../../../@types';
import {RootStore} from '../../../redux/store';
import WebView from 'react-native-webview';
import Text from '../../../components/Text';
import {EventNames} from '../../../@types/EventNames';
import {VISITOR_SCREEN_TIMEOUT_DURATION} from '../../../constants';
import isWeb from '../../../utils/isWeb';
import Container from '../../../components/Container';
import ResponseBadge from '../components/ReponseBadge';

const NotificationDetails = ({navigation, route}) => {
  const {colorMode} = useColorMode();
  const color = colorMode === 'light' ? '#fff' : '#000';
  const {alertId, id, isFromTray} = route.params;
  const [isLoading, setIsloading] = useState(true);
  const [notification, setNotification] = useState<Alert>({} as Alert);
  const [isRepliedTo, setIsRepliedTo] = useState(true);
  const dispatch = useDispatch();
  const [buttonsShown, setButtonsShown] = useState(true);
  const [alertTimedOut, setAlertTimedOut] = useState(false);

  const {isReading} = useSelector((state: RootStore) => state.alerts);
  const {height, width} = useWindowDimensions();

  // useFocusEffect(
  //   useCallback(() => {
  //     fetchNotification(alertId,id);
  //   }, [alertId, id]),
  // );

  useEffect(()=>{
    fetchNotification(alertId,id);
  },[alertId, id]);

  useEffect(()=>{
    if(isWeb  && !!notification.appMessageHTML){
     setTimeout(()=>{
        const templateContainer = document.getElementById('tableContainer');
        console.log("templateContainer", templateContainer);
        if(templateContainer){
          templateContainer.style.transition = 'width 1s';
            templateContainer.style.width = '100%';
          }
      }, 1000)
    }

  },[isWeb, notification.appMessageHTML, id])

  const fetchNotification = async (_alertId: string, _id: string) => {
    setIsloading(true);
    try {
      let res: Alert = null;

      if(isFromTray){
         res = await fetchNotificationById(
          _alertId,
          Math.round(width / 4),
          Math.round(height / 4),
        );
      }
      else{
        res = await fetchAlertDetail(
          _id,
          Math.round(width / 4),
          Math.round(height / 4),
        ); 
      }

      if (!res.read) {
        dispatch(ReadAlert(res.id));
      }

      const _isRepliedTo =
        res.eventName === 'PeopleLink' ||
        res.eventName === 'RequestAccess' ||
        res.action !== null;

      setIsRepliedTo(_isRepliedTo);

      /**
       * When should buttons be hidden
       * 1. If are visitorScreening and
       * 2. They don't have an action and
       * 3. Were received in the last 15 minutes
       */
      const isVisitorScreen = res.eventName === EventNames.VISITOR_SCREEN;
      const repliedTo = !!res.action;
      const receivedInLast15Mins =
        new Date().getTime() - new Date(res.time).getTime() >
        VISITOR_SCREEN_TIMEOUT_DURATION;

      if (isVisitorScreen) {
        if (receivedInLast15Mins && !repliedTo) {
          setAlertTimedOut(true);
          setButtonsShown(false);
        } else {
          setAlertTimedOut(false);
          setButtonsShown(true);
        }
      }

      let appMessageHTMLWithImage = res.appMessageHTML.replace(
        'cid:PictureOut',
        `data:image/jpeg;base64,${res.sender.image}`,
      );

      setNotification({...res, appMessageHTML: appMessageHTMLWithImage});
      setIsloading(false);
    } catch (error) {
      showErrorMessage(error.message);
      setIsloading(false);
    }
  };

  const action = getNotificationAction(notification?.action);

  const imageUrl = notification?.sender?.image
    ? `data:image/jpeg;base64,${notification?.sender?.image}`
    : 'https://dummyimage.com/110x88/cccccc/cccccc.png';

  const Main = () => {
    return (
      <Card style={tailwind('p-2 my-4')}>
        <Image source={{uri: imageUrl}} style={styles.detailsImage} />
        <View style={tailwind('flex-row  my-3')}>
          <Text style={styles.textLabel}>Person name </Text>
          <Text style={styles.detailsText}>{notification?.sender?.name}</Text>
        </View>
        <View style={tailwind('flex-row  my-3')}>
          <Text style={styles.textLabel}>Event name </Text>
          <Text style={styles.detailsText}>{notification?.eventName}</Text>
        </View>
        <Divider />
        <View style={tailwind('flex-row justify-between my-3')}>
          <Text style={styles.textLabel}>Status</Text>
          <Text style={styles.detailsText}>{notification?.status}</Text>
        </View>
        <Divider />
        {action && (
          <View style={tailwind('flex-row my-3')}>
            <Text
              style={[
                action.startsWith('N')
                  ? styles.alertResponseDanger
                  : styles.alertResponseSuccess,
                tailwind('mr-0'),
              ]}>
              {action}
            </Text>
          </View>
        )}
        <Divider />
        <View style={tailwind('my-3')}>
          <Text style={styles.textLabel}>Message</Text>
          <Text style={styles.textLabel}>{notification.message}</Text>
        </View>
        <Divider />
        <View style={tailwind('flex-row  my-3')}>
          <Text style={styles.textLabel}>Phone number</Text>
          <Text style={styles.detailsText}>
            {notification.phoneNumber
              ? notification.phoneNumber
              : 'Not provided'}
          </Text>
        </View>
        <Divider />
        <View style={tailwind('flex-row justify-between my-3')}>
          <Text style={styles.textLabel}>Time</Text>
          <Text style={styles.detailsText}>
            {new Date(notification.time).toLocaleString()}
          </Text>
        </View>
      </Card>
    );
  };

  const Buttons = () => {
    return (
      <>
        {(notification.button1 ||
          notification.button2 ||
          notification.button3) && (
          <Card style={tailwind('p-2 my-3')}>
            <View style={tailwind('flex-row my-3 justify-between')}>
              {notification.button1 && buttonsShown && (
                <TouchableOpacity
                  onPress={() => {
                    setButtonsShown(false);
                    dispatch(
                      HandleAlertButton(
                        notification,
                        notification.button1.text,
                        parseInt(notification.button1.value, 10),
                      ),
                    );
                    fetchNotification(alertId);
                  }}
                  style={tailwind(
                    'mb-2 flex-row px-2 py-2 bg-green-400 rounded',
                  )}>
                  <Ionicons
                    name="checkmark-circle-outline"
                    size={16}
                    color={color}
                    style={tailwind('mr-2')}
                  />
                  <Text style={tailwind('text-white text-center mr-2')}>
                    {notification.button1.text}
                  </Text>
                </TouchableOpacity>
              )}
              {notification.button2 && buttonsShown && (
                <TouchableOpacity
                  onPress={() => {
                    setButtonsShown(false);
                    dispatch(
                      HandleAlertButton(
                        notification,
                        notification.button2.text,
                        parseInt(notification.button2.value, 10),
                      ),
                    );
                    fetchNotification(alertId);
                  }}
                  style={tailwind(
                    'mb-2 flex-row px-2 py-2 bg-red-400 rounded',
                  )}>
                  <Ionicons
                    name="close"
                    size={16}
                    style={tailwind('mr-2')}
                    color={color}
                  />
                  <Text style={tailwind('text-white text-center mr-2')}>
                    {notification.button2.text}
                  </Text>
                </TouchableOpacity>
              )}
              {notification.button3 &&
                (notification.phoneNumber !== '' ||
                  notification.phoneNumber !== null) &&
                notification.button3.text === 'Call' &&
                buttonsShown && (
                  <TouchableOpacity
                    onPress={() =>
                      Linking.openURL(`tel:${notification.phoneNumber}`)
                    }
                    style={tailwind(
                      'mb-2 flex-row px-2 py-2 bg-blue-400 rounded',
                    )}>
                    <Ionicons
                      name="call-outline"
                      size={16}
                      color={color}
                      style={tailwind('mr-2')}
                    />
                    <Text style={tailwind('text-white text-center mr-2')}>
                      {notification.button3.text}
                    </Text>
                  </TouchableOpacity>
                )}
            </View>
          </Card>
        )}
      </>
    );
  };

  return (
    <Container>
      <Navbar navigation={navigation} title="Notification details" />
      {isLoading || isReading ? (
        <LoadingSpinner />
      ) : (
        <ScrollView contentContainerStyle={{flex: 1}}>
          {!isRepliedTo && buttonsShown && !alertTimedOut && <Buttons />}
          {alertTimedOut && (
            <View style={notificationItemStyles.timedOutContainer}>
              <Text>Timed Out</Text>
            </View>
          )}
          {isRepliedTo && !alertTimedOut && (
            <View style={notificationItemStyles.responseContainer}>
              <ResponseBadge action={notification.action} />
            </View>
          )}
          {notification?.appMessageHTML ? (
            <>
              {isWeb ? (
                <div
                  style={{
                    marginTop: 56,
                  }}>
                  <div
                    id="html-container"
                    dangerouslySetInnerHTML={{
                      __html: notification?.appMessageHTML,
                    }}
                  />
                </div>
              ) : (
                <WebView
                  source={{html: notification.appMessageHTML}}
                  renderLoading={() => <LoadingSpinner />}
                />
              )}
            </>
          ) : (
            <Main />
          )}
        </ScrollView>
      )}
    </Container>
  );
};

const notificationItemStyles = StyleSheet.create({
  timedOutContainer: {
    backgroundColor: '#eab308',
    marginBottom: 4,
    borderRadius: 16,
    textAlign: 'center',
    marginLeft: 16,
    paddingLeft: 4,
    paddingRight: 4,
    margin: 8,
    width: 76,
    alignSelf: 'flex-end',
  },
  responseContainer: {
    maxWidth: 130,
    margin: 8,
    alignSelf: 'flex-end'
  }
});

export default NotificationDetails;
