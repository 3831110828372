import { fileTreeType } from './data';

const convert2Base64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (e) => {
        reject(e);
      };
    });
  };

type params = {
  currentFileTree: fileTreeType[];
  currentAbsolutePath: string;
  newFileTree: fileTreeType[];
};
export function updateFolderByPath({
  currentFileTree,
  currentAbsolutePath,
  newFileTree,
}: params): fileTreeType[] {
  console.log(currentFileTree, currentAbsolutePath, newFileTree);
  const parts = currentAbsolutePath?.split('/').filter(Boolean);
  // console.log('parts', parts);

  function traverseAndUpdate(
    currentFileTree: fileTreeType[],
    pathParts: string[]
  ): fileTreeType[] {
    if (pathParts.length === 0) {
      return currentFileTree;
    }

    const [currentPart, ...remainingParts] = pathParts;

    return currentFileTree.map(dir => {
      if (dir.isDirectory && dir.name === currentPart) {
        if (remainingParts.length === 0) {
          // Update the items of the found folder
          return {
            ...dir,
            items: newFileTree,
          };
        } else if (dir.items) {
          return {
            ...dir,
            items: traverseAndUpdate(dir.items, remainingParts),
          };
        }
      }
      return dir;
    });
  }

  return traverseAndUpdate(currentFileTree, parts);
}

export const convertFileToBase64 = async (data: Blob) => {
  const base64 = await convert2Base64(data);

  //@ts-ignore
  const binaryData = base64.split(',');
  const result = binaryData[1];

  return result;
};