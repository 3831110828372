import {AnyAction, combineReducers} from 'redux';

import {notificationsReducer} from './notificationsReducer';
import {loadingReducer} from './loadingReducer';
import {deviceInfoReducer} from './deviceInfoReducer';
import {connectionReducer} from './connectionReducer';
import deliveriesReducer from '../../modules/Deliveries/deliveriesReducer';
import storagesReducer from '../../modules/Storage/storagesReducer';
import alertsReducer from '../../modules/Alerts/alertsReducer';
import authReducer from '../../modules/Auth/reducer';
import unitsReducer from '../../modules/Units/unitsReducer';
import peopleReducer from '../../modules/People/peopleReducer';
import vehicleReducer from '../../modules/Vehicles/vehicleReducer';
import petReducer from '../../modules/Pets/petReducer';
import registerReducer from '../../modules/Auth/registerReducer';
import listPeopleTypesReducer from './listPeopleTypesReducer';
import approvalsReducer from './approvalsReducer';
import gateCardsReducer from '../../modules/Gatecards/gateCardsReducer';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {E_STORAGE_KEYS} from '../../@types';
import packagesReducer from './packagesReducer';
import listEventsReducer from './listEventsReducer';
import navigationReducer from '../../navigation/reducer';
import documentsReducer from '../../modules/Documents/reducer';

const appReducer = combineReducers({
  units: unitsReducer,
  notifications: notificationsReducer,
  isLoading: loadingReducer,
  deviceInfo: deviceInfoReducer,
  connection: connectionReducer,
  deliveries: deliveriesReducer,
  storages: storagesReducer,
  alerts: alertsReducer,
  auth: authReducer,
  people: peopleReducer,
  vehicles: vehicleReducer,
  pets: petReducer,
  register: registerReducer,
  listPeopleTypes: listPeopleTypesReducer,
  approvals: approvalsReducer,
  gatecards: gateCardsReducer,
  packages: packagesReducer,
  listEvents: listEventsReducer,
  navigation: navigationReducer,
  documents: documentsReducer,
});

const rootReducer = (state: RootState, action: AnyAction) => {
  if (action.type === E_STORAGE_KEYS.CLEAR_REDUX_PERSIST_ROOT) {
    AsyncStorage.removeItem(E_STORAGE_KEYS.REDUX_PERSIST_ROOT);

    state = {} as RootState;
  }

  return appReducer(state, action);
};

export default rootReducer;

export type RootState = ReturnType<typeof appReducer>;
