import React from "react";
import { Calendar } from "antd";
import "antd/dist/reset.css"; // Import Ant Design styles


const CustomCalendar = ({ markedDates }) => {
  const dateCellRender = (value) => {
    const dateKey = value.format("YYYY-MM-DD");

    if (markedDates[dateKey] && markedDates[dateKey].selected) {
      const { selectedColor, textColor } = markedDates[dateKey];
      return (
        <div
          style={{
            backgroundColor: selectedColor,
            color: textColor,
            height: '100%'
          }}
        >
          {/* {value.date()} */}
        </div>
      );
    }

    // return value.date();
  };

  return <Calendar dateCellRender={dateCellRender} />;
};

export default CustomCalendar;