const dataFrameInitialState = {
  unitId: '',
  type: 'Visitor',
  startDate: new Date().toISOString(),
  endDate: new Date().toISOString(),
  shortDescription: '',
  notes: '',
  status: 'Active',
  // projectId: '',
  // reservationId: '',
  overlapAllowed: false,
};

export default dataFrameInitialState;
