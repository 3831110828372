import React, { useEffect } from 'react';
import {
  Platform,
  SafeAreaView,
  ScrollView,
  View,
  useWindowDimensions,
} from 'react-native';
import Text from '../../../components/Text';
import * as yup from 'yup';
import Button from '../../../components/Button';
import Heading from '../../../components/Heading';
import HStack from '../../../components/HStack';
import tailwind from 'tailwind-rn';
import {RegisterActionBar, RegisterPrevButton} from '../../../components';
import {useDispatch} from 'react-redux';
import {RegistrationData} from '../types';
import {setRegisterData} from '../registerReducer';
import ScreenWrapper from '../components/ScreenWrapper';
import isWeb from '../../../utils/isWeb';
import TextInput from '../../../components/TextInput';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';


const phoneValidationSchema = yup.object().shape({
  phone1: yup
    .string()
    .required('Phone is required')
    .min(10, 'Invalid phone number'),
});

const Phone = ({navigation}) => {
  const dispatch = useDispatch();
  const {width} = useWindowDimensions();
  const {control, handleSubmit, reset } = useForm({
    defaultValues: {
      phone1: '',
    },
    resolver: yupResolver(phoneValidationSchema),
  });

  const handlePhoneSubmit = (values: {phone1: string}) => {
    const registrationData: RegistrationData = {
      phone1: values.phone1,
    };
    //@ts-ignore
    dispatch(setRegisterData(registrationData));
    if (Platform.OS === 'web') {
      navigation.navigate('User Credentials');
      return;
    }
    navigation.navigate('Add Contact');
  };

  return (
    <ScreenWrapper>
      <SafeAreaView
        style={[
          tailwind('flex-1 my-4'),
          isWeb && {backgroundColor: '#ffffff', width: width / 3},
        ]}>
        <ScrollView
          // style={[tailwind('mx-2 mb-4'), {backgroundColor: 'yellow'}]}
          keyboardShouldPersistTaps="handled">
          <RegisterActionBar step={5} />
          <View style={tailwind('mx-6 mt-4')}>
            <Heading size="lg">Cell Phone</Heading>
            <Text style={tailwind('mt-4 text-lg')}>
              Type your valid cell phone number. We will send you a code to
              confirm your phone number.
            </Text>
            <TextInput
              //@ts-ignore
              control={control}
              name="phone1"
              placeholder="e.g (520) 689-4415"
              label="Phone"
              keyboardType="number-pad"
            />
          </View>
        </ScrollView>
        <HStack style={{margin: 16, }}>
          <RegisterPrevButton />
          <Button
            onPress={handleSubmit(handlePhoneSubmit)}
            style={tailwind('mx-2 flex-1')}>
            Next
          </Button>
        </HStack>
      </SafeAreaView>
    </ScreenWrapper>
  );
};

export default Phone;
