import React from 'react';
import {
  CardStyleInterpolators,
  createStackNavigator,
} from '@react-navigation/stack';
import {makeId} from '../../utils';
import {E_NAVIGATION_DESTINATIONS} from '../../@types';
import { VisitorLogDetails, VisitorLogs } from './screens';

const Stack = createStackNavigator();

const VisitorLogsNavigator = () => {
  const options = {
    headerShown: false,
    cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
  };

  return (
    <Stack.Navigator>
      <Stack.Screen
        name={E_NAVIGATION_DESTINATIONS.VISITOR_LOGS_LIST}
        component={VisitorLogs}
        options={{...options, animationTypeForReplace: 'pop'}}
        key={makeId(12)}
      />
 
      <Stack.Screen
        name={E_NAVIGATION_DESTINATIONS.VISITOR_LOG_DETAILS}
        component={VisitorLogDetails}
        options={{...options, animationTypeForReplace: 'pop'}}
        key={makeId(12)}
      />
    </Stack.Navigator>
  );
};

export const visitorLogScreens = {
  [E_NAVIGATION_DESTINATIONS.VISITOR_LOGS_LIST] :"/visitorLogsList",
  [E_NAVIGATION_DESTINATIONS.VISITOR_LOG_DETAILS] :"/visitorLogDetails",
}

export default VisitorLogsNavigator;
