import React from 'react';
import {TouchableOpacity} from 'react-native';
import {styles} from '../styles';
import Text from '../components/Text';

const SubmitButton = ({onPress, title}) => {
  return (
    <TouchableOpacity
      activeOpacity={0.6}
      onPress={() => onPress()}
      style={styles.submitButton}>
      <Text style={styles.submitButtonText}>{title}</Text>
    </TouchableOpacity>
  );
};

export default SubmitButton;
