import {IInputProps, Input as NativeBaseInput} from 'native-base';
import React from 'react';
import tailwind from 'tailwind-rn';
import { colorSwatches } from '../styles';

const Input = (props: IInputProps) => {
  return (
    <NativeBaseInput {...props} style={[props.style, tailwind('text-lg')]} 
    _disabled={{
       backgroundColor: colorSwatches.gray.color 
    }}
    _readOnly={{
      backgroundColor: colorSwatches.gray.color 
    }}
    />
  );
};

export default Input;
