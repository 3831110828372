import { residentApiWithAuth } from "../../api/resident";

//create file folders

export type newFolder = {
  unitId: string;
  relativeLocation: string;
  newFolderName: string;
  permission: number;
};

export const createFolder = async ({
  unitId,
  relativeLocation,
  newFolderName,
  permission,
}: newFolder) => {
  try {
    const response = await residentApiWithAuth().post(`/files/folder`, {
      unitId,
      relativeLocation,
      newFolderName,
      permission,
    });
    return response.data;
  } catch (error) {

  }
};

//delete folder
export type deleteFolderType = {
  unitId: string;
  relativeLocation: string;
  itemToDelete: string;
};

export const deleteFolder = async ({
  unitId,
  relativeLocation,
  itemToDelete,
}: deleteFolderType) => {
  try {
    const response = await residentApiWithAuth().delete(`/files/item`, {
      data: {
        unitId,
        relativeLocation,
        itemToDelete,
      },
    });
    return response.data;
  } catch (error) {
  }
};

export type subFolderQuery = {
  unitId: string;
  relativeLocation?: string;
};
//get subfolders
export const getSubFolders = async ({
  unitId,
  relativeLocation,
}: subFolderQuery) => {
  try {
    const params = {
      unitId,

      ...(relativeLocation && { relativeLocation }),
    };
    const response = await residentApiWithAuth().get(`/files/subDirectories`, {
      params,
    });
    return response?.data?.data;
  } catch (error) {
  }
};

//upload file
export type uploadFileType = {
  unitId: string;
  relativeLocation: string;
  fileName: string;
  permission: number;
  base64Contents: string;
};

export const uploadFile = async ({
  unitId,
  relativeLocation,
  fileName,
  permission,
  base64Contents,
}: uploadFileType) => {
  try {
    const response = await residentApiWithAuth().post(`/files/file`, {
      unitId,
      relativeLocation,
      fileName,
      permission,
      base64Contents,
    });
    console.log('response', response);
    return response.data;
  } catch (error) {
  }
};
//get files
export type getFiles = {
  unitId: string;
  relativeLocation: string;
};

export const getFiles = async ({ unitId, relativeLocation }: getFiles) => {
  try {
    const response = await residentApiWithAuth().get(`/files`, {
      params: {
        unitId,
        relativeLocation,
        action: 'download',
      },
      responseType: 'blob',
    });
    return response.data; // file.pdf
  } catch (error) {
  }
};

export const renameFile = async (params: renameFileType) => {
  try {
    const response = await residentApiWithAuth().post(`/files/rename`, params);
    return response.data;
  } catch (error) {
  }
};

//move

export const moveItems = async (params: moveItemsType) => {
  try {
    const response = !params.action
      ? await residentApiWithAuth().post(`/files/move`, params)
      : await residentApiWithAuth().post(
          `/files/move?action=${params.action}`,
          params
        );
 
    return response.data;
  } catch (error) {
  }
};

export type moveItemsType = {
  unitId: string;
  itemsToMove: string[];
  itemsToMoveRelativePath: string;
  destinationDirectoryRelativePath: string;
  action?: 'copy' | 'move';
};

//rename
export type renameFileType = {
  unitId: string;
  relativeLocation: string;
  itemToRename: string;
  newName: string;
};

export type fileTreeType = {
  name: string;
  path: string;
  permission: number;
  isDirectory: boolean;
  items?: fileTreeType[];
  size?: number;
};

export type filesResponse = {
  name: string;
  path: string;
  isFolder: boolean;
  permission: number;
};