const initialPetState = {
  unitId: '',
  picture: null,
  petOwnerName: '',
  typeOfPet: '',
  colorOfPet: '',
  petWeight: 0,
  petName: '',
  vetinerian: '',
  vetPhoneNumber: '',
  petTagNumber: '',
  vacineOne: '',
  vacineOneExpiration: new Date().toISOString(),
  vacineTwo: '',
  vacineTwoExpiration: new Date().toISOString(),
  vacineThree: '',
  vacineThreeExppiration: new Date().toISOString(),
  vacineFour: '',
  vacineFourExpiration: new Date().toISOString(),
  createdLogon: '',
  createdTimeDate: new Date().toISOString(),
  lastLogon: '',
  lastTimeDate: new Date().toISOString(),
};

const fields = [
  {
    fieldName: 'Pet name',
    state: 'petName',
    type: 'textInput',
  },
  {
    fieldName: 'Pet picture',
    state: 'picture',
    type: 'imageCapture',
  },
  {
    fieldName: 'Owner name',
    state: 'petOwnerName',
    type: 'textInput',
  },
  {
    fieldName: 'Type of pet',
    state: 'typeOfPet',
    type: 'textInput',
  },
  {
    fieldName: 'Color',
    state: 'colorOfPet',
    type: 'textInput',
  },
  {
    fieldName: 'Weight',
    state: 'petWeight',
    type: 'textInput',
    keyboardType: 'number-pad',
  },
  {
    fieldName: 'Vetinerian',
    state: 'vetinerian',
    type: 'textInput',
  },
  {
    fieldName: 'Vet Phone Number',
    state: 'vetPhoneNumber',
    type: 'textInput',
    keyboardType: 'number-pad',
  },
  {
    fieldName: 'Pet Tag Number',
    state: 'petTagNumber',
    type: 'textInput',
  },
];

export {initialPetState, fields};
