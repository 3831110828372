import {ActivityIndicator, StyleSheet, View} from 'react-native';
import React from 'react';

export default function LoadingSpinner({
  size = 'large',
  color = 'gray',
}: {
  size?: 'small' | 'large';
  color?: string;
}) {
  return (
    <View style={spinnerStyles.container}>
      <ActivityIndicator color={color} size={size} />
    </View>
  );
}

const spinnerStyles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
