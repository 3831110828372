import React, {useState} from 'react';
import {View, Linking} from 'react-native';
import Text from '../../../components/Text';
import {Menu, Card} from 'react-native-paper';
import tailwind from 'tailwind-rn';
import {styles} from '../../../styles';
import {deriveDisplayName, getCurrentUnitLink} from '../../../utils';
import {
  showErrorMessage,
  showInformationMessage,
} from '../../../service/flashMessage';
import {ApprovalStatus, RequireApproval} from '../../../components';
import IconButton from '../../../components/IconButton';
import {initiateSMS, initiateSendEmail} from '../../App/service';
import isWeb from '../../../utils/isWeb';
import ZoomableImage from '../../../components/ZoomableImage';

const PeopleListItem = ({
  unitPeopleLink,
  itemClickHandler,
  currentUser,
  openCalendar,
  onClickApprovalsRequired,
  openPermissions,
  onSelectDelete,
  onSelectEditOwnUnitLink,
  sendBarCode,
  onSelectEdit,
  onAddToContacts,
}) => {
  //Only people with canManagePeople permission who should edit people

  const [menuVisible, setMenuVisible] = useState(false);
  // const hasOwner = unitPeopleLink.person.owner ? true : false;
  const imageUrl = unitPeopleLink?.profilePicture
    ? `data:image/jpeg;base64,${unitPeopleLink?.profilePicture}`
    : 'https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/default-avatar.png';
  const phoneNumber = unitPeopleLink?.person.phone
    ? unitPeopleLink?.person.phone
    : unitPeopleLink.phone;
  const phoneAvailable = !!phoneNumber;
  const email = unitPeopleLink?.person.email
    ? unitPeopleLink?.person.email
    : unitPeopleLink.email;
  const emailAvailable = !!email;
  const isCurrentUser = currentUser === unitPeopleLink?.person?.id;
  const currentUnitLink = getCurrentUnitLink();

  /** - Desc - People should be allowed to edit their own unitPeopleLink
   *  - Editable fields -  alwaysCall, unitPeopleLinkPicture
   *  - Permissions - No permission required
   * */
  const canEditOwnUnitPeopleLink = isCurrentUser;
  /**
   * - People should be allowed be edit other people unitPeopleLink
   * - Editable fields - Permissions
   * - Permissions - editing person (canManagePeople), person being edited (residentCanAdd),
   * - People should not edit their own permissions
   */

  const canModifyUnitPeopleLink = isCurrentUser
    ? unitPeopleLink?.peopleType?.residentCanAdd
    : currentUnitLink?.canManagePeople;
  // &&
  //   unitPeopleLink?.peopleType?.residentCanAdd;
  const linkApproved = unitPeopleLink.unitLinkApproved;

  return (
    <Card
      style={tailwind(`${linkApproved ? '' : 'bg-gray-400'} rounded my-2`)}
      onPress={() =>
        unitPeopleLink?.person?.id
          ? itemClickHandler()
          : showInformationMessage("This person doesn't have a profile")
      }>
      <View style={tailwind('flex-row p-2 justify-between items-center')}>
        <ZoomableImage
          title={deriveDisplayName(unitPeopleLink)}
          style={styles.userImage}
          source={{
            uri: imageUrl,
          }}
        />
        <View style={tailwind('flex-1 ml-2 flex-col justify-around')}>
          <View style={tailwind('flex-row')}>
            <Text style={tailwind('text-lg flex-1')}>
              {deriveDisplayName(unitPeopleLink)}
            </Text>
            {isCurrentUser && (
              <View style={styles.currentUser}>
                <Text style={tailwind('text-xs px-2 mx-1')} numberOfLines={1}>
                  You
                </Text>
              </View>
            )}
          </View>
          {deriveDisplayName(unitPeopleLink?.person) !== ' ' && (
            <Text style={tailwind('flex-1')}>
              {`Profile name-  ${deriveDisplayName(unitPeopleLink?.person)}`}
            </Text>
          )}
          <Text style={tailwind('text-xs my-1')}>
            {unitPeopleLink?.peopleType?.name?.toUpperCase()}
          </Text>
          <ApprovalStatus unitPeopleLink={unitPeopleLink} />
          <Text style={tailwind('text-xs my-1')}>
            {unitPeopleLink?.person?.id
              ? 'Linked profile'
              : 'No linked profile'}
          </Text>
          <View style={tailwind('flex-row my-1')}>
            <View
              style={tailwind(
                `bg-${
                  unitPeopleLink.dateFrameDay.allowedToday ? 'green' : 'red'
                }-400 rounded-full`,
              )}>
              <Text
                style={tailwind('text-xs text-white mx-1 mr-1')}
                numberOfLines={1}>
                Today
              </Text>
            </View>
            <View
              style={tailwind(
                `bg-${
                  unitPeopleLink.dateFrameDay.allowedTomorrow ? 'green' : 'red'
                }-400 rounded-full text-white mx-1 mr-1`,
              )}>
              <Text
                style={tailwind('text-xs text-white px-1 mr-1')}
                numberOfLines={1}>
                Tommorow
              </Text>
            </View>
          </View>
        </View>
        <View style={tailwind('mt-3 mr-4')}>
          <Menu
            visible={menuVisible}
            onDismiss={() => setMenuVisible(false)}
            anchor={
              <IconButton
                icon="ellipsis-vertical"
                iconColor={'#718096'}
                size={24}
                onPress={() => setMenuVisible(true)}
              />
            }>
            <>
              <Menu.Item
                onPress={() => {
                  if (!phoneAvailable) {
                    showErrorMessage('Failed to call, phone number N/A');
                    setMenuVisible(false);
                    return;
                  }
                  setMenuVisible(false);
                  Linking.openURL(`tel:${unitPeopleLink?.person?.phone}`);
                }}
                title={'Call'}
              />
              {!isWeb && (
                <Menu.Item
                  onPress={() => {
                    if (!phoneAvailable) {
                      showErrorMessage('Failed to send text, phone number N/A');
                      setMenuVisible(false);
                      return;
                    }
                    setMenuVisible(false);
                    initiateSMS(unitPeopleLink?.person?.phone);
                  }}
                  title={'Send Text'}
                />
              )}
            </>
            <Menu.Item
              onPress={() => {
                if (!emailAvailable) {
                  showErrorMessage('Failed to send email, email address N/A');
                  setMenuVisible(false);
                  return;
                }
                setMenuVisible(false);
                initiateSendEmail(unitPeopleLink?.person?.email, '', '');
              }}
              title={'Send Email'}
            />
            {canModifyUnitPeopleLink && (
              <Menu.Item
                onPress={() => {
                  setMenuVisible(false);
                  onSelectEdit();
                }}
                title="Edit"
              />
            )}
            <Menu.Item
              onPress={() => {
                setMenuVisible(false);
                openCalendar();
              }}
              title="Calendar"
            />
            {/* {canEditOwnUnitPeopleLink && (
              <Menu.Item
                onPress={() => {
                  setMenuVisible(false);
                  onSelectEditOwnUnitLink();
                }}
                title="Edit my info in unit"
              />
            )} */}
            <Menu.Item
              onPress={() => {
                setMenuVisible(false);
                openPermissions();
              }}
              title="Permissions"
            />
            <Menu.Item
              onPress={() => {
                if (!emailAvailable && !phoneAvailable) {
                  showErrorMessage("Can't send code. No phone number/email.");
                  setMenuVisible(false);
                  return;
                } else if (!currentUnitLink.allowedToAuthorizeEntry) {
                  showErrorMessage("You don't have permission to send code.");
                  setMenuVisible(false);
                  return;
                }
                setMenuVisible(false);
                sendBarCode();
              }}
              title="Send QR Code"
            />
            {/* If they are trying to remove themselves?
             *   Check if they have canManagePeople and peopleType.residentCanAdd for that unit
             *  If they are trying to remove others?
             *    Check if they canManagePeople and the person they are removing has peopleType.residentCanAdd for that unit
             *
             *  Do we check canManagePeople or  use people permission
             */}
            <Menu.Item
              onPress={() => {
                setMenuVisible(false);
                onAddToContacts();
              }}
              title="Add to contacts"
            />
            {canModifyUnitPeopleLink && (
              <Menu.Item
                onPress={() => {
                  setMenuVisible(false);
                  setTimeout(()=>{
                    onSelectDelete();
                  },1000)
                }}
                title="Remove from unit"
              />
            )}
          </Menu>
        </View>
      </View>
      <RequireApproval
        approvals={unitPeopleLink.approvals}
        onClickApprovalsRequired={() => onClickApprovalsRequired()}
      />
    </Card>
  );
};

export default PeopleListItem;
