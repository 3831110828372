import React from 'react';
import {
  CardStyleInterpolators,
  createStackNavigator,
} from '@react-navigation/stack';
import {makeId} from '../../utils';
import {E_NAVIGATION_DESTINATIONS} from '../../@types';
import { CreateViolation, EditViolation, ViolationDetail, Violations } from './screens';

const Stack = createStackNavigator();

const ViolationsNavigator = () => {
  const options = {
    headerShown: false,
    cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
  };

  return (
    <Stack.Navigator>
      <Stack.Screen
        name={E_NAVIGATION_DESTINATIONS.VIOLATIONS_LIST}
        component={Violations}
        options={{...options, animationTypeForReplace: 'pop'}}
        key={makeId(12)}
      />
      <Stack.Screen
        name={E_NAVIGATION_DESTINATIONS.VIOLATIONS_ADD}
        component={CreateViolation}
        options={{...options, animationTypeForReplace: 'pop'}}
        key={makeId(12)}
      />
      <Stack.Screen
        name={E_NAVIGATION_DESTINATIONS.VIOLATIONS_EDIT}
        component={EditViolation}
        options={{...options, animationTypeForReplace: 'pop'}}
        key={makeId(12)}
      />
      <Stack.Screen
        name={E_NAVIGATION_DESTINATIONS.VIOLATIONS_DETAILS}
        component={ViolationDetail}
        options={{...options, animationTypeForReplace: 'pop'}}
        key={makeId(12)}
      />
    </Stack.Navigator>
  );
};

export const violationScreens = {
  [E_NAVIGATION_DESTINATIONS.VIOLATIONS_LIST] :"violationsList",
  [E_NAVIGATION_DESTINATIONS.VIOLATIONS_ADD] :"/violationsAdd",
  [E_NAVIGATION_DESTINATIONS.VIOLATIONS_EDIT] :"/violationEdit",
  [E_NAVIGATION_DESTINATIONS.VIOLATIONS_DETAILS] :"/violationDetails",
}

export default ViolationsNavigator;
