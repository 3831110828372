import {Dispatch} from 'react';
import {Package} from '../../@types';
import {residentApiWithAuth} from '../../api/resident';
import {getCurrentUnitLink} from '../../utils';

export const PACKAGES_LOADING = 'PACKAGES_LOADING';
export const PACKAGES_FAIL = 'PACKAGES_FAIL';
export const PACKAGES_SUCCESS = 'PACKAGES_SUCCESS';

export function setPackages(packages: Array<Package>) {
  return {
    type: PACKAGES_SUCCESS,
    packages,
  };
}

export interface PackagesLoading {
  type: typeof PACKAGES_LOADING;
}

export interface PackagesFail {
  type: typeof PACKAGES_FAIL;
  error: string | object;
}
export interface PackagesSuccess {
  type: typeof PACKAGES_SUCCESS;
  packages: Package[];
}

export type PackagesDispatchTypes =
  | PackagesLoading
  | PackagesFail
  | PackagesSuccess;

interface DefaultStateI {
  loading: boolean;
  error?: string | object;
  packages?: Package[];
}

const initialState: DefaultStateI = {
  loading: false,
  error: null,
  packages: [],
};

export const GetPackages =
  () => async (dispatch: Dispatch<PackagesDispatchTypes>) => {
    try {
      dispatch({type: PACKAGES_LOADING});
      const {person} = getCurrentUnitLink();
      const {data: packagesData} = await residentApiWithAuth().get(
        `/packages/person/${person.id}?allClaimednUnclaimed=false&claimedOnly=false&thumbWidth=46&thumbHeight=46`,
      );
      let packages: Array<Package> = packagesData.data;

      dispatch({
        type: PACKAGES_SUCCESS,
        packages: packages,
      });
    } catch (error) {
      dispatch({type: PACKAGES_FAIL, error: error});
    }
  };

const packagesReducer = (
  state: DefaultStateI = initialState,
  action: PackagesDispatchTypes,
): DefaultStateI => {
  switch (action.type) {
    case PACKAGES_LOADING:
      return {
        ...state,
        loading: true,
      };
    case PACKAGES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case PACKAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        packages: action.packages,
      };
    default:
      break;
  }
  return state;
};

export default packagesReducer;
