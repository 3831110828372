// @ts-nocheck
import React, {useState} from 'react';
import {ScrollView, View, StyleSheet, Platform} from 'react-native';
import AsterixSwitch from '../../../components/AsterixSwitch';
import {Navbar} from '../../../components';
import FormContainer from '../../../components/FormContainer';
import Text from '../../../components/Text';
import {getCurrentUnitLink} from '../../../utils';
import Select from '../../../components/Select';
import Button from '../../../components/Button';
import {residentApiWithAuth} from '../../../api/resident';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../service/flashMessage';
import {UpdateMyDirectorySettings} from '../../../modules/Units/unitsReducer';
import {useDispatch} from 'react-redux';
import Container from '../../../components/Container';
import Content from '../../../navigation/Content';

const EditDirectoryInfo = ({navigation}) => {
  const currentUnitLink = getCurrentUnitLink();
  const locationInfo = currentUnitLink?.unit;
  const directoryScopes = [
    `1. Site - Everybody in ${locationInfo.site.name}`,
    `2. Community - Everybody in ${locationInfo.community.name}`,
    `3. Building - Everybody in ${locationInfo.building.name}`,
    `4. Unit - Everybody in ${locationInfo.unitName}`,
  ];
  const dispatch = useDispatch();

  const unitPeopleLink = getCurrentUnitLink();
  const initialDirectoryInfo = {
    directoryOptOut: unitPeopleLink.directoryOptOut,
    directoryDoNotShareEmail: unitPeopleLink.directoryDoNotShareEmail,
    directoryDoNotSharePhone1: unitPeopleLink.directoryDoNotSharePhone1,
    directoryScope: unitPeopleLink.directoryScope,
  };

  const [directoryInfo, setDirectoryInfo] = useState(initialDirectoryInfo);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const res = await residentApiWithAuth().post(
        `/unitPeopleLinks/update/${unitPeopleLink.id}`,
        directoryInfo,
      );
      const newUnitPeopleLink = await res.data?.data;
      const newDirectoryInfo = {
        directoryOptOut: newUnitPeopleLink.directoryOptOut,
        directoryDoNotShareEmail: newUnitPeopleLink.directoryDoNotShareEmail,
        directoryDoNotSharePhone1: newUnitPeopleLink.directoryDoNotSharePhone1,
        directoryScope: newUnitPeopleLink.directoryScope,
      };
      dispatch(UpdateMyDirectorySettings(unitPeopleLink.id, newDirectoryInfo));
      showSuccessMessage('Settings updated successfully');
      navigation.goBack();
    } catch (error) {
      showErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Content>
        <Navbar navigation={navigation} title="Edit my directory info" />
        <FormContainer>
          <ScrollView
            style={{
              marginHorizontal: 8,
              marginBottom: 16,
              flex: 1,
            }}>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingTop: 4,
              }}>
              <Text style={styles.checkBoxLabel}>Directory Opt out ?</Text>
              <AsterixSwitch
                value={directoryInfo.directoryOptOut}
                onValueChange={value => {
                  if (value) {
                    setDirectoryInfo({
                      ...directoryInfo,
                      directoryOptOut: value,
                      directoryDoNotShareEmail: value,
                      directoryDoNotSharePhone1: value,
                    });
                  } else {
                    setDirectoryInfo({
                      ...directoryInfo,
                      directoryOptOut: value,
                    });
                  }
                }}
              />
            </View>

            <>
              <Text style={[styles.onePickerLabel, {marginTop: 16}]}>
                Directory scope
              </Text>
              <Select
                style={{height: 40}}
                selectedValue={
                  directoryInfo.directoryScope
                    ? directoryInfo.directoryScope
                    : ''
                }
                onValueChange={val => {
                  setDirectoryInfo({
                    ...directoryInfo,
                    directoryScope: val,
                  });
                }}>
                {directoryScopes.map(scope => (
                  <Select.Item key={scope} label={scope} value={scope} />
                ))}
              </Select>
            </>
            <View style={styles.switchRow}>
              <Text style={styles.checkBoxLabel}>
                Directory do not share email ?
              </Text>
              <AsterixSwitch
                value={directoryInfo.directoryDoNotShareEmail}
                onValueChange={value => {
                  setDirectoryInfo({
                    ...directoryInfo,
                    directoryDoNotShareEmail: value,
                  });
                }}
              />
            </View>
            <View style={styles.switchRow}>
              <Text style={styles.checkBoxLabel}>
                Directory do not share phone 1 ?
              </Text>
              <AsterixSwitch
                value={directoryInfo.directoryDoNotSharePhone1}
                onValueChange={value => {
                  setDirectoryInfo({
                    ...directoryInfo,
                    directoryDoNotSharePhone1: value,
                  });
                }}
              />
            </View>
          </ScrollView>
          <Button
            onPress={() => handleSubmit()}
            size="lg"
            mx="2"
            py="3"
            mb="16"
            isDisabled={loading}
            isLoadingText="Submitting ..."
            isLoading={loading}>
            Submit
          </Button>
        </FormContainer>
      </Content>
    </Container>
  );
};

export default EditDirectoryInfo;

const styles = StyleSheet.create({
  checkBoxLabel: {
    color: '#52525b',
    marginBottom: 4,
    flex: 1,
    fontSize: 15,
    marginTop: 8,
  },
  onePickerLabel: {
    color: '#2D3748',
    marginTop: Platform.OS === 'ios' ? 96 : 16,
    flex: 1,
    fontSize: 15,
  },
  switchRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 4,
    paddingBottom: 12,
  },
});
