import React from 'react';
import {Dimensions, StyleSheet, View, Platform} from 'react-native';
import isWeb from '../utils/isWeb';
import WebDrawer from '../navigation/WebDrawer.web';
import {getCurrentUnitLink} from '../utils';

const height = Dimensions.get('window').height;

export default function Container(props) {
  const currentUnitLink = getCurrentUnitLink();
  const {
    children,
    style,
    fullWidth,
    hasDrawer = true,
    fullHeight = false,
    ...otherProps
  } = props;
  return (
    <View
      style={{
        alignSelf: 'stretch',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
      }}>
      {!!currentUnitLink && hasDrawer && isWeb && <WebDrawer />}
      <View
        {...otherProps}
        style={[
          styles.container,
          style,
          {
            maxWidth: fullWidth ? undefined : 1140,
          },
        ]}>
        <View
          //@ts-ignore
          style={{
            flex: 1,
            height: isWeb ? '100vh' : height,
          }}>
          {children}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'center',
  },
});
