import React from 'react';
import {
  CardStyleInterpolators,
  createStackNavigator,
} from '@react-navigation/stack';
import {makeId} from '../../utils';
import {E_NAVIGATION_DESTINATIONS} from '../../@types';
import {Deliveries, PackageDetails, Packages, PackagesHistory} from './screens';
import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import {colorSwatches} from '../../styles';
import {Badge} from 'react-native-paper';

const Stack = createStackNavigator();

const PackageHistoryNavigator = () => {
  const options = {
    headerShown: false,
    cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
  };

  return (
    <Stack.Navigator>
      <Stack.Screen
        name={E_NAVIGATION_DESTINATIONS.PACKAGES_HISTORIES_LIST}
        component={PackagesHistory}
        options={{...options, animationTypeForReplace: 'pop'}}
        key={makeId(12)}
      />
           <Stack.Screen
        name={E_NAVIGATION_DESTINATIONS.PACKAGE_DETAILS}
        component={PackageDetails}
        options={{...options, animationTypeForReplace: 'pop'}}
        key={makeId(12)}
      />
    </Stack.Navigator>
  );
};

const PackagesNavigator = () => {
  const options = {
    headerShown: false,
    cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
  };

  return (
    <Stack.Navigator>
      <Stack.Screen
        name={E_NAVIGATION_DESTINATIONS.PACKAGES}
        component={Packages}
        options={{...options, animationTypeForReplace: 'pop'}}
        key={makeId(12)}
      />
      <Stack.Screen
        name={E_NAVIGATION_DESTINATIONS.PACKAGE_DETAILS}
        component={PackageDetails}
        options={{...options, animationTypeForReplace: 'pop'}}
        key={makeId(12)}
      />
    </Stack.Navigator>
  );
};

const {Navigator, Screen} = createMaterialTopTabNavigator();

export function DeliveriesTabs({unclaimedPackagesCount, deliveriesCount}) {
  return (
    <Navigator
      screenOptions={{
        tabBarStyle: {
          backgroundColor: colorSwatches.primary.color,
        },
        tabBarActiveTintColor: '#ffffff',
        tabBarInactiveTintColor: '#ffffff',
        tabBarIndicatorStyle: {
          backgroundColor: '#ffffff',
        },
        tabBarScrollEnabled: true,
      }}>
      <Screen
        name={E_NAVIGATION_DESTINATIONS.PACKAGES}
        component={Packages}
        options={{
          tabBarBadge: () =>
            unclaimedPackagesCount ? (
              <Badge>{unclaimedPackagesCount}</Badge>
            ) : null,
        }}
      />
      <Screen
        name={E_NAVIGATION_DESTINATIONS.DELIVERIES}
        component={Deliveries}
        options={{
          tabBarBadge: () =>
            deliveriesCount > 0 ? <Badge>{deliveriesCount}</Badge> : null,
        }}
      />
    </Navigator>
  );
}

export const packageHistoryScreens = {
  [E_NAVIGATION_DESTINATIONS.PACKAGES_HISTORIES_LIST]: '/packagesHistoryList',
  [E_NAVIGATION_DESTINATIONS.PACKAGE_DETAILS]: '/packageDetails',
};

export default PackageHistoryNavigator;
