import React from 'react';
import isWeb from '../../../utils/isWeb';
import {Center} from 'native-base';
import {KeyboardAvoidingView, Platform, ScrollView} from 'react-native';

export default function ScreenWrapper(props: {children: any}) {
  if (isWeb) {
    return (
      <ScrollView>
        <Center>{props.children}</Center>
      </ScrollView>
    );
  }
  return (
    <>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        enabled
        keyboardVerticalOffset={10}
        style={{
          flex: Platform.OS === 'ios' ? 1 : 0,
          flexDirection: 'column',
          justifyContent: 'center',
        }}>
        {Platform.OS === 'ios' && props.children}
      </KeyboardAvoidingView>
      {Platform.OS === 'android' && props.children}
    </>
  );
}
