// @ts-nocheck
import {useNavigation, useRoute} from '@react-navigation/native';
import React, {useState} from 'react';
import {
  ScrollView,
  View,
  TouchableOpacity,
  Image,
  useWindowDimensions,
  SafeAreaView,
} from 'react-native';
import AsterixSwitch from '../../../components/AsterixSwitch';
import Text from '../../../components/Text';
import {Navbar} from '../../../components';
import {deriveUnitName} from '../../../utils/deriveDisplayName';
import {styles} from '../../../styles';
import Icon from 'react-native-vector-icons/Feather';
import {residentApiWithAuth} from '../../../api/resident';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../service/flashMessage';
import {useDispatch} from 'react-redux';
import {GetUnitScreenData} from '../../../modules/Units/unitsReducer';
import {GetPeople} from '../../People/peopleReducer';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import Container from '../../../components/Container';
import PhotoCaptureSheet from '../../../components/PhotoCaptureSheet';

type State = {
  profilePicture: string | null;
  alwaysCall: boolean;
  directoryOptOut: boolean;
  directoryDoNotShareEmail: boolean;
  directoryDoNotSharePhone1: boolean;
  directoryScope: string;
  relationship: string;
  officeNotes: string;
  guardNotes: string;
};

/**
 * Allow users to edit their Directory information
 */
export default function EditOwnUnitLink() {
  const navigation = useNavigation();
  const route = useRoute();
  const directoryScopes = ['Unit', 'Community', 'Building', 'Site'];
  const {unitPeopleLink, from} = route.params;
  const unitName = deriveUnitName(unitPeopleLink);
  const initialState: State = {
    profilePicture: unitPeopleLink.profilePicture,
    alwaysCall: unitPeopleLink.alwaysCall,
    directoryOptOut: unitPeopleLink.directoryOptOut,
    directoryDoNotShareEmail: unitPeopleLink.directoryDoNotShareEmail,
    directoryDoNotSharePhone1: unitPeopleLink.directoryDoNotSharePhone1,
    directoryScope: unitPeopleLink.directoryScope,
    relationship: unitPeopleLink.relationship,
    officeNotes: unitPeopleLink.string,
    guardNotes: unitPeopleLink.string,
  };
  const [linkState, setLinkState] = useState<State>(initialState);
  const [picturePanelActive, setPicturePanelActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const fieldsUpdated =
    initialState.alwaysCall !== linkState.alwaysCall ||
    initialState.profilePicture !== linkState.profilePicture;
  const dispatch = useDispatch();

  const {width} = useWindowDimensions();

  async function handleSubmit() {
    setLoading(true);
    try {
      await residentApiWithAuth().post(
        `/unitPeopleLinks/update/${unitPeopleLink.id}`,
        linkState,
      );
      if (from === 'UNITS') {
        dispatch(GetUnitScreenData());
      } else if (from === 'PEOPLE') {
        dispatch(GetPeople());
      }
      showSuccessMessage('Info updated successfully');
      navigation.goBack();
    } catch (error) {
      showErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container>
      <Navbar
        navigation={navigation}
        title={`Edit my info for unit ${unitName}`}
      />
      <SafeAreaView
        style={[
          {
            marginHorizontal: 8,
            marginBottom: 16,
            flex: 1,
          },
        ]}>
        <ScrollView>
          <View>
            <Text>Picture</Text>
            <TouchableOpacity
              onPress={() => setPicturePanelActive(true)}
              style={styles.pictureUploadContainer}>
              {linkState.profilePicture ? (
                <Image
                  source={{
                    uri: `data:image/jpeg;base64,${linkState.profilePicture}`,
                  }}
                  style={{
                    borderRadius: 8,
                    width: 128,
                    height: 128,
                  }}
                />
              ) : (
                <Icon name="image" size={24} style={styles.pictureUploadIcon} />
              )}
            </TouchableOpacity>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingTop: 4,
                paddingBottom: 12,
                marginTop: 32,
              }}>
              <Text style={styles.checkBoxLabel}>Always call </Text>
              <AsterixSwitch
                value={linkState.alwaysCall}
                onValueChange={value =>
                  setLinkState({
                    ...linkState,
                    alwaysCall: value,
                  })
                }
              />
            </View>

            {/* {unitPeopleLink?.resident && (
              <>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    paddingTop: 4,
                  }}>
                  <Text style={styles.checkBoxLabel}>Directory Opt out ?</Text>
                  <AsterixSwitch
                    value={linkState.directoryOptOut}
                    onValueChange={value => {
                      setLinkState({
                        ...linkState,
                        directoryOptOut: value,
                        directoryDoNotShareEmail: value,
                        directoryDoNotSharePhone1: value,
                      });
                    }}
                  />
                </View>
                <Text style={[styles.onePickerLabel, {marginTop: 16}]}>
                  Directory scope
                </Text>
                <Select
                  selectedValue={
                    linkState.directoryScope ? linkState.directoryScope : ''
                  }
                  onValueChange={val => {
                    setLinkState({
                      ...linkState,
                      directoryScope: val,
                    });
                  }}>
                  {directoryScopes.map(scope => (
                    <Select.Item key={scope} label={scope} value={scope} />
                  ))}
                </Select>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    paddingTop: 4,
                    paddingBottom: 12,
                  }}>
                  <Text style={styles.checkBoxLabel}>
                    Directory do not share email ?
                  </Text>
                  <AsterixSwitch
                    value={linkState.directoryDoNotShareEmail}
                    onValueChange={value => {
                      setLinkState({
                        ...linkState,
                        directoryDoNotShareEmail: value,
                      });
                    }}
                  />
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    paddingTop: 4,
                    paddingBottom: 12,
                  }}>
                  <Text style={styles.checkBoxLabel}>
                    Directory do not share phone 1 ?
                  </Text>
                  <AsterixSwitch
                    value={linkState.directoryDoNotSharePhone1}
                    onValueChange={value => {
                      setLinkState({
                        ...linkState,
                        directoryDoNotSharePhone1: value,
                      });
                    }}
                  />
                </View>
              </>
            )} */}

            <Text style={styles.textInputLabel}>Relationship</Text>
            <Input
              placeholder="Enter relationship"
              value={linkState.relationship}
              onChangeText={text => {
                setLinkState({
                  ...linkState,
                  relationship: text,
                });
              }}
            />
          </View>
        </ScrollView>
        <Button
          onPress={() => handleSubmit()}
          size="lg"
          mx="2"
          py="3"
          mt="16"
          isDisabled={loading || !fieldsUpdated}
          isLoadingText="Submitting ..."
          isLoading={loading}>
          {'Update '}
        </Button>
      </SafeAreaView>
      <PhotoCaptureSheet
        isPanelActive={picturePanelActive}
        setIsActive={(val: boolean) => setPicturePanelActive(val)}
        onConfirmImage={(imgData: string) =>
          setLinkState({...linkState, profilePicture: imgData})
        }
      />
    </Container>
  );
}
