import React, {useState} from 'react';
import {ScrollView, View} from 'react-native';
import Text from '../../../components/Text';
import {ActivityIndicator, Card, Divider} from 'react-native-paper';
import tailwind from 'tailwind-rn';
import {styles} from '../../../styles';
import {Navbar, VerifyEmailAndPhone} from '../../../components';
import {useEffect} from 'react';
import {showErrorMessage} from '../../../service/flashMessage';
import {initialAuthState} from '../utils';
import {fetchPerson} from '../../../api/people';
import {RootStore} from '../../../redux/store';
import {useSelector} from 'react-redux';
import ZoomableImage from '../../../components/ZoomableImage';
import {deriveDisplayName} from '../../../utils';

const PeopleDetailComponent = ({navigation, route}) => {
  const {personId} = route.params;
  const [isLoading, setIsLoading] = useState(true);
  const [person, setPerson] = useState(initialAuthState);
  const activePerson = useSelector((state: RootStore) => state.auth.user);

  const fetchUser = async id => {
    try {
      const user = await fetchPerson(id);
      setPerson(user);
      setIsLoading(false);
    } catch (error) {
      showErrorMessage(error.message);
    }
  };

  useEffect(() => {
    fetchUser(personId);
  }, [personId]);

  return (
    <View style={[tailwind('flex-1')]}>
      <Navbar navigation={navigation} title="Profile" />
      {isLoading ? (
        <ActivityIndicator />
      ) : (
        <>
          <Card style={tailwind('mx-2 mt-4 pb-2')}>
            <View style={tailwind('flex-row justify-between p-2')}>
              <ZoomableImage
                title={deriveDisplayName(person)}
                source={{
                  uri: person.profilePicture
                    ? `data:image/jpeg;base64,${person.profilePicture}`
                    : 'https://nichemodels.co/wp-content/uploads/2019/03/user-dummy-pic.png',
                }}
                style={styles.image}
              />
              <View style={tailwind('flex-1 ml-4')}>
                <Text
                  style={
                    styles.userName
                  }>{`Firstname : ${person.firstName}`}</Text>
                <Text style={styles.email}>{`Email : ${person.email}`}</Text>
              </View>
            </View>
            {activePerson?.id === personId && (
              <VerifyEmailAndPhone person={person} />
            )}
          </Card>
          <ScrollView
            style={tailwind('mx-2 py-4')}
            showsVerticalScrollIndicator={false}>
            <Card style={[tailwind('p-2 mb-8')]}>
              <View style={tailwind('flex-1 flex-row justify-between')} />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Title</Text>
                <Text style={styles.detailsText}>{person.title}</Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Type</Text>
                <Text style={styles.detailsText}>{person.type}</Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>First name</Text>
                <Text style={styles.detailsText}>{person.firstName}</Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Middle name</Text>
                <Text style={styles.detailsText}>{person.middleName}</Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row my-3')}>
                <Text style={styles.textLabel}>Last name</Text>
                <Text style={styles.detailsText}>{person.lastName}</Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Nick name</Text>
                <Text style={styles.detailsText}>{person.nickname}</Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Middle name</Text>
                <Text style={styles.detailsText}>{person.middleName}</Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Company</Text>
                <Text style={styles.detailsText}>{person.company}</Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Salutation</Text>
                <Text style={styles.detailsText}>{person.salutation}</Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Address Line 1</Text>
                <Text style={styles.detailsText}>{person.addressLine1}</Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Address Line 2 </Text>
                <Text style={styles.detailsText}>{person.addressLine2}</Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row my-3')}>
                <Text style={styles.textLabel}>City</Text>
                <Text style={styles.detailsText}>{person.city}</Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>State</Text>
                <Text style={styles.detailsText}>{person.state}</Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Zip</Text>
                <Text style={styles.detailsText}>{person.zip}</Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Country</Text>
                <Text style={styles.detailsText}>{person.country}</Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Phone 1</Text>
                <Text style={styles.detailsText}>{person.phone1}</Text>
              </View>
              <Divider />
              <Divider />
              <View style={tailwind(' my-3')}>
                <Text style={styles.textLabel}>Notes</Text>
                <Text style={styles.textLabel}>{person.notes}</Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Username</Text>
                <Text style={styles.detailsText}>{person.username}</Text>
              </View>
            </Card>
          </ScrollView>
        </>
      )}
    </View>
  );
};

export default PeopleDetailComponent;
