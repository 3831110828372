/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import {View} from 'react-native';
import Ionicon from 'react-native-vector-icons/Ionicons';
import {colorSwatches} from '../styles';
import Text from '../components/Text';

export default function PermissionsWarning({message}) {
  return (
    <View
      style={{
        marginVertical: 8,
        backgroundColor: colorSwatches.orange[200],
        borderRadius: 4,
      }}>
      <View
        style={{
          padding: 8,
          flexDirection: 'row',
          flex: 1,
          alignItems: 'center', 
        }}>
        <Ionicon
          name="warning-outline"
          size={24}
          color={colorSwatches.orange[500]}
        />
        <Text
          style={{
            marginLeft: 8,
            textAlign: 'center'
          }}>
          {message}
        </Text>
      </View>
    </View>
  );
}
