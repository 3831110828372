import React from 'react';
import {
  CardStyleInterpolators,
  createStackNavigator,
} from '@react-navigation/stack';
import {makeId} from '../../utils';
import {E_NAVIGATION_DESTINATIONS} from '../../@types';
import { AddStorage, EditStorage, StorageDetail, Storages } from './screens';

const Stack = createStackNavigator();

const StoragesNavigator = () => {
  const options = {
    headerShown: false,
    cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
  };

  return (
    <Stack.Navigator>
      <Stack.Screen
        name={E_NAVIGATION_DESTINATIONS.STORAGES_LIST}
        component={Storages}
        options={{...options, animationTypeForReplace: 'pop'}}
        key={makeId(12)}
      />
      <Stack.Screen
        name={E_NAVIGATION_DESTINATIONS.STORAGE_ADD}
        component={AddStorage}
        options={{...options, animationTypeForReplace: 'pop'}}
        key={makeId(12)}
      />
      <Stack.Screen
        name={E_NAVIGATION_DESTINATIONS.STORAGES_EDIT}
        component={EditStorage}
        options={{...options, animationTypeForReplace: 'pop'}}
        key={makeId(12)}
      />
      <Stack.Screen
        name={E_NAVIGATION_DESTINATIONS.STORAGES_DETAILS}
        component={StorageDetail}
        options={{...options, animationTypeForReplace: 'pop'}}
        key={makeId(12)}
      />
    </Stack.Navigator>
  );
};

export const storageScreens = {
  [E_NAVIGATION_DESTINATIONS.STORAGES_LIST] :"storageList",
  [E_NAVIGATION_DESTINATIONS.STORAGE_ADD] :"/storageAdd",
  [E_NAVIGATION_DESTINATIONS.STORAGES_EDIT] :"/storageEdit",
  [E_NAVIGATION_DESTINATIONS.STORAGES_DETAILS] :"/storageDetails",
}

export default StoragesNavigator;
