// @ts-nocheck
import {useFocusEffect, useNavigation} from '@react-navigation/native';
import React, {createRef, useCallback, useEffect, useRef, useState} from 'react';
import {useAppSelector} from '../hooks/redux';
import {RootStore} from '../redux/store';
import {
  Collapse,
  Divider,
  Grid,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
} from '@mui/material';
import {withStyles} from '@mui/styles';
import {
  deriveDisplayName,
  getCurrentUnitLink,
  permissionToBool,
} from '../utils';
import Ionicon from 'react-native-vector-icons/Ionicons';
import FeatherIcon from 'react-native-vector-icons/Feather';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import ConfirmationDialog from '../components/ConfirmationDialog';
import {useDispatch} from 'react-redux';
import {SignOut} from '../modules/Auth/reducer';
import {ExpandLess, ExpandMore, Inbox} from '@mui/icons-material';

import {fetchUnitById} from '../api/units';
import {LoadingSpinner} from '../components';
import {Unit} from '../@types/Unit';
import {UnitLink} from '../modules/UnitPeopleLink/types';
import { E_NAVIGATION_DESTINATIONS } from '../@types';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflowX: 'hidden',
  },
  list: {
    color: 'gray',
    '& .MuiListItem-root.Mui-selected, & .MuiListItem-root.Mui-selected:hover':
      {
        backgroundColor: '#ffdead',
      },
  },
  listSubHeader: {
    color: 'inherit',
    position: 'relative',
  },
  logoContainer: {
    textAlign: 'center',
    fontSize: 14,
    paddingTop: 20,
    paddingBottom: 20,
    marginTop: 'auto',
  },
  label: {
    fontWeight: 700,
  },
  logoImg: {
    width: '120px',
    marginTop: 10,
  },
  profileContainer: {
    padding: '30px 0 30px 20px',
  },
  myProfileLink: {
    color: 'blue',
  },
});

const getSections = (currentUnitLink: UnitLink) => {
  const people = [
    {
      name: 'people',
      label: 'People',
      icon: <FeatherIcon name="users" size={20} color="gray" />,
      route: 'People',
    },
  ];
  const deliveriesAndPackages = [
    {
      name: 'packages',
      label: E_NAVIGATION_DESTINATIONS.PACKAGES,
      icon: <FeatherIcon name="shopping-bag" size={20} color="gray" />,
      route: 'Unclaimed Packages',
    },
  ];

  const vehicles = [
    {
      name: 'vehicles',
      label: 'Vehicles',
      icon: <Ionicon name="car-sport-outline" size={20} color="gray" />,
      route: 'Vehicles List',
    },
  ];

  const pets = [
    {
      name: 'pets',
      label: 'Pets',
      icon: <Ionicon name="paw-outline" size={20} color="gray" />,
      route: 'Pets List',
    },
  ];

  const storages = [
    {
      name: 'storage',
      label: 'Storages',
      icon: <Ionicon name="archive" size={20} color="gray" />,
      route: 'Storages List',
    },
  ];

  const parking = [
    {
      name: 'parking',
      label: E_NAVIGATION_DESTINATIONS.PARKINGS,
      icon: <FeatherIcon name="layout" size={20} color="gray" />,
      route: 'Parkings List'
    },
  ];
  const gatecards = [
    {
      name: 'gateCards',
      label: E_NAVIGATION_DESTINATIONS.GATE_CARDS,
      icon: <FeatherIcon name="credit-card" size={20} color="gray" />,
      route: 'Gate Cards List'
    },
  ];

  const keylogs = [
    {
      name: 'keyLogs',
      label: E_NAVIGATION_DESTINATIONS.KEY_LOGS,
      icon: <FeatherIcon name="key" size={20} color="gray" />,
      route: 'Key Logs List'
    },
  ];

  const visitorHistory = [
    {
      name: 'visitorHistory',
      label: E_NAVIGATION_DESTINATIONS.VISITOR_LOGS,
      icon: <FeatherIcon name="user" size={20} color="gray" />,
      route: 'Visitor Logs List'
    },
  ];

  const doorLogs = [
    {
      name: 'doorLogs',
      label: E_NAVIGATION_DESTINATIONS.DOOR_LOGS,
      icon: <MaterialCommunityIcons name="door" size={20} color="gray" />,
      route: 'Door Logs List'
    },
  ];

  const packagesHistory = [
    {
      name: 'packagesHistory',
      label: E_NAVIGATION_DESTINATIONS.PACKAGES_HISTORIES,
      icon: <FeatherIcon name="gift" size={20} color="gray" />,
      route: 'Packages History List'
    },
  ];

  const violations = [
    {
      name: null,
      icon: null,
      routes: [
        {
          name: 'violations',
          label: 'Violations',
          icon: <Ionicon name="flame-outline" size={20} color="gray" />,
          route: 'Violations List',
        },
      ],
    },
  ];

  const directory = [
    {
      name: 'Directory',
      icon: <Ionicon name="folder-outline" size={20} color="gray" />,
      routes: [
        {
          name: 'directory',
          label: 'Search',
          icon: (
            <MaterialCommunityIcons
              name="folder-search-outline"
              size={20}
              color="gray"
            />
          ),
        },
        {
          name: 'settings',
          label: 'Edit Directory Settings',
          icon: (
            <MaterialCommunityIcons
              name="folder-edit-outline"
              size={20}
              color="gray"
            />
          ),
        },
      ],
    },
  ];

  const sections = [
    {
      name: null,
      icon: null,
      routes: [
        {
          name: 'alerts',
          label: 'Alerts',
          icon: <FeatherIcon name="bell" size={20} color="gray" />,
          route: 'Alerts'
        },
        {
          name: 'units',
          label: 'Units Chooser',
          icon: <FeatherIcon name="home" size={20} color="gray" />,
          route: 'Units Chooser',
        },
        ...(permissionToBool(currentUnitLink?.peoplePermission?.charAt(0))
          ? people
          : []),
        ...(permissionToBool(currentUnitLink?.deliveryPermission?.charAt(0))
          ? deliveriesAndPackages
          : []),
      ],
    },
    {
      name: 'Other Unit Info',
      icon: <FeatherIcon name="align-justify" size={20} color="gray" />,
      routes: [
        ...(permissionToBool(currentUnitLink?.vehiclePermission?.charAt(0))
          ? vehicles
          : []),
        ...(permissionToBool(currentUnitLink?.petPermission?.charAt(0))
          ? pets
          : []),
        ...(permissionToBool(currentUnitLink?.storagePermission?.charAt(0))
          ? storages
          : []),
        ...(permissionToBool(currentUnitLink?.parkingPermission?.charAt(0))
          ? parking
          : []),
        ...(permissionToBool(currentUnitLink?.gateCardPermission?.charAt(0))
          ? gatecards
          : []),
      ],
    },
    {
      name: 'History',
      icon: <MaterialCommunityIcons name="history" size={20} color="gray" />,
      routes: [
        ...(currentUnitLink?.allowedToChekoutKey &&
        currentUnitLink?.canViewUnitHistory
          ? keylogs
          : []),
        ...(currentUnitLink?.canViewUnitHistory ? visitorHistory : []),
        ...(currentUnitLink?.canViewUnitHistory ? doorLogs : []),
        ...(currentUnitLink?.allowedToClaimPackages &&
        currentUnitLink?.allowedToClaimPackages
          ? packagesHistory
          : []),
      ],
    },
    // ...(currentUnitLink?.resident ? directory : []),
    ...(permissionToBool(currentUnitLink?.violationsPermission?.charAt(0))
      ? violations
      : []),
    {
      name: null,
      icon: null,
      routes: [
        {
          name: 'Others',
          label: 'Others',
          icon: <Ionicon name="list-outline" color="gray" size={20} />,
          route: 'Others',
        },
      ],
    },
    {
      name: null,
      icon: null,
      routes: [
        {
          name: 'Documents',
          label: E_NAVIGATION_DESTINATIONS.DOCUMENTS,
          icon: (
            <FeatherIcon name="file-text" color="gray" size={20} />
          ),
          route: 'Documents',
        },
      ],
    },
    {
      name: null,
      icon: null,
      routes: [
        {
          name: 'Permissions',
          label: E_NAVIGATION_DESTINATIONS.NOTIFICATION_SETTINGS,
          icon: (
            <Ionicon name="shield-checkmark-outline" color="gray" size={20} />
          ),
          route: 'Notification settings',
        },
      ],
    },
  ];

  return sections;
};

const DrawerScreen = props => {
  const {classes} = props;
  const navigation = useNavigation();
  const currentRoute = useAppSelector(
    (state: RootStore) => state.navigation.currentRoute,
  );
  const dispatch = useDispatch();
  const {user} = useAppSelector((state: RootStore) => state.auth);
  const currentUnitLink = getCurrentUnitLink();
  const personName = deriveDisplayName(currentUnitLink);
  const [loadingUnit, setLoadingUnit] = useState(null);
  const [unit, setUnit] = useState<Unit>(null);

  const sections = getSections(currentUnitLink);

  const [isOpen, setIsOpen] = React.useState(false);
  const onClose = () => setIsOpen(false);

  const viewRef = createRef(null);

  const onYesPressed = () => {
    dispatch(SignOut());
  };

  const onNoPressed = () => {
    console.log('Cancel Pressed');
  };

  useFocusEffect(
    useCallback(() => {
      if (currentUnitLink) {
        fetchUnit(currentUnitLink?.unit?.id);
      }
    }, [currentUnitLink]),
  );

  const fetchUnit = async (id: string) => {
    setLoadingUnit(true);
    try {
      const res = await fetchUnitById(id);
      setUnit(res);
    } catch (error) {
    } finally {
      setLoadingUnit(false);
    }
  };

  const [currentCollapsibe, setCurrentCollapsibe] = React.useState(null);
  
  useEffect(() => {
    const currentSection = sections.find(section =>
      section.routes.find(route => route.route === currentRoute),
    );
    if (currentSection) {
      setCurrentCollapsibe(currentSection.name);
    }
  },[currentRoute]);

  return (
    <div className={classes.root}>
      <div className={classes.profileContainer}>
        <ListSubheader
          style={{
            padding: 0,
          }}>
          PROFILE
        </ListSubheader>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <img
              src={`data:image/jpeg;base64,${user.profilePicture}`}
              style={{
                borderRadius: '50%',
                width: 56,
                height: 56,
              }}
            />
          </Grid>
          <Grid item xs zeroMinWidth>
            <Typography noWrap>{personName}</Typography>
            <Typography noWrap style={{fontSize: 14}}>
              {currentUnitLink.email}
            </Typography>
            <Link
              underline="always"
              style={{
                cursor: 'pointer',
              }}
              onClick={() => navigation.navigate('Edit Profile')}>
              Edit
            </Link>
          </Grid>
        </Grid>
        <ListSubheader
          style={{
            padding: 0,
          }}>
          UNIT DETAILS
        </ListSubheader>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs zeroMinWidth>
            <Typography noWrap>{currentUnitLink.unit.unitName}</Typography>
            <Typography noWrap style={{fontSize: 14}}>
              {currentUnitLink.unit.unitDisplay}
            </Typography>
            {permissionToBool(currentUnitLink.unitPermission.charAt(1)) && (
              <>
                {loadingUnit ? (
                  <LoadingSpinner size="small" />
                ) : (
                  <Link
                    underline="always"
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      navigation.navigate('Edit Unit', {
                        unit,
                      })
                    }>
                    Edit
                  </Link>
                )}
              </>
            )}
          </Grid>
          <Grid item>
            <img
              src={
                !!unit?.picture
                  ? `data:image/jpeg;base64,${unit?.picture}`
                  : require('../images/unit.jpeg')
              }
              style={{
                borderRadius: '50%',
                width: 56,
                height: 56,
                marginRight: 16,
              }}
            />
          </Grid>
        </Grid>
      </div>
      <Divider />
      <ListSubheader>MENU</ListSubheader>
      <List
        className={classes.list}
        id="drawer-list"
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          border: 'none',
        }}>
        {sections.map(section => {
          const result = [];
          if (section.name) {
            const [open, setOpen] = useState(false);

            const handleClick = () => {
              const openedSection = sections.find(s => s.name === section.name);
              setCurrentCollapsibe(openedSection?.name);
              setOpen(!open);
            };

            result.push(
              <>
                <ListItemButton onClick={handleClick}>
                  <ListItemIcon>{section.icon}</ListItemIcon>
                  <ListItemText primary={section.name} />
                  {currentCollapsibe === section.name ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </ListItemButton>
                <Collapse
                  in={currentCollapsibe === section.name}
                  timeout="auto">
                  {section.routes.map(route => {
                    const isCurrentRoute = currentRoute === route.route;

                    return (
                      <List disablePadding>
                        <ListItemButton
                          sx={{pl: 4}}
                          key={route.name}
                          selected={isCurrentRoute}
                          onClick={() => {
                            // @ts-ignore
                            navigation.navigate(route.label);
                          }}>
                          <ListItemIcon>{route.icon}</ListItemIcon>
                          <ListItemText primary={route.label} />
                        </ListItemButton>
                      </List>
                    );
                  })}
                </Collapse>
              </>,
            );
          } else {
            section.routes.forEach(route => {
              result.push(
                <ListItemButton
                  key={route.name}
                  selected={route.route === currentRoute}
                  onClick={() => {
                    // @ts-ignore
                    navigation.navigate(route.label);
                  }}>
                  <ListItemIcon>{route.icon}</ListItemIcon>
                  <ListItemText
                    primary={
                      route.label.startsWith('Units') ? 'Units' : route.label
                    }
                  />
                </ListItemButton>,
              );
            });
          }

          return result;
        })}
      </List>
      <div className={classes.logoContainer}>
        <div>
          <ListItemButton
            onClick={() => {
              setIsOpen(!isOpen);
            }}>
            <FeatherIcon name={'log-out'} color="#718096" size={20} />
            <ListItemText
              primary="Sign Out"
              style={{
                marginLeft: 12,
              }}
            />
          </ListItemButton>
        </div>
      </div>
      <ConfirmationDialog
        isOpen={isOpen}
        onClose={onClose}
        onNoPressed={onNoPressed}
        onYesPressed={onYesPressed}
        description="Are you sure you wish to logout?"
      />
      <div style={{float: 'left', clear: 'both'}} ref={viewRef}></div>
    </div>
  );
};

// @ts-ignore
export default withStyles(styles)(DrawerScreen);
