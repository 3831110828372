/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import { Image } from 'react-native';

interface I_Deliveries_Package_Icon {
  tintColor: string;
}

export default function DeliveriesPackageIcon(
  props: I_Deliveries_Package_Icon,
) {
  return (
    <Image
      source={require('../images/deliveries_packages.gif')}
      tintColor={props?.tintColor}
      style={{
        width: 34,
        height: 22,
      }}
    />
  );
}
