import React from 'react';
import {
  CardStyleInterpolators,
  createStackNavigator,
} from '@react-navigation/stack';
import {
  CreateVehicle,
  EditVehicle,
  VehicleDetail,
  Vehicles,
} from '../../modules/Vehicles/screens';
import {makeId} from '../../utils';
import {E_NAVIGATION_DESTINATIONS} from '../../@types';

const Stack = createStackNavigator();

const VehiclesNavigator = (props) => {
  const options = {
    headerShown: false,
    cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
  };

  return (
    <Stack.Navigator initialRouteName={E_NAVIGATION_DESTINATIONS.VEHICLES_LIST}>
      <Stack.Screen
        name={E_NAVIGATION_DESTINATIONS.VEHICLES_LIST}
        component={Vehicles}
        options={{...options, animationTypeForReplace: 'pop'}}
        key={makeId(12)}
      />
      <Stack.Screen
        name={E_NAVIGATION_DESTINATIONS.VEHICLE_ADD}
        component={CreateVehicle}
        options={{...options, animationTypeForReplace: 'pop'}}
        key={makeId(12)}
      />
      <Stack.Screen
        name={E_NAVIGATION_DESTINATIONS.VEHICLE_EDIT}
        component={EditVehicle}
        options={{...options, animationTypeForReplace: 'pop'}}
        key={makeId(12)}
      />
      <Stack.Screen
        name={E_NAVIGATION_DESTINATIONS.VEHICLE_DETAILS}
        component={VehicleDetail}
        options={{...options, animationTypeForReplace: 'pop'}}
        key={makeId(12)}
      />
    </Stack.Navigator>
  );
};

export const vehicleScreens = {
  [E_NAVIGATION_DESTINATIONS.VEHICLES_LIST] :"/vehiclesList",
  [E_NAVIGATION_DESTINATIONS.VEHICLE_ADD] :"/vehiclesAdd",
  [E_NAVIGATION_DESTINATIONS.VEHICLE_EDIT] :"/vehicleEdit",
  [E_NAVIGATION_DESTINATIONS.VEHICLE_DETAILS] :"/vehicleDetails",
}

export default VehiclesNavigator;
