import {residentApiWithAuth} from './resident';

export default async function fetchListEvents() {
  try {
    let res = await residentApiWithAuth().get(
      `/listEvents/people?pageSize=${1500}&pageNumber=1`,
    );
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
}
