import React from 'react';
import {AlertDialog, Button, Center} from 'native-base';
import LoadingSpinner from './LoadingSpinner';

interface I_ConfirmationDialog_Props {
  isOpen: boolean;
  onClose: () => void;
  onYesPressed: () => void;
  onNoPressed: () => void;
  description: string;
  title?: string;
  loading?: boolean;
  negativeLabel?: string;
  positiveLabel?: string;
}

const ConfirmationDialog = ({
  isOpen,
  onClose,
  onYesPressed,
  onNoPressed,
  description,
  title,
  loading,
  negativeLabel= `NO `,
  positiveLabel= "YES"
}: I_ConfirmationDialog_Props) => {
  
  const cancelRef = React.useRef(null);

  return (
    <Center>
      <AlertDialog
        leastDestructiveRef={cancelRef}
        isOpen={isOpen}
        onClose={onClose}>
        <AlertDialog.Content>
          <AlertDialog.Header>{!!title ? title : 'Confirm'}</AlertDialog.Header>
          <AlertDialog.Body>{description}</AlertDialog.Body>
          <AlertDialog.Footer>
            <Button.Group space={2}>
               <Button
                variant="ghost"
                onPress={() => {
                  onClose();
                  onNoPressed();
                }}
                isDisabled={!!loading}
                ref={cancelRef}>
                {negativeLabel}
              </Button>
              {!!loading ? (
                <LoadingSpinner size="small" />
              ) : (
                <Button
                  colorScheme="red"
                  onPress={() => {
                    onClose();
                    onYesPressed();
                  }}>
                  {positiveLabel}
                </Button>
              )}
            </Button.Group>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    </Center>
  );
};

export default ConfirmationDialog;
