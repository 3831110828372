import axios from 'axios';
import { store } from '../redux/store';
import { BASE_URL } from './constants';
import { residentApi, residentApiWithAuth, setToken } from './resident';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { E_STORAGE_KEYS } from '../@types';

const signIn = async (username, password) => {
  const { deviceInfo } = store.getState();
  const url = `${BASE_URL}/people/login`;
  const data = { username: username, password: password };
  try {
    let res = await axios.post(url, data, {
      headers: {
        common: deviceInfo,
      },
    });
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    const message = error?.response?.data?.errors[0];
    throw new Error(message);
  }
};

const createUserAccount = async (user) => {
  try {
    let res = await residentApi().post('/people/register', user);

    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    const message = error.response.data.errors[0];
    throw new Error(message);
  }
};

const addPerson = async (user) => {
  try {
    let res = await residentApiWithAuth().post('/people/addPerson', user);

    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    const message = error.response.data.errors[0];
    throw new Error(message);
  }
};

const updateUserAccount = async (userId, userData) => {
  try {
    let res = await residentApiWithAuth().post(`/people/${userId}`, userData);
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error('updateUserAccount' + error.message);
  }
};

const fetchPeopleByUnit = async (unitId) => {
  try {
    let res = await residentApiWithAuth().get(`/people/unit/${unitId}`);

    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (e) {
    throw new Error(e.message);
  }
};

const deletePerson = async (personId) => {
  try {
    let res = await residentApiWithAuth().delete(`/people/${personId}`);
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetchPerson = async (personId) => {
  try {
    let res = await residentApiWithAuth().get(`/people/${personId}`);
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetchAllPersons = async () => {
  try {
    let res = await residentApiWithAuth().get('/people');
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

const postDriverLicense = async (driversLicense) => {
  try {
    let res = await residentApi().post('/digitalDl', driversLicense);
    const result = res.data;

    if (result.errors) {
      throw new Error(result.errors[0]);
    } else if (result.data.ERROR) {
      throw new Error(result.data.ERROR);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

const checkUser = async (username) => {
  try {
    let res = await residentApiWithAuth().get(`/checkuser/${username}`);
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

const resetPassword = async (username) => {
  try {
    let res = await residentApi().post('/people/passwordemail', {
      username,
    });
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    const message = error?.response?.data?.data?.errors[0] || error.message;
    throw new Error(message);
  }
};

const getNewToken = async () => {
  const tokenString = await AsyncStorage.getItem(
    E_STORAGE_KEYS.AUTH_TOKEN,
  );
  const tokenObj = JSON.parse(tokenString);

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(tokenObj),
  };
  
  try {
    const res = await fetch(`${BASE_URL}/people/refresh`, options);
    const json = await res.json();
    console.log("json", json);
    const { token, refreshToken } = json;

    if (token) {
      //save token in asyncStorage
      await AsyncStorage.setItem(
        E_STORAGE_KEYS.AUTH_TOKEN,
        JSON.stringify({
          token,
          refreshToken,
        }),
      );
    }
    return { token, refreshToken };
  } catch (error) {
    throw new Error(error.message);
  }
};

export {
  signIn,
  createUserAccount,
  addPerson,
  updateUserAccount,
  fetchPeopleByUnit,
  deletePerson,
  fetchPerson,
  fetchAllPersons,
  postDriverLicense,
  checkUser,
  getNewToken,
  resetPassword,
};
