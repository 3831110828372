import {residentApiWithAuth} from './resident';

const createViolation = async (violation) => {
  try {
    let res = await residentApiWithAuth().post('/violations', violation);
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

const updateViolation = async (violation, violationId) => {
  try {
    let res = await residentApiWithAuth().post(
      `/violations/${violationId}`,
      violation,
    );
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetchViolation = async (violationId) => {
  try {
    let res = await residentApiWithAuth().get(`/violations${violationId}`);
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

const deleteViolationById = async (violationId) => {
  try {
    let res = await residentApiWithAuth().delete(`/violations/${violationId}`);
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetchViolationsByUnit = async (unitId) => {
  try {
    let res = await residentApiWithAuth().get(`/violations/unit/${unitId}`);
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export {
  createViolation,
  updateViolation,
  fetchViolation,
  deleteViolationById,
  fetchViolationsByUnit,
};
