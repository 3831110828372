import React from 'react';
import {
  CardStyleInterpolators,
  createStackNavigator,
} from '@react-navigation/stack';
import {makeId} from '../../utils';
import {E_NAVIGATION_DESTINATIONS} from '../../@types';
import { KeyLogDetails, KeyLogs } from './screens';

const Stack = createStackNavigator();

const KeyLogsNavigator = () => {
  const options = {
    headerShown: false,
    cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
  };

  return (
    <Stack.Navigator>
      <Stack.Screen
        name={E_NAVIGATION_DESTINATIONS.KEY_LOGS_LIST}
        component={KeyLogs}
        options={{...options, animationTypeForReplace: 'pop'}}
        key={makeId(12)}
      />
 
      <Stack.Screen
        name={E_NAVIGATION_DESTINATIONS.KEY_LOG_DETAILS}
        component={KeyLogDetails}
        options={{...options, animationTypeForReplace: 'pop'}}
        key={makeId(12)}
      />
    </Stack.Navigator>
  );
};

export const keyLogScreens = {
  [E_NAVIGATION_DESTINATIONS.KEY_LOGS_LIST] :"/keyLogsList",
  [E_NAVIGATION_DESTINATIONS.KEY_LOG_DETAILS] :"/keyLogDetails",
}

export default KeyLogsNavigator;
