import React from 'react';
import {ScrollView, View} from 'react-native';
import Text from '../../../components/Text';
import Button from '../../../components/Button';
import Heading from '../../../components/Heading';
import HStack from '../../../components/HStack';
import tailwind from 'tailwind-rn';
import Ionicons from 'react-native-vector-icons/Ionicons';
import IconButton from '../../../components/IconButton';

const RegisterProgress = ({navigation}) => {
  return (
    <View style={tailwind('flex-1 mb-4')}>
      <ScrollView style={tailwind('mx-2 mb-4')}>
        <IconButton
          icon={'close-outline'}
          size={30}
          style={tailwind('')}
          onPress={() => navigation.goBack()}
        />
        <View style={tailwind('mx-6 mt-4')}>
          <Heading>Registration </Heading>
          <View style={tailwind('flex-row mt-8')}>
            <Ionicons
              name="checkmark-circle-outline"
              color="#47B275"
              size={24}
              style={tailwind('mt-1')}
            />
            <Text style={tailwind('mb-4 ml-2 text-lg')}>
              Congratulations, your account has been set up successfully.
            </Text>
          </View>
        </View>
      </ScrollView>
      <HStack>
        <Button
          onPress={() => navigation.navigate('Sign In')}
          style={tailwind('mx-2 flex-1')}>
          Sign In
        </Button>
      </HStack>
    </View>
  );
};

export default RegisterProgress;
