import {Dispatch} from 'redux';
import {residentApiWithAuth} from '../../api/resident';
import {showErrorMessage, showSuccessMessage} from '../../service/flashMessage';
import {RootStackParamList} from '../../@types';
import * as RootNavigation from '../../navigation';
import {UnitLink} from '../../@types/UnitLink';
import {
  fetchAlertDetail,
  fetchNotificationById,
  readAllAlerts,
  sendAction,
} from '../../api/alert';
import {store} from '../../redux/store';
import {
  buildResponseAlert,
  handleAuthorize,
  handleIgnore,
} from '../../service/configureNotifications';
import {Linking} from 'react-native';
import PushNotification from 'react-native-push-notification';
import {LIMIT_PER_PAGE} from '../../api/constants';
import {Pagination} from '../../@types/Pagination';
import {ApprovalsDispatchTypes} from '../../redux/reducers/approvalsReducer';
import {PackagesDispatchTypes} from '../../redux/reducers/packagesReducer';
import {EventNames} from '../../@types/EventNames';
import {Alert} from './types';
import {sendAck} from '../Auth/api';

export const ALERTS_LOADING = 'ALERTS_LOADING';
export const ALERTS_FAIL = 'ALERTS_FAIL';
export const ALERTS_SUCCESS = 'ALERTS_SUCCESS';
export const ALERTS_FILTER = 'ALERTS_FILTER';
export const HANDLE_FIRST_BUTTON = 'HANDLE_FIRST_BUTTON';
export const HANDLE_SECOND_BUTTON = 'HANDLE_SECOND_BUTTON';
export const HANDLE_THIRD_BUTTON = 'HANDLE_THIRD_BUTTON';
export const SEND_ALERT = 'SEND_ALERT';
export const RECEIVE_ALERT = 'RECEIVE_ALERT';
export const RECEIVE_SILENT_ALERT = 'RECEIVE_SILENT_ALERT';
export const RESPOND_TO_ALERT = 'RESPOND_TO_ALERT';
export const RESPONDING_TO_ALERT = 'RESPONDING_TO_ALERT';
export const NAVIGATE_TO_LINKING = 'NAVIGATE_TO_LINKING';
export const REMOVE_DELIVERED_ALERT = 'REMOVE_DELIVERED_ALERT';
export const ALERTS_DELETE_ALL = 'ALERTS_DELETE_ALL';
export const ALERTS_RESET = 'ALERTS_RESET';
export const ALERT_READ_LOADING = 'ALERT_READ_LOADING';
export const ALERT_READ_SUCCESS = 'ALERT_READ_SUCCESS';
export const ALERT_READ_FAIL = 'ALERT_READ_FAIL';
export const ALERT_READ_ALL_LOADING = 'ALERT_READ_ALL_LOADING';
export const ALERT_READ_ALL_SUCCESS = 'ALERT_READ_ALL_SUCCESS';
export const ALERT_READ_ALL_FAIL = 'ALERT_READ_ALL_FAIL';
export const ALERT_CLEAR_BUTTONS = 'ALERT_CLEAR_BUTTONS';

export type FilterArguments = {
  unit: string | null;
  eventName: string | null;
  startDate: string | null;
  endDate: string | null;
};

export interface AlertsLoading {
  type: typeof ALERTS_LOADING;
}

export interface AlertsFail {
  type: typeof ALERTS_FAIL;
  error: string | object;
}

export interface AlertsSuccess {
  type: typeof ALERTS_SUCCESS;
  alertsPayload: Alert[];
  paginationPayload: Pagination;
  unread: number;
}

export interface AlertsFilter {
  type: typeof ALERTS_FILTER;
  filterArgs: FilterArguments;
}

export interface HandleAlertButton {
  type: typeof HANDLE_FIRST_BUTTON;
  notif: Alert;
}

export interface NavigateToLinking {
  type: typeof NAVIGATE_TO_LINKING;
  payload: RootStackParamList['Link person to unit'];
}

export interface RemoveDeliveredAlert {
  type: typeof REMOVE_DELIVERED_ALERT;
  id: string;
}
export interface SendAlert {
  type: typeof SEND_ALERT;
  payload: {
    unitPeopleLink: UnitLink;
    requestingPeoplId: string;
    unitId: string;
  };
}

export interface ReceiveAlert {
  type: typeof RECEIVE_ALERT;
  payload: Alert;
}

export interface ReceiveSilentAlert {
  type: typeof RECEIVE_SILENT_ALERT;
  payload: Alert;
}

export interface RespondAlert {
  type: typeof RESPOND_TO_ALERT;
  payload: Alert;
}

export interface RespondingToAlert {
  type: typeof RESPONDING_TO_ALERT;
  payload: boolean;
}

export interface DeleteAllAlerts {
  type: typeof ALERTS_DELETE_ALL;
  alertsPayload: Alert[];
  paginationPayload: Pagination;
}

export interface ResetAlerts {
  type: typeof ALERTS_RESET;
}

export interface ReadAlertLoading {
  type: typeof ALERT_READ_LOADING;
}

export interface ReadAlertFail {
  type: typeof ALERT_READ_FAIL;
  error: string | object;
}

export interface ReadAlertSuccess {
  type: typeof ALERT_READ_SUCCESS;
  alertDetailId: string;
}

export interface ReadAllAlertsLoading {
  type: typeof ALERT_READ_ALL_LOADING;
}

export interface ReadAllAlertsFail {
  type: typeof ALERT_READ_ALL_FAIL;
  error: string | object;
}

export interface ReadAllAlertsSuccess {
  type: typeof ALERT_READ_ALL_SUCCESS;
}

export interface ClearAlertButtons {
  type: typeof ALERT_CLEAR_BUTTONS;
  parentAlertId: string;
  action: number | null;
}

export type AlertsDispatchTypes =
  | AlertsLoading
  | AlertsFail
  | AlertsSuccess
  | AlertsFilter
  | HandleAlertButton
  | SendAlert
  | ReceiveAlert
  | RespondAlert
  | ReceiveSilentAlert
  | NavigateToLinking
  | RemoveDeliveredAlert
  | RespondingToAlert
  | DeleteAllAlerts
  | ResetAlerts
  | ReadAlertLoading
  | ReadAlertSuccess
  | ReadAlertFail
  | ApprovalsDispatchTypes
  | PackagesDispatchTypes
  | ReadAllAlertsLoading
  | ReadAllAlertsFail
  | ReadAllAlertsSuccess
  | ClearAlertButtons;

export const GetAlerts =
  (args: FilterArguments) =>
  async (dispatch: Dispatch<AlertsDispatchTypes>) => {
    try {
      dispatch({type: ALERTS_LOADING});
      const {pageNumber, filterArgs} = store.getState().alerts;

      let url = `/alertDetail/person?pageNumber=${pageNumber}&pageSize=${LIMIT_PER_PAGE}`;

      if (filterArgs?.unit) {
        url = `${url}&unitId=${filterArgs?.unit}`;
      }

      if (filterArgs?.eventName) {
        url = `${url}&eventName=${filterArgs?.eventName}`;
      }

      if (filterArgs?.startDate) {
        url = `${url}&startDate=${filterArgs?.startDate}`;
      }

      if (filterArgs?.endDate) {
        url = `${url}&endDate=${filterArgs?.endDate}`;
      }
      console.log('url', url);
      let res = await residentApiWithAuth().get(url);

      let result = res.data;

      if (result.errors) {
        throw new Error(result.errors[0]);
      } else {
        let alerts: Array<Alert> = result.data;

        alerts = alerts.sort((a, b) => {
          //@ts-ignore
          return new Date(b.time) - new Date(a.time);
        });

        const pagination: Pagination = {
          pageNumber: result.pageNumber,
          pageSize: result.pageSize,
          nextPage: result.nextPage,
          previous: result.previous,
          totalRows: result.totalRows,
        };

        alerts = alerts.sort((a, b) => {
          //@ts-ignore
          return new Date(b.time) - new Date(a.time);
        });

        //Hide alerts whose eventNames are inactive
        // const activeListEvents = getActiveListEvents();
        // const filteredAlerts = alerts.filter((item) => {
        //   const isActiveListEvent = activeListEvents.find((listEvent) => {
        //     return listEvent.eventName === item.eventName;
        //   });

        //   return isActiveListEvent ? true : false;
        // });
        dispatch({
          type: ALERTS_SUCCESS,
          alertsPayload: alerts,
          paginationPayload: pagination,
          unread: result?.unread,
        });
      }
    } catch (error) {
      dispatch({type: ALERTS_FAIL, error: error});
    }
  };

export const GetNextAlerts =
  (args?: FilterArguments) =>
  async (dispatch: Dispatch<AlertsDispatchTypes>) => {
    const {filterArgs} = store.getState().alerts;
    try {
      dispatch({type: ALERTS_LOADING});
      const {pageNumber} = store.getState().alerts;

      let url = `/alertDetail/person?pageNumber=${pageNumber}&pageSize=${LIMIT_PER_PAGE}`;

      if (filterArgs?.unit) {
        url = `${url}&unitId=${filterArgs?.unit}`;
      }

      if (filterArgs?.eventName) {
        url = `${url}&eventName=${filterArgs?.eventName}`;
      }

      if (filterArgs?.startDate) {
        url = `${url}&startDate=${filterArgs?.startDate}`;
      }

      if (filterArgs?.endDate) {
        url = `${url}&endDate=${filterArgs?.endDate}`;
      }

      let res = await residentApiWithAuth().get(url);

      let result = res.data;

      if (result.errors) {
        throw new Error(result.errors[0]);
      } else {
        let alerts: Array<Alert> = result.data;
        alerts = alerts.sort((a, b) => {
          //@ts-ignore
          return new Date(b.time) - new Date(a.time);
        });

        const pagination: Pagination = {
          pageNumber: result.pageNumber,
          pageSize: result.pageSize,
          nextPage: result.nextPage,
          previous: result.previous,
          totalRows: result?.totalRows,
        };

        alerts = alerts.sort((a, b) => {
          //@ts-ignore
          return new Date(b.time) - new Date(a.time);
        });
        console.log('unread count - GetNextAlerts', result?.unread);
        dispatch({
          type: ALERTS_SUCCESS,
          alertsPayload: alerts,
          paginationPayload: pagination,
          unread: result?.unread,
        });
      }
    } catch (error) {
      dispatch({type: ALERTS_FAIL, error: error});
    }
  };

export const CreateAlert =
  (alert: Alert) => async (dispatch: Dispatch<AlertsDispatchTypes>) => {
    try {
      dispatch({type: ALERTS_LOADING});
      await residentApiWithAuth().post('/alertHeader/person', alert);
    } catch (error) {
      dispatch({type: ALERTS_FAIL, error: error});
      showErrorMessage(error);
    }
  };

export const ReceiveAlert =
  (
    id: string,
    parentAlertId: string,
    eventName: string,
    action: number | null,
  ) =>
  async (dispatch: Dispatch<AlertsDispatchTypes>) => {
    try {
      const newAlert: Alert = await fetchNotificationById(id);
      dispatch({type: RECEIVE_ALERT, payload: newAlert});

      //Send receiveVerified here
      const ackData = {
        alertDetailId: newAlert.id,
        receiveVerified: true,
      };
      await sendAck(ackData);

      if (
        parentAlertId !== null &&
        (eventName === EventNames.VISITOR_APPROVED_ENTRANCE ||
          eventName === EventNames.VISITOR_DENIED_ENTRANCE)
      ) {
        dispatch({
          type: ALERT_CLEAR_BUTTONS,
          parentAlertId: parentAlertId,
          action: action,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

export const FilterAlerts =
  (filterArgs: FilterArguments) =>
  async (dispatch: Dispatch<AlertsDispatchTypes>) => {
    //reset pageNumber to 1
    dispatch({type: ALERTS_RESET});
    dispatch({type: ALERTS_LOADING});
    dispatch({type: ALERTS_FILTER, filterArgs});
    try {
    } catch (error) {}
  };

export const HandleAlertButton =
  (notification: Alert | null, action: string, response: number) =>
  async (dispatch: Dispatch<AlertsDispatchTypes>) => {
    const finishNotification = async () => {
      try {
        const newAlert: Alert = await fetchAlertDetail(notification.id);
        dispatch({type: RESPOND_TO_ALERT, payload: newAlert});
      } catch (error) {
        console.log('HandleAlertButton' + error);
      }
      dispatch({type: REMOVE_DELIVERED_ALERT, id: notification.alertId});
    };

    const eventName = notification.eventName;
    if (eventName === 'ResidentAccess') {
      if (action === 'Authorize') {
        await handleAuthorize(notification, response);
        await finishNotification();
      } else if (action === 'Do not Authorize') {
        await buildResponseAlert(
          notification,
          response,
          'ResidentAccessDenied',
          'person',
        );
        await finishNotification();
      } else if (action === 'Call') {
        await Linking.openURL(`tel:${notification.phoneNumber}`);
        await finishNotification();
      }
    } else if (eventName === 'UnitLink') {
      if (action === 'Link') {
        const navOptions = {
          notification,
          peopleId: notification.sender.id,
          unitId: notification.unit.unitId,
          notificationId: notification.id,
          notificationResponse: response,
        };
        dispatch({type: NAVIGATE_TO_LINKING, payload: navOptions});
      } else if (action === 'Ignore') {
        await handleIgnore(notification, response);
        await finishNotification();
      } else if (action === 'Call') {
        await Linking.openURL(`tel:${notification.phoneNumber}`);
        await finishNotification();
      }
    } else if (eventName === 'VisitorScreen') {
      if (action === 'Call') {
        await Linking.openURL(`tel:${notification.phoneNumber}`);
        await finishNotification();
      } else if (action === 'Authorize') {
        await buildResponseAlert(
          notification,
          response,
          'VisitorApprovedEntrance',
          'user',
        );
        await finishNotification();
      } else if (action === 'Do not Authorize') {
        await buildResponseAlert(
          notification,
          response,
          'VisitorDeniedEntrance',
          'user',
        );
        await finishNotification();
      }
    }
  };

export const UpdateLinkAlert =
  (notification: Alert) => async (dispatch: Dispatch<AlertsDispatchTypes>) => {
    try {
      const newAlert: Alert = await fetchNotificationById(notification.id);
      dispatch({type: RESPOND_TO_ALERT, payload: newAlert});
    } catch (error) {
      console.log('UpdateLinkAlert' + error);
    }
    dispatch({type: REMOVE_DELIVERED_ALERT, id: notification.alertId});
  };

export const NavigateToDetails = (alertId: string) => async () => {
  RootNavigation.navigate('Notification Details', {
    alertId,
    isFromTray: true,
  });
};

export const DeleteAllAlerts =
  () => async (dispatch: Dispatch<AlertsDispatchTypes>) => {
    dispatch({
      type: ALERTS_DELETE_ALL,
      paginationPayload: {
        pageNumber: 1,
        pageSize: 15,
        nextPage: null,
        previous: null,
        totalRows: 0,
      },
      alertsPayload: [],
    });
  };

export const ReadAlert =
  (alertDetailId: string) =>
  async (dispatch: Dispatch<AlertsDispatchTypes>) => {
    try {
      dispatch({type: ALERT_READ_LOADING});
      await sendAction({alertDetailId, read: true});
      dispatch({type: ALERT_READ_SUCCESS, alertDetailId});
    } catch (error) {
      dispatch({type: ALERT_READ_FAIL, error: error});
      showErrorMessage(error);
    }
  };

export const ReadAllAlerts =
  () => async (dispatch: Dispatch<AlertsDispatchTypes>) => {
    try {
      dispatch({type: ALERT_READ_ALL_LOADING});
      await readAllAlerts();
      dispatch({type: ALERT_READ_ALL_SUCCESS});
      showSuccessMessage('All alerts have been read');
    } catch (error) {
      dispatch({type: ALERT_READ_ALL_FAIL, error: error});
      showErrorMessage(error);
    }
  };

interface DefaultStateI {
  loading: boolean;
  error?: string | object;
  alerts?: Alert[];
  pageSize: number;
  pageNumber: number;
  nextPage: string | null;
  previous: string | null;

  filterArgs?: FilterArguments;
  responding?: boolean;

  isReading?: boolean;
  readingError?: string | object;

  isReadingAll?: boolean;

  unread: number;
  totalRows: number;
}

const defaultState: DefaultStateI = {
  loading: true,
  alerts: [],
  pageSize: LIMIT_PER_PAGE,
  pageNumber: 1,
  nextPage: null,
  previous: null,
  unread: 0,
  totalRows: 0,
};

const alertsReducer = (
  state: DefaultStateI = defaultState,
  action: AlertsDispatchTypes,
): DefaultStateI => {
  switch (action.type) {
    case ALERTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ALERTS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ALERTS_SUCCESS:
      const _newAlerts = action.alertsPayload;
      const currentAlertIds = state.alerts.map(alert => alert.id);
      const distinctAlerts = _newAlerts
        .filter(alert => {
          const exist = currentAlertIds.includes(alert.id);
          return !exist;
        })
        .map(alert => {
          return {
            ...alert,
            receivedAt: new Date(alert.time).getTime(),
          };
        });
      return {
        ...state,
        loading: false,
        alerts: [...state.alerts, ...distinctAlerts],
        ...action.paginationPayload,
        pageNumber:
          action?.paginationPayload?.nextPage !== null
            ? state.pageNumber + 1
            : state.pageNumber,
        unread: action.unread,
      };
    case ALERTS_FILTER:
      return {
        ...state,
        filterArgs: action.filterArgs,
      };
    case RECEIVE_ALERT:
      const existingAlert = state.alerts.find(
        alert => alert.alertId === action.payload.alertId,
      );
      if (existingAlert) {
        return {...state};
      }
      const newAlert = {
        ...action.payload,
        receivedAt: new Date(action.payload.time).getTime(),
      };
      return {
        ...state,
        alerts: [newAlert, ...state.alerts],
        unread: state.unread + 1,
      };
    case RECEIVE_SILENT_ALERT:
      const draftState = state.alerts.filter(
        alert => alert.id !== action.payload.id,
      );
      return {
        ...state,
        alerts: filterAlerts([...draftState, action.payload]),
      };
    case RESPOND_TO_ALERT:
      const newState = state.alerts.filter(
        alert => alert.id !== action.payload.id,
      );
      return {
        ...state,
        alerts: filterAlerts([...newState, action.payload]),
      };
    case NAVIGATE_TO_LINKING:
      RootNavigation.navigate('Link person to unit', action.payload);
      return {
        ...state,
      };
    case REMOVE_DELIVERED_ALERT:
      PushNotification.getDeliveredNotifications(notifs => {
        const notf = notifs.filter(notif => notif.tag === action.id)[0];
        if (notf) {
          console.log('notf', notf);
          PushNotification.removeDeliveredNotifications([notf.identifier]);
        }
      });
      return {
        ...state,
      };
    case RESPONDING_TO_ALERT:
      return {
        ...state,
        responding: action.payload,
      };
    case ALERTS_DELETE_ALL:
      return {
        ...state,
        loading: false,
        alerts: action.alertsPayload,
        ...action.paginationPayload,
      };
    case ALERTS_RESET:
      return {
        ...state,
        ...defaultState,
      };
    case ALERT_READ_FAIL:
      return {
        ...state,
        isReading: false,
        error: action.error,
      };
    case ALERT_READ_LOADING:
      return {
        ...state,
        isReading: true,
      };
    case ALERT_READ_SUCCESS:
      console.log(action);
      const newAlerts = state.alerts.map(alert => {
        if (alert.id === action.alertDetailId) {
          console.log('Mark this alert as read');
          return {
            ...alert,
            read: true,
          };
        }
        return alert;
      });
      return {
        ...state,
        isReading: false,
        error: null,
        alerts: filterAlerts([...newAlerts]),
        unread: state.unread > 0 ? state.unread - 1 : 0,
      };
    case ALERT_READ_ALL_LOADING:
      return {
        ...state,
        isReadingAll: true,
      };
    case ALERT_READ_ALL_SUCCESS:
      const prevAlerts = state.alerts;
      const nextAlerts = prevAlerts.map(alert => {
        return {
          ...alert,
          read: true,
        };
      });
      return {
        ...state,
        isReadingAll: false,
        alerts: filterAlerts(nextAlerts),
        unread: 0,
      };
    case ALERT_READ_ALL_FAIL:
      return {
        ...state,
        isReadingAll: false,
      };
    case ALERT_CLEAR_BUTTONS:
      const currentAlerts = state.alerts;
      const alertIdsToUpdate = state.alerts
        .filter(
          alert =>
            alert.alertId?.toUpperCase() ===
            action.parentAlertId?.toUpperCase(),
        )
        .map(alert => alert.id);
      if (alertIdsToUpdate.length !== 0) {
        const updatedAlerts = currentAlerts.map(alert => {
          if (alertIdsToUpdate.includes(alert.id)) {
            console.log('update THIS', alert.id);
            return {
              ...alert,
              action: action.action,
              button1: null,
              button2: null,
              button3: null,
            };
          }
          return {...alert};
        });
        return {
          ...state,
          alerts: [...updatedAlerts],
        };
      }
      return {...state};
    default:
      break;
  }
  return state;
};

const filterAlerts = (alerts: Alert[]) => {
  const sortedAlerts = alerts.sort(
    //@ts-ignore
    (alert1, alert2) => new Date(alert2.time) - new Date(alert1.time),
  );
  return sortedAlerts.filter(
    alert => alert.eventName !== 'UpdateVisitorScreen',
  );
};

export default alertsReducer;
