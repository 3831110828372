import React from 'react';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles({
  root: props => ({
    '& .MuiAppBar-root': {
      paddingLeft: '280px !important',
    },
  }),
});

const WebDrawerScreensWrapper = props => {
  //@ts-ignore
  const classes = useStyles();

  return <div className={classes.root}>{props.children}</div>;
};

export default WebDrawerScreensWrapper;
