const copyToClipboard = (text: string) => {
    //@ts-ignore
    navigator.clipboard.writeText(text);
}

const pasteFromClipboard = async() =>{
    //@ts-ignore
    const text = await navigator.clipboard.readText();
    return text;
}

export  {copyToClipboard, pasteFromClipboard};