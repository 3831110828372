import {residentApiWithAuth} from '../../api/resident';
import { CreateFolder, DeleteItem, MoveItem, RenameItem } from './types';

const fetchSubDirectories = async (
  unitId: string,
  relativeLocation?: string,
) => {
  try {
    let url = `/files/subDirectories?unitId=${unitId}`;

    if (relativeLocation) {
      url = url + `&relativeLocation=${relativeLocation}`;
    }
    const res = await residentApiWithAuth().get(url);
    return res.data?.data;
  } catch (e) {
    throw new Error(e.message);
  }
};

const previewFile = async (unitId, relativeLocation) => {
    let url = `/files?unitId=${unitId}&relativeLocation=${relativeLocation}&action=download`;
    const res = await residentApiWithAuth().get(url, {
      responseType: 'blob',
    });
    return res.data;
};

const createFolder = async (data: CreateFolder) => {
  let url = '/files/folder';
  const res = await residentApiWithAuth().post(url, data);
  return res.data;
};

const deleteItem = async (data: DeleteItem) => {
  let url = '/files/item';
  //@ts-ignore
  const res = await residentApiWithAuth().delete(url, {data});
  return res.data;
};

const renameItem  = async (data: RenameItem) => {
  let url = '/files/rename';
  const res = await residentApiWithAuth().post(url, data);
  return res.data;
}

const moveItem  = async (data: MoveItem) => {
  let url = '/files/move';
  const res = await residentApiWithAuth().post(url, data);
  return res.data;
}

export {
  fetchSubDirectories,
  previewFile,
  createFolder,
  deleteItem,
  renameItem,
  moveItem,
};
