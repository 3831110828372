const showAlert = (
  title: string,
  message: string,
  noClickHandler: any,
  yesClickHandler: any,
) => {
  confirm(message) ? yesClickHandler() : noClickHandler();
};

export default showAlert;
