/* eslint-disable react-native/no-inline-styles */
import React, {useState} from 'react';
import {View} from 'react-native';
import Text from '../../../components/Text';
import tailwind from 'tailwind-rn';
import {getCurrentUnitLink} from '../../../utils';
import {useSelector} from 'react-redux';
import {RootStore} from '../../../redux/store';
import {deriveUnitName} from '../../../utils/deriveDisplayName';
import Button from '../../../components/Button';
import HStack from '../../../components/HStack';
import Radio from '../../../components/Radio';
import Select from '../../../components/Select';
import {Modal} from 'native-base';
import DateTimeInput from '../../../components/DateTimeInput';
import { showErrorMessage } from '../../../service/flashMessage';

export default function FilterPackageHistory({
  panelActive,
  setPanelActive,
  updateFilters,
}) {
  const [startPickerDateVisible, setStartDatePickerVisible] = useState(false);
  const [endDatePickerVisible, setEndDatePickerVisible] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(undefined);
  const [selectedEndDate, setSelectedEndDate] = useState(undefined);
  const currentUnitId = getCurrentUnitLink()?.unit?.id;
  const [selectedUnit, setSelectedUnit] = useState(currentUnitId);
  const units = useSelector((state: RootStore) => state.units.unitLinks);
  const [selectedStatus, setSelectedStatus] = useState<
    'Unclaimed' | 'Claimed' | 'All'
  >('All');

  function setFilters() {
    const startTime = selectedStartDate
      ? new Date(selectedStartDate).toISOString()
      : null;
    const endTime = selectedEndDate
      ? new Date(selectedEndDate).toISOString()
      : null;

      if(new Date(selectedEndDate).getTime() < new Date(selectedStartDate).getTime()){
        showErrorMessage("End date must be greater than start time");
        return;
      }

    const filters = {
      startDate: startTime,
      endDate: endTime,
      selectedUnit,
      selectedStatus: selectedStatus === 'All' || selectedStatus === 'Claimed',
      claimedOnly: selectedStatus === 'Claimed',
    };
    updateFilters(filters);

    setPanelActive(false);
  }

  function resetFilters() {
    setPanelActive(false);

    updateFilters({
      startDate: null,
      endDate: null,
      selectedUnit: currentUnitId,
      selectedStatus: true,
      claimedOnly: false,
    });
    setSelectedStatus('All');
    setSelectedUnit(currentUnitId);
    setSelectedStartDate(null);
    setSelectedEndDate(null);
  }

  function RadioButtons() {
    const buttons = [
      {
        value: 'Unclaimed',
        label: 'Unclaimed',
      },
      {
        value: 'Claimed',
        label: 'Claimed',
      },
      {
        value: 'All',
        label: 'All',
      },
    ];

    return (
      <Radio.Group
        name="package_history_radio"
        style={{
          flexDirection: 'row',
        }}
        value={selectedStatus}
        onChange={nextValue => {
          //@ts-ignore
          setSelectedStatus(nextValue);
        }}>
        {buttons.map(button => {
          return (
            <Radio value={button.value} m="1">
              {button.label}
            </Radio>
          );
        })}
      </Radio.Group>
    );
  }

  return (
    <Modal isOpen={panelActive} onClose={() => setPanelActive(false)}>
      <Modal.Content>
        <Modal.CloseButton />
        <Modal.Header>Filter visitor logs</Modal.Header>
        <Modal.Body>
          <View style={tailwind('flex-1 mx-2 justify-between mb-4')}>
            <Text>Unit</Text>
            <Select
              minWidth={128}
              placeholder="Select unit"
              selectedValue={selectedUnit}
              onValueChange={itemValue => setSelectedUnit(itemValue)}>
              {units?.map(value => (
                <Select.Item
                  value={value.unit.id}
                  label={deriveUnitName(value)}
                />
              ))}
            </Select>
          </View>
          <View>
            <Text style={tailwind('mt-4')}>Status</Text>
            <RadioButtons />
          </View>
          <DateTimeInput
            label="From"
            value={selectedStartDate ? new Date(selectedStartDate) : new Date()}
            onConfirm={date => {
              setSelectedStartDate(date);
            }}
            description=''
            mode='date'
            maximumDate={new Date()}
          />
      
          <DateTimeInput
            label="To"
            value={selectedEndDate ? new Date(selectedEndDate) : new Date()}
            onConfirm={date => {
              setSelectedEndDate(date);
            }}
            description=''
            mode='date'
            maximumDate={new Date()}
          />
          <HStack mt={8}>
            <Button
              colorScheme="primary"
              mr={2}
              py={4}
              style={tailwind('flex-1')}
              onPress={() => setFilters()}>
              Apply
            </Button>
            <Button
              onPress={() => resetFilters()}
              colorScheme="primary"
              variant="outline"
              py={4}
              style={tailwind('flex-1')}>
              Reset
            </Button>
          </HStack>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
}
