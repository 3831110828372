import {residentApiWithAuth} from './resident';

const fetchUnitPeopleConnectors = async () => {
  try {
    let res = await residentApiWithAuth().get('/unitPeopleConnector');

    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

const createUnitPeopleConnector = async (connector) => {
  try {
    let res = await residentApiWithAuth().post(
      '/unitPeopleConnector',
      connector,
    );
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

const checkPeopleLinkCode = async (linkCode) => {
  try {
    let res = await residentApiWithAuth().get(
      `/unitPeopleConnector/checkLinkCode/${linkCode}`,
    );

    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export {fetchUnitPeopleConnectors, createUnitPeopleConnector, checkPeopleLinkCode };
