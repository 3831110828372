import React, { useCallback, useState } from 'react';
import {
  SafeAreaView,
  ScrollView,
  View,
  useWindowDimensions,
} from 'react-native';
import ScreenWrapper from '../components/ScreenWrapper';
import isWeb from '../../../utils/isWeb';
import {HStack, Heading} from 'native-base';
import Text from '../../../components/Text';
import Button from '../../../components/Button';
import TextInput from '../../../components/TextInput';
import {useForm} from 'react-hook-form';
import {RegisterActionBar, RegisterPrevButton} from '../../../components';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import { RegistrationData } from '../types';
import { useDispatch } from 'react-redux';
import { resetRegisterData, setRegisterData } from '../registerReducer';
import { useFocusEffect } from '@react-navigation/native';
import QRScanner from '../../UnitPeopleLink/components/QRScanner';

const codeValidationScheme = yup.object().shape({
  code: yup
    .string()
    .required('Code is required')
    .test(
      'Check code starts with UC',
      `Code normally starts with 'UC'`,
      function () {
        let code = this.parent['code'];

        if (code) {
          return code.toUpperCase().startsWith('UC') ? true : false;
        }
      },
    ),
});

const InviteCode = ({navigation}) => {
  const {width} = useWindowDimensions();
  const dispatch = useDispatch();

  const {control, handleSubmit, setValue} = useForm({
    defaultValues: {
      code: null,
    },
    resolver : yupResolver(codeValidationScheme)
  });

  const handleCodeSubmit = async values => {
    const registrationData: RegistrationData = {
      linkCode: values.code,
    };
    //@ts-ignore
    dispatch(setRegisterData(registrationData));
    navigation.navigate('Dl Capture');
  };

  useFocusEffect(
    useCallback(() => {
      //@ts-ignore
      dispatch(resetRegisterData());
    }, []),
  );

  return (
    <ScreenWrapper>
      <SafeAreaView
        style={[
          {
            flex: 1,
            marginVertical: 24,
          },
          isWeb && {
            backgroundColor: '#ffffff',
            width: width / 3,
          },
        ]}>
        <ScrollView keyboardShouldPersistTaps="handled">
          <RegisterActionBar
            step={1}
            hasSkip
            onSkip={() => navigation.navigate('Dl Capture')}
          />
          <View
            style={{
              marginHorizontal: 24,
              marginTop: 16,
            }}>
            <Heading size="lg">Invite Code</Heading>
            <Text
              style={{
                marginTop: 32,
                fontSize: 18,
              }}>
              Type in the code you received in your welcome to unit email.
            </Text>
            <TextInput
              //@ts-ignore
              control={control}
              name="code"
              placeholder="e.g UC-YQ3R-1N2B"
              label="Invite code"
            />
            <Text style={{fontSize: 24, textAlign: 'center', marginBottom: 10}}>
              or
            </Text>
            {!isWeb && (
              <QRScanner
                setProfileCode={qrCode => {
                    setValue('code', qrCode);
                }}
              />
            )}
            <Button
              variant="ghost"
              onPress={() => navigation.navigate('Dl Capture')}>
              Don't have invite code ?
            </Button>
          </View>
        </ScrollView>
        <HStack style={{margin: 24, marginTop: 32}}>
          <RegisterPrevButton />
          <Button onPress={handleSubmit(handleCodeSubmit)} style={{flex: 1}}>
            Next
          </Button>
        </HStack>
      </SafeAreaView>
    </ScreenWrapper>
  );
};

export default InviteCode
