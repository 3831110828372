import HStack from './HStack';
import Select from './Select';
import React, {useEffect, useState} from 'react';
import {View, TouchableOpacity} from 'react-native';
import Text from '../components/Text';
import tailwind from 'tailwind-rn';
import Ionicon from 'react-native-vector-icons/Ionicons';

type DateFrame = {
  id: string;
  unitId: string;
  type: string | null;
  startDate: string;
  endDate: string | null;
  shortDescription: string | null;
  notes: string | null;
  status: string | null;
  projectId: string | null;
  reservationId: string | null;
  overlapAllowed: string | null;
};

const DateFrameChooser = ({dateFrames, selectHandler, addClickHandler, selectedDateFrame}) => {
  const [_selectedDateFrame, _setSelectedDateFrame] = useState(selectedDateFrame);

  const onSelectedItemsChange = (dateFrame: string) => {
    _setSelectedDateFrame(dateFrame);
    selectHandler(dateFrame);
  };

  useEffect(()=> {
    _setSelectedDateFrame(selectedDateFrame);
  },[selectedDateFrame])

  return (
    <View style={tailwind('flex-1')}>
      <Text style={tailwind('mt-4 mb-2')}>Date frame</Text>
      <HStack>
        <Select
          minWidth={270}
          selectedValue={selectedDateFrame}
          placeholder="Select a dateframe"
          onValueChange={(val) => onSelectedItemsChange(val)}>
          {dateFrames?.map((dateFrame) => (
            <Select.Item
              key={dateFrame?.id}
              label={
                dateFrame?.shortDescription
                  ? dateFrame?.shortDescription
                  : `${new Date(
                      dateFrame?.startDate,
                    ).toLocaleDateString()}-${new Date(
                      dateFrame?.endDate,
                    ).toLocaleDateString()}   `
              }
              value={dateFrame.id}
            />
          ))}
        </Select>
        <View style={tailwind('ml-3')}>
          <TouchableOpacity
            onPress={() => addClickHandler()}
            style={tailwind('mb-2 px-2 py-2 bg-green-400 rounded')}>
            <HStack>
              <Ionicon name="add" size={20} color="#fff" />
              <Text style={tailwind('text-white text-center')}>Add new</Text>
            </HStack>
          </TouchableOpacity>
        </View>
      </HStack>
    </View>
  );
};

export default DateFrameChooser;
