import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(1),
    width: '100%',
    // height: '80%',
    border: '1px solid #ccc',
    borderRadius: '2px',
    padding: '1rem',
    paddingBottom: '1rem',
    display: 'flex',
    flexDirection: 'column',
    // overflow: 'hidden',

    // position: 'relative',
  },
  preview: {
    margin: theme.spacing(1),
    width: 700,
    // height: '80%',
    border: '1px solid #ccc',
    borderRadius: '4px',
    padding: '1rem',
    paddingBottom: '1rem',
    display: 'flex',
    flexDirection: 'column',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    position: 'relative',
  },

  input: {
    paddingBottom: '1rem',
    flexGrow: 1,
  },
  button: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '1rem',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    padding: '1rem',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
}));