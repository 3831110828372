import React, {useEffect, useState} from 'react';
import Container from '../../components/Container';
import Text from '../../components/Text';
import Button from '../../components/Button';
import useColorModeValue from '../../components/useColorModeValue';
import {Navbar} from '../../components';
import {useNavigation} from '@react-navigation/native';
import {
  checkMultiple,
  openSettings,
  PERMISSIONS,
  RESULTS,
  request,
  Permission,
} from 'react-native-permissions';
import {ScrollView} from 'react-native';
import {showErrorMessage} from '../../service/flashMessage';
import {Accordion, Box} from 'native-base';
import tailwind from 'tailwind-rn';

const DevicePermissions = () => {
  const navigation = useNavigation();
  const [notificationPermission, setNotificationPermission] = useState({
    id: 'Notifications',
    label: 'Notifications',
    message: null,
  });
  const [cameraPermission, setCameraPermission] = useState({
    id: 'Camera',
    label: 'Camera',
    message: null,
  });

  useEffect(() => {
    checkNotificationPermission();
    checkCameraPermission();
  }, []);

  const checkNotificationPermission = async () => {
    // @ts-ignore
    const status = await window.Notification.permission;
   setNotificationPermission({...notificationPermission, message: status});
  };

  const checkCameraPermission = async () => {
    // @ts-ignore
    const status = await window.Notification.permission;
    setNotificationPermission({...notificationPermission, message: status});
  };

  const requestPermission = (permission: Permission) => {
    request(permission)
      .then(status => {
        switch (status) {
          case RESULTS.UNAVAILABLE:
            showErrorMessage(
              'This feature is not available (on this device / in this context)',
            );
            break;
          case RESULTS.DENIED:
            showErrorMessage(
              'The permission has not been requested / is denied but requestable',
            );
            break;
          case RESULTS.LIMITED:
            showErrorMessage(
              'The permission is limited: some actions are possible',
            );
            break;
          case RESULTS.GRANTED:
            showErrorMessage('The permission is granted');
            break;
          case RESULTS.BLOCKED:
            showErrorMessage(
              'The permission is denied and not requestable anymore',
            );
            openSettings();
            break;
        }
      })
      .catch(err => showErrorMessage(err.message))
      .finally(() => {
        // checkNotificationPermission();
      });
  };

  const requestNotificationPermission = async () => {
    // @ts-ignore
    const status = await window.Notification.requestPermission();
    setNotificationPermission({...notificationPermission, message: status});
  }

  return (
    <Container>
      <Navbar navigation={navigation} title="Permissions" />
      <ScrollView>
        <Box
          bg={useColorModeValue('gray.50', 'gray.700')}
          style={tailwind('mx-4 mt-4')}>
          <Accordion>
            <Accordion.Item>
              <Accordion.Summary>
                {notificationPermission.label}
              </Accordion.Summary>
              <Accordion.Details>
                <Text>{`Status - ${notificationPermission.message}`}</Text>
                {notificationPermission.message !== 'granted' && (
                  <Button
                    onPress={() => requestNotificationPermission()}
                    variant="outline"
                    style={{marginTop: 16}}>
                    Request
                  </Button>
                )}
              </Accordion.Details>
            </Accordion.Item>
            <Accordion.Item>
              <Accordion.Summary>
                {cameraPermission.label}
              </Accordion.Summary>
              <Accordion.Details>
                <Text>{`Status - ${cameraPermission.message}`}</Text>
                {cameraPermission.message !== 'granted' && (
                  <Button
                    // onPress={() => requestPermission(permission.id)}
                    variant="outline"
                    style={{marginTop: 16}}>
                    Request
                  </Button>
                )}
              </Accordion.Details>
            </Accordion.Item>
          </Accordion>
        </Box>
      </ScrollView>
    </Container>
  );
};

export default DevicePermissions;
