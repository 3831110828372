import AsyncStorage from '@react-native-async-storage/async-storage';

const storeRememberMe = async rememberMe => {
  try {
    await AsyncStorage.setItem('rememberMe', rememberMe);
  } catch (e) {
    throw new Error(e.message);
  }
};

const storeUser = async user => {
  try {
    const userJson = JSON.stringify(user);
    await AsyncStorage.setItem('user', userJson);
  } catch (e) {
    throw new Error(e.message);
  }
};

export {storeRememberMe, storeUser};
