import React from "react";
import {Permissions} from 'devextreme-react/file-manager';

const FileManagerPermissions = () => {
  return (
    <Permissions
      create={false}
      copy={false}
      move={false}
      delete={false}
      rename={false}
      upload={false}
      download={false}
    />
  );
};

export default FileManagerPermissions;