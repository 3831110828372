//@ts-nocheck

import React, { useState, useEffect } from 'react';
import FileViewerReact from 'react-file-viewer';
import Button from '../../../components/Button';
import printJS, { PrintTypes } from 'print-js';
import DownloadIcon from '@mui/icons-material/Download';
type IDocument = {
  uri: string;
  fileType: string;
};

import PrintIcon from '@mui/icons-material/Print';
const FileViewer = ({ file, name }: { file: Blob; name: string }) => {
  const [fileToView, setFileToView] = useState<IDocument>();

  useEffect(() => {
    async function convertBlobToFile() {
      if (file && file instanceof Blob && file.size > 0) {
        const fType = name.split('.').pop()?.toLowerCase() || '';
        const objectURL = URL.createObjectURL(file);
        setFileToView({ uri: objectURL, fileType: fType });
      } else {
        console.error('Invalid Blob or Blob is empty');
      }
    }

    convertBlobToFile();

    return () => {
      if (fileToView) {
        URL.revokeObjectURL(fileToView.uri);
      }
    };
  }, [file, name]);

  const handlePrint = () => {
    if (fileToView) {
      // Separate all image types to be of type image
      const type: PrintTypes | undefined = [
        'jpg',
        'jpeg',
        'png',
        'gif',
      ].includes(fileToView.fileType)
        ? 'image'
        : undefined;
      printJS(fileToView.uri, type);
    }
  };

  // Function to handle download
  const handleDownload = () => {
    if (fileToView?.uri) {
      // Create a link element, set href and download attributes, and click it programmatically
      const link = document.createElement('a');
      link.href = fileToView.uri; // The file's URL
      link.download = name; // The file name to download
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
    }
  };

  const imageTypes = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'svg'];
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '1rem',
        }}>
        <Button
        //   sx={{
        //     display: 'flex',
        //     alignSelf: 'flex-end',
        //     marginLeft: '2rem',
        //   }}
        //   variant="soft"
          onPress={handleDownload}>
          <DownloadIcon />
        </Button>

        <Button
        //   sx={{
        //     display: 'flex',
        //     alignSelf: 'flex-end',
        //     marginLeft: '2rem',
        //   }}
        //   variant="soft"
          onPress={handlePrint}>
          <PrintIcon />
        </Button>
      </div>
      {/* if filetype is not image display view compnent else use image tag */}
      {fileToView &&
      fileToView.fileType &&
      imageTypes.includes(fileToView.fileType) ? (
        <img
          src={fileToView.uri}
          alt={name}
          style={{ width: '100%', height: '100%', objectFit: 'contain' }}
        />
      ) : (
        <FileViewerReact
          style={{ height: '100%', width: '100%' }}
          fileType={fileToView?.fileType ?? ''}
          filePath={fileToView?.uri ?? ''}
          errorComponent={() => <div>Error</div>}
          onError={(e: Error) => console.log(e)}
        />
      )}
    </div>
  );
};

export default FileViewer;