// @ts-nocheck
import {Dispatch} from 'redux';
import {showErrorMessage, showSuccessMessage} from '../../service/flashMessage';
import {fetchLinksByPerson, updateUnitPeopleLink} from '../../api/links';
import * as RootNavigation from '../../navigation';
import {Building, Community, UnitLink} from '../../@types/UnitLink';
import {store} from '../../redux/store';
import {ListEventsDispatchTypes} from '../../redux/reducers/listEventsReducer';
import DirectoryInfo from '../../@types/DirectoryInfo';
import { updateUnit } from '../../api/units';

export const UNITS_LOADING = 'UNITS_LOADING';
export const UNITS_FAIL = 'UNITS_FAIL';
export const UNITS_SUCCESS = 'UNITS_SUCCESS';
export const UNITS_UPDATE_LOADING = 'UNITS_LOADING';
export const UNITS_UPDATE_FAIL = 'UNITS_FAIL';
export const UNITS_UPDATE_SUCCESS = 'UNITS_UPDATE_SUCCESS';
export const UNITS_SET_DEFAULT = 'UNITS_SET_DEFAULT';
export const UNITS_SET_DEFAULT_LOADING = 'UNITS_SET_DEFAULT_LOADING';
export const UNITS_FILTER = 'UNITS_FILTER';
export const UNITS_SET_COMMUNITIES = 'UNITS_SET_COMMUNITIES';
export const UNITS_SET_BUILDINGS = 'UNITS_SET_BUILDINGS';
export const UNITS_ADD = 'UNITS_ADD';
export const UNITS_SEARCH = 'UNITS_SEARCH';
export const TOGGLE_AUTOMATED_CALLS = 'TOGGLE_AUTOMATED_CALLS';
export const UPDATE_DIRECTORY_SETTINGS = 'UPDATE_DIRECTORY_SETTINGS';

export type FilterArguments = {
  community: string | null;
  building: string | null;
};

export interface UnitsLoading {
  type: typeof UNITS_LOADING;
}

export interface UnitsFail {
  type: typeof UNITS_FAIL;
  error: string | object;
}

export interface UnitsSuccess {
  type: typeof UNITS_SUCCESS;
  payload: UnitLink[];
}

export interface UnitUpdateLoading {
  type: typeof UNITS_UPDATE_LOADING;
}

export interface UnitUpdateFail {
  type: typeof UNITS_UPDATE_FAIL;
  error: string | object;
}

export interface UnitUpdateSuccess {
  type: typeof UNITS_UPDATE_SUCCESS;
  payload: UnitLink;
}

export interface UnitsSetDefault {
  type: typeof UNITS_SET_DEFAULT;
  payload: string;
}

export interface UnitsFilter {
  type: typeof UNITS_FILTER;
  filterArgs: FilterArguments;
}

export interface UnitsSetCommunities {
  type: typeof UNITS_SET_COMMUNITIES;
  communities: Community[];
}

export interface UnitsSetBuildings {
  type: typeof UNITS_SET_BUILDINGS;
  buildings: Building[];
}

export interface UnitsAdd {
  type: typeof UNITS_ADD;
  unitLink: UnitLink;
}

export interface UnitsSetDefaultLoading {
  type: typeof UNITS_SET_DEFAULT_LOADING;
  payload: boolean;
}

export interface UnitsSearch {
  type: typeof UNITS_SEARCH;
  searchTerm: string;
}

export interface ToggleAutomatedCalls {
  type: typeof TOGGLE_AUTOMATED_CALLS;
  unitId: string;
  allowAutomatedCalls: boolean;
}

export interface UpdateDirectorySettings {
  type: typeof UPDATE_DIRECTORY_SETTINGS;
  directoryInfo: DirectoryInfo;
  unitPeopleLinkId: string;
}

export type UnitsDispatchTypes =
  | UnitsLoading
  | UnitsFail
  | UnitsSuccess
  | UnitUpdateSuccess
  | UnitsSetDefault
  | UnitsFilter
  | UnitsSetCommunities
  | UnitsSetBuildings
  | UnitsAdd
  | UnitsSetDefaultLoading
  | UnitsSearch
  | ListEventsDispatchTypes
  | ToggleAutomatedCalls
  | UpdateDirectorySettings;

export const GetUnitScreenData =
  () => async (dispatch: Dispatch<UnitsDispatchTypes>) => {
    try {
      dispatch({type: UNITS_LOADING});
      const personId = store.getState()?.auth?.user?.id;
      const oldDefault = store
        .getState()
        .units.unitLinks.filter(linkObj => linkObj.isDefault === true)[0];
      let links: UnitLink[] = await fetchLinksByPerson(1, personId);

      dispatch({type: UNITS_SUCCESS, payload: links});

      // Retain old default unitLink between refreshes
      if (oldDefault) {
        dispatch({type: UNITS_SET_DEFAULT, payload: oldDefault.id});
      }

      const linksWithCommunity = links?.filter(
        link => link.unit.community.id !== null,
      );

      const communitesArr = linksWithCommunity.map(
        linkItem => linkItem.unit.community,
      );

      dispatch({
        type: UNITS_SET_COMMUNITIES,
        communities: Array.from(new Set(communitesArr)),
      });

      const linksWithBuildings = links?.filter(
        link => link.unit.building.id !== null,
      );
      const buildingsArr = linksWithBuildings.map(
        linkItem => linkItem.unit.building,
      );
      dispatch({
        type: UNITS_SET_BUILDINGS,
        buildings: Array.from(new Set(buildingsArr)),
      });

      //Fetch listEvents
      // dispatch({type: LIST_EVENTS_LOADING});

      // let listEvents: ListEvent[] = await fetchListEvents();

      // dispatch({type: LIST_EVENTS_SUCCESS, payload: listEvents});

      //If person is linked to one and only one unit, autoselect it as default
      if (links.length === 1) {
        const unitPeopleLink = links[0];
        const isApproved =
          unitPeopleLink.unitLinkApproved && unitPeopleLink.peopleLinkApproved;
        if (isApproved) {
          dispatch({type: UNITS_SET_DEFAULT, payload: links[0].id});
        }
      }
    } catch (error) {
      showErrorMessage(error);
      dispatch({type: UNITS_FAIL, error});
    }
  };

//If link belongs to current user
//  Add it to peopleLinks and unitLinks (redux and sqlite)
//If link doesn't belong to current user
//  Add it to peopleLinks (redux and sqlite)
export const CreateUnit =
  (unitLink: UnitLink) => async (dispatch: Dispatch<UnitsDispatchTypes>) => {
    const {id} = store.getState().auth.user;

    try {
      if (id === unitLink.person.id) {
        //update state
        dispatch({type: UNITS_UPDATE_SUCCESS, payload: unitLink});

        showSuccessMessage('Your link request was approved');
      }
    } catch (error) {
      console.log('CreatePeople' + error.message);
    }
  };

export const UpdateUnitLink =
  (unitLinkId: string, unitLink: UnitLink) =>
  async (dispatch: Dispatch<UnitsDispatchTypes>) => {
    try {
      dispatch({type: UNITS_UPDATE_LOADING});
      const oldDefault = store
        .getState()
        .units.unitLinks.filter(link => link.isDefault === true)[0];
      //Update in API,
      const newUnitLink = await updateUnitPeopleLink(unitLinkId, unitLink);

      //Retain old default unitLink
      if (oldDefault) {
        //Set new default  unitLink
        dispatch({type: UNITS_UPDATE_SUCCESS, payload: newUnitLink});
      } else {
      }

      showSuccessMessage('Unit link updated successfully');
      RootNavigation.goBack();
    } catch (error) {
      dispatch({type: UNITS_FAIL, error: error});
      showErrorMessage(error);
    }
  };

export const SetDefaultUnitLink =
  (unitLinkId: string) => async (dispatch: Dispatch<UnitsDispatchTypes>) => {
    dispatch({type: UNITS_SET_DEFAULT_LOADING, payload: true});

    //Update state with new default
    dispatch({type: UNITS_SET_DEFAULT, payload: unitLinkId});

    //Navigate to alerts
    // RootNavigation.navigate('Alerts', null);
    dispatch({type: UNITS_SET_DEFAULT_LOADING, payload: false});
  };

export const SetSwitchingUnits =
  (switching: boolean) => async (dispatch: Dispatch<UnitsDispatchTypes>) => {
    dispatch({type: UNITS_SET_DEFAULT_LOADING, payload: switching});
  };

export const FilterUnits =
  (filterArgs: FilterArguments) =>
  async (dispatch: Dispatch<UnitsDispatchTypes>) => {
    dispatch({type: UNITS_FILTER, filterArgs});
  };

export const SearchUnits =
  (searchTerm: string) => async (dispatch: Dispatch<UnitsDispatchTypes>) => {
    dispatch({type: UNITS_SEARCH, searchTerm});
  };

export const ToggleAutomatedCalls =
  (unitId: string, allowAutomatedCalls:boolean) =>
  async (dispatch: Dispatch<UnitsDispatchTypes>) => {
    try {
    const res =    await updateUnit(unitId, {allowAutomatedCalls});
       dispatch({type: TOGGLE_AUTOMATED_CALLS,unitId, allowAutomatedCalls});
    } catch (error) {
      showErrorMessage(error.message)
    }
  };

export const UpdateMyDirectorySettings =
  (unitPeopleLinkId: string, directoryInfo: DirectoryInfo) =>
  async (dispatch: Dispatch<UnitsDispatchTypes>) => {
    dispatch({
      type: UPDATE_DIRECTORY_SETTINGS,
      unitPeopleLinkId,
      directoryInfo,
    });
  };

interface DefaultStateI {
  loading: boolean;
  error?: string | object;
  unitLinks?: UnitLink[];
  unitLinksHolder: UnitLink[];
  filterArgs?: FilterArguments;
  communities?: Community[];
  buildings?: Building[];
  isSwitching?: boolean;
}

const defaultState: DefaultStateI = {
  loading: false,
  unitLinks: [],
  error: null,
  communities: [],
  buildings: [],
  isSwitching: false,
  unitLinksHolder: [],
};

const unitsReducer = (
  state: DefaultStateI = defaultState,
  action: UnitsDispatchTypes,
): DefaultStateI => {
  switch (action.type) {
    case UNITS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case UNITS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case UNITS_SUCCESS:
      const linksHolder = action.payload;
      return {
        ...state,
        loading: false,
        unitLinks: action.payload,
        unitLinksHolder: linksHolder,
      };
    case UNITS_UPDATE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case UNITS_UPDATE_SUCCESS:
      const {id} = action.payload;
      const previousState = state.unitLinks;
      const newState = previousState.map((item, _) => {
        if (item.id !== id) {
          return item;
        }
        return {
          ...item,
          ...action.payload,
        };
      });
      return {
        ...state,
        unitLinks: newState,
        loading: false,
      };
    case UNITS_SET_DEFAULT:
      const idToUpdate = action.payload;
      const previousUnitLinks = state.unitLinks;
      const newUnitLinks = previousUnitLinks.map((item, _) => {
        if (item.id !== idToUpdate) {
          return {...item, isDefault: false};
        }
        return {
          ...item,
          isDefault: true,
        };
      });
      return {
        ...state,
        unitLinks: newUnitLinks,
      };
    case UPDATE_DIRECTORY_SETTINGS:
      const {unitPeopleLinkId, directoryInfo} = action;
      const nextUnitLinks = state.unitLinks.map((item, _) => {
        if (item.id !== unitPeopleLinkId) {
          return {
            ...item,
          };
        }
        return {
          ...item,
          ...directoryInfo,
        };
      });

      return {
        ...state,
        unitLinks: nextUnitLinks,
      };
    case UNITS_FILTER:
      let newUnits = state.unitLinks;
      const {community, building} = action.filterArgs;
      if (community) {
        newUnits = newUnits.filter(unit => unit.unit.community === community);
      }
      if (building) {
        newUnits = newUnits.filter(unit => unit.unit.building === building);
      }
      return {
        ...state,
        loading: false,
        filterArgs: action.filterArgs,
        unitLinks: newUnits,
      };
    case UNITS_SET_COMMUNITIES:
      return {
        ...state,
        communities: action.communities,
      };
    case UNITS_SET_BUILDINGS:
      return {
        ...state,
        buildings: action.buildings,
      };
    case UNITS_SET_DEFAULT_LOADING:
      return {
        ...state,
        isSwitching: action.payload,
      };
    case UNITS_SEARCH:
      if (action.searchTerm) {
        const links = state.unitLinksHolder.filter(link => {
          const nickNameData = link.unitNickname
            ? `${link.unitNickname}`.toUpperCase()
            : `${link.unit.unitName}`.toUpperCase();

          const searchTermData = action.searchTerm.toUpperCase();
          return nickNameData.indexOf(searchTermData) > -1;
        });
        const currentUnit = state.unitLinks.find(val => val.isDefault);
        return {
          ...state,
          unitLinks: [...links, ...(currentUnit ? [currentUnit] : [])],
        };
      } else {
        return {
          ...state,
          unitLinks: state.unitLinksHolder,
        };
      }
    case TOGGLE_AUTOMATED_CALLS: {
      const {unitId, allowAutomatedCalls} = action;
      const nextUnitLinks = state.unitLinks.map(unitLink => {
        if (unitLink.unit.id === unitId) {
          return {
            ...unitLink,
            unit: {
              ...unitLink.unit,
              allowAutomatedCalls: allowAutomatedCalls,
            },
          };
        }
        return {
          ...unitLink,
        };
      });
      return {
        ...state,
        unitLinks: [...nextUnitLinks],
      };
    }
    default:
      break;
  }
  return state;
};

export default unitsReducer;
