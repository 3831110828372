import React from 'react';
import {
  Alert,
  PermissionsAndroid,
  Platform,
  SafeAreaView,
  ScrollView,
  View,
  useWindowDimensions,
  Linking
} from 'react-native';
import Text from '../../../components/Text';
import Button from '../../../components/Button';
import Heading from '../../../components/Heading';
import HStack from '../../../components/HStack';
import tailwind from 'tailwind-rn';
import {RegisterActionBar, RegisterPrevButton} from '../../../components';
import ScreenWrapper from '../components/ScreenWrapper';
import isWeb from '../../../utils/isWeb';
import Contacts from 'react-native-contacts';
import {showErrorMessage, showSuccessMessage} from '../../../service/flashMessage';

const AddContact = ({navigation}) => {
  const {width} = useWindowDimensions();

  const saveContact = async() => {
    const newContact = {
      emailAddresses: [
        {
          label: 'home',
          email: 'info@communityemail.info',
        },
      ],
      phoneNumbers: [
        {
          label: 'mobile',
          number: '(954) 449-6239',
        },
      ],
      familyName: 'Office',
      givenName: 'Management',
    };

    const contacts = await Contacts.getAll();
  
    for (let index = 0; index < contacts.length; index++) {
      const element = contacts[index];
      if(element.givenName + element.familyName === newContact.givenName + newContact.familyName) {
        navigation.navigate('User Credentials');
        return;
      }
    }

    const contact = await Contacts.addContact(newContact);
    if (contact) {
      showSuccessMessage('Contact added to phone book sucessfully');
      navigation.navigate('User Credentials');
    }
  }

  const showPermissionDialog=()=> {
    Alert.alert(
      'Alert',
      'Please grant Contact permission in settings or skip to continue.',
      [
        {
          text: 'SKIP',
          onPress: () => navigation.navigate('User Credentials'),
        },
        {
          text: 'OK',
          onPress: () => {
            Linking.openSettings();
          },
        },
      ],
    );
  }

  const onNext = async () => {
    if (Platform.OS === 'android') {
      try {
        const writePermission = await PermissionsAndroid.request(
          PermissionsAndroid.PERMISSIONS.WRITE_CONTACTS,
          {
            title: 'Contacts',
            message: 'This app would like to view your contacts.',
            buttonPositive: 'Accept',
          },
        );
        const readPermission = await PermissionsAndroid.request(
          PermissionsAndroid.PERMISSIONS.READ_CONTACTS,
        );
        if (writePermission === 'granted' && readPermission === 'granted') {
          await saveContact();
        } else if (
          writePermission === 'never_ask_again' ||
          readPermission === 'never_ask_again'
        ) {
          showPermissionDialog();
        }
      } catch (e) {
        showErrorMessage('Pemission error' + e);
      }
    }
    if(Platform.OS === 'ios') {
    const permission  = await Contacts.checkPermission(); 
    if(permission === 'undefined'){
    const requestedPermission = await Contacts.requestPermission();
    if(requestedPermission === 'authorized'){
     await saveContact();
    }
    if(requestedPermission === 'denied'){
      showErrorMessage("Contact permission denied, please enable in settings");
      showPermissionDialog();
    }
    }
    if(permission === 'authorized'){
      await saveContact();
    }
    if(permission === 'denied'){
      showErrorMessage("Contact permission denied, please enable in settings");
      showPermissionDialog();
    }
    }
  };

  return (
    <ScreenWrapper>
      <SafeAreaView
        style={[
          tailwind('flex-1 my-4'),
          isWeb && {backgroundColor: '#ffffff', width: width / 3},
        ]}>
        <ScrollView keyboardShouldPersistTaps="handled">
          <RegisterActionBar step={5} />
          <View style={tailwind('mx-6 mt-4')}>
            <Heading size="lg">Add Contact</Heading>
            <Text style={tailwind('mt-8 text-lg')}>
              This app keeps you informed about events and changes. You will
              always receive an app message and an email. In important
              situations, you may also get a text or call.
            </Text>
            <Text style={tailwind('mt-4 text-lg')}>
              To ensure you receive these messages, we need to add a phone
              number and email to your contacts.
            </Text>
            <Text style={tailwind('mt-4 text-lg')}>
              We do this so you will always know who is calling or emailing you.
              We will add this contact for you:
            </Text>
            <Text style={tailwind('mt-4 text-lg')}>
              Name: Management Office
            </Text>
            <Text style={tailwind('mt-1 text-lg')}>Phone: 954-449-6239</Text>
            <Text style={tailwind('mt-1 text-lg')}>
              Email: info@communityemail.info
            </Text>
            <Text style={tailwind('mt-4 text-lg')}>
              If you do not grant this permission you may miss important
              messages that could affect your property or access.
            </Text>
          </View>
        </ScrollView>
        <HStack style={{margin: 24, marginTop: 32}}>
          <RegisterPrevButton />
          <Button onPress={onNext} style={tailwind('flex-1')}>
            Next
          </Button>
        </HStack>
      </SafeAreaView>
    </ScreenWrapper>
  );
};

export default AddContact;
