import {NOTIFICATION_STATUS} from '../../screens/constants';

const NOTIFICATIONS = {
  SET: 'SET_NOTIFICATIONS',
  READ: 'READ_NOTIFICATION',
  RELOAD: 'RELOAD_NOTIFICATIONS',
};

const CONNECTION_ID = {
  SET: 'SET_CONNECTION_ID',
};

const NEW_NOTIFICATIONS = {
  SET: 'SET_NEW_NOTIFICATIONS',
};

const CONNECTION_STATUS = {
  SET: 'SET_CONNECTION_STATUS',
};

const CONNECTION = {
  SET: 'SET_CONNECTION',
};

export const setNotifications = (notifications) => ({
  type: NOTIFICATIONS.SET,
  notifications,
});

export const setConnectionId = (connectionId) => ({
  type: CONNECTION_ID.SET,
  connectionId,
});

export const setNewNotifications = (newNotifications) => ({
  type: NEW_NOTIFICATIONS.SET,
  newNotifications,
});

export const setConnectionStatus = (connectionStatus) => ({
  type: CONNECTION_STATUS.SET,
  connectionStatus,
});

export const setConnection = (connection) => ({
  type: CONNECTION.SET,
  connection,
});

export const setShouldNotificationsReload = (reload) => ({
  type: NOTIFICATIONS.RELOAD,
  reload,
});

export const readNotification = (id) => ({
  type: NOTIFICATIONS.READ,
  id,
});

const initialState = {
  connection: null,
  connectionId: null,
  connectionStatus: 0,
  newNotifications: 0,
  notifications: [],
  reload: false,
};

export const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATIONS.SET:
      return {...state, notifications: action.notifications};
    case NOTIFICATIONS.RELOAD:
      return {...state, reload: action.reload};
    case CONNECTION_ID.SET:
      return {...state, connectionId: action.connectionId};
    case NEW_NOTIFICATIONS.SET:
      return {...state, newNotifications: action.newNotifications};
    case CONNECTION_STATUS.SET:
      return {...state, connectionStatus: action.connectionStatus};
    case CONNECTION.SET:
      return {...state, connection: action.connection};
    case NOTIFICATIONS.READ:
      const {id} = action;
      const notifications = state.notifications.map((notification, _) => {
        if (notification.id !== id) {
          return notification;
        }
        return {
          ...notification,
          status: NOTIFICATION_STATUS.READ,
        };
      });
      return {...state, notifications: notifications};
  }
  return state;
};
