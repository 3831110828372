import Button from './Button';
import React from 'react';
import tailwind from 'tailwind-rn';

const RequireApproval = ({approvals, onClickApprovalsRequired}) => {
  const approvalsLength = approvals.length;
  return (
    <>
      {approvalsLength > 0 && (
        <Button
          style={tailwind('py-2')}
          variant="ghost"
          onPress={() => onClickApprovalsRequired()}>
          {`${approvalsLength} approval${
            approvalsLength > 1 ? 's' : ''
          } required`}
        </Button>
      )}
    </>
  );
};

export default RequireApproval;
