import React from 'react';
import {KeyboardAvoidingView, Platform, StyleProp} from 'react-native';

export default function FormContainer({
  children,
  style,
  ...otherProps
}: {
  children: JSX.Element[] | JSX.Element;
  style?: StyleProp<any>;
}) {
  return (
    <>
      {children}
    </>
  );
}
