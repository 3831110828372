import {Dispatch} from 'redux';
import {store} from '../../redux/store';
import {GateCard} from '../../@types/GateCard';
import {residentApiWithAuth} from '../../api/resident';

export const GATECARD_LOADING = 'GATECARD_LOADING';
export const GATECARD_FAIL = 'GATECARD_FAIL';
export const GATECARD_SUCCESS = 'GATECARD_SUCCESS';
export const GATECARD_UPDATE_LOADING = 'GATECARD_UPDATE_LOADING';
export const GATECARD_UPDATE_FAIL = 'GATECARD_UPDATE_FAIL';
export const GATECARD_UPDATE_SUCCESS = 'GATECARD_UPDATE_SUCCESS';
export const GATECARD_CREATE_LOADING = 'VGATECARD_CREATE_LOADING';
export const GATECARD_CREATE_FAIL = 'GATECARD_CREATE_FAIL';
export const GATECARD_CREATE_SUCCESS = 'GATECARD_CREATE_SUCCESS';
export const GATECARD_SEARCH = 'GATECARD_SEARCH';

export interface GateCardLoading {
  type: typeof GATECARD_LOADING;
}

export interface GateCardFail {
  type: typeof GATECARD_FAIL;
  error: string | object;
}

export interface GateCardSuccess {
  type: typeof GATECARD_SUCCESS;
  payload: GateCard[];
}

export interface GateCardUpdateLoading {
  type: typeof GATECARD_UPDATE_LOADING;
}

export interface GateCardUpdateFail {
  type: typeof GATECARD_UPDATE_FAIL;
  error: string | object;
}

export interface GateCardUpdateSuccess {
  type: typeof GATECARD_UPDATE_SUCCESS;
  payload: GateCard;
}

export interface GateCardCreateLoading {
  type: typeof GATECARD_CREATE_LOADING;
}

export interface GateCardCreateFail {
  type: typeof GATECARD_CREATE_FAIL;
  error: string | object;
}

export interface GateCardCreateSuccess {
  type: typeof GATECARD_CREATE_SUCCESS;
  payload: GateCard;
}

export interface GateCardSearch {
  type: typeof GATECARD_SEARCH;
  payload: {
    searchTerm: string;
    cachedGateCards: GateCard[];
  };
}

export type GateCardDispatchTypes =
  | GateCardLoading
  | GateCardFail
  | GateCardSuccess
  | GateCardUpdateSuccess
  | GateCardUpdateLoading
  | GateCardUpdateFail
  | GateCardCreateSuccess
  | GateCardCreateLoading
  | GateCardCreateFail
  | GateCardSearch;

export const GetGateCards =
  () => async (dispatch: Dispatch<GateCardDispatchTypes>) => {
    try {
      dispatch({type: GATECARD_LOADING});

      const defaultLink = store
        .getState()
        .units.unitLinks.filter(unitLink => unitLink.isDefault === true)[0];

      console.log(`/gateCards/unit/${defaultLink?.unit.id}`);

      let res = await residentApiWithAuth().get(
        `/gateCards/unit/${defaultLink?.unit.id}`,
      );

      const gateCards: Array<GateCard> = await res.data.data;

      dispatch({type: GATECARD_SUCCESS, payload: gateCards});
    } catch (error) {
      dispatch({type: GATECARD_FAIL, error: error});
    }
  };

interface DefaultStateI {
  loading: boolean;
  error?: string | object;
  gatecards?: Array<GateCard>;
}

const defaultState: DefaultStateI = {
  loading: false,
  error: null,
  gatecards: [],
};

const gateCardsReducer = (
  state: DefaultStateI = defaultState,
  action: GateCardDispatchTypes,
): DefaultStateI => {
  switch (action.type) {
    case GATECARD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case GATECARD_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GATECARD_SUCCESS:
      return {
        ...state,
        loading: false,
        gatecards: action.payload,
      };
    default:
      break;
  }
  return state;
};

export default gateCardsReducer;
