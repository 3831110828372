const getNotificationAction = (label: number) => {
  switch (label) {
    case 1:
      return 'Approved';
    case 2:
      return 'Not Approved';
    case 5:
      return 'Approved by Guard';
    case 6:
      return 'Not Approved By Guard';
    default:
      return null;
  }
};

export default getNotificationAction;
