/* eslint-disable curly */
import PushNotification from 'react-native-push-notification';

export const sendIOSInActiveAlert = () => {
  PushNotification.getDeliveredNotifications((notifs) => {
    notifs.forEach((notif) => {
      if (notif.userInfo.id === '12345')
        PushNotification.removeDeliveredNotifications([notif.identifier]);
    });
  });
  PushNotification.localNotification({
    userInfo: {id: '12345'},
    title: 'Resident App',
    message: 'You are offline',
    playSound: false,
    importance: 'low',
  });
};
