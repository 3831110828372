import React from 'react';
import {Dimensions, View, StyleSheet} from 'react-native';
import {RNCamera as Camera} from 'react-native-camera';

export default class CameraScreen extends React.Component<any, any> {
  render() {
    const {height, width} = Dimensions.get('window');
    const maskRowHeight = Math.round((height - 50) / 18);
    const maskColWidth = (width - 10) / 2;

    return (
      <View style={styles.container}>
        <Camera
          //   ref={(cam) => {
          //     this.camera = cam;
          //   }}
          //   onBarCodeRead={this._onBarCodeRead}
          style={styles.cameraView}
          playSoundOnCapture>
          <View style={styles.maskOutter}>
            <View
              style={[{flex: maskRowHeight}, styles.maskRow, styles.maskFrame]}
            />
            <View style={[{flex: 30}, styles.maskCenter]}>
              <View style={[{width: maskColWidth}, styles.maskFrame]} />
              <View style={styles.maskInner} />
              <View style={[{width: maskColWidth}, styles.maskFrame]} />
            </View>
            <View
              style={[{flex: maskRowHeight}, styles.maskRow, styles.maskFrame]}
            />
          </View>
        </Camera>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  cameraView: {
    flex: 1,
    justifyContent: 'flex-start',
  },
  maskOutter: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  maskInner: {
    width: 300,
    height: 300,
    backgroundColor: 'transparent',
    borderColor: 'white',
    borderWidth: 1,
    borderRadius: 150,
  },
  maskFrame: {
    backgroundColor: 'rgba(1,1,1,0.6)',
    // borderRadius: 150,
  },
  maskRow: {
    width: '100%',
  },
  maskCenter: {
    flexDirection: 'row',
  },
});
