import React, {useEffect, useState} from 'react';
import Modal from '../../../components/Modal';
import QRCode from 'react-native-qrcode-svg';
import Input from '../../../components/Input';
import Text from '../../../components/Text';
import Button from '../../../components/Button';
import {createUnitPeopleConnector} from '../../../api/connector';
import {showErrorMessage} from '../../../service/flashMessage';
import {RootStore} from '../../../redux/store';
import {useSelector} from 'react-redux';
import {LoadingSpinner} from '../../../components';
import {copyToClipboard} from '../../../service/clipboard';
import {View} from 'react-native';

const InviteCodeGenerator = ({modalVisible, closeModal}) => {
  const peopleId = useSelector((state: RootStore) => state?.auth?.user?.id);
  const [inviteCode, setInviteCode] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchLinkCode = async () => {
    try {
      const link = await createUnitPeopleConnector({
        expiration: new Date(
          new Date().setHours(new Date().getHours() + 24),
        ).toISOString(),
        note: '',
        peopleId,
      });
      setInviteCode(link.linkCode);
    } catch (e) {
      showErrorMessage(e.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (modalVisible) {
      fetchLinkCode();
    }
  }, [modalVisible]);

  return (
    <Modal isOpen={modalVisible} onClose={closeModal} size="xl">
      <Modal.Content>
        <Modal.CloseButton />
        <Modal.Header>Your invitation code is: </Modal.Header>
        <Modal.Body
          _scrollview={{
            keyboardShouldPersistTaps: 'handled',
          }}>
          {loading ? (
            <LoadingSpinner size="large" />
          ) : (
            <>
              <Text>Share this code to owner or managemen office. </Text>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                <View
                  style={{
                    flex: 3,
                  }}>
                  <Input
                    mt={4}
                    mb={4}
                    placeholder=""
                    value={inviteCode}
                    textAlign="center"
                    isReadOnly
                    editable={false}
                  />
                </View>
                <View
                  style={{
                    flex: 1,
                    marginLeft: 10,
                  }}>
                  <Button
                    py={3}
                    onPress={() => {
                      copyToClipboard(inviteCode);
                      closeModal();
                    }}>
                    {'Copy '}
                  </Button>
                </View>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}>
                <QRCode value={inviteCode} size={100} />
              </View>
            </>
          )}
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

export default InviteCodeGenerator;
