import React from 'react';
import {
  CardStyleInterpolators,
  createStackNavigator,
} from '@react-navigation/stack';
import {makeId} from '../../utils';
import {E_NAVIGATION_DESTINATIONS} from '../../@types';
import { CreateParking, EditParking, ParkingDetail, ParkingList } from './screens';

const Stack = createStackNavigator();

const ParkingsNavigator = () => {
  const options = {
    headerShown: false,
    cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
  };

  return (
    <Stack.Navigator>
      <Stack.Screen
        name={E_NAVIGATION_DESTINATIONS.PARKINGS_LIST}
        component={ParkingList}
        options={{...options, animationTypeForReplace: 'pop'}}
        key={makeId(12)}
      />
      <Stack.Screen
        name={E_NAVIGATION_DESTINATIONS.PARKING_ADD}
        component={CreateParking}
        options={{...options, animationTypeForReplace: 'pop'}}
        key={makeId(12)}
      />
      <Stack.Screen
        name={E_NAVIGATION_DESTINATIONS.PARKING_EDIT}
        component={EditParking}
        options={{...options, animationTypeForReplace: 'pop'}}
        key={makeId(12)}
      />
      <Stack.Screen
        name={E_NAVIGATION_DESTINATIONS.PARKING_DETAILS}
        component={ParkingDetail}
        options={{...options, animationTypeForReplace: 'pop'}}
        key={makeId(12)}
      />
    </Stack.Navigator>
  );
};

export const parkingScreens = {
  [E_NAVIGATION_DESTINATIONS.PARKINGS_LIST] :"parkingList",
  [E_NAVIGATION_DESTINATIONS.PARKING_ADD] :"/parkingAdd",
  [E_NAVIGATION_DESTINATIONS.PARKING_EDIT] :"/parkingEdit",
  [E_NAVIGATION_DESTINATIONS.PARKING_DETAILS] :"/parkingDetails",
}

export default ParkingsNavigator;
