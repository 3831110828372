// @ts-nocheck
/* eslint-disable react-native/no-inline-styles */
import {useNavigation, useRoute} from '@react-navigation/native';
import React from 'react';
import {ScrollView, View} from 'react-native';
import Text from '../../../components/Text';
import {Card} from 'react-native-paper';
import {DoorLog} from '../types';
import {Navbar} from '../../../components';
import {styles} from '../../../styles';
import Container from '../../../components/Container';
import ZoomableImage from '../../../components/ZoomableImage';

export default function DoorLogDetail() {
  const route = useRoute();
  const navigation = useNavigation();
  const doorLog: DoorLog = route.params?.doorLog;

  return (
    <Container>
        <Navbar navigation={navigation} title="Door log details" />
        <ScrollView
          style={{
            marginHorizontal: 8,
            paddingHorizontal: 16,
          }}>
          <ZoomableImage
            title='Door Log'
            source={{
              uri: doorLog.image
                ? `data:image/jpeg;base64,${doorLog.image}`
                : 'https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/default-avatar.png',
            }}
            style={[styles.image, {alignSelf: 'center', marginBottom: 16}]}
          />
          <Card style={{padding: 8, marginBottom: 32}}>
            <View style={{flexDirection: 'row', marginHorizontal: 12}}>
              <Text style={styles.textLabel}>Tag holder's name</Text>
              <Text style={styles.detailsText}>{doorLog.tagHoldersName}</Text>
            </View>
            <View style={{flexDirection: 'row', marginHorizontal: 12}}>
              <Text style={styles.textLabel}>Action</Text>
              <Text style={styles.detailsText}>{doorLog.action}</Text>
            </View>
            <View style={{flexDirection: 'row', marginHorizontal: 12}}>
              <Text style={styles.textLabel}>Tag read</Text>
              <Text style={styles.detailsText}>{doorLog.tagRead}</Text>
            </View>
            <View style={{flexDirection: 'column', marginHorizontal: 12}}>
              <Text style={styles.textLabel}>Reject reason:</Text>
              <Text style={styles.detailsText}>{doorLog.rejectReason}</Text>
            </View>
            <View style={{flexDirection: 'row', marginHorizontal: 12}}>
              <Text style={styles.textLabel}>Status of read</Text>
              <Text style={styles.detailsText}>{doorLog.statusOfRead}</Text>
            </View>
            <View style={{flexDirection: 'row', marginHorizontal: 12}}>
              <Text style={styles.textLabel}>When</Text>
              <Text style={styles.detailsText}>
                {new Date(doorLog.whenDateTime).toLocaleString()}
              </Text>
            </View>
          </Card>
        </ScrollView>
    </Container>
  );
}
