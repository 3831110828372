import {Dispatch} from 'redux';
import {residentApiWithAuth} from '../../api/resident';
import {showErrorMessage, showSuccessMessage} from '../../service/flashMessage';
import * as RootNavigation from '../../navigation';
import {DateFrame} from '../../@types/DateFrame';
import {Storage} from '../../@types';
import {store} from '../../redux/store';

export const STORAGES_LOADING = 'STORAGES_LOADING';
export const STORAGES_FAIL = 'STORAGES_FAIL';
export const STORAGES_SUCCESS = 'STORAGES_SUCCESS';
export const STORAGES_UPDATE_LOADING = 'STORAGES_UPDATE_LOADING';
export const STORAGES_UPDATE_FAIL = 'STORAGES_UPDATE_FAIL';
export const STORAGES_UPDATE_SUCCESS = 'STORAGES_UPDATE_SUCCESS';
export const STORAGES_SEARCH = 'STORAGES_SEARCH';
export const STORAGES_DELETE = 'STORAGES_DELETE';

export interface StoragesLoading {
  type: typeof STORAGES_LOADING;
}

export interface StoragesFail {
  type: typeof STORAGES_FAIL;
  error: string | object;
}

export interface StoragesSuccess {
  type: typeof STORAGES_SUCCESS;
  payload: Storage[];
}

export interface StoragesUpdateLoading {
  type: typeof STORAGES_UPDATE_LOADING;
}

export interface StoragesUpdateFail {
  type: typeof STORAGES_UPDATE_FAIL;
  error: string | object;
}

export interface StoragesUpdateSuccess {
  type: typeof STORAGES_UPDATE_SUCCESS;
  payload: Storage;
}

export interface StoragesSearch {
  type: typeof STORAGES_SEARCH;
  payload: {
    searchTerm: string;
    cachedStorages: Storage[];
  };
}

export interface StoragesDelete {
  type: typeof STORAGES_DELETE;
  id: string;
}

export type StoragesDispatchTypes =
  | StoragesLoading
  | StoragesFail
  | StoragesSuccess
  | StoragesUpdateLoading
  | StoragesUpdateFail
  | StoragesUpdateSuccess
  | StoragesSearch
  | StoragesDelete;

export const GetStorages =
  () => async (dispatch: Dispatch<StoragesDispatchTypes>) => {
    try {
      dispatch({type: STORAGES_LOADING});

      const defaultLink = store
        .getState()
        .units.unitLinks.filter(unitLink => unitLink.isDefault === true)[0];

      console.log(`/storage/unit/${defaultLink?.unit.id}`);

      const res = await residentApiWithAuth().get(
        `/storage/unit/${defaultLink?.unit.id}`,
      );
      let data: Array<Storage> = await res.data.data;
      //sort in descending order
      const sortedStorages = data.sort((a, b) => {
        //@ts-ignore
        return new Date(b.createdTimeDate) - new Date(a.createdTimeDate);
      });

      dispatch({type: STORAGES_SUCCESS, payload: sortedStorages});
    } catch (error) {
      dispatch({type: STORAGES_FAIL, error: error});
    }
  };

export const CreateStorage =
  (dateFrame: any, storage: Storage) =>
  async (dispatch: Dispatch<StoragesDispatchTypes>) => {
    try {
      dispatch({type: STORAGES_LOADING});
      const newDateFrame = await (
        await residentApiWithAuth().post('/dateFrame', dateFrame)
      ).data.data;
      await residentApiWithAuth().post('/storage', {
        ...storage,
        dateFrameId: newDateFrame.id,
      });
      showSuccessMessage('Storage added');

      dispatch({type: STORAGES_LOADING});
      const res = await residentApiWithAuth().get(
        `/storage/unit/${storage.unitId}`,
      );
      dispatch({type: STORAGES_SUCCESS, payload: res.data.data});

      RootNavigation.goBack();
    } catch (error) {
      dispatch({type: STORAGES_FAIL, error: error});
      showErrorMessage(error);
    }
  };

export const UpdateStorage =
  (dateFrame: DateFrame, storage: Storage) =>
  async (dispatch: Dispatch<StoragesDispatchTypes>) => {
    const updateDto = storage;
    delete updateDto.dateFrame;
    //@ts-ignore
    delete updateDto.dateFrameId;
    try {
      // dispatch({type: STORAGES_LOADING});
      // await residentApiWithAuth().post(`/dateFrame/${dateFrame.id}`, dateFrame);
      const res = await residentApiWithAuth().post(
        `/storage/${storage.id}`,
        updateDto,
      );
      dispatch({type: STORAGES_UPDATE_SUCCESS, payload: res.data.data});

      showSuccessMessage('Storage updated successfully');
      RootNavigation.goBack();
    } catch (error) {
      dispatch({type: STORAGES_FAIL, error: error});
      showErrorMessage(error);
    }
  };

export const DeleteStorage =
  (id: string) => async (dispatch: Dispatch<StoragesDispatchTypes>) => {
    try {
      await residentApiWithAuth().delete(`/storage/${id}`);

      dispatch({type: STORAGES_DELETE, id});

      showSuccessMessage('Storage deleted');
    } catch (error) {
      dispatch({type: STORAGES_FAIL, error: error});
    }
  };

export const StorageSearch =
  (searchTerm: string) => async (dispatch: Dispatch<StoragesDispatchTypes>) => {
    let cachedStorages: Storage[] = [];
    if (searchTerm) {
      dispatch({
        type: STORAGES_SEARCH,
        payload: {searchTerm, cachedStorages},
      });
    } else {
      dispatch({type: STORAGES_SUCCESS, payload: cachedStorages});
    }
  };

interface DefaultStateI {
  loading: boolean;
  error?: string | object;
  storages?: Storage[];
}

const defaultState: DefaultStateI = {
  loading: true,
  storages: [],
};

const storagesReducer = (
  state: DefaultStateI = defaultState,
  action: StoragesDispatchTypes,
): DefaultStateI => {
  switch (action.type) {
    case STORAGES_FAIL:
      return {
        loading: false,
        error: action.error,
        storages: [],
      };
    case STORAGES_LOADING:
      return {
        loading: true,
      };
    case STORAGES_SUCCESS:
      return {
        loading: false,
        storages: action.payload,
      };
    case STORAGES_UPDATE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case STORAGES_UPDATE_SUCCESS:
      const {id} = action.payload;
      const newState = state.storages.map((item, _) => {
        if (item.id !== id) {
          return item;
        }
        return {
          ...item,
          ...action.payload,
        };
      });
      return {
        ...state,
        storages: newState,
        loading: false,
      };
    case STORAGES_SEARCH:
      const newStorages = action.payload.cachedStorages.filter(storage => {
        const storageData = storage.binLocation
          ? `${storage.binLocation}`.toUpperCase()
          : ''.toUpperCase();
        const searchTermData = action.payload.searchTerm.toUpperCase();
        return storageData.indexOf(searchTermData) > -1;
      });
      return {
        ...state,
        storages: newStorages,
      };
    case STORAGES_DELETE:
      const nextState = state.storages.filter(
        storage => storage.id !== action.id,
      );
      return {
        ...state,
        storages: nextState,
      };
    default:
      break;
  }
  return state;
};

export default storagesReducer;
