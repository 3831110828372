import {Alert} from 'react-native';

export const showDeleteUnitPrompt = (title: string, callbackFn: () => void) => {
  Alert.alert(
    '',
    `Do you really want to exit this ${title} ?`,
    [
      {
        text: 'NO',
        onPress: () => console.log('Cancel Pressed'),
        style: 'cancel',
      },
      {
        text: 'YES',
        onPress: () => callbackFn(),
      },
    ],
    {
      cancelable: true,
    },
  );
};
