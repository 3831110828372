import React from 'react';
import {Text, Pressable} from 'react-native';
import {colorSwatches} from '../styles';

const PendingApprovalsBadge = ({pendingApprovals, onPress}) => {
  return (
    <Pressable
      onPress={onPress}
      style={{
        backgroundColor: colorSwatches.red[400],
      }}>
      <Text
        style={{
          textAlign: 'center',
          color: colorSwatches.white.color,
          paddingVertical: 4,
        }}>
        {`${pendingApprovals} pending approval${pendingApprovals > 1 ? 's': ''}`}
      </Text>
    </Pressable>
  );
};

export default PendingApprovalsBadge;
