export const initialDeliveryState = {
  unitId: '',
  dateFrameId: '',
  company: '',
  companyId: null,
  expectedTime: null,
  authorizationTo: '',
  personAuthorizing: '',
  createdTimeDate: new Date().toISOString(),
  instructions: '',
  category: null,
};

export const initialDateFrameState = {
  startDate: new Date().toISOString(),
  endDate: new Date().toISOString(),
  description: '',
};
