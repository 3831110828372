import React from 'react';
import {FAB} from 'react-native-paper';
import {colorSwatches} from '../styles';

const QuickAccessFAB = ({onPress}) => {
  return (
    <FAB
      icon="information-circle-outline"
      style={{
        position: 'absolute',
        margin: 16,
        right: 0,
        bottom: 76,
        backgroundColor: colorSwatches.primary.color,
      }}
      color="#fff"
      size='small'
      onPress={onPress}
    />
  );
};

export default QuickAccessFAB;
