import {DEFAULT_THEME} from '../styles';

export const formatDate = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) {
    month = '0' + month;
  }
  if (day.length < 2) {
    day = '0' + day;
  }

  return [year, month, day].join('-');
};

export const convertDateFrameToDays = function (start, end) {
  for (
    var arr = [], dt = new Date(start);
    dt <= end;
    dt.setDate(dt.getDate() + 1)
  ) {
    arr.push(formatDate(new Date(dt)));
  }
  return arr;
};

export const markDays = (days, isApproved) => {
  const markedDaysObj = {};
  const styles = {
    selectedColor: isApproved
      ? DEFAULT_THEME.PRIMARY_COLOR
      : DEFAULT_THEME.DEFAULT_GREY,
    textColor: 'white',
    selected: true,
  };
  for (let i = 0; i < days.length; i++) {
    if (i === 0) {
      markedDaysObj[days[i]] = {
        // startingDay: true,
        ...styles,
      };
    } else if (i === days.length - 1) {
      markedDaysObj[days[i]] = {
        // endingDay: true,
        ...styles,
      };
    } else {
      markedDaysObj[days[i]] = {...styles};
    }
  }

  return markedDaysObj;
};

export const markCalendarDays = (dateFrames) => {
  let markedDaysArray = {};
  dateFrames.forEach((dateFrame) => {
    markedDaysArray = {
      ...markedDaysArray,
      ...markDays(dateFrame.days, dateFrame?.approvals?.length === 0),
    };
  });

  return markedDaysArray;
};
