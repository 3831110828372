// @ts-nocheck
/* eslint-disable react-native/no-inline-styles */
import {useFocusEffect, useNavigation} from '@react-navigation/native';
import React, {useEffect, useState} from 'react';
import {FlatList} from 'react-native';
import {E_NAVIGATION_DESTINATIONS} from '../../../@types';
import {EmptyList, LoadingSpinner, NavbarWithAction} from '../../../components';
import {usePaginatedDoorLogsByUnit} from '../../../hooks/doorlogs';
import Container from '../../../components/Container';
import Content from '../../../navigation/Content';
import DoorLogItem from '../components/DoorLogItem';
import FilterPanel from '../components/FilterPanel';

export default function DoorLogsList() {
  const [panelActive, setPanelActive] = useState(false);
  const initialQueryParams: QueryParams = {
    doorId: null,
    startDate: null,
    endDate: null,
  };
  const [queryParams, setQueryParams] = useState(initialQueryParams);
  const navigation = useNavigation();
  const {
    isLoading,
    data,
    refetch,
    isRefetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = usePaginatedDoorLogsByUnit(queryParams);

  const loadMore = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  const enabledRef = React.useRef(false);

  useFocusEffect(
    React.useCallback(() => {
      if (enabledRef.current) {
        refetch();
      } else {
        enabledRef.current = true;
      }
    }, [refetch]),
  );

  useEffect(() => {
    refetch();
  }, [queryParams]);

  return (
    <Container>
      <Content>
        <NavbarWithAction
          navigation={navigation}
          hasBackArrow
          title="Door Logs"
          showCurrentUnit
          showUnitPicture={false}
          fullScreen
          // hasFilter
          // onClickFilter={()=> setPanelActive(true)}
        />
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <FlatList
            contentContainerStyle={{
              padding: 16,
            }}
            data={data?.pages.map(pageItem => pageItem.data).flat()}
            ListEmptyComponent={
              <EmptyList message="No Door Logs for you at the moment" />
            }
            renderItem={({item}) => (
              <DoorLogItem
                doorLog={item}
                onPress={() => {
                  navigation.navigate(
                    E_NAVIGATION_DESTINATIONS.DOOR_LOG_DETAILS,
                    {
                      doorLog: item,
                    },
                  );
                }}
              />
            )}
            onRefresh={refetch}
            refreshing={isRefetching}
            onEndReached={loadMore}
            onEndReachedThreshold={0.3}
            ListFooterComponent={isFetchingNextPage ? <LoadingSpinner /> : null}
          />
        )}
      </Content>
      {/* <FilterPanel
        panelActive={panelActive}
        setPanelActive={setPanelActive}
        setQueryParams={params => {
          setQueryParams({
            ...queryParams,
            ...params,
          });
        }}
      /> */}
    </Container>
  );
}
