import React from 'react';
import Button from './Button';
import tailwind from 'tailwind-rn';
import {goBack} from '../navigation';

const RegisterPrevButton = () => {
  return (
    <Button
      variant="outline"
      py="4"
      onPress={() => goBack()}
      style={tailwind('mr-2 flex-1')}>
      Previous
    </Button>
  );
};

export default RegisterPrevButton;
