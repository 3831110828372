function formatPhoneNumber(value: string) {
  // Remove all non-digit characters
  const cleaned = ('' + value).replace(/\D/g, '');

  // Format the phone number if it has 10 digits
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  // For inputs with less than 10 digits, return cleaned input with formatting
  if (cleaned.length < 4) {
    return cleaned; // No formatting yet
  }
  if (cleaned.length < 7) {
    return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3)}`; // Format part way
  }
  return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`; // Format up to 7 digits
}

export default formatPhoneNumber;
