// @ts-nocheck
import {useFocusEffect, useNavigation} from '@react-navigation/native';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {LoadingSpinner, NavbarWithAction} from '../../../components';
import Text from '../../../components/Text';
import {RootStore} from '../../../redux/store';
import {getCurrentUnitLink} from '../../../utils';
import {getUnclaimedPackagesCount} from '../../../utils/packages';
import {E_NAVIGATION_DESTINATIONS} from '../../../@types';import { FlatList, Platform } from 'react-native';
import PackagesItem from '../components/PackageItem';
import isWeb from '../../../utils/isWeb';
import { usePackagesByPerson } from '../../../hooks/packages';
import Container from '../../../components/Container';

export default function DeliveriesPackages() {
  const navigation = useNavigation();


  const personId = useSelector((state: RootStore) => state.auth.user.id);
  const [status, setStatus] = React.useState<'Unclaimed' | 'Claimed' | 'All'>(
    'Unclaimed',
  );
  const {isLoading, data, refetch, isRefetching} = usePackagesByPerson(
    personId,
    status === 'All' || status === 'Claimed',
    status === 'Claimed',
  );
  const packages: Array<Package> = data?.data;

  const enabledRef = React.useRef(false);

  useFocusEffect(
    React.useCallback(() => {
      if (enabledRef.current) {
        refetch();
      } else {
        enabledRef.current = true;
      }
    }, [refetch]),
  );

  return (
    <Container>
      <NavbarWithAction
        hasSignOut={false}
        navigation={navigation}
        hasBackArrow={false}
        title="packages"
        showCurrentUnit
        hasAdd={false}
        onClickAdd={() => navigation.navigate('Add delivery', {delivery: null})}
        showUnitPicture={false}
        onClickFilter={() => null}
        hasFilter={false}
        hasAccessCard
        onClickAccessCard={() =>
          navigation.navigate(E_NAVIGATION_DESTINATIONS.ID_CARD)
        }
        fullScreen
      />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <FlatList
          style={[
            {
              marginBottom: Platform.OS === 'ios' ? 160 : isWeb ? 0 : 100,
              paddingHorizontal: 16,
            },
          ]}
          data={packages}
          ListEmptyComponent={<Text>No packages for you at the moment</Text>}
          renderItem={({item}) => (
            <PackagesItem
              packageItem={item}
              itemClickHandler={() =>
                navigation.navigate(E_NAVIGATION_DESTINATIONS.PACKAGE_DETAILS, {
                  packageItem: item,
                })
              }
            />
          )}
          onRefresh={refetch}
          refreshing={isRefetching}
        />
      )}
    </Container>
  );
}
