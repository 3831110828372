// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps */
import {useNavigation} from '@react-navigation/core';
import React, {useEffect, useState} from 'react';
import {ScrollView} from 'react-native';
import Text from '../../../components/Text';
import tailwind from 'tailwind-rn';
import {Navbar} from '../../../components';
import DateTimeInput from '../../../components/DateTimeInput';
import {initialDateFrameState, initialStorageState} from '../utils';
import {useDispatch, useSelector} from 'react-redux';
import {RootStore} from '../../../redux/store';
import Button from '../../../components/Button';
import TextArea from '../../../components/TextArea';
import Input from '../../../components/Input';
import {styles} from '../../../styles';
import {CreateStorage, UpdateStorage} from '../storagesReducer';
import {RouteProp, useRoute} from '@react-navigation/native';
import {RootStackParamList} from '../../../@types';
import FormContainer from '../../../components/FormContainer';
import Container from '../../../components/Container';
import Content from '../../../navigation/Content';

const AddStorage: React.FC = () => {
  const storage =
    useRoute<RouteProp<RootStackParamList, 'Add storage'>>().params?.storage;
  const isInEditMode = !!storage;
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const userId = useSelector((state: RootStore) => state.auth.user.id);
  const unitId = useSelector(
    (state: RootStore) => state.units.unitLinks,
  ).filter(link => link.isDefault === true)[0].unit.id;
  const [storageState, setStorageState] = useState({
    ...initialStorageState,
    createdLogon: userId,
    lastLogon: userId,
    unitId,
  });
  const [dateFrameState, setDateFrameState] = useState({
    ...initialDateFrameState,
    unitId,
  });
  const [startPickerDateVisible, setStartDatePickerVisible] = useState(false);
  const [endDatePickerVisible, setEndDatePickerVisible] = useState(false);
  const storagesState = useSelector((state: RootStore) => state.storages);
  const [title, setTitle] = useState('Add storage');

  useEffect(() => {
    if (isInEditMode) {
      //@ts-ignore
      setStorageState({...storageState, ...storage});
      setDateFrameState({...storage?.dateFrame, ...dateFrameState});
      setTitle('Edit storage');
    }
  }, []);

  const updateState = (field: string, text: string) =>
    setStorageState({
      ...storageState,
      [field]: text,
    });

  return (
    <Container>
      <Content>
        <Navbar title={title} navigation={navigation} />
        <FormContainer>
          <ScrollView
            style={tailwind('mx-2 my-4')}
            showsVerticalScrollIndicator={false}>
            <Text style={[styles.textInputLabel, tailwind('mt-4 flex-1')]}>
              Storage bin
            </Text>
            <Input
              minWidth={300}
              style={tailwind('flex-1')}
              value={storageState.storageBin}
              onChangeText={text => updateState('storageBin', text)}
            />
            <Text style={[styles.textInputLabel, tailwind('mt-4 flex-1')]}>
              Bin location
            </Text>
            <Input
              minWidth={300}
              style={tailwind('flex-1')}
              value={storageState.binLocation}
              onChangeText={text => updateState('binLocation', text)}
            />
            <Text style={[styles.textInputLabel, tailwind('mt-4 flex-1')]}>
              Monthly cost
            </Text>
            <Input
              minWidth={300}
              style={tailwind('flex-1')}
              placeholder="e.g 20"
              value={storageState.monthlyCost.toString()}
              onChangeText={text =>
                setStorageState({
                  ...storageState,
                  monthlyCost: parseInt(text, 10),
                })
              }
              keyboardType="number-pad"
            />
            <DateTimeInput
              label="Start date"
              value={new Date(dateFrameState.startDate)}
              onConfirm={date => {
                setDateFrameState({
                  ...dateFrameState,
                  startDate: new Date(date).toISOString(),
                });
              }}
              mode='date'
            />
            <DateTimeInput
              label="End date"
              value={new Date(dateFrameState.endDate)}
              onConfirm={date => {
                setDateFrameState({
                  ...dateFrameState,
                  endDate: new Date(date).toISOString(),
                });
              }}
              mode='date'
            />
            <Text style={[styles.textInputLabel, tailwind('mt-4 flex-1')]}>
              Description
            </Text>
            <TextArea
              minWidth={300}
              style={tailwind('flex-1')}
              value={storageState.description}
              onChangeText={text =>
                setStorageState({...storageState, description: text})
              }
            />
          </ScrollView>
          <Button
            onPress={() => {
              isInEditMode
                ? dispatch(UpdateStorage(dateFrameState, storageState))
                : dispatch(CreateStorage(dateFrameState, storageState));
            }}
            style={tailwind('mx-2 mb-20')}
            isLoading={storagesState.loading}
            py="4"
            isLoadingText="Submitting ...">
            {isInEditMode ? 'Update ' : 'Save'}
          </Button>
        </FormContainer>
      </Content>
    </Container>
  );
};

export default AddStorage;
