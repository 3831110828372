// @ts-nocheck
import {AxiosError} from 'axios';
import {useInfiniteQuery, useQuery} from 'react-query';
import {useDispatch} from 'react-redux';
import {PackageHistoryQueryParams} from '../@types';
import {residentApiWithAuth} from '../api/resident';
import {setPackages} from '../redux/reducers/packagesReducer';
import {showErrorMessage} from '../service/flashMessage';
import {getCurrentUnitLink} from '../utils';
import E_QUERY_NAMES from './query-names';

function buildUrl(params: PackageHistoryQueryParams) {
  let url = `/packages/unit/${params.unitId}?allClaimednUnclaimed=${params.allClaimednUnclaimed}&pageSize=${params.pageSize}&claimedOnly=${params.claimedOnly}`;

  if (params?.dateFrom) {
    url = `${url}&dateFrom=${params.dateFrom}`;
  }

  if (params?.dateTo) {
    url = `${url}&dateTo=${params.dateTo}`;
  }

  if (params?.thumbHeight) {
    url = `${url}&thumbHeight=${params.thumbHeight}`;
  }
  if (params?.thumbWidth) {
    url = `${url}&thumbWidth=${params.thumbWidth}`;
  }

  if (params?.searchKey) {
    url = `${url}&searchKey=${params.searchKey}`;
  }
  return url;
}

export function usePackagesByPerson(
  personId: string,
  allClaimednUnclaimed: boolean = false,
  claimedOnly: boolean = false,
) {
  const dispatch = useDispatch();

  return useQuery(
    E_QUERY_NAMES.PACKAGES_BY_PERSON_QUERY +
      personId +
      allClaimednUnclaimed +
      claimedOnly,
    async () => {
      const {data} = await residentApiWithAuth().get(
        `/packages/person/${personId}?allClaimednUnclaimed=${allClaimednUnclaimed}&claimedOnly=${claimedOnly}&thumbWidth=46&thumbHeight=46`,
      );
      return data;
    },
    {
      onSuccess: (data) => {
        dispatch(setPackages(data?.data));
      },
      onError: (error: AxiosError) => {
        const message = error.response.data.errors[0];
        showErrorMessage(message);
      },
    },
  );
}

export function usePackage(packageId: string) {
  return useQuery(
    E_QUERY_NAMES.PACKAGE_QUERY + packageId,
    async () => {
      const {data} = await residentApiWithAuth().get(`/packages/${packageId}`);
      return data;
    },
    {
      onSuccess: (data) => {

      },
      onError: (error: AxiosError) => {
        const message = error.response.data.errors[0];
        showErrorMessage(message);
      },
    },
  );
}

export function usePackageLogs(packageId: string) {
  return useQuery(
    E_QUERY_NAMES.PACKAGE_LOGS + packageId,
    async () => {
      const response = await residentApiWithAuth().get(
        `/packageLogs/${packageId}`,
      );
      return response;
    },
    {
      onSuccess: (_) => {},
      onError: (error: AxiosError) => {
        const message = error.response.data.errors[0];
        showErrorMessage(message);
      },
    },
  );
}

export function usePackageHistories() {
  const currentUnitId = getCurrentUnitLink()?.unit?.id;

  return useQuery(
    E_QUERY_NAMES.PACKAGES_HISTORIES_QUERY + currentUnitId,
    async () => {
      const {data} = await residentApiWithAuth().get(
        `/packages/unit/${currentUnitId}?allClaimednUnclaimed=true&thumbWidth=46&thumbHeight=46`,
      );
      return data;
    },
    {
      onSuccess: (_) => {
        console.log('refetched >>>>');
      },
      onError: (error: AxiosError) => {
        const message = error.response.data.errors[0];
        showErrorMessage(message);
      },
    },
  );
}

export function usePaginatedPackageHistories(
  params: PackageHistoryQueryParams,
) {

  const currentUnitId = getCurrentUnitLink()?.unit?.id;
  const url = buildUrl(params);
  //  `/packages/unit/${currentUnitId}?allClaimednUnclaimed=true&thumbWidth=46&thumbHeight=46&pageSize=15`;

  return useInfiniteQuery(
    [E_QUERY_NAMES.PACKAGES_HISTORIES_QUERY, currentUnitId, params],
    async ({pageParam = 1}) => {
      const paginatedUrl = `${url}&pageNumber=${pageParam}`;
      console.log('URL ===> ', paginatedUrl);

      const response = await residentApiWithAuth().get(paginatedUrl);
      const data = await response?.data;

      return data;
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.nextPage !== null) {
          return lastPage.pageNumber + 1;
        }

        return undefined;
      },
      onSuccess: (data) => {
        return data;
      },
      onError: (error: AxiosError) => {
        const message = error.response.data.errors[0];
        showErrorMessage(message);
      },
    },
  );
}
