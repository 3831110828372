import {Linking} from 'react-native';
import SendSMS, { AndroidSuccessTypes } from 'react-native-sms';
import isWeb from '../../utils/isWeb';
import { showErrorMessage } from '../../service/flashMessage';

const initiateSMS = (recipient:string, body?:string) => {
  SendSMS.send(
    {
      body: !!body ? body: '',
      recipients: [recipient],
      allowAndroidSendWithoutReadPermission: true,
      successTypes: ['sent', 'queued'] as AndroidSuccessTypes[]
    },
    (completed, cancelled, error) => {
      if (completed) {
        console.log('SMS Sent Completed --');
      } else if (cancelled) {
        console.log('SMS Sent Cancelled --');
      } else if (error) {
        console.log('Some error occurred --', error.toString());
      }
    },
  );
};

const initiateSendEmail = async (to, subject, body) => {
  let url = `mailto:${to}`;

  if (isWeb) {
    //@ts-ignore
    window.location.href = url;
    return;
  }

  const query = JSON.stringify({
    subject: subject,
    body: body,
  });

  if (query.length) {
    url += `?${query}`;
  }

  const canOpen = await Linking.canOpenURL(url);
  if (!canOpen) {
   showErrorMessage('Provided URL cannot be handled');
  }
  return Linking.openURL(url);
};

const openUrl = async(url) => {
  const canOpen = await Linking.canOpenURL(url);
  if(!canOpen) {
    showErrorMessage("Failed to open url");
  }

  return Linking.openURL(url);
}

export {initiateSMS, initiateSendEmail, openUrl};
