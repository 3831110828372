import React from 'react';
import {Appearance} from 'react-native';
import {SwipeablePanel} from 'rn-swipeable-panel';
import {SwipeablePanelProps} from 'rn-swipeable-panel/dist/Panel';
import {styles} from '../styles';

export default function ThemedSwipeablePanel(
  props: SwipeablePanelProps & {children: Element},
) {
  const colorScheme = Appearance.getColorScheme();
  return (
    <SwipeablePanel
      {...props}
      // {...(colorScheme === 'dark' ? styles.panelDarkTheme : {})}
      >
      {props.children}
    </SwipeablePanel>
  );
}
