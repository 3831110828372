// @ts-nocheck
import React, {useEffect, useState} from 'react';
import {
  FlatList,
  Alert,
  View,
  KeyboardAvoidingView,
  Platform,
} from 'react-native';
import tailwind from 'tailwind-rn';
import {useDispatch, useSelector} from 'react-redux';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../service/flashMessage';
import {deleteUnitLinkById} from '../../../api/links';
import UnitListitem from '../components/UnitListitem';
import {
  AsterixDialog,
  MySearchbar,
  NavbarWithAction,
} from '../../../components';
import {checkUnitCode} from '../../../api/units';
import {deriveDisplayName} from '../../../utils/deriveDisplayName';
import {
  GetUnitScreenData,
  SearchUnits,
  SetDefaultUnitLink,
  SetSwitchingUnits,
} from '../unitsReducer';
import {RootStore, store} from '../../../redux/store';
import {dateFrameRequestAccess} from '../../../api/dataframe';
import {RequestAccess} from '../types';
import {
  DeleteAllApprovals,
  GetApprovals,
} from '../../../redux/reducers/approvalsReducer';
import {E_NAVIGATION_DESTINATIONS} from '../../../@types';
import NoUnits from '../components/NoUnits';
import Modal from '../../../components/Modal';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import {getCurrentUnitLink, permissionToBool} from '../../../utils';
import {pasteFromClipboard} from '../../../service/clipboard';
import Container from '../../../components/Container';
import InviteCodeGenerator from '../../../screens/more/components/InviteCodeGenerator';
import {DeleteAllAlerts, GetAlerts} from '../../Alerts/alertsReducer';
import isWeb from '../../../utils/isWeb';

const UnitList = ({navigation, hasDrawer = false}) => {
  const unitsAndLinks = useSelector(
    (state: RootStore) => state.units.unitLinks,
  );
  const currentUnitPeopleLink = getCurrentUnitLink();
  const [searchTerm, setSearchTerm] = useState('');
  const isLoading = useSelector((state: RootStore) => state.units.loading);

  const dispatch = useDispatch();
  const personId = useSelector(
    (allState: RootStore) => allState?.auth?.user?.id,
  );
  const [isNavbarShown, setIsNavbarShown] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [linkCodeEntry, setLinkCodeEntry] = useState('');
  const isSwitching = useSelector(
    (state: RootStore) => state.units,
  )?.isSwitching;
  const [showInviteCodeModal, setShowInviteCodeModal] = useState(false);

  const onClickGenerateInviteCode = () => {
    setShowInviteCodeModal(true);
  };

  const checkUnitCodeValidity = async () => {
    try {
      const unit = await checkUnitCode(linkCodeEntry);
      navigation.navigate('People In Unit', {
        unit,
        unitCode: linkCodeEntry,
        unitName: unit.unit ? unit.unit : unit.unitDisplay,
      });
    } catch (error) {
      showErrorMessage(
        "We couldn't find that unit code. Unit codes are case sensitive. Please try again",
      );
    }
  };

  useEffect(() => {
    dispatch(GetUnitScreenData());
  }, []);

  useEffect(() => {
    // Refetch alerts when units count change
    dispatch(DeleteAllAlerts());
    dispatch(GetAlerts());
    dispatch(GetApprovals());
  }, [unitsAndLinks.length]);

  const showRemoveSelfPrompt = (id: string) => {
    Alert.alert(
      '',
      'Do you really want to exit this unit ?',
      [
        {
          text: 'NO',
          onPress: () => console.log('Cancel Pressed'),
          style: 'cancel',
        },
        {
          text: 'YES',
          onPress: () => deleteUnitLink(id),
        },
      ],
      {
        cancelable: true,
      },
    );
  };

  const deleteUnitLink = async (id: string) => {
    try {
      await deleteUnitLinkById(id);
      showSuccessMessage('You unlinked from the unit successfully');
    } catch (error) {
      showErrorMessage(error.message);
    }
    dispatch(GetUnitScreenData());
  };

  const sendAccessRequest = async (request: RequestAccess) => {
    try {
      await dateFrameRequestAccess({
        unitId: request.unitId,
        startDate: request.startDate,
        endDate: request.endDate,
        description: request.description,
      });
      showSuccessMessage(
        'Request request sent, you will be notified when accepted.',
      );
      //Update requesters approvals list to include the newly created approval.
      setTimeout(() => {
        //@ts-ignore
        store.dispatch(DeleteAllApprovals());
        //@ts-ignore
        store.dispatch(GetApprovals());
      }, 2000);
    } catch (error) {
      showErrorMessage(error.message);
    }
  };

  const fetchCopiedText = async () => {
    const text = await pasteFromClipboard();
    setLinkCodeEntry(text);
  };

  const copyToClipBoard = text => {
    copyToClipBoard(text);
    showSuccessMessage('Code copied to clipboard');
  };

  const renderHeader = () => {
    return (
      <>
        {isNavbarShown ? (
          <NavbarWithAction
            hasAdd={true}
            hasSearch={unitsAndLinks.length > 1}
            //TODO: Perform a search in a different screen
            // and pass the results to this screen ? No
            // Problem is maintaining the default unit during search
            onClickSearch={() => setIsNavbarShown(false)}
            onClickAdd={() => navigation.navigate('Add with UnitCode')}
            title="Select unit"
            hasMoreMenu
            hasSignOut
            navigation={navigation}
            hasAccessCard={!!currentUnitPeopleLink}
            onClickAccessCard={() =>
              navigation.navigate(E_NAVIGATION_DESTINATIONS.ID_CARD)
            }
            fullScreen
            hasProfile
            openProfile={() => navigation.navigate('Edit Profile')}
            hasGenerateInviteCode
            onClickGenerateInviteCode={onClickGenerateInviteCode}
            hasOthers
            openOthers={() => navigation.navigate('Others')}
          />
        ) : (
          <MySearchbar
            placeholder="Search by nickname or unit name"
            setValue={val => {
              setSearchTerm(val?.trim());
              dispatch(SearchUnits(val?.trim()));
            }}
            resetSearch={() => {
              setIsNavbarShown(true);
              setSearchTerm('');
              dispatch(SearchUnits(''));
            }}
          />
        )}
      </>
    );
  };

  return (
    <Container hasDrawer={hasDrawer}>
      {renderHeader()}
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        enabled
        keyboardVerticalOffset={10}
        style={{
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'center',
        }}>
        <FlatList
          data={unitsAndLinks}
          style={[tailwind('pt-6')]}
          contentContainerStyle={{paddingBottom: 150}}
          showsVerticalScrollIndicator={false}
          renderItem={({item}) => (
            <UnitListitem
              unitPeopleLink={item}
              onSelectRemoveSelf={() => showRemoveSelfPrompt(item.id)}
              onSelectUnitCode={() => onSelectUnitCode('ZQ84RAP0')}
              selectUnitHandler={() => {
                dispatch(SetSwitchingUnits(true));
                setTimeout(() => {
                  dispatch(SetDefaultUnitLink(item.id));
                  if(isWeb){
                    navigation.navigate('Alerts', {activeTab: 'Notifications'});
                  }
                }, 100);
              }}
              onOpenCalendar={() => {
                navigation.navigate('Unit Calendar', {
                  unitId: item.unit.id,
                  unitPeopleLinkId: item.id,
                  isLoggedInUser: item.person.id === personId,
                  personId: item.person.id,
                  personName: deriveDisplayName(item.person),
                  unitName: item.unitNickname
                    ? item.unitNickname
                    : item.unit.unitName,
                });
              }}
              onSelectDay={request => sendAccessRequest(request)}
              onSelectCustom={() =>
                navigation.navigate('Link Request', {
                  unitId: item.unit.id,
                  unitName: item.unit.unitName
                    ? item.unit.unitName
                    : item.unitNickname,
                  personId,
                  eventName: 'ResidentAccess',
                })
              }
              onClickApprovalsRequired={() => {
                navigation.navigate('Approvals History', {
                  personName: `${deriveDisplayName(item)}`,
                  isLoggedInUser: true,
                  approvals: item.approvals,
                  type: 'pending',
                  recordName: item.unitNickname
                    ? item.unitNickname
                    : item.unit.unitName,
                });
              }}
              onOpenPermissions={() => {
                navigation.navigate('User Permissions', {
                  isLoggedInUser: true,
                  unitPeopleLink: item,
                  canEditOtherPeoplePermissions: item.canManagePeople,
                  canEditOwnPermissions: item.canEditSelf,
                });
              }}
              onSelectEditUnitLink={() =>
                navigation.navigate('Edit Person Link', {
                  unitLink: item,
                })
              }
              onSelectEditOwnUnitLink={() => {
                navigation.navigate(
                  E_NAVIGATION_DESTINATIONS.EDIT_OWN_UNITLINK,
                  {
                    unitPeopleLink: item,
                    from: 'UNITS',
                  },
                );
              }}
              onSelectEditUnit={() => {
                if (permissionToBool(item?.unitPermission?.charAt(1))) {
                  navigation.navigate('Edit Unit', {
                    unit: item.unit,
                  });
                }
              }}
            />
          )}
          ListFooterComponent={<View style={tailwind('h-8')} />}
          ListEmptyComponent={
            <NoUnits
              searchMode={!!searchTerm}
              isLoading={isLoading}
              handleUnitCode={() => navigation.navigate('Add with UnitCode')}
              handlePeopleCode={() => setShowInviteCodeModal(true)}
            />
          }
          refreshing={isLoading}
          onRefresh={() => {
            if (!!searchTerm) {
              dispatch(SearchUnits(searchTerm));
            } else {
              dispatch(GetUnitScreenData());
            }
          }}
        />
      </KeyboardAvoidingView>
      <Modal isOpen={modalVisible} onClose={setModalVisible}>
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Header>Enter unit Link Code</Modal.Header>
          <Modal.Body>
            <Input
              mt={4}
              mb={4}
              placeholder="ZQ84RAP0"
              value={linkCodeEntry}
              onChangeText={text => setLinkCodeEntry(text)}
              InputLeftElement={
                <Button
                  variant="ghost"
                  startIcon={
                    <MaterialCommunityIcons
                      name="content-paste"
                      color="gray"
                      size={24}
                    />
                  }
                  onPress={fetchCopiedText}
                />
              }
              InputRightElement={
                <Button
                  variant="link"
                  onPress={() => {
                    setModalVisible(!modalVisible);
                    checkUnitCodeValidity();
                  }}>
                  Continue
                </Button>
              }
            />
          </Modal.Body>
        </Modal.Content>
      </Modal>
      <AsterixDialog
        message={
          isSwitching ? 'Gathering unit information ...' : 'Loading units ...'
        }
        isSwitching={isSwitching || isLoading}
      />
      <InviteCodeGenerator
        modalVisible={showInviteCodeModal}
        closeModal={() => setShowInviteCodeModal(false)}
      />
    </Container>
  );
};

export default UnitList;
