import {residentApiWithAuth} from './resident';

const createPet = async (pet) => {
  try {
    let res = await residentApiWithAuth().post('/pets', pet);
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    console.log(error);
    const message = error.response.data.errors[0];
    throw new Error(message);
  }
};

const updatePet = async (pet, petId) => {
  try {
    let res = await residentApiWithAuth().post(`/pets/${petId}`, pet);
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetchPetById = async (petId) => {
  try {
    let res = await residentApiWithAuth().get(`/pets/${petId}`);
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

const deletePetById = async (petId) => {
  try {
    let res = await residentApiWithAuth().delete(`/pets/${petId}`);
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetchPetsByUnit = async (unitId, searchTerm) => {
  try {
    let res = await residentApiWithAuth().get(
      `/pets/unit/${unitId}?searchKey=${searchTerm}`,
    );
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export {createPet, updatePet, fetchPetById, deletePetById, fetchPetsByUnit};
