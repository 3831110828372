import {LIMIT_PER_PAGE} from './constants';
import {residentApiWithAuth} from './resident';

const createUnitPeopleLink = async (unitData) => {
  try {
    let res = await residentApiWithAuth().post('/unitPeopleLinks', unitData);
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    const message = error.response.data.errors[0];
    throw new Error(message);
  }
};

const fetchLinkByPerson = async (pageNumber = 1, personId) => {
  try {
    let res = await residentApiWithAuth().get(
      `/unitPeopleLinks/people/${personId}?pageNumber=${pageNumber}&pageSize=${LIMIT_PER_PAGE}&thumbWidth=${300}&thumbHeight=${300}`,
    );
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetchLinksByPerson = async (pageNumber = 1, personId) => {
  try {
    const results = await fetchLinkByPerson(pageNumber, personId);
    if (results.length >= 15) {
      return results.concat(await fetchLinksByPerson(pageNumber + 1, personId));
    } else {
      return results;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetchLinksByCode = async (unitCode) => {
  try {
    let res = await residentApiWithAuth().get(
      `/unitPeopleLinks/unitCode/${unitCode}`,
    );
    const result = res.data;
    if (result.error) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (e) {
    throw new Error('fetchLinksByCode' + e.message);
  }
};

const fetchLinksByUnit = async (unitId) => {
  try {
    let res = await residentApiWithAuth().get(
      `/unitPeopleLinks/unit/${unitId}`,
    );
    const result = res.data;
    if (result.error) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (e) {
    throw new Error(e.message);
  }
};

const updateUnitPeopleLink = async (unitPeopleLinkId, unitPeopleLinkData) => {
  try {
    let res = await residentApiWithAuth().post(
      `/unitPeopleLinks/update/${unitPeopleLinkId}`,
      unitPeopleLinkData,
    );

    const result = res.data;
    if (result.error) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (e) {
    console.log('err', e.response);
    const message = e.response.data.errors[0];
    throw new Error(message);
  }
};

const getUnitLinkById = async (linkId) => {
  try {
    let res = await residentApiWithAuth().get(`/unitPeopleLinks/${linkId}`);
    const result = res.data;
    if (result.error) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (e) {
    throw new Error(e.message);
  }
};

const unlinkUnitLinkById = async (linkId) => {
  console.log('linkId', linkId);
  try {
    let res = await residentApiWithAuth().get(
      `/unitPeopleLinks/unlink/${linkId}`,
    );
    const result = res.data;
    if (result.error) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (e) {
    throw new Error(e.message);
  }
};

const deleteUnitLinkById = async (linkId) => {
  try {
    let res = await residentApiWithAuth().delete(`/unitPeopleLinks/${linkId}`);
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (e) {
    const message = e.response?.data?.errors[0];
    throw new Error(message || e.message);
  }
};

const requestLink = async (unitLinkData) => {
  try {
    let res = await residentApiWithAuth().post(
      '/unitPeopleLinks/requestlink',
      unitLinkData,
    );
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (e) {
    const message = e.response.data.errors[0];
    throw new Error(message);
  }
};


export {
  createUnitPeopleLink,
  fetchLinksByPerson,
  fetchLinksByUnit,
  updateUnitPeopleLink,
  unlinkUnitLinkById,
  getUnitLinkById,
  fetchLinksByCode,
  requestLink,
  deleteUnitLinkById,
};
