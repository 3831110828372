import {Dispatch} from 'redux';

export const NAVIGATION_SET_CURRENT_ROUTE = 'NAVIGATION_SET_CURRENT_ROUTE';
export const NAVIGATION_TOGGLE_DRAWER = 'NAVIGATOR_TOGGLE_DRAWER';

export interface SetCurrentRoute {
  type: typeof NAVIGATION_SET_CURRENT_ROUTE;
  payload: string;
}

export interface ToggleDrawer {
  type: typeof NAVIGATION_TOGGLE_DRAWER;
}

export const setCurrentRoute =
  (payload: string) => async (dispatch: Dispatch<SetCurrentRoute>) => {
    dispatch({type: NAVIGATION_SET_CURRENT_ROUTE, payload});
  };

export const toggleDrawer = () => async (dispatch: Dispatch<ToggleDrawer>) => {
  dispatch({type: NAVIGATION_TOGGLE_DRAWER});
};

export type NavigationDispatchTypes = SetCurrentRoute | ToggleDrawer;

const defaultState = {
  drawerOpen: true,
  currentRoute: null,
};

const navigationReducer = (state = defaultState, action) => {
  switch (action.type) {
    case NAVIGATION_TOGGLE_DRAWER:
      return {
        ...state,
        drawerOpen: !state.drawerOpen,
      };
    case NAVIGATION_SET_CURRENT_ROUTE:
      return {
        ...state,
        currentRoute: action.payload,
      };
  }
  return state;
};

export default navigationReducer;
