import React from 'react';
import {ScrollView, View, useWindowDimensions} from 'react-native';
import Button from '../../../components/Button';
import Heading from '../../../components/Heading';
import HStack from '../../../components/HStack';
import tailwind from 'tailwind-rn';
import Ionicons from 'react-native-vector-icons/Ionicons';
import {SafeAreaView} from 'react-native-safe-area-context';
import IconButton from '../../../components/IconButton';
import Text from '../../../components/Text';
import ScreenWrapper from '../components/ScreenWrapper';
import isWeb from '../../../utils/isWeb';

const Success = ({navigation}) => {
  const {width} = useWindowDimensions();

  return (
    <ScreenWrapper>
      <SafeAreaView
        style={[
          tailwind('flex-1 mb-4'),
          isWeb && {backgroundColor: '#ffffff', width: width / 3},
        ]}>
        <ScrollView style={tailwind('mx-2 mb-4')}>
          <IconButton
            icon={'close-outline'}
            size={30}
            style={tailwind('')}
            onPress={() => navigation.goBack()}
          />
          <View style={tailwind('mx-6 mt-4')}>
            <Heading>Success </Heading>
            <View style={tailwind('flex-row mt-8')}>
              <Ionicons
                name="checkmark-circle-outline"
                color="#47B275"
                size={24}
                style={tailwind('mt-1')}
              />
              <Text style={tailwind('mb-4 ml-2 text-lg')}>
                Congratulations, your account has been set up successfully.
              </Text>
            </View>
          </View>
        </ScrollView>
        <HStack style={{margin: 24, marginTop: 32}}>
          <Button
            onPress={() => navigation.navigate('Sign In')}
            style={tailwind('flex-1 py-2')}>
            Sign In
          </Button>
        </HStack>
      </SafeAreaView>
    </ScreenWrapper>
  );
};

export default Success;
