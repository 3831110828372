import AsyncStorage from '@react-native-async-storage/async-storage';
import { LIMIT_PER_PAGE } from './constants';
import { residentApiWithAuth } from './resident';
import { E_STORAGE_KEYS } from '../@types';

const createAlert = async (alertData, senderType) => {
  try {
    let res = await residentApiWithAuth().post(
      `/alertHeader/${senderType}`,
      alertData,
    );
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    const message = error.response?.data?.errors[0] || 'Error occured';
    throw new Error(message);
  }
};

const sendAction = async alertData => {
  let url = '/alertDetail/person/updateAction';

  try {
    let res = await residentApiWithAuth().post(url, {
      ...alertData,
    });
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error);
  }
};

const fetchAlertById = async (alertId, width = 100, height = 100) => {
  let url = `/alertDetail/person/${alertId}?thumbWidth=${width}&thumbHeight=${height}`;

  const pushyTokenId = JSON.parse(
    await AsyncStorage.getItem(E_STORAGE_KEYS.PUSHY_DEVICE_TOKEN),
  )?.deviceToken;

  if (pushyTokenId) {
    url = `${url}&pushyTokenId=${pushyTokenId}`;
  }

  try {
    let res = await residentApiWithAuth().get(url);
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error);
  }
};

const fetchAlertDetail = async (id, width = 100, height = 100) => {
  let url = `/alertDetail/details/${id}?thumbWidth=${width}&thumbHeight=${height}`;
  try {
    let res = await residentApiWithAuth().get(url);
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error);
  }
}

const fetchAlertByPerson = async (pageNumber = 1) => {
  try {
    let res = await residentApiWithAuth().get(
      `/alertDetail/person?pageNumber=${pageNumber}&pageSize=${LIMIT_PER_PAGE}`,
    );

    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetchAlertsByPerson = async (pageNumber = 1) => {
  try {
    const results = await fetchAlertByPerson(pageNumber);
    if (results.length > 0) {
      return results.concat(await fetchAlertsByPerson(pageNumber + 1));
    } else {
      return results;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

const readAllAlerts = async () => {
  try {
    let res = await residentApiWithAuth().post('/alertDetail/person/readAll');
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error);
  }
};

export {
  createAlert,
  fetchAlertsByPerson,
  fetchAlertById as fetchNotificationById,
  sendAction,
  readAllAlerts,
  fetchAlertDetail,
};
