export enum EventNames {
  REQUEST_ACCESS = 'RequestAccess',
  REQUEST_ACCESS_APPROVED = 'RequestAccessApproved',
  REQUEST_ACCESS_DENIED = 'RequestAccessDenied',

  UNITLINK = 'UnitLink',
  UNITLINK_APPROVED = 'UnitLinkApproved',
  UNITLINK_DENIED = 'UnitLinkDenied',

  PEOPLELINK = 'PeopleLink',
  PEOPLELINK_APPROVED = 'PeopleLinkApproved',
  PEOPLELINK_DENIED = 'PeopleLinkDenied',

  VISITOR_SCREEN = 'VisitorScreen',
  VISITOR_APPROVED_ENTRANCE = 'VisitorApprovedEntrance',
  VISITOR_DENIED_ENTRANCE = 'VisitorDeniedEntrance',
  VISITOR_UPDATE = 'UpdateVisitorScreen',
}
