// @ts-nocheck
import {useNavigation} from '@react-navigation/core';
import React, {useEffect, useState} from 'react';
import {View, ScrollView, FlatList} from 'react-native';
import Text from '../../../components/Text';
import tailwind from 'tailwind-rn';
import Icon from 'react-native-vector-icons/Feather';
import {Navbar, ThemedSwipeablePanel} from '../../../components';
import {CreateDelivery, UpdateDelivery} from '../deliveriesReducer';
import {initialDateFrameState, initialDeliveryState} from '../data';
import {useDispatch, useSelector} from 'react-redux';
import {RootStore} from '../../../redux/store';
import {styles} from '../../../styles';
import {TouchableOpacity} from 'react-native-gesture-handler';
import {RouteProp, useRoute} from '@react-navigation/native';
import {RootStackParamList} from '../../../@types';
import {residentApiWithAuth} from '../../../api/resident';
import {showErrorMessage} from '../../../service/flashMessage';
import {deriveDisplayName} from '../../../utils';
import Accordion from '../../../components/Accordion';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import TextArea from '../../../components/TextArea';
import VStack from '../../../components/VStack';
import Container from '../../../components/Container';
import DateTimeInput from '../../../components/DateTimeInput';

const AddDelivery: React.FC = () => {
  const {delivery} =
    useRoute<RouteProp<RootStackParamList, 'Add delivery'>>().params;
  const isInEditMode = !!delivery;
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const {id, unit} = useSelector(
    (state: RootStore) => state.units.unitLinks,
  ).filter(link => link.isDefault === true)[0];
  const {user} = useSelector((state: RootStore) => state.auth);

  const [deliveryState, setDeliveryState] = useState({
    ...initialDeliveryState,
    unitId: unit.id, // unitId should be renamed to unitLinkId in the API
    personAuthorizing: user.id,
  });
  const [dateFrameState, setDateFrameState] = useState({
    ...initialDateFrameState,
    unitId: unit.id,
  });

  const deliveriesState = useSelector((state: RootStore) => state.deliveries);
  const [title, setTitle] = useState('Add delivery');
  const [companiesPanelShown, setCompaniesPanelShown] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [duration, setDuration] = useState(0);
  // Needs to come from list site
  const [categories, setCategories] = useState([]);
  const [advancedSectionShown, setAdvancedSectionShown] = useState(true);

  useEffect(() => {
    fetchCategories().then(() => fetchCompanies(unit.id));
    if (isInEditMode) {
      const deliveryObj = Object.assign({}, delivery);
      setDeliveryState({
        ...deliveryState,
        category: deliveryObj.category,
        authorizationTo: deliveryObj.authorizationTo,
        company: deliveryObj.company,
        expectedTime: deliveryObj.expectedTime,
        //@ts-ignore
        id: deliveryObj.id,
        instructions: deliveryObj.instructions,
        personAuthorizing: deliveryObj.personAuthorizing,
      });
      setDateFrameState({...dateFrameState, ...deliveryObj?.dateFrame});
      setTitle('Edit delivery');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fetchCompanies = async (unitId: string) => {
    try {
      const res = await residentApiWithAuth().get(
        `/deliveries/CompanyNamesInUnit/${unitId}`,
      );
      const data = res.data.data;
      setCompanies(data);
    } catch (error) {
      showErrorMessage(error);
    }
  };

  const fetchCategories = async () => {
    try {
      const res = await residentApiWithAuth().get(
        'listbysite?table=Deliveries',
      );
      const data = res.data.data;
      const options = data.filter(item => item.field === 'Category');
      const optionsList = options[0].options.split(',');
      setCategories(optionsList);
    } catch (error) {
      showErrorMessage(error);
    }
  };

  const updateState = (field: string, text: string) =>
    setDeliveryState({
      ...deliveryState,
      [field]: text,
    });

  const calcEndDate = (mins: number) => {
    const now = new Date();
    now.setMinutes(now.getMinutes() + mins);

    setDateFrameState({
      ...dateFrameState,
      endDate: new Date(now).toISOString(),
    });
  };

  const handleDurationChange = (durationInMins: string) => {
    if (durationInMins === '') {
      setDuration(0);
      calcEndDate(0);
    } else {
      setDuration(parseInt(durationInMins, 10));
      calcEndDate(parseInt(durationInMins, 10));
    }
  };

  const CompaniesPanel = () => {
    return (
      <ThemedSwipeablePanel
        onlyLarge
        fullWidth
        style={{
          maxWidth: 1100,
        }}
        isActive={companiesPanelShown}
        onClose={() => setCompaniesPanelShown(false)}
        showCloseButton>
        <FlatList
          data={companies}
          renderItem={({item, index}) => (
            <Select.Item
              key={index}
              label={item.company}
              value={item.company}
              onPress={() => {
                setCompaniesPanelShown(false);
                updateState('company', item.company);
              }}
            />
          )}
        />
      </ThemedSwipeablePanel>
    );
  };

  return (
    <>
      <Container>
        <Navbar
          title={title}
          navigation={navigation}
          backHandler={() => navigation.navigate('Dashboard')}
        />
        <ScrollView
          style={tailwind('mx-2 mb-4')}
          showsVerticalScrollIndicator={false}>
          <>
            <Text style={[styles.textInputLabel, tailwind('mt-2')]}>
              Category
            </Text>
            <Select
              selectedValue={deliveryState.category}
              minWidth="200"
              placeholder="Choose Category"
              mt={1}
              onValueChange={itemValue =>
                setDeliveryState({...deliveryState, category: itemValue})
              }>
              {categories.map(category => (
                <Select.Item label={category} value={category} key={category} />
              ))}
            </Select>
            <Text style={[styles.textInputLabel, tailwind('mt-4 flex-1')]}>
              From
            </Text>
            <Input
              minWidth={300}
              style={tailwind('flex-1')}
              placeholder="eg. Asterix Inc"
              value={deliveryState.company}
              onChangeText={text => updateState('company', text)}
              InputRightElement={
                <TouchableOpacity
                  style={tailwind('px-4')}
                  onPress={() => setCompaniesPanelShown(true)}>
                  <Icon name="chevron-down" size={24} color="#007bff" />
                </TouchableOpacity>
              }
            />
            <DateTimeInput
              label="Delivery date"
              value={new Date(dateFrameState.startDate)}
              onConfirm={date => {
                setDateFrameState({
                  ...dateFrameState,
                  startDate: new Date(date).toISOString(),
                  endDate:
                    duration === 0
                      ? new Date(date).toISOString()
                      : dateFrameState.endDate,
                });
              }}
              mode='date'
            />
            <Text style={[styles.textInputLabel, tailwind('mt-4 flex-1')]}>
              Person authorizing
            </Text>
            <Input
              minWidth={300}
              style={tailwind('flex-1')}
              placeholder="eg. Terry"
              isReadOnly={true}
              value={
                isInEditMode
                  ? deriveDisplayName(deliveryState.personAuthorizing)
                  : deriveDisplayName(user)
              }
            />
          </>
          {advancedSectionShown && (
            <Accordion style={tailwind('mt-4')}>
              <Accordion.Item>
                <Accordion.Summary>
                  Advanced
                  <Accordion.Icon />
                </Accordion.Summary>
                <Accordion.Details>
                  <>
                    <Text
                      style={[styles.textInputLabel, tailwind('mt-4 flex-1')]}>
                      Authorization to
                    </Text>
                    <Input
                      minWidth={300}
                      style={tailwind('flex-1')}
                      placeholder="eg. Doe"
                      value={deliveryState.authorizationTo}
                      onChangeText={text =>
                        updateState('authorizationTo', text)
                      }
                    />
                    <VStack>
                      <Text style={[styles.textInputLabel, tailwind('mt-2')]}>
                        Delivery duration
                      </Text>
                      <View style={tailwind('flex-row')}>
                        <Input
                          value={duration.toString()}
                          InputRightElement={
                            <Text style={tailwind('px-4')}>Mins</Text>
                          }
                          minWidth={200}
                          keyboardType="numeric"
                          onChangeText={text => handleDurationChange(text)}
                        />
                      </View>
                    </VStack>
                    <DateTimeInput
                      label="Delivery end date"
                      value={new Date(dateFrameState.endDate)}
                      onConfirm={date => {
                        setDateFrameState({
                          ...dateFrameState,
                          endDate: new Date(date).toISOString(),
                        });
                      }}
                      mode='date'
                    />
                    <Text
                      style={[styles.textInputLabel, tailwind('mt-4 flex-1')]}>
                      Duration description
                    </Text>
                    <Input
                      minWidth={300}
                      style={tailwind('flex-1')}
                      value={dateFrameState.description}
                      onChangeText={text =>
                        setDateFrameState({
                          ...dateFrameState,
                          description: text,
                        })
                      }
                    />
                    <Text
                      style={[styles.textInputLabel, tailwind('mt-4 flex-1')]}>
                      Instructions
                    </Text>
                    <TextArea
                      minWidth={300}
                      style={tailwind('flex-1')}
                      placeholder="eg. this delivery is fragile"
                      value={deliveryState.instructions}
                      onChangeText={text => updateState('instructions', text)}
                    />
                  </>
                </Accordion.Details>
              </Accordion.Item>
            </Accordion>
          )}
        </ScrollView>
        <Button
          onPress={() => {
            isInEditMode
              ? dispatch(UpdateDelivery(dateFrameState, deliveryState))
              : dispatch(CreateDelivery(dateFrameState, deliveryState));
          }}
          isLoading={deliveriesState.loading}
          isDisabled={deliveriesState.loading}
          isLoadingText="Saving ..."
          size="lg"
          m="3"
          style={tailwind('mt-4 mb-12')}>
          Save
        </Button>
      </Container>
      <CompaniesPanel />
    </>
  );
};

export default AddDelivery;
