import {permissionToBool} from '.';
import { E_PERMISSIONS } from '../@types';
import {UnitLink} from '../@types/UnitLink';

export default function mapPermissionStringToSwitches(peopleState: UnitLink) {
  const permissionTypes = [
    {
      title: E_PERMISSIONS.PEOPLE,
      resourcePermission: peopleState.peoplePermission,
    },
    {title: E_PERMISSIONS.UNIT, resourcePermission: peopleState.unitPermission},
    {
      title: E_PERMISSIONS.VEHICLE,
      resourcePermission: peopleState.vehiclePermission,
    },
    {title: E_PERMISSIONS.PET, resourcePermission: peopleState.petPermission},
    {
      title: E_PERMISSIONS.STORAGE,
      resourcePermission: peopleState.storagePermission,
    },
    {
      title: E_PERMISSIONS.PARKING,
      resourcePermission: peopleState.parkingPermission,
    },
    {
      title: E_PERMISSIONS.VIOLATION,
      resourcePermission: peopleState.violationsPermission,
    },
    {
      title: E_PERMISSIONS.DELIVERY,
      resourcePermission: peopleState.deliveryPermission,
    },
    {
      title: E_PERMISSIONS.GATE_CARD,
      resourcePermission: peopleState.gateCardPermission,
    },
  ];

  //Data structure to manage the permissions
  const nextPermissions = permissionTypes.map((type) => {
    let permObj = {
      title: type.title,
      permissions: [
        {
          value: permissionToBool(type?.resourcePermission?.charAt(0)),
          text: `Can See ${type.title}`,
        },
        {
          value: permissionToBool(type?.resourcePermission?.charAt(1)),
          text: `Can Edit ${type.title}`,
        },
        {
          value: permissionToBool(type?.resourcePermission?.charAt(2)),
          text: `Can Edit non-management approved ${type.title}`,
        },
        {
          value: permissionToBool(type?.resourcePermission?.charAt(3)),
          text: `Can Add ${type.title}`,
        },
        {
          value: permissionToBool(type?.resourcePermission?.charAt(4)),
          text: `Can Delete ${type.title}`,
        },
        {
          value: permissionToBool(type?.resourcePermission?.charAt(5)),
          text: `Can Delete non-management approved ${type.title}`,
        },
      ],
    };

    if (type.title === 'People') {
      const peoplePermisions = [
        {
          value: permissionToBool(type?.resourcePermission?.charAt(6)),
          text: 'Can add peopletype with resident allowed to add',
        },
        {
          value: permissionToBool(type?.resourcePermission?.charAt(7)),
          text: 'Edit peopletype with resident allowed to add',
        },
        {
          value: permissionToBool(type?.resourcePermission?.charAt(8)),
          text: 'Delete people with resident allowed to add',
        },
      ];
      permObj = {
        ...permObj,
        permissions: [...permObj.permissions, ...peoplePermisions],
      };
    }

    return permObj;
  });

  return nextPermissions;
}
