import {Drawer, useTheme} from '@mui/material';
import {withStyles} from '@mui/styles'; 
import React from 'react';
import {useWindowDimensions} from 'react-native';
import DrawerScreen from './DrawerScreen.web';

const drawerWidth = 280;
const webMaxWidth = 1140;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    // flexShrink: 0,
  },
  // toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: `${drawerWidth}px !important`,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
  },
});

// const checkIsDrawerStatic = width => {
//   return width > webMaxWidth + drawerWidth;
// };

const WebDrawer = props => {
  const {classes} = props;

  return (
    <nav className={classes.drawer}>
      <Drawer
        classes={{
          paper: classes.drawerPaper,
        }}
        variant="permanent"
        open
        >
        <DrawerScreen {...props} />
      </Drawer>
    </nav>
  );
};

export default withStyles(styles)(WebDrawer);
