import React from 'react';
import {View} from 'react-native';
import Text from '../../../components/Text';
import {Card} from 'react-native-paper';
import tailwind from 'tailwind-rn';
import {DoorLog} from '../types';
import {
  StatusPill,
} from '../../../components';

export default function DoorLogItem({
    doorLog,
    onPress,
  }: {
    doorLog: DoorLog;
    onPress: () => void;
  }) {
    return (
      <Card style={tailwind('rounded my-2')} onPress={() => onPress()}>
        <View
          style={{
            flexDirection: 'row',
            padding: 8,
            marginVertical: 4,
            justifyContent: 'space-between',
          }}>
          <View
            style={{
              flex: 1,
              marginHorizontal: 8,
              flexDirection: 'column',
              justifyContent: 'space-around',
            }}>
            <Text>{`Tag read - ${doorLog.tagRead}`}</Text>
            <Text>{`Tag Holder's name - ${doorLog.tagHoldersName}`}</Text>
            <Text>{`Action - ${doorLog.action}`}</Text>
            <StatusPill status={doorLog.statusOfRead} />
            <Text
              style={{
                alignSelf: 'flex-end',
                fontSize: 11,
              }}>{`${new Date(doorLog.whenDateTime).toLocaleString()}`}</Text>
          </View>
        </View>
      </Card>
    );
  }