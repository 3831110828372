const SET_CONNECTED = 'SET_CONNECTED';

export const setIsConnected = (connected: boolean) => ({
  type: SET_CONNECTED,
  connected,
});

export const connectionReducer = (state = null, action) => {
  if (action.type === SET_CONNECTED) {
    return action.connected;
  }
  return state;
};
