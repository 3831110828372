import React from 'react';
import {Switch, SwitchProps} from 'react-native';
import {colorSwatches} from '../styles';

const AsterixSwitch = (props: SwitchProps) => {
  return (
    <Switch
      {...props}
      thumbColor={
        props.disabled ? colorSwatches.gray.color : colorSwatches.primary.color
      }
      aria-label="Description here"
    />
  );
};

export default AsterixSwitch;
