import PushNotification, {Importance} from 'react-native-push-notification';

const createNotificationChannel = () =>
  PushNotification.createChannel(
    {
      channelId: 'RESIDENT_ID',
      channelName: 'RESIDENT_NAME',
      channelDescription: 'A channel to categorise your notifications',
      playSound: true,
      soundName: 'alert.mp3',
      importance: Importance.HIGH,
      vibrate: true,
    },
    (created) =>
      console.log(created ? 'Channel created' : 'Channel not created'),
  );

export default createNotificationChannel;
