import React from 'react';
import {Image, ImageProps, Pressable} from 'react-native';
import {navigate} from '../navigation';
import {E_NAVIGATION_DESTINATIONS} from '../@types';

interface I_ZoomableImage_Props {
  title: string;
  message?:string;
}

const ZoomableImage = (props: I_ZoomableImage_Props & ImageProps) => {
  const {title,message, ...otherProps} = props;
  return (
    <Pressable
      onPress={() =>
        navigate(E_NAVIGATION_DESTINATIONS.PICTURE_ZOOM, {
          title,
          source: props.source,
          message,
        })
      }>
      <Image {...otherProps} />
    </Pressable>
  );
};

export default ZoomableImage;
