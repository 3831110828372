const SET_AGENT = 'SET_AGENT';

export const setDeviceInfo = (info) => ({
  type: SET_AGENT,
  info,
});

export const deviceInfoReducer = (state = {}, action) => {
  if (action.type === SET_AGENT) {
    return action.info;
  }
  return state;
};
