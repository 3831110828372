// @ts-nocheck
import PushNotification from 'react-native-push-notification';
import {deriveDisplayName} from '../utils/deriveDisplayName';
import {store} from '../redux/store';
import {fetchLinksByPerson, updateUnitPeopleLink} from '../api/links';
import {
  HandleAlertButton,
  NavigateToDetails,
} from '../modules/Alerts/alertsReducer';
import {showErrorMessage} from './flashMessage';
import {fetchPerson} from '../api/people';
import {fetchUnitById} from '../api/units';
import {createAlert, sendAction} from '../api/alert';
import {createDateFrame, updatePersonDateFrames} from '../api/dataframe';
import {Alert} from '../@types';
import {UnitLink} from '../@types/UnitLink';
import {Platform} from 'react-native';
import {NavigateToApprovalDetails} from '../redux/reducers/approvalsReducer';

const configureNotifications = () => {
  PushNotification.configure({
    onNotification: function (notification) {
      if (notification.data) {
        if (notification.action) {
          const action = notification.action;

          if (notification.data.actions) {
            const response = parseInt(notification.data.actions[action], 10);
            store.dispatch(
              HandleAlertButton(notification.data, action, response),
            );
          }
        } else {
          if (Platform.OS === 'android') {
              store.dispatch(NavigateToDetails(notification.data.id));
          }
        }
      }
    },
    permissions: {
      alert: true,
      sound: true,
    },

    popInitialNotification: true,
    requestPermissions: Platform.OS === 'ios',
  });
};

export const buildResponseAlert = async (
  notif: Alert,
  response: number,
  eventName: string,
  receiverType: string,
) => {
  const responder = store?.getState()?.auth?.user;
  const name = deriveDisplayName(responder);
  let data = {
    unitId: notif?.unit?.unitId,
    userId: notif?.sender?.id,
    eventName: eventName,
    alertArguments: `{'Name':'${name}','Unit':'${
      notif?.unit?.unitName
    }','Phonenumber':'${
      responder?.phone1
    }','SenderType':'person', 'Approver':'${name}','StartDate':'${
      notif?.dateFrame?.startDate || null
    }','EndDate':'${notif?.dateFrame?.endDate || null}'}`,
    // Image: responder.profilePicture,
    parentAlertId: notif?.alertId,
    action: response,
  };
  if (receiverType === 'person') {
    delete data.userId;
    data = {
      ...data,
      personId: notif?.sender?.id,
    };
  }
  try {
    const sendActionData = {
      alertDetailId: notif?.id,
      action: response,
    }
    await sendAction(sendActionData);
    await createAlert(data, receiverType);
  } catch (error) {
    showErrorMessage(error.message);
  }
};

export const handleIgnore = async (notif: Alert, response: number) => {
  try {
    const personUnitLinks: UnitLink[] = await fetchLinksByPerson(
      notif.sender.id,
    );
    const personLinkToUpdate: UnitLink = personUnitLinks.filter(
      (unitLink: UnitLink) => unitLink.unit.id === notif.unit.unitId,
    )[0];
    await updateUnitPeopleLink(personLinkToUpdate.id, {
      lInkApproved: 'Denied',
    });
    await buildResponseAlert(notif, response, 'UnitLinkDenied', 'person');
  } catch (error) {
    showErrorMessage(error.message);
  }
};

export const handleAuthorize = async (notif: Alert, response: number) => {
  try {
    const personUnitLinks: UnitLink[] = await fetchLinksByPerson(
      notif.sender.id,
    );
    const personLinkToUpdate: UnitLink = personUnitLinks.filter(
      unitLink => unitLink.unit.id === notif.unit.unitId,
    )[0];

    //Create new dateFrame
    const dateFrame = {
      unitId: notif.unit.unitId,
      startDate: notif?.dateFrame.startDate,
      endDate: notif?.dateFrame.endDate,
      notes: notif?.dateFrame.description,
    };
    const newDateFrame = await createDateFrame(dateFrame);
    const dateFrameId = await newDateFrame.id;

    //Send response
    await sendAction({
      alertDetailId: notif?.id,
      action: response,
    });

    //Update person dateFrame
    await updatePersonDateFrames({
      unitPeopleLinkId: personLinkToUpdate.id,
      dateFrameIds: [dateFrameId],
    });

    //Send alert to people in unit and/or to requesting person
    buildResponseAlert(notif, response, 'ResidentAccessApproved', 'person');
    // sendAlert(personLinkToUpdate, notif.sender.id, notif.unit.unitId);
  } catch (error) {
    showErrorMessage(error.message);
  }
};

export const sendAlert = async (
  unitPeopleLink: UnitLink,
  requestingPersonId: string,
  unitId: string,
) => {
  const approver = store?.getState()?.auth?.user;
  const requestingPerson = await fetchPerson(requestingPersonId);
  const personName = deriveDisplayName(requestingPerson);
  const approverName = deriveDisplayName(approver);
  const unit = await fetchUnitById(unitId);
  const unitName = unit.unit ? unit.unit : unit.unitDisplay;

  const alertArguments = `{'Name':'${personName}','Unit':'${unitName}','Approver':'${approverName}'}`;
  const personAlertArguments = `{'Unit':'${unitName}','Approver':'${approverName}'}`;

  if (unitPeopleLink) {
    if (unitPeopleLink.canManagePeople) {
      await createAlert(
        {
          alertArguments,
          eventName: 'UnitLinkApproved',
          unitId,
        },
        'person',
      );
    } else {
      await createAlert(
        {
          alertArguments,
          eventName: 'UnitLinkApproved',
          unitId,
        },
        'person',
      );
      await createAlert(
        {
          alertArguments: personAlertArguments,
          eventName: 'UnitLinkApprovedPerson',
          personId: requestingPerson.id,
        },
        'person',
      );
    }
  } else {
    await createAlert(
      {
        alertArguments: personAlertArguments,
        eventName: 'UnitLinkDenied',
        personId: requestingPerson.id,
      },
      'person',
    );
  }
  // showSuccessMessage('Request access processed successfully');
};

export default configureNotifications;
