import {Dispatch} from 'redux';
import {ListEvent} from '../../@types';
import fetchListEvents from '../../api/list-events';

export const LIST_EVENTS_LOADING = 'LIST_EVENTS_LOADING';
export const LIST_EVENTS_FAIL = 'LIST_EVENTS_FAIL';
export const LIST_EVENTS_SUCCESS = 'LIST_EVENTS_SUCCESS';
export const UPDATE_EVENTS_SUCCESS = 'UPDATE_EVENTS_SUCCESS';

export interface ListEventsLoading {
  type: typeof LIST_EVENTS_LOADING;
}

export interface ListEventsFail {
  type: typeof LIST_EVENTS_FAIL;
  error: string | object;
}

export interface ListEventsSuccess {
  type: typeof LIST_EVENTS_SUCCESS;
  payload: ListEvent[];
}
export interface ListEventsUpdate {
  type: typeof UPDATE_EVENTS_SUCCESS;
  payload: ListEvent[];
}

export type ListEventsDispatchTypes =
  | ListEventsLoading
  | ListEventsFail
  | ListEventsSuccess
  | ListEventsUpdate;

export const GetListEvents =
  () => async (dispatch: Dispatch<ListEventsDispatchTypes>) => {
    try {
      dispatch({type: LIST_EVENTS_LOADING});

      let listEvents: ListEvent[] = await fetchListEvents();

      dispatch({type: LIST_EVENTS_SUCCESS, payload: listEvents});
    } catch (error) {
      dispatch({type: LIST_EVENTS_FAIL, error: error});
    }
  };

export const UpdateListEvents =
  (listEvents: ListEvent[]) =>
  async (dispatch: Dispatch<ListEventsDispatchTypes>) => {
    dispatch({type: UPDATE_EVENTS_SUCCESS, payload: listEvents});
  };

interface DefaultStateI {
  loading: boolean;
  error?: string | object;
  listEvents?: ListEvent[];
}

const initialState: DefaultStateI = {
  loading: false,
  error: null,
  listEvents: [],
};

const listEventsReducer = (
  state: DefaultStateI = initialState,
  action: ListEventsDispatchTypes,
): DefaultStateI => {
  switch (action.type) {
    case LIST_EVENTS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case LIST_EVENTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case LIST_EVENTS_SUCCESS:
      const listEventsPrefs = action.payload.map((listEvent) => {
        return {
          ...listEvent,
          active: true,
        };
      });
      return {
        ...state,
        loading: false,
        listEvents: [...listEventsPrefs],
      };
    case UPDATE_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        listEvents: action.payload,
      };
    default:
      break;
  }
  return state;
};

export default listEventsReducer;
