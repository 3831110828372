import styled from 'styled-components/native';

const SelectContainer = styled.View`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin: 8px 8px 0px 8px;
  justify-content: space-between;
`;

export default SelectContainer;
