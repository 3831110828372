const initialVehicleState = {
  unitId: '',
  vehicleOwner: '',
  dateFrameId: '',
  vehicleImage: null,
  licensePlate: '',
  licenseState: '',
  makeOfVehicle: '',
  modelOfVehicle: '',
  yearOfVehicle: '',
  colorOfVehicle: '',
  vehicleSecurityPass: '',
  valetVehicleNo: '',
  valetInformation: '',
  valetStatus: '',
  createdLogon: '',
  createdTimeDate: new Date().toISOString(),
  lastLogon: '',
  lastTimeDate: new Date().toISOString(),
};

export {initialVehicleState};
