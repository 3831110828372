import {UnitLink} from '../../@types/UnitLink';

export const getDeriveUserFromState = state => {
  return {
    faceInfoId: state.faceInfoId,
    profilePicture: state.profilePicture,
    picture: state.picture,
    type: state.type,
    title: state.title,
    firstName: state.firstName,
    firstnameSoundx: state.firstnameSoundx,
    nickname: state.nickname,
    lastName: state.lastName,
    lastNameSoundx: state.lastNameSoundx,
    middleName: state.middleName,
    company: state.company,
    salutation: state.salutation,
    addressLine1: state.addressLine1,
    addressLine2: state.addressLine2,
    city: state.city,
    state: state.state,
    zip: state.zip,
    country: state.country,
    phone1: state.phone1,
    phone1Stripped: state.phone1Stripped,
    phone1CanReceiveText: state.phone1CanReceiveText,
    phone1Type: state.phone1Type,
    notes: state.notes,
    driversLicenseImage: state.driversLicenseImage
      ? state.driversLicenseImage
      : null,
    email: state.email,
    username: state.username,
    ufc: state.ufc,
    owner: state.owner,
  };
};

export const getDeriveLinkFromState = (state: UnitLink) => {
  return {
    unitNickname: state.unitNickname,
    peopleType: state.personType,
    disabled: state.disabled,
    disabledDate: state.disabledDate,
    disabledreason: state.disabledreason,
    emergencyContact: state.emergencyContact,
    needsHelpInEmergency: state.needsHelpInEmergency,
    resident: state.resident,
    canManagePeople: state.canManagePeople,
    allowedToChekoutKey: state.allowedToChekoutKey,
    allowedToClaimPackages: state.allowedToClaimPackages,
    allowedToAuthorizeEntry: state.allowedToAuthorizeEntry,
    mailTo: state.mailTo,
    alwaysNeedsEntrancePermission: state.alwaysNeedsEntrancePermission,
    canViewLog: state.canViewLog,
    directoryOptOut: state.directoryOptOut,
    directoryScope: state.directoryScope,
    directoryDoNotShareEmail: state.directoryDoNotShareEmail,
    directoryDoNotSharePhone1: state.directoryDoNotSharePhone1,
    accessInstructions: state.accessInstructions,
    relationship: state.relationship,
    unitPermission: state.unitPermission,
    peoplePermission: state.peoplePermission,
    vehiclePermission: state.vehiclePermission,
    petPermission: state.petPermission,
    storagePermission: state.storagePermission,
    parkingPermission: state.parkingPermission,
    violationsPermission: state.violationsPermission,
    officeNotes: state.officeNotes,
    guardNotes: state.guardNotes,
  };
};

export const deriveEditableLink = (state: UnitLink) => {
  return {
    firstName: state.firstName,
    lastName: state.lastName,
    // useProfileAddress: true,
    addressLine1: state.addressLine1,
    addressLine2: state.addressLine2,
    city: state.city,
    state: state.state,
    zip: state.zip,
    phone: state.phone,
    email: state.email,
    emergencyContact: state.emergencyContact,
    needsHelpInEmergency: state.needsHelpInEmergency,
    alwaysCall: state.alwaysCall,
    getNotifications: state.getNotifications,
    directoryOptOut: state.directoryOptOut,
    directoryScope: state.directoryScope,
    directoryDoNotShareEmail: state.directoryDoNotShareEmail,
    directoryDoNotSharePhone1: state.directoryDoNotSharePhone1,
    profilePicture: state.profilePicture,
    peopleType: state.peopleType.id,
    peopleTypeId: state.peopleType.id,
    resident: state.resident,
    canManagePeople: state.canManagePeople,
    allowedToChekoutKey: state.allowedToChekoutKey,
    allowedToClaimPackages: state.allowedToClaimPackages,
    allowedToAuthorizeEntry: state.allowedToAuthorizeEntry,
    allowedtoApprove: state.allowedtoApprove,
    canViewUnitHistory: state.canViewUnitHistory,
    mailTo: state.mailTo,
    alwaysNeedsEntrancePermission: state.alwaysNeedsEntrancePermission,
    canViewLog: state.canViewLog,
    canEditSelf: state.canEditSelf,
    accessInstructions: state.accessInstructions,
    relationship: state.relationship,
    unitPermission: state.unitPermission,
    peoplePermission: state.peoplePermission,
    vehiclePermission: state.vehiclePermission,
    petPermission: state.petPermission,
    storagePermission: state.storagePermission,
    parkingPermission: state.parkingPermission,
    violationsPermission: state.violationsPermission,
    deliveryPermission: state.deliveryPermission,
    gateCardPermission: state.gateCardPermission,
    officeNotes: state.officeNotes,
    guardNotes: state.guardNotes,
  };
};

const initialAuthState = {
  faceInfoId: 'testFaceInfoId',
  profilePicture: null,
  picture: null,
  type: '',
  title: 'Mr',
  firstName: '',
  firstnameSoundx: '',
  nickname: '',
  lastName: '',
  lastNameSoundx: '',
  middleName: '',
  company: '',
  salutation: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zip: '',
  country: '',
  phone1: '',
  phone1Stripped: '',
  phone1CanReceiveText: true,
  phone1Type: 'Company',
  driversLicenseImage: null,
  ufc: '',
  // owner: null,
  username: '',
  password: '',
  notes: '',
  email: '',
};

export {initialAuthState};
