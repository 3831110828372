import getConfig from "../utils/getConfig";
import isWeb from "../utils/isWeb";

const domain = getConfig("MY_WORKS_DOMAIN");
const apiSuffix = getConfig("MY_WORKS_API_SUFFIX");
const BASE_URL = `${domain}${apiSuffix}`;
const LIMIT_PER_PAGE =isWeb ? 50 : 20;
const OWNER_ID = 'EB099755-EE2C-45B4-A542-DBF788F126DC';

export {BASE_URL, LIMIT_PER_PAGE, OWNER_ID};
