// @ts-nocheck
import React, {useEffect, useState} from 'react';
import {ScrollView, View} from 'react-native';
import Text from '../../../components/Text';
import {
  ActivityIndicator,
  Card,
  Divider,
  TouchableRipple,
} from 'react-native-paper';
import Ionicons from 'react-native-vector-icons/Ionicons';
import tailwind from 'tailwind-rn';
import {ApprovalSection, Navbar} from '../../../components';
import {styles} from '../../../styles';
import {Approval} from '../../../@types/Approval';
import {residentApiWithAuth} from '../../../api/resident';
import styled from 'styled-components/native';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../service/flashMessage';
import {useDispatch, useSelector} from 'react-redux';
import {RootStore} from '../../../redux/store';
import Container from '../../../components/Container';

const LoadingIndicator = styled(ActivityIndicator)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ApprovalDetail = ({navigation, route}) => {
  const {id, unitId} = route.params;
  const [approval, setApproval] = useState<Approval>({});
  const [isLoading, setIsLoading] = useState(true);

  //if this person's unitlink is allowed to approve for this unit, show  buttons
  const unitLink = useSelector(
    (state: RootStore) => state.units.unitLinks,
  )?.filter(link => link.unit.id === unitId)[0];
  const requiresResidentApproval =
    unitLink?.allowedtoApprove && approval.residentApprovalStatus === 'Pending';

  const residentApprovalDate = approval?.residentApprovalDateTime
    ? new Date(approval.residentApprovalDateTime).toLocaleDateString()
    : 'Date not available';
  const managementApprovalDate = approval?.managementApprovalDateTime
    ? new Date(approval.managementApprovalDateTime).toLocaleDateString()
    : 'Date not available';

  //Refresh approval on [Approve, Deny]

  useEffect(() => {
    fetchApproval(id);
  }, [id]);

  const fetchApproval = async (approvalId: string) => {
    setIsLoading(true);
    try {
      const res = await residentApiWithAuth().get(`/approvals/${approvalId}`);
      const result = await res.data;
      if (result) {
        setApproval(result);
      }
    } catch (error) {
      showErrorMessage(error.message);
    }
    setIsLoading(false);
  };

  //Refresh approval history on [Approve, Deny]
  async function handleApprove(approve: boolean) {
    setIsLoading(true);
    try {
      const res = await residentApiWithAuth().post('/approvals', {
        id,
        approve,
      });
      const result = res.data;
      if (result.errors) {
        showErrorMessage(result.errors[0]);
      } else {
        await fetchApproval(id);
        navigation.goBack();
        showSuccessMessage('Your response has been sent');
      }
    } catch (error) {
      const message = error.response.data.errors[0];
      showErrorMessage(message);
    }
    setIsLoading(false);
  }

  return (
    <Container>
      <Navbar navigation={navigation} title="Approval details" />
      <ScrollView style={tailwind('mx-2 mb-4')}>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <Card style={tailwind('p-2 my-4')}>
            <Text>{approval.message}</Text>
            <ApprovalSection approval={approval} />
            <View style={tailwind('flex-row  my-3')}>
              <Text style={styles.textLabel}>Requester name </Text>
              <Text style={styles.detailsText}>
                {approval.approvalRequesterName}
              </Text>
            </View>
            <View style={tailwind('flex-row  my-3')}>
              <Text style={styles.textLabel}>Request date </Text>
              <Text style={styles.detailsText}>
                {new Date(
                  approval.approvalRequestDateTime,
                ).toLocaleDateString()}
              </Text>
            </View>
            <View style={tailwind('flex-row  my-3')}>
              <Text style={styles.textLabel}>Description</Text>
              <Text style={styles.detailsText}>{approval.description}</Text>
            </View>
            <Divider />

            <View style={tailwind('flex-row justify-between my-3')}>
              <Text style={styles.textLabel}>Resident Approval Status</Text>
              <Text style={styles.detailsText}>
                {approval.residentApprovalStatus}
              </Text>
            </View>
            <View style={tailwind('flex-row justify-between my-3')}>
              <Text style={styles.textLabel}>Resident Approval Name</Text>
              <Text style={styles.detailsText}>
                {approval.residentApprovalName}
              </Text>
            </View>
            <View style={tailwind('flex-row justify-between my-3')}>
              <Text style={styles.textLabel}>Resident Approval Date time</Text>
              <Text style={styles.detailsText}>{residentApprovalDate}</Text>
            </View>
            <Divider />

            <View style={tailwind('flex-row justify-between my-3')}>
              <Text style={styles.textLabel}>Management Approval Status</Text>
              <Text style={styles.detailsText}>
                {approval.managementApprovalStatus}
              </Text>
            </View>
            <View style={tailwind('flex-row justify-between my-3')}>
              <Text style={styles.textLabel}>Management Approval Name</Text>
              <Text style={styles.detailsText}>
                {approval.managementApprovalName}
              </Text>
            </View>
            <View style={tailwind('flex-row justify-between my-3')}>
              <Text style={styles.textLabel}>
                Management Approval Date time
              </Text>
              <Text style={styles.detailsText}>{managementApprovalDate}</Text>
            </View>
            <Divider />
            <View style={tailwind('flex-row justify-between my-3')}>
              <Text style={styles.textLabel}>Management Approval Name</Text>
              <Text style={styles.detailsText}>
                {approval.managementApprovalName}
              </Text>
            </View>

            <View style={tailwind('flex-row justify-between my-3')}>
              <Text style={styles.textLabel}>Type</Text>
              <Text style={styles.textLabel}>{approval.type}</Text>
            </View>
            <Divider />
            <View style={tailwind('flex-row justify-between my-3')}>
              <Text style={styles.textLabel}>Unit name</Text>
              <Text style={styles.textLabel}>{approval?.unit?.unitName}</Text>
            </View>
            <Divider />
            {requiresResidentApproval && (
              <View style={tailwind('flex-row justify-between mt-4 mb-2')}>
                <TouchableRipple onPress={() => handleApprove(true)}>
                  <View
                    style={tailwind('flex-row px-2 py-2 bg-green-400 rounded')}>
                    <Ionicons
                      name="checkmark-circle-outline"
                      size={16}
                      style={tailwind('mr-2')}
                    />
                    <Text style={tailwind('text-white text-center mr-2')}>
                      Approve
                    </Text>
                  </View>
                </TouchableRipple>
                <TouchableRipple onPress={() => handleApprove(false)}>
                  <View
                    style={tailwind(
                      'flex-row flex-1 px-2 py-2 bg-red-400 rounded',
                    )}>
                    <Ionicons name="close" size={16} style={tailwind('mr-2')} />
                    <Text style={tailwind('text-white text-center pr-2')}>
                      {'Deny '}
                    </Text>
                  </View>
                </TouchableRipple>
              </View>
            )}
          </Card>
        )}
      </ScrollView>
    </Container>
  );
};

export default ApprovalDetail;
