/* eslint-disable curly */
import PushNotification from 'react-native-push-notification';

export const sendIOSActiveAlert = () => {
  PushNotification.getDeliveredNotifications(notifs => {
    notifs.forEach(notif => {
      if (notif.userInfo.id === '12345')
        PushNotification.removeDeliveredNotifications([notif.identifier]);
    });
  });
  PushNotification.localNotification({
    userInfo: {id: '12345'},
    title: 'Asterix Community App',
    message: 'Asterix Community App is running',
    playSound: false,
    importance: 'low',
  });
};
