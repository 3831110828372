// @ts-nocheck
import React, {useState} from 'react';
import {
  View,
  TouchableOpacity,
  useWindowDimensions,
} from 'react-native';
import Text from '../../../components/Text';
import tailwind from 'tailwind-rn';
import Icon from 'react-native-vector-icons/Feather';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../service/flashMessage';
import {resetPassword} from '../api';
import Button from '../../../components/Button';
import Heading from '../../../components/Heading';
import Input from '../../../components/Input';
import IconButton from '../../../components/IconButton';
import ScreenWrapper from '../components/ScreenWrapper';
import ScreenContent from '../components/ScreenContent';

const ForgotPassword = ({navigation}) => {
  const [submitting, setSubmitting] = useState(false);
  const [username, setUsername] = useState('');

  async function sendPasswordResetLink(name: string) {
    setSubmitting(true);
    try {
      const res = await resetPassword(name);
      showSuccessMessage(res?.message[0]);
      navigation.goBack();
    } catch (error) {
      showErrorMessage(error.message);
    }
    setSubmitting(false);
  }

  return (
    <ScreenWrapper>
      <ScreenContent>
        <IconButton
          icon={'close-outline'}
          size={30}
          style={tailwind('')}
          onPress={() => navigation.goBack()}
        />
        <View style={tailwind('mx-6 mt-4 mx-4 mt-6 flex-1 justify-around')}>
          <Heading size="lg">Reset Password</Heading>
          <Text style={tailwind('my-8 text-lg')}>Type your username</Text>
          <Input
            style={tailwind('flex-1 ml-3 text-sm ')}
            placeholder="e.g johndoe1"
            onChangeText={text => setUsername(text)}
            value={username}
            autoCorrect={false}
            keyboardType="email-address"
            autoCapitalize="none"
            InputLeftElement={
              <TouchableOpacity style={tailwind('px-2')}>
                <Icon name={'user'} size={24} color="grey" />
              </TouchableOpacity>
            }
          />
          <Button
            onPress={() => sendPasswordResetLink(username)}
            isLoadingText="Submitting ..."
            isLoading={submitting}
            style={tailwind('mt-12')}
            py="4">
            Reset password
          </Button>
          <Button
            onPress={() => navigation.navigate('Sign In')}
            variant="link"
            isLoadingText="Submitting ..."
            style={tailwind('mt-8')}>
            Already have an account? Sign in
          </Button>
        </View>
      </ScreenContent>
    </ScreenWrapper>
  );
};

export default ForgotPassword;
