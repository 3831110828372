import React from 'react';
import {StyleSheet, View} from 'react-native';
import Text from '../components/Text';

interface I_EmptyList_Props {
  message: string;
}

export default function EmptyList(props: I_EmptyList_Props) {
  return (
    <View style={emptyListStyles.container}>
      <Text style={emptyListStyles.message}>{props.message}</Text>
    </View>
  );
}

const emptyListStyles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  message: {
    fontSize: 16,
  },
});
