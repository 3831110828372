/* eslint-disable react-native/no-inline-styles */
import HStack from './HStack';
import VStack from './VStack';
import { Badge } from 'react-native-paper';
import React from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';
import { RootStore } from '../redux/store';
import { colorSwatches } from '../styles';
import Text from '../components/Text';
import LoadingSpinner from './LoadingSpinner';

const HeaderButton = (props) => {
  const loadingApprovals = useSelector((state: RootStore) => state.approvals.loading);
  const unitPeopleLinks = useSelector(
    (state: RootStore) => state.units.unitLinks,
  );
  const pendingApprovals = useSelector(
    (state: RootStore) => state.approvals.approvals,
  )?.filter(
    (approval) =>
      approval.residentApprovalStatus === 'Pending' ||
      approval.managementApprovalStatus === 'Pending',
  );

  const pendingOwnerApprovals = useSelector(
    (state: RootStore) => state.approvals.approvals,
  )?.filter((approval) => approval.residentApprovalStatus === 'Pending');

  const pendingManagementApprovals = useSelector(
    (state: RootStore) => state.approvals.approvals,
  )?.filter((approval) => approval.managementApprovalStatus === 'Pending');

  const approvables = pendingApprovals.map((approval) => {
    const allowedToApproveInUnit = unitPeopleLinks.filter(
      (unitLink) => unitLink.unit.id === approval.unit.unitId,
    )[0]?.allowedtoApprove;
    return {
      ...approval,
      allowedToApproveInUnit,
    };
  });

  const approvableApprovals = approvables.filter(
    (approval) => approval.allowedToApproveInUnit === true,
  );

  const newNotifs = useSelector((state: RootStore) => state.alerts.unread) || 0;

  const pendingOwnerApprovalsCount = pendingOwnerApprovals?.length || 0;
  const pendingManagementApprovalsCount = pendingManagementApprovals?.length || 0;

  return (
    <TouchableOpacity
      style={[StylesWithProps(props).tabWrapper]}
      onPress={() => props.setActiveTab(props.text)}>
      <VStack>
        <HStack>
          <Text style={[StylesWithProps(props).tabTitle]}>{props.text}</Text>
        </HStack>
        {props.text === 'Pending Approvals' && <>
          {loadingApprovals ?
            <View style={{ height: 50 }}>
              <LoadingSpinner size='small' color='white' />
            </View>
            : <>
              <View
                style={{
                  flexDirection: 'row',
                  marginTop: 4,
                }}>
                {!!pendingOwnerApprovalsCount && <Badge style={StylesWithProps(props).badge}>
                  {`${pendingOwnerApprovalsCount} `}
                </Badge>
                }
                <Text style={[StylesWithProps(props).tabText]}>
                  {`${!!pendingOwnerApprovalsCount ? '' : 'None'} for unit`}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  marginTop: 4,
                }}>
                {!!pendingManagementApprovalsCount && <Badge style={StylesWithProps(props).badge}>{`${pendingManagementApprovalsCount
                  } `}</Badge>}
                <Text style={[StylesWithProps(props).tabText]}>
                  {`${!!pendingManagementApprovalsCount ? '' : 'None'} for management`}
                </Text>
              </View>
            </>}
        </>}
        {props.text === 'Notifications' && (
          <View
            style={{
              flexDirection: 'row',
              marginTop: 4,
            }}>
            {!!newNotifs &&<Badge style={StylesWithProps(props).badge}>{`${newNotifs} `}</Badge>}
            <Text style={[StylesWithProps(props).tabText]}>{`${!!newNotifs ? 'Unread' : 'All read' } `}</Text>
          </View>
        )}
      </VStack>
    </TouchableOpacity>
  );
};

const HeaderTabs = ({ activeTab, setActiveTab }) => {
  return (
    <View
      style={{
        backgroundColor: colorSwatches.primary.color,
      }}>
      <View
        style={{
          // alignSelf: 'center',
          flexDirection: 'row',
          justifyContent: 'space-around',
        }}>
        <HeaderButton
          text="Notifications"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <HeaderButton
          text="Pending Approvals"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </View>
    </View>
  );
};

function StylesWithProps(props) {
  return StyleSheet.create({
    tabWrapper: {
      // backgroundColor:
      //   props.activeTab === props.text
      //     ? colorSwatches.primary.color
      //     : 'transparent',
      paddingVertical: 6,
      flexDirection: 'row',
      borderBottomColor: colorSwatches.white.color,
      borderBottomWidth: props.activeTab === props.text ? 4 : 0,
      marginHorizontal: 4,
      paddingHorizontal: 6,
      width: '45%',
      opacity: 1
    },
    tabTitle: {
      color: colorSwatches.white.color,
      fontSize: 14,
      textAlign: 'center',
      fontWeight: '600',
    },
    tabText: {
      color: colorSwatches.white.color,
      fontSize: 14,
      paddingHorizontal: 0,
      marginLeft: 8,
    },
    badge: {
      backgroundColor: 'red',
      color: '#FFFFFF',
      fontSize: 14,
      fontWeight: '500',
    },
  });
}

export default HeaderTabs;
