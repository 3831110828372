export type Unit = {
  id?: string;
  siteId?: string;
  communityId?: string;
  buildingId?: string;
  unit?: string;
  unitDisplay?: string;
  floor?: string;
  line?: string;
  communityDelinquent?: string;
  buildingDelinquent?: string;
  alternateUnit?: string;
  status?: string;
  accountNumber?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  zip?: string;
  keyInKeyboxNamed?: string;
  keyTag?: string;
  numberOfKeysOnRing?: string;
  notes?: string;
  directions?: string;
  specialInstructions?: string;
  picture?: string;
  pictureName?: string;
  linkCode?: string;
  siteName?: string;
  communityName?: string;
  buildingName?: string;
  pictureThumbnail?: string;
  startBlockAutomatedCalls:  string | null;
  endBlockautomatedCalls:string | null;
  allowAutomatedCalls: boolean | null;
};

export type RequestAccess = {
  unitId?: string;
  startDate?: string;
  endDate?: string;
  description?: string;
};

export enum E_CONFIRM_MODES {
  TODAY = 'today',
  TOMMOROW = 'tommorow',
}
