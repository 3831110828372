import {residentApiWithAuth} from './resident';

const createUnit = async (unitData) => {
  try {
    let res = await residentApiWithAuth().post('/units', unitData);
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

const updateUnit = async (unitId, unitData) => {
  try {
    let res = await residentApiWithAuth().post(`/units/${unitId}`, unitData);
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetchUnits = async () => {
  try {
    let res = await residentApiWithAuth().get('/units');
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetchUnitsByPerson = async (personId) => {
  try {
    let res = await residentApiWithAuth().get(`/units/person/${personId}`);

    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetchUnitById = async (unitId) => {
  try {
    let res = await residentApiWithAuth().get(`/units/${unitId}`);
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

const deleteUnit = async (unitId) => {
  try {
    let res = await residentApiWithAuth().delete(`/units/${unitId}`);
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

const checkUnitCode = async (unitCode) => {
  try {
    let res = await residentApiWithAuth().get(`/units/checkUnit/${unitCode}`);

    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};
export {
  createUnit,
  fetchUnitById,
  updateUnit,
  fetchUnits,
  fetchUnitsByPerson,
  deleteUnit,
  checkUnitCode,
};
