export default function injectFonts(){
    const iconFontStyles = `
        @font-face {
            src: url(${
                require('react-native-vector-icons/Fonts/MaterialIcons.ttf').default
            });
            font-family: MaterialIcons;
        }
        @font-face {
            src: url(${
                require('react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf').default
            });
            font-family: MaterialCommunityIcons;
        }
        @font-face {
            src: url(${
                require('react-native-vector-icons/Fonts/Ionicons.ttf').default
            });
            font-family: Ionicons;
        }
        @font-face {
            src: url(${
                require('react-native-vector-icons/Fonts/Feather.ttf').default
            });
            font-family: Feather;
        }
    `

    //Create stylesheet
    const style = document.createElement('style');
    style.type = 'text/css';
    if(style.styleSheet){
        style.styleSheet.cssText = iconFontStyles;
    }
    else {
        style.appendChild(document.createTextNode(iconFontStyles));
    }

    //Inject stylesheet
    document.head.appendChild(style);
}