import React from 'react';
import {SafeAreaView, useWindowDimensions} from 'react-native';
import Ionicons from 'react-native-vector-icons/Ionicons';
import Divider from './Divider';
import VStack from './VStack';
import Box from './Box';
import Input from './Input';
import Icon from './Icon';

const MySearchbar = ({placeholder, setValue, resetSearch}) => {
  const {width} = useWindowDimensions();
  const [searchTerm, setSearchTerm] = React.useState('');

  return (
    <SafeAreaView>
      <VStack
        space={5}
        w="100%"
        p={3}
        maxW={width}
        divider={
          <Box px="2">
            <Divider />
          </Box>
        }>
        <VStack w="100%" space={5} alignSelf="center">
          <Input
            placeholder={placeholder}
            onChangeText={(text) => {
              setSearchTerm(text);
              setValue(text);
            }}
            value={searchTerm}
            width="100%"
            borderRadius="4"
            py="3"
            px="1"
            bg="white"
            fontSize="14"
            InputLeftElement={
              <Icon
                onPress={() => {
                  setValue(null);
                  resetSearch();
                }}
                m="2"
                ml="3"
                size="6"
                color="gray.400"
                as={<Ionicons name="arrow-back" />}
              />
            }
            autoFocus
          />
        </VStack>
      </VStack>
    </SafeAreaView>
  );
};

export default MySearchbar;
