// @ts-nocheck
import {AxiosError} from 'axios';
import {useInfiniteQuery, useQuery} from 'react-query';
import {VisitorLogsQueryParams} from '../@types';
import {residentApiWithAuth} from '../api/resident';
import {showErrorMessage} from '../service/flashMessage';
import {getCurrentUnitLink} from '../utils';
import E_QUERY_NAMES from './query-names';

function buildUrl(visitorLogsParams: VisitorLogsQueryParams, unitId: string) {
  let url = `/visitorLogs/unit/${unitId}?pageSize=${visitorLogsParams.pageSize}`;

  if (visitorLogsParams?.dateFrom) {
    url = `${url}&dateFrom=${visitorLogsParams.dateFrom}`;
  }

  if (visitorLogsParams?.dateTo) {
    url = `${url}&dateTo=${visitorLogsParams.dateTo}`;
  }

  if (visitorLogsParams?.thumbHeight) {
    url = `${url}&thumbHeight=${visitorLogsParams.thumbHeight}`;
  }
  if (visitorLogsParams?.thumbWidth) {
    url = `${url}&thumbWidth=${visitorLogsParams.thumbWidth}`;
  }
  if (visitorLogsParams?.searchKey) {
    url = `${url}&searchKey=${visitorLogsParams.searchKey}`;
  }
  return url;
}

export function useVisitorLogsByUnit(
  visitorLogsParams: VisitorLogsQueryParams,
) {
  const currentUnitId = getCurrentUnitLink()?.unit?.id;
  const url = buildUrl(visitorLogsParams, currentUnitId);
  return useQuery(
    [E_QUERY_NAMES.VISITOR_LOGS + currentUnitId, visitorLogsParams],
    async () => {
      const response = await residentApiWithAuth().get(url);
      const data = await response?.data;
      return data?.data;
    },
    {
      onSuccess: (data) => {
        return data;
      },
      onError: (error: AxiosError) => {
        const message = error.response.data.errors[0];
        showErrorMessage(message);
      },
    },
  );
}

export function usePaginatedVisitorLogsByUnit(
  visitorLogsParams: VisitorLogsQueryParams,
) {
  const currentUnitId = getCurrentUnitLink()?.unit?.id;
  const url = buildUrl(visitorLogsParams, currentUnitId);

  return useInfiniteQuery(
    [E_QUERY_NAMES.VISITOR_LOGS + currentUnitId, visitorLogsParams],
    async ({pageParam = 1}) => {
      const paginatedUrl = `${url}&pageNumber=${pageParam}`;

      const response = await residentApiWithAuth().get(paginatedUrl);
      const data = await response?.data;
      return data;
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.nextPage !== null) {
          return lastPage.pageNumber + 1;
        }
        return undefined;
      },
      onSuccess: (data) => {
        return data;
      },
      onError: (error: AxiosError) => {
        const message = error.response.data.errors[0];
        showErrorMessage(message);
      },
    },
  );
}

export function useVisitorLog(logId: string) {
  return useQuery(
    E_QUERY_NAMES.PACKAGE_QUERY + logId,
    async () => {
      const {data} = await residentApiWithAuth().get(`/visitorLogs/${logId}`);
      return data?.data;
    },
    {
      onSuccess: (_) => {},
      onError: (error: AxiosError) => {
        const message = error.response.data.errors[0];
        showErrorMessage(message);
      },
    },
  );
}
