import {residentApiWithAuth} from './resident';

const createParking = async (parking) => {
  try {
    let res = await residentApiWithAuth().post('/parking', parking);
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    const message = error.response.data.errors[0];
    throw new Error(message);
  }
};

const updateParking = async (parking, parkingId) => {
  try {
    let res = await residentApiWithAuth().post(
      `/parking/${parkingId}`,
      parking,
    );
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetchParking = async (parkingId) => {
  try {
    let res = await residentApiWithAuth().get(`/parking${parkingId}`);
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

const deleteParkingById = async (parkingId) => {
  try {
    let res = await residentApiWithAuth().delete(`/parking/${parkingId}`);
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetchParkingByUnit = async (unitId) => {
  try {
    let res = await residentApiWithAuth().get(`/parking/unit/${unitId}`);
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export {
  createParking,
  updateParking,
  fetchParking,
  fetchParkingByUnit,
  deleteParkingById,
};
