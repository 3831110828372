//selected documents location
export const SET_SELECTED_DOCUMENTS_LOCATION =
  'SET_SELECTED_DOCUMENTS_LOCATION';

  export interface SetSelectedDocumentLocation {
    type: typeof SET_SELECTED_DOCUMENTS_LOCATION;
    data: any;
  }

  export const setSelectedDocumentsLocation = data => {
    return dispatch => {
      dispatch({
        type: SET_SELECTED_DOCUMENTS_LOCATION,
        payload: {
          data: data,
        },
      });
    };
  };

export type DocumentDispatchType = | SetSelectedDocumentLocation;


interface DefaultStateI {
    selectedDocumentsLocation: string;
  }

  const defaultState: DefaultStateI = {
    // @ts-ignore
    selectedDocumentsLocation: {
      currentAbsolutePath: '/'
    }
  };

  const documentsReducer = (state: DefaultStateI = defaultState, action: DocumentDispatchType): DefaultStateI => {
    switch(action.type) {
        // @ts-ignore
        case SET_SELECTED_DOCUMENTS_LOCATION:
        return {
            ...state,
             // @ts-ignore
            selectedDocumentsLocation: action.payload.data
        }
        default: {
            return state;
        }
    }
  }

  export default documentsReducer;
  