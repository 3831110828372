// @ts-nocheck
import React, {Component, createRef} from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import isWeb from '../utils/isWeb';
import KeyboardAvoidingHOC from './KeyboardAvoidingHOC';

const scrollviewRef = createRef();

class Content extends Component {
  currentScroll = 0;

  scrollToContent = animated => {
    scrollviewRef.current?.scrollToEnd({animated});
  };

  handleScroll = event => {
    this.currentScroll = event.nativeEvent.contentOffset.y;
    this.props.onScroll && this.props.onScroll(event);
  };

  render() {
    const {children, style, form, fullWidth, ...otherProps} = this.props;
    const {defaultPadding, grow, contentContainerStyle} = this.props;

    //This avoid adding padding to ScrollView, because it breaks everything
    const {padding, paddingTop, paddingLeft, paddingRight, ...otherStyles} =
      style || {};

    return (
      <ScrollView
        {...otherProps}
        keyboardShouldPersistTaps="handled"
        ref={scrollviewRef}
        contentContainerStyle={[
          isWeb && {
            alignSelf: 'stretch',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
          },
          grow && {flexGrow: 1},
        ]}
        onScroll={this.handleScroll}
        scrollEventThrottle={10}
        style={[styles.content, otherStyles]}>
        <View
          style={[
            defaultPadding && {
              // padding: 30,
            },
            isWeb && {
              flex: 1,
              maxWidth: fullWidth ? undefined : form ? 500 : 1140,
            },
            {
              backgroundColor: '#FFFFFF',
            },
            contentContainerStyle,
          ]}>{children}</View>
      </ScrollView>
    );
  }
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
  },
  webContainerStyle: isWeb && {
    maxWidth: 1024,
  },
});

export default KeyboardAvoidingHOC(Content);
