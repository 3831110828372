import {residentApiWithAuth} from './resident';
import {LIMIT_PER_PAGE} from './constants';
import {RequestAccess} from '../@types/RequestAcess';

const fetchDateFrameByPerson = async (pageNumber = 1, unitPeopleLinkId) => {
  try {
    let res = await residentApiWithAuth().get(
      `/unitlinkDateFrames/unitlink/${unitPeopleLinkId}?pageNumber=${pageNumber}&pageSize=${LIMIT_PER_PAGE}`,
    );
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetchDateFramesByPerson = async (pageNumber = 1, unitPeopleLinkId) => {
  try {
    const results = await fetchDateFrameByPerson(pageNumber, unitPeopleLinkId);
    if (results.length >= 15) {
      return results.concat(
        await fetchDateFramesByPerson(pageNumber + 1, unitPeopleLinkId),
      );
    } else {
      return results;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetchDateFrameByUnit = async (
  pageNumber: number = 1,
  unitId: string,
) => {
  try {
    let res = await residentApiWithAuth().get(
      `/dateFrame/unit/${unitId}?pageNumber=${pageNumber}&pageSize=${LIMIT_PER_PAGE}`,
    );
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

const fetchDateFramesByUnit = async (
  pageNumber: number = 1,
  unitId: string,
) => {
  try {
    const results = await fetchDateFrameByUnit(pageNumber, unitId);
    if (results.length >= 15) {
      return results.concat(
        await fetchDateFramesByUnit(pageNumber + 1, unitId),
      );
    } else {
      return results;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

const createDateFrame = async (dateFrame) => {
  try {
    let res = await residentApiWithAuth().post('/dateFrame', dateFrame);
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

const updatePersonDateFrames = async (dateFrame) => {
  try {
    let res = await residentApiWithAuth().post(
      '/unitlinkDateFrames',
      dateFrame,
    );
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    const errorMessage = error.response.data.errors;
    throw new Error(errorMessage);
  }
};

const updateDateFrame = async (dateFrame, dateFrameId) => {
  try {
    let res = await residentApiWithAuth().post(
      `/dateFrame/${dateFrameId}`,
      dateFrame,
    );
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

const getDateFrameById = async (dateFrameId) => {
  try {
    let res = await residentApiWithAuth().get(`/dateFrame/${dateFrameId}`);
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

const createPersonDateFrame = async (personDateFrame) => {
  try {
    let res = await residentApiWithAuth().post(
      '/peopleDateFrame',
      personDateFrame,
    );
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

const dateFrameRequestAccess = async (dateFrameData: RequestAccess) => {
  try {
    let res = await residentApiWithAuth().post(
      '/unitlinkDateFrames/requestaccess',
      dateFrameData,
    );
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error.response?.data?.errors[0]);
  }
};

const deleteUnitLinkDateFrame = async (unitLinkDateFrameId: string) => {
  try {
    let res = await residentApiWithAuth().delete(
      `/unitlinkDateFrames/${unitLinkDateFrameId}`,
    );
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error?.response?.data?.errors[0]);
  }
};

const updateUnitLinkDateFrame = async (
  unitLinkDateFrameId: string,
  unitLinkDateFrame: object,
) => {
  try {
    let res = await residentApiWithAuth().post(
      `/unitlinkDateFrames/${unitLinkDateFrameId}`,
      unitLinkDateFrame,
    );
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    throw new Error(error?.response?.data?.errors[0]);
  }
};

export {
  fetchDateFramesByUnit,
  createDateFrame,
  createPersonDateFrame,
  fetchDateFrameByPerson,
  fetchDateFramesByPerson,
  getDateFrameById,
  updateDateFrame,
  updatePersonDateFrames,
  dateFrameRequestAccess,
  deleteUnitLinkDateFrame,
  updateUnitLinkDateFrame,
};
