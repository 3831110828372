import axios from 'axios';
import { getDeviceName, getUniqueId } from 'react-native-device-info';
import { BASE_URL } from './constants';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { E_STORAGE_KEYS } from '../@types';
import isWeb from '../utils/isWeb';
import { getNewToken } from './people';
import * as Sentry from '@sentry/react-native'

export const residentApi = () => {
  const resident = axios.create({
    baseURL: BASE_URL,
  });

  return resident;
};

let resident = axios.create({
  baseURL: BASE_URL,
});

export const residentApiWithAuth = () => {
  resident.interceptors.request.use(injectAuthHeaders, rejectWithError);

  // Track how often tokens are expiring while user is performing action
  // If it's happening more often then we need to handle renewals better

  resident.interceptors.response.use(
    response => response,
    async error => {
      const originalRequest = error.config;
      if (error.response?.status === 401 && !originalRequest._retry) {
        Sentry.captureException(error)
        return Promise.reject(error);
        //Mark a request as retried to avoid infinite loops
        originalRequest._retry = true;
        try {
          const { token } = getNewToken();
          resident.defaults.headers.common['Authorization'] = `Bearer ${token}`
          return resident(originalRequest);
        } catch (refreshError) {
          AsyncStorage.removeItem(E_STORAGE_KEYS.TOKEN_PERSIST).then(() => {
            dispatch({ type: E_STORAGE_KEYS.CLEAR_REDUX_PERSIST_ROOT });
          });
        }
      }
      return Promise.reject(error);
    }
  )

  if (!isWeb) {
    resident.interceptors.request.use(injectDeviceInfoHeaders, rejectWithError);
  }

  return resident;
};

async function injectAuthHeaders(requestConfig) {
  try {
    const tokenString = await AsyncStorage.getItem(E_STORAGE_KEYS.AUTH_TOKEN);
    const { token } = JSON.parse(tokenString);

    if (token !== null) {
      requestConfig.headers["Authorization"] = `Bearer ${token}`;
    }
  } catch (error) {
  }
  finally {
    return requestConfig;
  }
}

async function injectDeviceInfoHeaders(requestConfig) {
  const uniqueId = await getUniqueId();
  const deviceName = await getDeviceName();

  requestConfig.headers["DeviceName"] = deviceName;
  requestConfig.headers["UniqueId"] = uniqueId;
  requestConfig.headers['UserAgent'] = deviceName;

  return requestConfig;
}

async function rejectWithError(error) {
  return Promise.reject(error);
}
