import {Dispatch} from 'redux';
import {residentApiWithAuth} from '../../api/resident';
import * as RootNavigation from '../../navigation';
import {LIMIT_PER_PAGE} from '../../api/constants';
import {Pagination} from '../../@types/Pagination';
import {Approval} from '../../@types/Approval';
import {showErrorMessage, showSuccessMessage} from '../../service/flashMessage';
import { store } from '../store';

export const APPROVALS_LOADING = 'APPROVALS_LOADING';
export const APPROVALS_FAIL = 'APPROVALS_FAIL';
export const APPROVALS_SUCCESS = 'APPROVALS_SUCCESS';
export const APPROVALS_FILTER = 'APPROVALS_FILTER';
export const APPROVE_LOADING = 'APPROVE_LOADING';
export const APPROVE_FAIL = 'APPROVE_FAIL';
export const APPROVE_SUCCESS = 'APPROVE_SUCCESS';
export const APPROVALS_DELETE_ALL = 'APPROVALS_DELETE_ALL';

export type FilterArguments = {
  unit: string | null;
  approver: string | null;
  type: string | null;
  requestedFrom: Date | null;
  requestedTo: Date | null;
};

export interface ApprovalsLoading {
  type: typeof APPROVALS_LOADING;
}

export interface ApprovalsFail {
  type: typeof APPROVALS_FAIL;
  error: string | object;
}

export interface ApprovalsSuccess {
  type: typeof APPROVALS_SUCCESS;
  approvalsPayload: Approval[];
  paginationPayload: Pagination;
}

export interface ApprovalsFilter {
  type: typeof APPROVALS_FILTER;
  filterArgs: FilterArguments;
}

export interface ApproveLoading {
  type: typeof APPROVE_LOADING;
  id: string;
}

export interface ApproveSuccess {
  type: typeof APPROVE_SUCCESS;
  id: string;
}

export interface ApproveFail {
  type: typeof APPROVE_FAIL;
  error: object | null | string;
  id: string;
}

export interface ApprovalsDeleteAll {
  type: typeof APPROVALS_DELETE_ALL;
  approvalsPayload: Approval[];
  paginationPayload: Pagination;
}

export type ApprovalsDispatchTypes =
  | ApprovalsLoading
  | ApprovalsFail
  | ApprovalsSuccess
  | ApprovalsFilter
  | ApproveLoading
  | ApproveSuccess
  | ApproveFail
  | ApprovalsDeleteAll;

export const GetApprovals =
  (showPending?: boolean) =>
  async (dispatch: Dispatch<ApprovalsDispatchTypes>) => {
    const {filterArgs} = store.getState().approvals;

    try {
      dispatch({type: APPROVALS_LOADING});
      let url = '/approvals';

      if(filterArgs?.unit){
        url = `${url}?unit=$${filterArgs.unit}`;
      }

      if(filterArgs?.type){
        url = `${url}?type=$${filterArgs.type}`;
      }
      if(filterArgs?.approver){
        url = `${url}?approver=$${filterArgs.approver}`;
      }
      if(filterArgs?.requestedFrom){
        url = `${url}?requestedFrom=$${filterArgs.requestedFrom}`;
      }
      if(filterArgs?.requestedTo){
        url = `${url}?requestedTo=$${filterArgs.requestedTo}`;
      }
      if(showPending){
        url = `${url}?pending=${showPending}`;
      }

      let res = await residentApiWithAuth().get(url);
      const result = res.data;
      if (result.errors) {
        throw new Error(result.errors[0]);
      } else {
        let approvals: Array<Approval> = result.data;
        const pagination: Pagination = {
          pageNumber: result.pageNumber,
          pageSize: result.pageSize,
          nextPage: result.nextPage,
          previous: result.previous,
        };

        dispatch({
          type: APPROVALS_SUCCESS,
          approvalsPayload: approvals,
          paginationPayload: pagination,
        });
      }
    } catch (error) {
      dispatch({type: APPROVALS_FAIL, error: error});
    }
  };

export const FilterApprovals =
  (filterArgs: FilterArguments) =>
  async (dispatch: Dispatch<ApprovalsDispatchTypes>) => {
    dispatch({type: APPROVALS_FILTER, filterArgs});
  };

export const NavigateToApprovalDetails = (id: string) => async () => {
  RootNavigation.navigate('Approval Detail', {
    id,
  });
};

export const Approve =
  (id: string, approve: boolean) =>
  async (dispatch: Dispatch<ApprovalsDispatchTypes>): Promise<any> => {
    dispatch({type: APPROVE_LOADING, id});
    try {
      const res = await residentApiWithAuth().post('/approvals', {
        id,
        approve,
      });
      const result = res.data;
      if (result.errors) {
        throw new Error(result.errors[0]);
      } else {
        showSuccessMessage('Your response has been sent');
      }
    } catch (error) {
      const message = error.response.data.errors[0];

      showErrorMessage(message);
    }
  };

export const DeleteAllApprovals =
  () => async (dispatch: Dispatch<ApprovalsDispatchTypes>) => {
    dispatch({
      type: APPROVALS_DELETE_ALL,
      paginationPayload: {
        pageNumber: 1,
        pageSize: 15,
        nextPage: null,
        previous: null,
      },
      approvalsPayload: [],
    });
  };

interface DefaultStateI {
  loading: boolean;
  error?: string | object;
  approvals?: Approval[];
  pageSize: number;
  pageNumber: number;
  nextPage: string | null;
  previous: string | null;

  filterArgs?: FilterArguments;
  approvalsHolder: Approval[];
  responding?: boolean;
}

const defaultState: DefaultStateI = {
  loading: true,
  approvals: [],
  approvalsHolder: [],
  pageSize: LIMIT_PER_PAGE,
  pageNumber: 1,
  nextPage: null,
  previous: null,
};

const approvalsReducer = (
  state: DefaultStateI = defaultState,
  action: ApprovalsDispatchTypes,
): DefaultStateI => {
  switch (action.type) {
    case APPROVALS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case APPROVALS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case APPROVALS_SUCCESS:
      //discard duplicates
      // if incoming approvalId is already existing in the array, remove it
      return {
        loading: false,
        approvals: [...action.approvalsPayload],
        ...action.paginationPayload,
        pageNumber:
          action.paginationPayload.nextPage !== null
            ? state.pageNumber + 1
            : null,
        approvalsHolder: [
          ...state.approvalsHolder.concat(action.approvalsPayload),
        ],
      };
    case APPROVALS_FILTER:
      let newApprovals = state.approvalsHolder;
      const {unit, approver, type, requestedFrom, requestedTo} =
        action.filterArgs;
      // if (unit) {
      //   newApprovals = newApprovals?.filter(
      //     (approval) => approval.unitId === unit,
      //   );
      // }
      if (type) {
        newApprovals = newApprovals?.filter((approval) => {
          return approval.type === type;
        });
      }
      return {
        ...state,
        loading: false,
        filterArgs: action.filterArgs,
        approvals: newApprovals,
      };
    case APPROVALS_DELETE_ALL:
      return {
        ...state,
        loading: false,
        approvals: action.approvalsPayload,
        approvalsHolder: action.approvalsPayload,
        ...action.paginationPayload,
      };
    default:
      break;
  }
  return state;
};

export default approvalsReducer;
