import {DateTimePicker, TimePicker} from '@mui/x-date-pickers';
import React from 'react';
import Text from './Text';
import {styles} from '../styles';
import dayjs from 'dayjs';

const DateTimeInput = (props: {
  label: string;
  description: string | null;
  value: Date;
  onConfirm: (date: Date | string) => void;
  mode: "date" | "time" | "datetime"
}) => {
  return (
    <>
      <Text style={[styles.textInputLabel, {marginTop: 8}]}>{props.label}</Text>
      {props.mode === "date" && <DateTimePicker
        value={dayjs(props.value)}
        onAccept={date => props.onConfirm(date.toDate())}
        ampm={false}
      />
      }
      {
        props.mode === "time" && <TimePicker
        value={dayjs(props.value)}
        onAccept={date => props.onConfirm(date.toDate().toLocaleTimeString("en-GB"))}
        ampm={false}
        />
      }
    </>
  );
};

export default DateTimeInput;
