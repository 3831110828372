// @ts-nocheck
import React, {useState} from 'react';
import {ScrollView, View} from 'react-native';
import Text from '../../../components/Text';
import {ActivityIndicator, Card, Divider} from 'react-native-paper';
import tailwind from 'tailwind-rn';
import {styles} from '../../../styles';
import {Navbar} from '../../../components';
import {useEffect} from 'react';
import {showErrorMessage} from '../../../service/flashMessage';
import {residentApiWithAuth} from '../../../api/resident';
import {Parking} from '../types';
import Container from '../../../components/Container';
import Content from '../../../navigation/Content';

const ParkingDetail = ({navigation, route}) => {
  const {parkingId} = route.params;
  const [isLoading, setIsLoading] = useState(false);
  const [parking, setParking] = useState<Parking>({});

  const fetchParking = async (id: string) => {
    try {
      let res = await residentApiWithAuth().get(`/parking/${id}`);
      const result = res.data;
      if (result.errors) {
        throw new Error(result.errors[0]);
      } else {
        setParking(result.data);
        setIsLoading(false);
      }
    } catch (error) {
      showErrorMessage(error);
    }
  };

  useEffect(() => {
    fetchParking(parkingId);
  }, [parkingId]);

  return (
    <Container>
      <Navbar navigation={navigation} title="Parking details" />
      {isLoading ? (
        <ActivityIndicator />
      ) : (
        <>
          <ScrollView
            style={tailwind('mx-2 py-4')}
            showsVerticalScrollIndicator={false}>
            <Card style={[tailwind('p-2 mb-8')]}>
              <View style={tailwind('flex-1 flex-row justify-between')} />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Name</Text>
                <Text style={styles.detailsText}>{parking.name}</Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Dateframe start date</Text>
                <Text style={styles.detailsText}>
                  {parking?.dateFrame?.startDate}
                </Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Dateframe end date</Text>
                <Text style={styles.detailsText}>
                  {parking?.dateFrame?.endDate}
                </Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row my-3')}>
                <Text style={styles.textLabel}>Dateframe description</Text>
                <Text style={styles.detailsText}>
                  {parking?.dateFrame?.description}
                </Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Vehicle in space</Text>
                <Text style={styles.detailsText}>{parking.vehicleInSpace}</Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Parking space</Text>
                <Text style={styles.detailsText}>{parking.parkingSpace}</Text>
              </View>
              <Divider />
              <View style={tailwind('flex-row  my-3')}>
                <Text style={styles.textLabel}>Notes</Text>
                <Text style={styles.detailsText}>{parking.notes}</Text>
              </View>
            </Card>
          </ScrollView>
        </>
      )}
    </Container>
  );
};

export default ParkingDetail;
