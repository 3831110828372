import {Dispatch} from 'redux';
import {residentApiWithAuth} from '../../api/resident';
import {showErrorMessage} from '../../service/flashMessage';
import {store} from '../store';
import {ListPeopleType} from '../../@types/ListPeopleType';

export const LIST_PEOPLE_TYPES_LOADING = 'LIST_PEOPLE_TYPES_LOADING';
export const LIST_PEOPLE_TYPES_FAIL = 'LIST_PEOPLE_TYPES_FAIL';
export const LIST_PEOPLE_TYPES_SUCCESS = 'LIST_PEOPLE_TYPES_SUCCESS';

export interface ListPeopleTypesLoading {
  type: typeof LIST_PEOPLE_TYPES_LOADING;
}

export interface ListPeopleTypesFail {
  type: typeof LIST_PEOPLE_TYPES_FAIL;
  error: string | object;
}

export interface ListPeopleTypesSuccess {
  type: typeof LIST_PEOPLE_TYPES_SUCCESS;
  payload: ListPeopleType[];
}

export type ListPeopleTypesDispatchTypes =
  | ListPeopleTypesLoading
  | ListPeopleTypesFail
  | ListPeopleTypesSuccess;

export const GetListPeopleTypes =
  () => async (dispatch: Dispatch<ListPeopleTypesDispatchTypes>) => {
    try {
      dispatch({type: LIST_PEOPLE_TYPES_LOADING});
      const defaultLink = store
        .getState()
        .units.unitLinks.filter((unitLink) => unitLink.isDefault === true)[0];

      const res = await residentApiWithAuth().get(
        `/listPeopleType/site/${defaultLink?.unit.site?.id}?unitStatus=${defaultLink.unit.status}`,
      );

      dispatch({type: LIST_PEOPLE_TYPES_SUCCESS, payload: res.data.data});

    } catch (error) {
      dispatch({type: LIST_PEOPLE_TYPES_FAIL, error: error});
      showErrorMessage(error.message);
    }
  };

interface DefaultStateI {
  loading: boolean;
  error?: string | object;
  listPeopleTypes?: ListPeopleType[];
}

const defaultState: DefaultStateI = {
  loading: true,
  listPeopleTypes: [],
};

const listPeopleTypesReducer = (
  state: DefaultStateI = defaultState,
  action: ListPeopleTypesDispatchTypes,
): DefaultStateI => {
  switch (action.type) {
    case LIST_PEOPLE_TYPES_FAIL:
      return {
        loading: false,
        error: action.error,
        listPeopleTypes: [],
      };
    case LIST_PEOPLE_TYPES_LOADING:
      return {
        loading: true,
        listPeopleTypes: [],
      };
    case LIST_PEOPLE_TYPES_SUCCESS:
      return {
        loading: false,
        listPeopleTypes: action.payload,
      };
    default:
      break;
  }
  return state;
};

export default listPeopleTypesReducer;
