import React from 'react';
import Input from './Input';
import Text from '../components/Text';
import {styles} from '../styles';
import {useColorScheme} from 'react-native';

const DisabledInput = ({title, value}) => {
  const colorScheme = useColorScheme();

  return (
    <>
      <Text style={styles.textInputLabel}>{title}</Text>
      <Input
        value={value ? value : ''}
        editable={false}
        isDisabled
        _disabled={{
          backgroundColor: '#e4e4e7' ,
        }}
      />
    </>
  );
};

export default DisabledInput;
