import {RootStackParamList} from './Navigation';
import {User} from './User';
import {PartialUnit} from './PartialUnit';
import {Alert} from './Alert';
import {Pet} from './Pet';
import {Storage} from './Storage';
import {Delivery} from './Delivery';
import {Package, PackageLog} from './Package';
import {VisitorLog} from './VisitorLog';
import {VisitorLogsQueryParams} from './VisitorLogsQueryParams';
import {ListEvent} from './ListEvent';
import {ListEventsQueryParams} from './ListEventsQueryParams';
import {KeyLogs} from '../modules/Keylogs/screens';
import {PackageHistoryQueryParams} from './PackageHistoryQueryParams';

export type {
  RootStackParamList,
  User,
  PartialUnit,
  Alert,
  Pet,
  Storage,
  Delivery,
  Package,
  VisitorLog,
  VisitorLogsQueryParams,
  ListEvent,
  ListEventsQueryParams,
  PackageLog,
  KeyLogs,
  PackageHistoryQueryParams,
};

export enum E_PERMISSIONS {
  PEOPLE = 'People',
  UNIT = 'Unit',
  VEHICLE = 'Vehicle',
  PET = 'Pet',
  STORAGE = 'Storage',
  PARKING = 'Parking',
  VIOLATION = 'Violation',
  DELIVERY = 'Delivery',
  GATE_CARD = 'Gate cards',
}

export enum E_NAVIGATION_DESTINATIONS {
  DELIVERIES = 'Deliveries',

  PACKAGES = 'Unclaimed Packages',
  PACKAGES_HISTORIES = 'Packages History',
  PACKAGES_HISTORIES_LIST = 'Packages History List',
  PACKAGE_DETAILS = 'Package Details',

  VISITOR_LOGS = 'Visitor Logs',
  VISITOR_LOGS_LIST = 'Visitor Logs List',
  VISITOR_LOG_DETAILS = 'Visitor Log Details',

  ALERTS_PREFERENCES = 'Alerts Preferences',
  PICTURE_ZOOM = 'Picture Zoom',

  KEY_LOGS = 'Key Logs',
  KEY_LOGS_LIST = 'Key Logs List',
  KEY_LOG_DETAILS = 'Key Log Details',

  EDIT_OWN_UNITLINK = 'Edit Own UnitLink',

  DOOR_LOGS = 'Door Logs',
  DOOR_LOGS_LIST = 'Door Logs List',
  DOOR_LOG_DETAILS = 'Door Log Details',

  ADD_UNIT_WITH_BARCODE = 'Add unit with barcode',
  ID_CARD = 'ID Card',

  SETTINGS = 'Settings',

  VEHICLES = 'Vehicles',
  VEHICLES_LIST = 'Vehicles List',
  VEHICLE_ADD = 'Add Vehicle',
  VEHICLE_EDIT = 'Edit Vehicle',
  VEHICLE_DETAILS = 'Vehicle Detail',

  PETS = 'Pets',
  PETS_LIST = 'Pets List',
  PET_ADD = 'Add Pet',
  PET_EDIT = 'Edit Pet',
  PET_DETAILS = 'Pet Detail',

  STORAGES = 'Storages',
  STORAGES_LIST = 'Storages List',
  STORAGE_ADD = 'Add Storage',
  STORAGES_EDIT = 'Edit Storage',
  STORAGES_DETAILS = 'Storage Detail',

  PARKINGS = 'Parkings',
  PARKINGS_LIST = 'Parkings List',
  PARKING_ADD = 'Add Parking',
  PARKING_EDIT = 'Edit Parking',
  PARKING_DETAILS = 'Parking Detail',

  GATE_CARDS = 'Gate Cards',
  GATE_CARDS_LIST = 'Gate Cards List',
  GATE_CARD_DETAILS = 'Gate Card Detail',

  VIOLATIONS = 'Violations',
  VIOLATIONS_LIST = 'Violations List',
  VIOLATIONS_ADD = 'Add Violation',
  VIOLATIONS_EDIT = 'Edit Violation',
  VIOLATIONS_DETAILS = 'Violation Detail',

  DIRECTORY_SEARCH = 'Search',
  EDIT_DIRECTORY_SETTINGS = 'Edit Directory Settings',

  NOTIFICATION_SETTINGS = 'Notification settings',
  ABOUT = 'About',
  HOWTO = 'How to',

  EDIT_PROFILE = 'Edit Profile',
  EDIT_UNIT = 'Edit Unit',
  NOTIFICATION_DETAILS = 'Notification Details',
  OTHERS = 'Others',
  UNIT_CALENDAR ="Unit Calendar",
  USER_PERMISSIONS = 'User Permissions',
  DELIVERY_DETAILS = 'Delivery detail',
  ADD_DELIVERY = 'Add delivery',
  ADD_WITH_UNITCODE = 'Add with UnitCode',

  CREATE_PEOPLE_AND_LINK = 'Create People and Link',

  DOCUMENTS_LIST = 'Documents List',
  DOCUMENTS = 'Documents',
  FILE_VIEWER = 'File Viewer',
  PDF_VIEWER = 'Pdf Viewer',

  CONFIRIRMATION_CODES_ENTRY = 'Confirmation Codes Entry'
}

export enum E_STORAGE_KEYS {
  REDUX_PERSIST_ROOT = '@redux_persist_root',
  CLEAR_REDUX_PERSIST_ROOT = '@redux_persist_root/clear',
  NAVIGATION_PERSIST = '@navigation_state',
  TOKEN_PERSIST = '@token_persist',
  DEVICE_INFO = '@device_info',
  PUSHY_DEVICE_TOKEN = '@push_device_token',
  AUTH_TOKEN = '@auth_token',
  USER = '@user'
}

export enum E_STORE_NAMES {
  PACKAGES = 'packages',
}
