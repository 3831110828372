import {Approval} from '../@types/Approval';

export const buildApprovalMessage = (approvalObj: Approval) => {
  const residentApprovalDate = approvalObj?.residentApprovalDateTime
    ? new Date(approvalObj.residentApprovalDateTime).toLocaleDateString()
    : 'Date not available';
  const managementApprovalDate = approvalObj?.managementApprovalDateTime
    ? new Date(approvalObj.managementApprovalDateTime).toLocaleDateString()
    : 'Date not available';

  const residentMessage =
    approvalObj.residentApprovalStatus === 'Approved'
      ? `Unit approval by ${approvalObj.residentApprovalName} on ${residentApprovalDate} .`
      : `Unit approval ${approvalObj.residentApprovalStatus} .`;
  const managementMessage =
    approvalObj.managementApprovalStatus === 'Approved'
      ? `Management approval by ${approvalObj.managementApprovalName} on ${managementApprovalDate} .`
      : `Management approval ${approvalObj.managementApprovalStatus} .`;

  return {residentMessage, managementMessage};
};
