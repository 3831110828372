import React, {useState} from 'react';
import {ScrollView} from 'react-native';
import Container from '../../components/Container';
import useColorModeValue from '../../components/useColorModeValue';
import Text from '../../components/Text';
import Button from '../../components/Button';
import {Navbar} from '../../components';
import tailwind from 'tailwind-rn';
import {Accordion, Box, HStack, View} from 'native-base';
import InviteCodeGenerator from './components/InviteCodeGenerator';
import {useSelector} from 'react-redux';
import {RootStore} from '../../redux/store';

const HowTo = ({navigation}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const currentUnitLink = useSelector(
    (state: RootStore) =>
      state.units.unitLinks.filter(link => link.isDefault === true)[0],
  );

  return (
    <Container>
      <Navbar navigation={navigation} title="How to" />
      <ScrollView>
        <Box
          bg={useColorModeValue('gray.50', 'gray.700')}
          style={tailwind('mx-4 mt-4')}>
          <Accordion>
            <Accordion.Item>
              <Accordion.Summary>Getting Started</Accordion.Summary>
              <Accordion.Details>
                <Text>
                  Congratulations, if you are reading this you have downloaded
                  the app, successfully built a profile, and signed in. If this
                  is your first time in and do not have any units you will be in
                  the select Unit Page.
                </Text>
                <Text style={{marginTop: 8}}>
                  Unless you live or work in a community that uses the Asterix
                  Software, this is as far as you go. As was
                  explained in the app description, this is required. But if you
                  have a Welcome Message or a unit to link to, you're in great
                  shape:
                </Text>
                <Text style={{marginTop: 8}}>
                  - Use one of the large blue buttons to Link a Unit through
                  your Welcome Message Code.
                </Text>
                <Text>
                  - Choose to click the button in the email if your reading the
                  email from your phone or tablet.
                </Text>
                <Text> - Or use the Q code </Text>
                <Text> - or just type in the code. </Text>
                <Text> - Your unit is now linked.</Text>
              </Accordion.Details>
            </Accordion.Item>
            <Accordion.Item>
              <Accordion.Summary>Menu Options</Accordion.Summary>
              <Accordion.Details>
                <Text style={{marginBottom: 8}}>
                  Once signed in you are asked to select your default unit (the
                  one you are at or want to work on). The menu choices are on
                  the top and bottom of the screen.
                </Text>
                <Text>-Visual id card - click here to show your id card</Text>
                <Text>-Search - if you have many units</Text>
                <Text>-Plus - To allow you add a unit</Text>
                <Text>
                  -Dot menu - Profile/Others/Generate Invite Code/Mark alerts as
                  read/Switch unit/Sign Out
                </Text>
              </Accordion.Details>
            </Accordion.Item>
            <Accordion.Item>
              <Accordion.Summary>
                Get added to a unit - Generate Link Code
              </Accordion.Summary>
              <Accordion.Details>
                <Text>The simplest way to get added to a unit is to:</Text>
                <Text>1. Create your profile here (you did that).</Text>
                <Text>2. Go to the Others Page (bottom Menu).</Text>
                <Text>
                  3. In the Extras section click the Generate invite code.Or
                  click 'Generate Link Code' button below.
                </Text>
                <Text>
                  4. Get the code to the Owner/ Tenant or office to allow then
                  to easily add you to the unit (might require office approval).
                </Text>
                <Button
                  onPress={() => setModalVisible(!modalVisible)}
                  variant="outline"
                  mt={4}>
                  Generate Link Code
                </Button>
              </Accordion.Details>
            </Accordion.Item>
            <Accordion.Item>
              <Accordion.Summary>
                Add Unit with Invite code - Received Welcome Message
              </Accordion.Summary>
              <Accordion.Details>
                <Text>
                  With a Welcome Message, you have received the code you need to
                  link to the unit, If you have no units linked yet, use one of
                  the large blue buttons to link to a Unit through your Welcome
                  Message Code.
                </Text>
                <Text style={{marginTop: 8}}>
                  -Choose to click the button in the email if your reading the
                  email from your phone or tablet.
                </Text>
                <Text>
                  -Or use the QR code with your device camera or just type in
                  the code.
                </Text>
                <Text>
                  -Select your name your name and your unit is now linked.
                </Text>
                <Button
                  variant="outline"
                  mt={4}
                  onPress={() => navigation.navigate('Add with UnitCode')}>
                  Go
                </Button>
              </Accordion.Details>
            </Accordion.Item>
            <Accordion.Item>
              <Accordion.Summary>
                Add a Person to your Unit - with Link Code or Manually
              </Accordion.Summary>
              <Accordion.Details>
                <Text>
                  You have to choices, as the saying goes the easy way and the
                  not as easy way.
                </Text>
                <Text style={{marginVertical: 4}}>
                  The easy way is get them to generate a link code, (from their
                  app) for you (instruction are in this list) Type or Paste it
                  into the box bellow (text box). After doing this you will see
                  their information (make sue this is the right person) and you
                  have to assign a People Type for them. This People Type
                  determines there permissions (what they can do). Almost
                  everyone you add here should be a Visitor or Vendor, lowest
                  permission, they cannot see or do anything in your unit, other
                  than themselves.
                </Text>
                <Text>
                  The less easy way: Type it in manually. This is their name,
                  phone and email if you have it (makes contacting them later
                  easier).
                </Text>
                <Text style={{fontWeight: 'bold', marginVertical: 8}}>
                  Why link someone to your unit
                </Text>
                <Text>
                  Why would you want to add someone to your unit? It is a simple
                  way to keep track of the vendors, guests and domestic help for
                  your unit, along with their contact information, phone and
                  email. It also makes it easy to grant them entrance permission
                  to speed their entry through the front gate. If you do not
                  want them to have access anymore, delete them, all done, they
                  are out.
                </Text>
                <Text style={{marginVertical: 4}}>
                  Why would someone want to be added to your unit. Easier to get
                  through the gate, when visiting your unit will appear linked
                  to them and if you have given them permission to enter, they
                  will not slow the line by having to wait for you to authorize
                  them. When they are let in the gate, you will receive an app
                  message (right here, Alerts tab) and a text message. They can
                  also check to see if they are authorized for today, or
                  tomorrow, and if not they can request access from you. You
                  will get an Approvals message that you can approve with a
                  single click.
                </Text>
                <HStack>
                  <Button
                    variant="outline"
                    mt={4}
                    flex={1}
                    onPress={() => navigation.navigate('Add with UnitCode')}>
                    Add with code
                  </Button>
                  <View style={{width: 12}} />
                  <Button
                    variant="outline"
                    mt={4}
                    flex={1}
                    onPress={() =>
                      navigation.navigate('Create People and Link', {
                        unitId: currentUnitLink.unit.id,
                      })
                    }>
                    Add manually
                  </Button>
                </HStack>
              </Accordion.Details>
            </Accordion.Item>
            <Accordion.Item>
              <Accordion.Summary>
                Invite a person to install the app and link - New event
              </Accordion.Summary>
              <Accordion.Details>
                <Text>
                  Coming soon. Welcome to my unit messages from residents
                </Text>
                <Button
                  variant="outline"
                  mt={4}
                  onPress={() => navigation.navigate('Add with UnitCode')}>
                  Go
                </Button>
              </Accordion.Details>
            </Accordion.Item>
            <Accordion.Item>
              <Accordion.Summary>Your profile</Accordion.Summary>
              <Accordion.Details>
                <Text>Description of your profile</Text>
                <Button
                  variant="outline"
                  mt={4}
                  onPress={() => navigation.navigate('Edit Profile')}>
                  Go
                </Button>
              </Accordion.Details>
            </Accordion.Item>
            <Accordion.Item>
              <Accordion.Summary>Check unit history</Accordion.Summary>
              <Accordion.Details>
                <Text>Description of Check unit history</Text>
                {/* <Button onPress={() => console.log('----> Check unit history')}>
                  Go
                </Button> */}
              </Accordion.Details>
            </Accordion.Item>
            <Accordion.Item>
              <Accordion.Summary>I have a pending Approval</Accordion.Summary>
              <Accordion.Details>
                <Text>I have a pending Approval</Text>
                <Button
                  mt={4}
                  variant="outline"
                  onPress={() =>
                    navigation.navigate('Alerts', {
                      activeTab: 'Pending Approvals',
                    })
                  }>
                  Go
                </Button>
              </Accordion.Details>
            </Accordion.Item>
            <Accordion.Item>
              <Accordion.Summary>Sign Out</Accordion.Summary>
              <Accordion.Details>
                <Text>Description for Sign Out</Text>
                <Button mt={4} variant="outline">
                  Go
                </Button>
              </Accordion.Details>
            </Accordion.Item>
          </Accordion>
        </Box>
      </ScrollView>
      <InviteCodeGenerator
        modalVisible={modalVisible}
        closeModal={() => setModalVisible(false)}
      />
    </Container>
  );
};

export default HowTo;
