import {UnitLink} from '../../@types/UnitLink';

export const deriveDirectoryFromState = (state: UnitLink) => {
  return {
    directoryOptOut: state.directoryOptOut,
    directoryScope: state.directoryScope,
    directoryDoNotShareEmail: state.directoryDoNotShareEmail,
    directoryDoNotSharePhone1: state.directoryDoNotSharePhone1,
    relationship: state.relationship,
    officeNotes: state.officeNotes,
    guardNotes: state.guardNotes,
    alwaysCall: state.alwaysCall,
    getNotifications: state.getNotifications,
  };
};
