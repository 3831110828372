// @ts-nocheck
import React, {useState} from 'react';
import {Pressable, View} from 'react-native';
import Text from '../../../components/Text';
import {Card} from 'react-native-paper';
import tailwind from 'tailwind-rn';
import {styles} from '../../../styles';
import {
  deriveDisplayName,
  deriveUnitName,
} from '../../../utils/deriveDisplayName';
import {useSelector} from 'react-redux';
import {
  showInformationMessage,
  showSuccessMessage,
} from '../../../service/flashMessage';
import {permissionToBool, toLocalDateTime} from '../../../utils';
import {RootStore} from '../../../redux/store';
import {UnitLink} from '../../../@types/UnitLink';
import {EventNames} from '../../../@types/EventNames';
import {useQueryClient} from 'react-query';
import Menu from '../../../components/Menu';
import Actionsheet from '../../../components/Actionsheet';
import useDisclose from '../../../components/useDisclose';
import Box from '../../../components/Box';
import {ToggleAutomatedCalls} from '../unitsReducer';
import {useAppDispatch} from '../../../hooks/redux';
import {
  ApprovalStatus,
  LoadingSpinner,
  RequireApproval,
} from '../../../components';
import Ionicons from 'react-native-vector-icons/Ionicons';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import ZoomableImage from '../../../components/ZoomableImage';
import {E_CONFIRM_MODES} from '../types';
import { buildTodayAlertArgs, buildTommorowAlertArgs } from '../utils';

type SelectDay = {
  alertArguments: string;
  eventName: EventNames;
  personId: string;
  image: string;
  unitId: string;
  startDate: string;
  endDate: string;
  description: string;
};

interface I_UnitListItemProps {
  unitPeopleLink: UnitLink;
  onOpenCalendar: () => void;
  onSelectRemoveSelf: () => void;
  onSelectUnitCode: () => void;
  selectUnitHandler: () => void;
  onSelectEdit: () => void;
  onSelectDay: (args: SelectDay) => void;
  onSelectCustom: () => void;
  onClickApprovalsRequired: () => void;
  onOpenPermissions: () => void;
  onSelectEditUnitLink: () => void;
  onSelectEditUnit: () => void;
}

const UnitListItem = (props: I_UnitListItemProps) => {
  const {
    unitPeopleLink,
    onOpenCalendar,
    onSelectRemoveSelf,
    selectUnitHandler,
    onSelectDay,
    onSelectCustom,
    onClickApprovalsRequired,
    onOpenPermissions,
    onSelectEditUnit,
  } = props;
  const imageUrl = `data:image/jpeg;base64,${unitPeopleLink.unit.unitPicture}`;

  const person = useSelector((allState: RootStore) => allState.auth.user);
  const personId = person.id;
  const personName = deriveDisplayName(person);
  const profilePicture = unitPeopleLink.person.profilePicture;
  const unitName = unitPeopleLink.unit.unitName;

  const {todayArguments, todayEndDate, todayStartDate, todayDescription} =
    buildTodayAlertArgs(personName, unitName);

  const {
    tommorowArguments,
    tommorowStartDate,
    tommorowEndDate,
    tommorowDescription,
  } = buildTommorowAlertArgs(personName, unitName);

  const [confirmDialogShown, setConfirmDialogShown] = useState(false);
  const [confirmMode, setConfirmMode] = useState<E_CONFIRM_MODES>(null);

  const {isOpen, onOpen, onClose} = useDisclose();

  const message = unitPeopleLink.unit.allowAutomatedCalls
    ? 'disable'
    : 'enable';
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const [automatedCallsOpen, setAutomatedCallsOpen] = useState(false);
  const onAutomatedCallsClose = () => {
    setAutomatedCallsOpen(false);
  };
  const onAutomatedCallsConfirm = () => {
    setLoading(true);
    dispatch(
      ToggleAutomatedCalls(
        unitPeopleLink.unit.id,
        !unitPeopleLink.unit.allowAutomatedCalls,
      ),
    )
      .then(() =>
        showSuccessMessage(
          `Automated calls has been ${
            !unitPeopleLink.unit.allowAutomatedCalls ? 'enabled' : 'disabled'
          }`,
        ),
      )
      .finally(() => {
        setLoading(false);
        if (
          !unitPeopleLink.unit.allowAutomatedCalls &&
          permissionToBool(unitPeopleLink?.unitPermission?.charAt(1))
        ) {
          setEditDialogOpen(true);
        }
      });
  };

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const onEditDialogClose = () => {
    setEditDialogOpen(false);
  };
  const onEditDialogConfirm = () => {
    onSelectEditUnit();
  };

  const onNoPressed = () => {
    console.log('Cancel Pressed');
  };

  const isApproved =
    unitPeopleLink.unitLinkApproved && unitPeopleLink.peopleLinkApproved;
  const queryClient = useQueryClient();

  const handleApproved = () => {
    queryClient.invalidateQueries();
    selectUnitHandler();
  };

  const onConfirmRequestAccess = () => {
    if (confirmMode === E_CONFIRM_MODES.TODAY) {
      if (unitPeopleLink.dateFrameDay.allowedToday) {
        showInformationMessage('You already have access for today');
      } else {
        onSelectDay({
          alertArguments: todayArguments,
          eventName: EventNames.REQUEST_ACCESS,
          personId,
          image: profilePicture,
          unitId: unitPeopleLink.unit.id,
          startDate: todayStartDate,
          endDate: todayEndDate,
          description: todayDescription,
        });
      }
    } else if (confirmMode === E_CONFIRM_MODES.TOMMOROW) {
      if (unitPeopleLink.dateFrameDay.allowedTomorrow) {
        showInformationMessage('You already have access for tommorow');
      } else {
        onSelectDay({
          alertArguments: tommorowArguments,
          eventName: EventNames.REQUEST_ACCESS,
          personId,
          image: profilePicture,
          unitId: unitPeopleLink.unit.id,
          startDate: tommorowStartDate,
          endDate: tommorowEndDate,
          description: tommorowDescription,
        });
      }
    }
  };

  return (
    <Card
      style={tailwind(`${isApproved ? '' : 'bg-gray-400'} rounded m-2`)}
      onPress={() =>
        isApproved
          ? handleApproved()
          : showInformationMessage(
              'Sorry, you are not approved to access this unit',
            )
      }>
      <View
        style={tailwind(
          `rounded ${
            unitPeopleLink.isDefault ? 'border-2 border-green-400' : ''
          }`,
        )}>
        <View style={tailwind('flex-row justify-between items-center')}>
          <ZoomableImage
            title={deriveUnitName(unitPeopleLink)}
            style={styles.unitsCardImage}
            source={
              unitPeopleLink.unit.unitPicture
                ? {uri: imageUrl}
                : 
                require('../../../images/unit.jpeg')
            }
            message='This is your people picture - go to people find your name, edit and upload image'
          />
          <View style={tailwind('flex-1 ml-2 flex-col justify-around')}>
            {unitPeopleLink.isDefault && (
              <Text
                numberOfLines={1}
                style={tailwind('text-green-500 text-xs flex-1 mt-1')}>
                DEFAULT
              </Text>
            )}
            <Text
              numberOfLines={1}
              style={tailwind('text-xs rounded-full mt-2')}>
              {unitPeopleLink.peopleType.id
                ? unitPeopleLink?.peopleType?.name.toUpperCase()
                : 'UNKNOWN'}
            </Text>
            <Text style={tailwind(' mt-1 text-lg')}>
              {deriveUnitName(unitPeopleLink)}
            </Text>
            <Text numberOfLines={1}>
              {unitPeopleLink.unit.building
                ? unitPeopleLink.unit.building.name
                : ''}
            </Text>
            <Text numberOfLines={1}>
              {unitPeopleLink.unit.community
                ? unitPeopleLink.unit.community.name
                : ''}
            </Text>
            <Text>
              {unitPeopleLink.unit.addressLine1
                ? unitPeopleLink.unit.addressLine1
                : unitPeopleLink.unit.addressLine2}
            </Text>
            <Text>{unitPeopleLink.unit.state}</Text>
            <Text>{unitPeopleLink.unit.zip}</Text>
            {!isApproved && <ApprovalStatus unitPeopleLink={unitPeopleLink} />}
            <View style={tailwind('flex-row my-1')}>
              <View
                style={tailwind(
                  `bg-${
                    unitPeopleLink.dateFrameDay.allowedToday ? 'green' : 'red'
                  }-400 rounded-full`,
                )}>
                <Text
                  style={tailwind('text-xs text-white mx-1 mr-1 mt-1')}
                  numberOfLines={1}>
                  Today
                </Text>
              </View>
              <View
                style={tailwind(
                  `bg-${
                    unitPeopleLink.dateFrameDay.allowedTomorrow
                      ? 'green'
                      : 'red'
                  }-400 rounded-full mx-1 mr-1`,
                )}>
                <Text
                  style={tailwind('text-xs  text-white px-1 mr-1 p-1')}
                  numberOfLines={1}>
                  Tommorow
                </Text>
              </View>
            </View>
            <RequireApproval
              approvals={unitPeopleLink.approvals}
              onClickApprovalsRequired={() => onClickApprovalsRequired()}
            />
          </View>
          {isApproved && (
            <Menu
              placement="right top"
              trigger={triggerProps => {
                return (
                  <Pressable
                    {...triggerProps}
                    style={tailwind(
                      `${isApproved ? 'pt-4' : 'pt-16'} pr-4 pl-2`,
                    )}>
                    <Ionicons name="ellipsis-vertical" size={22} />
                  </Pressable>
                );
              }}>
              {unitPeopleLink.allowedToRequestAccess && (
                <Menu.Item onPress={() => onOpen()}>Request Access</Menu.Item>
              )}
              <Menu.Item onPress={() => onOpenCalendar()}>Calendar</Menu.Item>
              {permissionToBool(unitPeopleLink?.unitPermission?.charAt(1)) && (
                <Menu.Item onPress={() => onSelectEditUnit()}>
                  Edit Unit
                </Menu.Item>
              )}
              <Menu.Item onPress={() => onOpenPermissions()}>
                Permissions
              </Menu.Item>
              {unitPeopleLink.peopleType.residentCanAdd && (
                <Menu.Item onPress={() => onSelectRemoveSelf()}>
                  Remove Self
                </Menu.Item>
              )}
              {/* Show this open only when automated calls are disabled in building
               *  Don't allow disabling it.
               */}
              {/* {
                // !!unitPeopleLink.unit.building.noAutomatedCalling
                permissionToBool(unitPeopleLink?.unitPermission?.charAt(1)) && (
                  <Menu.Item onPress={() => setAutomatedCallsOpen(true)}>
                    {loading ? (
                      <LoadingSpinner />
                    ) : unitPeopleLink.unit.allowAutomatedCalls ? (
                      'Disable Automated calls'
                    ) : (
                      'Enable Automated Calls'
                    )}
                  </Menu.Item>
                )
              } */}
            </Menu>
          )}
        </View>
      </View>

      <Actionsheet isOpen={isOpen} onClose={onClose}>
        <Actionsheet.Content>
          <Box w="100%" h={60} px={4} justifyContent="center">
            <Text
              style={{
                fontSize: 16,
              }}>
              Request access for
            </Text>
          </Box>
          <Actionsheet.Item
            onPress={() => {
              onClose();
              setConfirmMode(E_CONFIRM_MODES.TODAY);
              setConfirmDialogShown(true);
            }}>
            Today
          </Actionsheet.Item>
          <Actionsheet.Item
            onPress={() => {
              onClose();
              setConfirmMode(E_CONFIRM_MODES.TOMMOROW);
              setConfirmDialogShown(true);
            }}>
            Tommorow
          </Actionsheet.Item>
          <Actionsheet.Item
            onPress={() => {
              onClose();
              onSelectCustom();
            }}>
            Custom time frame
          </Actionsheet.Item>
        </Actionsheet.Content>
      </Actionsheet>

      <ConfirmationDialog
        isOpen={automatedCallsOpen}
        onClose={onAutomatedCallsClose}
        onYesPressed={onAutomatedCallsConfirm}
        onNoPressed={onNoPressed}
        description={`Do you really want to ${message} automated calls ?`}
      />

      <ConfirmationDialog
        isOpen={editDialogOpen}
        onClose={onEditDialogClose}
        onYesPressed={onEditDialogConfirm}
        onNoPressed={onNoPressed}
        description={'Would you like to set calls start and stop time ?'}
      />
      <ConfirmationDialog
        isOpen={confirmDialogShown}
        onClose={() => setConfirmDialogShown(false)}
        onYesPressed={onConfirmRequestAccess}
        onNoPressed={() => setConfirmDialogShown(false)}
        description={`Would you like to request access for ${confirmMode} ?`}
      />
    </Card>
  );
};

export default UnitListItem;
