// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {View, ScrollView, TouchableOpacity} from 'react-native';
import Text from '../../../components/Text';
import {Navbar} from '../../../components';
import tailwind from 'tailwind-rn';
import {styles} from '../../../styles';
import {useSelector} from 'react-redux';
import {initialParkingState} from '../utils';
import Icon from 'react-native-vector-icons/Feather';
import DateTimeInput from '../../../components/DateTimeInput';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../service/flashMessage';
import {createParking, updateParking} from '../../../api/parking';
import {RootStore} from '../../../redux/store';
import FormContainer from '../../../components/FormContainer';
import VStack from '../../../components/VStack';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Container from '../../../components/Container';

const CreateParking = ({route, navigation}) => {
  const {parking} = route.params;
  const isInEditMode = parking != null;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const userId = useSelector((state: RootStore) => state.auth.user.id);
  const unitId = useSelector(
    (state: RootStore) => state.units.unitLinks,
  ).filter(link => link.isDefault === true)[0].unit.id;
  const [parkingState, setParkingState] = useState({
    ...initialParkingState,
    createdLogon: userId,
    lastLogon: userId,
    unitId: unitId,
  });
  const [numberOfDays, setNumberOfDays] = useState(0);

  useEffect(() => {
    if (isInEditMode) {
      setParkingState({...parkingState, ...parking});
      navigation.setOptions({
        title: 'Edit parking',
      });
    }
  }, []);

  const collectUserInput = async parkingData => {
    setIsSubmitting(true);
    try {
      isInEditMode
        ? await updateParking(parkingData, parkingData.id)
        : await createParking(parkingData);
      showSuccessMessage('Parking saved successfully');
      navigation.goBack();
    } catch (e) {
      showErrorMessage(e.message);
    }
    setIsSubmitting(false);
  };

  const handleStartDateConfirm = date => {
    setStartDatePickerVisible(false);

    const newDate = new Date(date);
    newDate.setDate(date.getDate() + parseInt(numberOfDays, 10));

    setParkingState({...parkingState, beginDate: date, endDate: newDate});
  };

  const handleEndDateConfirm = date => {
    setEndDatePickerVisible(false);
    const diffTime = new Date(date) - new Date(parkingState.startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    setNumberOfDays(diffDays);
    setParkingState({
      ...parkingState,
      endDate: new Date(date).toISOString(),
    });
  };

  return (
    <Container style={tailwind('flex-1')}>
        <Navbar navigation={navigation} title={ isInEditMode ? "Edit parking" : "Add parking"  }/>
        <FormContainer>
          <ScrollView
            style={tailwind('mx-2 my-4')}
            showsVerticalScrollIndicator={false}>
            <VStack style={tailwind('flex-1')}>
              <Text style={[styles.textInputLabel, tailwind('mt-4 flex-1')]}>
                Name
              </Text>
              <Input
                minWidth={300}
                style={tailwind('flex-1')}
                placeholder="Enter  name"
                value={parkingState.name}
                onChangeText={text =>
                  setParkingState({
                    ...parkingState,
                    name: text,
                  })
                }
              />
            </VStack>
            <DateTimeInput
              label="From"
              value={new Date(parkingState.beginDate)}
              onConfirm={date => {
                handleStartDateConfirm(date)
              }}
              mode='date'
            />
            <VStack>
              <Text style={[styles.textInputLabel, tailwind('mt-2')]}>
                Days
              </Text>
              <Input
                placeholder="Enter number of days"
                value={numberOfDays.toString()}
                keyboardType="number-pad"
                onChangeText={text => {
                  setNumberOfDays(parseInt(text === '' ? 0 : text, 10));
                  const date = new Date(parkingState.beginDate);
                  date.setDate(
                    date.getDate() + parseInt(text === '' ? 0 : text, 10),
                  );
                  setParkingState({...parkingState, endDate: date});
                }}
                minWidth={200}
                InputRightElement={
                  <TouchableOpacity
                    style={tailwind('px-4')}
                    onPress={() => {
                      let newDays = numberOfDays + 1;
                      setNumberOfDays(newDays);
                      const date = new Date(parkingState.beginDate);
                      date.setDate(date.getDate() + newDays);
                      setParkingState({...parkingState, endDate: date});
                    }}>
                    <Icon name="plus" size={24} color="#007bff" />
                  </TouchableOpacity>
                }
              />
            </VStack>
            <DateTimeInput
              label="To"
              value={new Date(parkingState.endDate)}
              onConfirm={date => {
                handleEndDateConfirm(date)
              }}
              mode='date'
            />
            <VStack style={tailwind('flex-1')}>
              <Text style={[styles.textInputLabel, tailwind('mt-4 flex-1')]}>
                Vehicle in space
              </Text>
              <Input
                minWidth={300}
                style={tailwind('flex-1')}
                placeholder="Enter vehicle in space"
                value={parkingState.vechileInSpace}
                onChangeText={text =>
                  setParkingState({
                    ...parkingState,
                    vechileInSpace: text,
                  })
                }
              />
            </VStack>
            <VStack style={tailwind('flex-1')}>
              <Text style={[styles.textInputLabel, tailwind('mt-4 flex-1')]}>
                Notes
              </Text>
              <Input
                minWidth={300}
                style={tailwind('flex-1')}
                multiline
                placeholder="Enter notes"
                value={parkingState.notes}
                onChangeText={text =>
                  setParkingState({
                    ...parkingState,
                    notes: text,
                  })
                }
              />
            </VStack>
          </ScrollView>
          <Button
            onPress={() => {
              collectUserInput(parkingState);
            }}
            style={tailwind('mx-2 mb-20')}
            isLoading={isSubmitting}
            py="4"
            isLoadingText="Submitting ...">
            {isInEditMode ? 'Update ' : 'Save'}
          </Button>
        </FormContainer>
    </Container>
  );
};

export default CreateParking;
