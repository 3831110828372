import {E_PERMISSIONS} from '../@types';

interface I_SWITCH {
  title: string;
  value: string;
}

type T_PERMISSION_SWITCH = {
  permissions: Array<I_SWITCH>;
  title: string;
};

export default function mapSwitchesToPermissionString(
  permissionSwitches: Array<T_PERMISSION_SWITCH>,
) {
  const peoplePermission = buildPermissionString(
    permissionSwitches,
    E_PERMISSIONS.PEOPLE,
  );
  const unitPermission = buildPermissionString(
    permissionSwitches,
    E_PERMISSIONS.UNIT,
  );
  const vehiclePermission = buildPermissionString(
    permissionSwitches,
    E_PERMISSIONS.VEHICLE,
  );
  const petPermission = buildPermissionString(
    permissionSwitches,
    E_PERMISSIONS.PET,
  );
  const storagePermission = buildPermissionString(
    permissionSwitches,
    E_PERMISSIONS.STORAGE,
  );
  const parkingPermission = buildPermissionString(
    permissionSwitches,
    E_PERMISSIONS.PARKING,
  );
  const violationsPermission = buildPermissionString(
    permissionSwitches,
    E_PERMISSIONS.VIOLATION,
  );
  const deliveryPermission = buildPermissionString(
    permissionSwitches,
    E_PERMISSIONS.DELIVERY,
  );
  const gateCardPermission = buildPermissionString(
    permissionSwitches,
    E_PERMISSIONS.GATE_CARD,
  );

  return {
    peoplePermission,
    unitPermission,
    vehiclePermission,
    petPermission,
    storagePermission,
    parkingPermission,
    violationsPermission,
    deliveryPermission,
    gateCardPermission,
  };
}

function buildPermissionString(
  permissionSwitches: Array<T_PERMISSION_SWITCH>,
  module: string,
) {
  const peoplePerm = permissionSwitches.filter((permSwitchStr) =>
    permSwitchStr.title.includes(module),
  )[0];

  let permStr = '';
  peoplePerm.permissions.map((item) => {
    permStr += item.value ? '1' : '0';
  });

  return permStr;
}
