import React, {useState} from 'react';
import {View} from 'react-native';
import {navigate} from '../navigation';
import Button from './Button';
import HStack from './HStack';
import IconButton from './IconButton';
import Text from '../components/Text';
import ConfirmationDialog from './ConfirmationDialog';

interface I_RegisiterActionBar_Props {
  step: number;
  hasSkip?: boolean;
  skipHandler?: ()=> void;
  onSkip?: ()=> void;
}

const RegisterActionBar = ({
  step,
  hasSkip,
  skipHandler,
  onSkip,
}: I_RegisiterActionBar_Props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [skipDialogOpen, setSkipDialogOpen] = React.useState(false);

  const onClose = () => setIsOpen(false);
  const onSkipDialogClose = () => setSkipDialogOpen(false);

  const onYesPressed = () => {
    navigate('Sign In', null);
  };

  const onNoPressed = () => {
    console.log('No');
  };

  return (
    <HStack
      style={{
        flex: 1,
        marginTop: 8,
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
      <View style={{ flex: 1}}>
        <IconButton
          icon={'close-outline'}
          size={30}
          onPress={() => setIsOpen(!isOpen)}
        />
      </View>
      <View style={{ flex: 1}}>
        <Text>{`${step} of 6 steps`}</Text>
      </View>
      {hasSkip && (
        <View style={{ flex: 1}}>
          <Button
            variant="ghost"
            onPress={() => setSkipDialogOpen(true) }>
            SKIP
          </Button>
        </View>
      )}
      <ConfirmationDialog
        isOpen={isOpen}
        onClose={onClose}
        onNoPressed={onNoPressed}
        onYesPressed={onYesPressed}
        description="Are you sure you wish to abort registration? Your data will not be saved."
      />
        <ConfirmationDialog
          isOpen={skipDialogOpen}
          onClose={onSkipDialogClose}
          onNoPressed={onNoPressed}
          onYesPressed={onSkip}
          title="Are you sure you wish to skip this step ? "
          description="This profile will not be approved by management if this information is not provided."
        />
    </HStack>
  );
};

export default RegisterActionBar;
