import AsyncStorage from '@react-native-async-storage/async-storage';
import {E_STORAGE_KEYS} from '../@types';
import {showErrorMessage} from '../service/flashMessage';
import {residentApiWithAuth} from './resident';

export async function registerDevice(authToken: string, deviceToken: string) {
  try {
    await residentApiWithAuth().get(`/people/registerPushyId/${deviceToken}`);
  } catch (error) {
    showErrorMessage(error.message);
  }
}

export async function deregisterDevice() {
  try {
    const savedTokenString = await AsyncStorage.getItem(
      E_STORAGE_KEYS.PUSHY_DEVICE_TOKEN,
    );
    const deviceTokenObj = savedTokenString
      ? JSON.parse(savedTokenString)
      : undefined;

    if (deviceTokenObj !== undefined) {
      const res = await residentApiWithAuth().get(
        `/people/deregisterPushyId/${deviceTokenObj.deviceToken}`,
      );
      await AsyncStorage.removeItem(E_STORAGE_KEYS.PUSHY_DEVICE_TOKEN);
    } else {
      Promise.resolve();
    }
  } catch (error) {
    throw new Error(error);
  }
}

export async function sendAck(ackData) {
  try {
    const savedTokenString = await AsyncStorage.getItem(
      E_STORAGE_KEYS.PUSHY_DEVICE_TOKEN,
    );
    const deviceTokenObj = savedTokenString
      ? JSON.parse(savedTokenString)
      : undefined;

    if (deviceTokenObj !== undefined) {
      let res = await residentApiWithAuth().post(
        'alertDetail/person/updateAction',
        ackData
      );
      const result = res.data;
      if (result.errors) {
        throw new Error(result.errors[0]);
      } else {
        return result.data;
      }
    }
   
  } catch (error) {
    console.log(JSON.stringify(error));
    throw new Error(error);
  }
}
