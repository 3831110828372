import AsyncStorage from '@react-native-async-storage/async-storage';

const storeToken = async (token) => {
  try {
    await AsyncStorage.setItem('token', token);
  } catch (e) {
    throw new Error(e.message);
  }
};

const getToken = async () => {
  let token = '';
  try {
    token = await AsyncStorage.getItem('token');
  } catch (e) {
    throw new Error(e.message);
  }
  return token;
};

const storeRememberMe = async (rememberMe) => {
  try {
    await AsyncStorage.setItem('rememberMe', rememberMe);
  } catch (e) {
    throw new Error(e.message);
  }
};

const getRememberMe = async () => {
  let rememberMe = 'false';
  try {
    rememberMe = await AsyncStorage.getItem('rememberMe');
  } catch (e) {
    throw new Error(e.message);
  }
  return rememberMe;
};

const storeUser = async (user) => {
  try {
    const userJson = JSON.stringify(user);
    await AsyncStorage.setItem('user', userJson);
  } catch (e) {
    throw new Error(e.message);
  }
};

const getUser = async () => {
  let user = null;
  try {
    const userJson = await AsyncStorage.getItem('user');
    user = JSON.parse(userJson);
  } catch (e) {
    throw new Error(e.message);
  }
  return user;
};

/**
 * Persist default  unit
 * @param {*} data
 * @returns
 */
const storeUnit = async (data) => await persistInLocalStorage('unit', data);

/**
 * Retrieve default  unit
 * @param {*} key
 * @returns
 */
const retrieveUnit = async () => await getFromLocalStorage('unit');

const clearLocalStorage = async () => {
  try {
    // await AsyncStorage.removeItem('rememberMe');
    // await AsyncStorage.removeItem('user');
    await AsyncStorage.removeItem('token');
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Generic function to store data in local storage
 * @param {*} key
 * @param {*} data
 */
const persistInLocalStorage = async (key, data) => {
  try {
    const stringData = JSON.stringify(data);
    await AsyncStorage.setItem(key, stringData);
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Generic function to retrieve data from local storage
 * @param {*} key
 * @returns
 */
const getFromLocalStorage = async (key) => {
  let data = null;
  try {
    const userJson = await AsyncStorage.getItem(key);
    data = JSON.parse(userJson);
  } catch (e) {
    throw new Error(e.message);
  }
  return data;
};

export {
  storeToken,
  getToken,
  storeRememberMe,
  getRememberMe,
  storeUser,
  getUser,
  clearLocalStorage,
  storeUnit,
  retrieveUnit,
};
