/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import {View} from 'react-native';
import {colorSwatches} from '../styles';
import Text from '../components/Text';

export default function StatusPill({
  status,
}: {
  status: 'Granted' | 'Denied' | null;
}) {
  return (
    <View
      style={{
        backgroundColor:
          status === 'Denied'
            ? colorSwatches.red[400]
            : colorSwatches.green.color,
        alignSelf: 'flex-start',
        borderWidth: 2,
        borderRadius: 12,
        borderColor:
          status === 'Denied'
            ? colorSwatches.red[400]
            : colorSwatches.green.color,
      }}>
      <Text
        style={{
          paddingHorizontal: 4,
          paddingVertical: 1,
          color: colorSwatches.white.color,
          fontSize: 11,
        }}>
        {status}
      </Text>
    </View>
  );
}
