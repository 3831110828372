const initialLinkState = {
  unitId: '',
  unitNickname: '',
  firstName: '',
  lastName: '',
  company: '',
  profilePicture: '',
  useProfileAddress: false,
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zip: '',
  phone: '',
  email: '',
  lInkApproved: 'Pending',
  peopleId: '',
  peopleType: '',
  disabled: false,
  disabledDate: null,
  disabledreason: '',
  emergencyContact: true,
  needsHelpInEmergency: true,
  resident: false,
  // autoCheckIn: false,
  // autoCheckOut: true,
  // residentDefault: false,
  // residentCanAdd: true,
  officeNotes: '',
  guardNotes: '',
  canManagePeople: true,
  allowedToChekoutKey: true,
  allowedToClaimPackages: true,
  allowedToAuthorizeEntry: true,
  mailTo: true,
  alwaysNeedsEntrancePermission: true,
  canViewLog: true,
  accessInstructions: '',
  relationship: '',
  unitPermission: '',
  peoplePermission: '',
  vehiclePermission: '',
  petPermission: '',
  storagePermission: '',
  parkingPermission: '',
  violationsPermission: '',
  directoryOptOut: true,
  directoryScope: 'Site',
  directoryDoNotShareEmail: true,
  directoryDoNotSharePhone1: true,
  alwaysCall: true,
  peopleTypeId: null,
};

const directoryScopes = ['Unit', 'Community', 'Building', 'Site'];

const initialUnitPeopleConnectorState = {
  // peopleId: '',
  expiration: new Date(
    new Date().setHours(new Date().getHours() + 24),
  ).toISOString(),
  note: '',
  // unitId: '',
};

const initialLinkRequestState = {
  unitId: '',
  eventName: 'ResidentAcess',
  note: '',
  alertArguments: '',
  peopleType: '',
  dateFrameId: '',
};

const dataFrameInitialState = {
  unitId: '',
  type: '',
  startDate: new Date().toISOString(),
  endDate: new Date().toISOString(),
  shortDescription: '',
  notes: '',
  // status: '',
  // projectId: '',
  // reservationId: '',
  overlapAllowed: true,
};

export {
  initialLinkState,
  directoryScopes,
  initialUnitPeopleConnectorState,
  initialLinkRequestState,
  dataFrameInitialState,
};
