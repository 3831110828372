import React from 'react';
import {styles} from '../../../styles';
import {View} from 'react-native';
import Text from '../../../components/Text';

const ResponseBadge = ({action}) => {
  let response =
    action === 1
      ? 'Approved'
      : action === 2
      ? 'Not Approved'
      : action === 5
      ? 'Approved by Guard'
      : action === 6
      ? 'Not Approved By Guard'
      : null;

  const responseStyle = response?.startsWith('N')
    ? styles.alertResponseDanger
    : styles.alertResponseSuccess;

  return (
    <View style={responseStyle}>
      <Text>{response}</Text>
    </View>
  );
};

export default ResponseBadge;
