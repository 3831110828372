import React from 'react';
import {
  CardStyleInterpolators,
  createStackNavigator,
} from '@react-navigation/stack';
import {makeId} from '../../utils';
import {E_NAVIGATION_DESTINATIONS} from '../../@types';
import { CreatePet, EditPet, PetDetail, Pets } from './screens';

const Stack = createStackNavigator();

const PetsNavigator = () => {
  const options = {
    headerShown: false,
    cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
  };

  return (
    <Stack.Navigator>
      <Stack.Screen
        name={E_NAVIGATION_DESTINATIONS.PETS_LIST}
        component={Pets}
        options={{...options, animationTypeForReplace: 'pop'}}
        key={makeId(12)}
      />
      <Stack.Screen
        name={E_NAVIGATION_DESTINATIONS.PET_ADD}
        component={CreatePet}
        options={{...options, animationTypeForReplace: 'pop'}}
        key={makeId(12)}
      />
      <Stack.Screen
        name={E_NAVIGATION_DESTINATIONS.PET_EDIT}
        component={EditPet}
        options={{...options, animationTypeForReplace: 'pop'}}
        key={makeId(12)}
      />
      <Stack.Screen
        name={E_NAVIGATION_DESTINATIONS.PET_DETAILS}
        component={PetDetail}
        options={{...options, animationTypeForReplace: 'pop'}}
        key={makeId(12)}
      />
    </Stack.Navigator>
  );
};

export const petScreens = {
  [E_NAVIGATION_DESTINATIONS.PETS_LIST] :"/petsList",
  [E_NAVIGATION_DESTINATIONS.PET_ADD] :"/petsAdd",
  [E_NAVIGATION_DESTINATIONS.PET_EDIT] :"/petEdit",
  [E_NAVIGATION_DESTINATIONS.PET_DETAILS] :"/petDetails",
}

export default PetsNavigator;
