const SET_LOADING = 'SET_LOADING';

export const setLoading = (loading) => ({
  type: SET_LOADING,
  loading,
});

export const loadingReducer = (state = true, action) => {
  if (action.type === SET_LOADING) {
    return action.loading;
  }
  return state;
};
