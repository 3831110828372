/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import {View} from 'react-native';
import Ionicon from 'react-native-vector-icons/Ionicons';
import {colorSwatches} from '../../styles';
import Text from '../../components/Text';

export default function PictureZoomHeader() {
  return (
    <View
      style={{
        margin: 8,
        backgroundColor: colorSwatches.blue[200],
        borderRadius: 4,
      }}>
      <View
        style={{
          padding: 8,
          flexDirection: 'row',
          alignItems: "center"
          // flex: 1,
        }}>
        <Ionicon
          name="information-circle-outline"
          size={24}
          color={colorSwatches.blue[500]}
        />
        <Text
          style={{
            marginLeft: 8,
          }}>
          Pinch to zoom the image
        </Text>
      </View>
    </View>
  );
}
